import React, { useEffect, useState } from "react";
import "../style/Blog.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Blogs() {
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // Step 3: Set loading state to true before fetching data
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/getBlogs`
          // "https://server-dot-path2study.el.r.appspot.com/getBlogs"
        );
        setBlogData(response.data);
        setIsLoading(false); // Step 3: Set loading state to false after fetching data
        // console.log("blog-data --------->", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCardClick = (blog) => {
    window.scrollTo(0, 0);
    const modifiedTitle = blog.titleOfTheBlog.replaceAll(" ", "-");
    navigate(`/blogs/${modifiedTitle}`, { state: blog });
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      ) : blogData && blogData.length !== 0 ? (
        <div className="blog mt-5 pt-5 pb-5">
          <div className="container">
            <h2 className="px-lg-4 mb-lg-3 mt-md-4 mt-3">
              Latest from our blog
            </h2>
            <div className="d-flex flex-wrap  mt-lg-4 mt-3 pb-lg-5 mb-lg-5 ">
              {blogData.map((blog) => {
                return (
                  <>
                    <div
                      className="text-dark cursor-pointer  mb-2 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0 col-12 col-lg-4 pe-lg-4 px-lg-4 "
                      onClick={() => handleCardClick(blog)}
                    >
                      <div className=" ">
                        <img
                          src={blog.coverImage}
                          alt=""
                          height={200}
                          width="100%"
                          className="rounded-5"
                          onClick={() => window.scrollTo(0, 0)}
                        />
                      </div>
                      <h4 className="mt-md-3 mt-3">{blog.titleOfTheBlog}</h4>
                      <div className="d-flex flex-wrap justify-content-between mt-2 mb-4">
                        <span className="text-secondary col-2  ">
                          {new Date(blog.updatedAt).toLocaleDateString()}
                        </span>

                        <ul className="text-secondary col-9 ">
                          <li>
                            {blog.author !== "" ? blog.author : "Path2Study"}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="text-center mt-5 pt-lg-5">
              <img
                src={require("../assests/img/cloud.png")}
                alt=""
                className="img-fluid col-md-1 col-3 "
                onClick={() => window.scrollTo(0, 0)}
              />
              <p className="text-primary fs-4">
                More blog content coming soon!{" "}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="">
            <div
              className="container  "
              style={{ marginTop: "18rem", marginBottom: "12.3rem" }}
            >
              <div className=" text-center px-2 ">
                <div className="border py-2 px-5 rounded-4">
                  <p className="fs-3">
                    {" "}
                    <img
                      src={require("../assests/img/cloud.png")}
                      alt=""
                      className="img-fluid col-md-1 col-3 "
                      onClick={() => window.scrollTo(0, 0)}
                    />
                    <p className=" mt-3 text-center px-3">
                      No blogs yet, stay tuned ! <br />
                      We have some great content coming your way.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Blogs;
