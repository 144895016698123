import React from "react";
import { Link } from "react-router-dom";

const AboveFooter = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    // <div>
    <div className="container mt-5">
      <div className="card mb-3 border border-white">
        <div className="row g-6">
          <div className="col-md-5">
            <img
              src={require("../assests/img/abovefooter1.png")}
              className="img-fluid rounded-5"
              alt="..."
            />
          </div>

          <div className="col-md-7">
            <div className="card-body">
              <h5 className="card-title fs-1 mb-4">
                <span className="fs-1 text  me-2">
                  INVEST IN YOUR TOMORROW: DISCOVER THE
                  <span className="text-primary fw-semibold">
                    {" "}
                    SCHOLARSHIPS AND LOANS{" "}
                  </span>
                  OPTIONS THAT ARE RIGHT FOR YOU{" "}
                </span>
              </h5>

              <div className="d-lg-flex">
                <Link className="col-6 pe-lg-2" to="/loan">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 mb-2  abovefooter-button-custom"
                    onClick={handleClick}
                  >
                    SEARCH LOANS
                  </button>
                </Link>
                <Link className="col-6 px-lg-2 " to="/pathstudyscholarship">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 mb-1 abovefooter-button-custom "
                    onClick={handleClick}
                  >
                    FIND SCHOLARSHIPS
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default AboveFooter;
