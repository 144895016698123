import "../../style/signUpmodal.css";
import React, { useState, useContext, useRef, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { ModalContext } from "../../contexts/ModalContext";
// import SignInModal from "./SignInModall";
// import { ToastContainer, toast } from "react-toastify";

const MultiStepModal = () => {
  const {
    // openModal,
    // isOpen,
    // setIsOpen,
    // signUpOpen,
    // setSignUpOpen,
    // // logout,
    // showSigninModal,
    // setShowSigninModal,
    steps,
    // closeModal,
    // handleNext,
    // handlePrevious,
    currentStep,
    // setCurrentStep,
    allCloseModal,
  } = useContext(ModalContext);

  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // closeModal();
        // allCloseModal()
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [allCloseModal]);

  return (
    // backdrop="static" to make modal unclose untill form is submitted
    // n the MultiStepModal component, the backdrop="static" attribute is added to the Modal component. This prevents the modal from being closed when clicking outside (backdrop="static" makes the modal static and does not close it when clicking outside).size="md"
    <>
      {/* <Modal show={true} onHide={allCloseModal} > original */}
      <Modal
        show={true}
        onHide={allCloseModal}
        backdrop="static" // not close on click outer the modal
      >
        <div ref={modalRef}>
          {/* <Modal.Header closeButton> */}
          <Modal.Header className="d-flex justify-content-center modal-header-signUp">
            <Modal.Title>
              <span className="fs-1 mx-auto">{steps[currentStep].title}</span>
            </Modal.Title>
            <button
              type="button"
              className="btn-close close-button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={allCloseModal}
            ></button>
          </Modal.Header>

          <p className="mx-5 text-center fs-6 mb-2">
            Path2Study gives you authentic information on loans and scholarship
            to help you achieve your education goals. Let’s create your profile
            on Path2Study.
          </p>

          <Modal.Body>
            {/* Dynamic components */}
            {steps[currentStep].component}
          </Modal.Body>
          {/* <Modal.Footer>
          {currentStep > 0 && (
            <Button
              variant="secondary"
              // onClick={handlePrevious}
            >
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 ? (
            <Button variant="primary" onClick={handleNext}>
              Next
            </Button>
          ) : (
            <Button variant="primary" onClick={closeModal}>
              Save changes
            </Button>
          )}
        </Modal.Footer> */}
        </div>
      </Modal>
    </>
  );
};
export default MultiStepModal;

//  // const handleNext = () => {
//   if (currentStep < steps.length - 1) {
//     setCurrentStep(currentStep + 1);
//   }
// };

// const handlePrevious = () => {
//   if (currentStep > 0) {
//     setCurrentStep(currentStep - 1);
//   }
// };
// // Usage example:
// const App = () => {
//   const steps = [
//     { title: 'Step 1', content: 'Content for step 1' },
//     { title: 'Step 2', content: 'Content for step 2' },
//     { title: 'Step 3', content: 'Content for step 3' }
//   ];

//   const [showModal, setShowModal] = useState(false);

//   const openModal = () => {
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//   };

//   return (
//     <div>
//       <Button variant="primary" onClick={openModal}>
//         Open Modal
//       </Button>
//       {showModal && (
//         <MultiStepModal steps={steps} handleClose={closeModal} />
//       )}
//     </div>
//   );
// };

// export default App;
