import "bootstrap-icons/font/bootstrap-icons.css";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
// import { API_BASE_URL } from "../appConfig";
import { convertISOToHumanReadable } from "../hooks/convertdate";
import { toast } from "react-toastify";
import { UserContext } from "../contexts/UserContext";
import { handleBackNavigation } from "../hooks/handleBackNavigation";
import Modal from "react-bootstrap/Modal";
import "../style/scholarshipinformation.css";
import { formatAmount } from "../hooks/useFormattedAmount";
// import { BsInfoCircle } from "react-icons/bs";

const ScholarshipInformation = () => {
  const [currency, setCurrency] = useState('');
  const [showPopupSubmitted, setShowPopupSubmitted] = useState(false);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [count, setCount] = useState(10);
  const [redirect, setDirect] = useState(true);
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);

  // const [hover, setHover] = useState(false); //  i tooltip

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {
        clearInterval(intervalId); // Stop the countdown when it reaches 0
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [count]);

  const [scholarship, setScholarship] = useState([]);

  const [minRequirement, setMinRequirement] = useState([]);

  const [SplittedText, setSplittedText] = useState([]);

  const { isUserLoggedIn } = useContext(UserContext);

  const { id } = useParams();
  const getScholData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const userToken = localStorage.getItem("userToken");
      const updatedToken = `Bearer ${userToken} `;
      const authAxios = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        // baseURL: API_BASE_URL,
        headers: {
          authorization: updatedToken,
        },
      });
      const response = await authAxios.get(
        `${process.env.REACT_APP_BASE_URL}/getScholarship/${userId}?scholarshipId=${id}`
        // `${API_BASE_URL}/getScholarship/${userId}?scholarshipId=${id}`
      );

      setScholarship(response.data.scholarships[0]);
      setCurrency(response.data.currency)
      const fetchedMinRequirement =
        response.data.scholarships[0].minimumRequirements || "";
      setMinRequirement(fetchedMinRequirement);

      const dottext = fetchedMinRequirement
        ? fetchedMinRequirement
            .split("\n")
            .filter(Boolean)
            .map((item) => item.trim())
        : [];

      setSplittedText(dottext);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getScholData();
  }, []);

  const handleClose = () => {
    setShowPopupConfirm(false);
    setDirect(true);
  };
  const handleCloseInAfterApply = () => {
    setShowPopupSubmitted(false);
    setShowPopupConfirm(false);
    setDirect(true);
  };
  const handleConfirmModal = () => {
    setShowPopupSubmitted(false);
    setShowPopupConfirm(true);
  };

  const handleApply = async () => {
    if (!isUserLoggedIn) {
      toast("Please Login First", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      try {
        setShowPopupConfirm(false);

        const userId = localStorage.getItem("userId");
        const userToken = localStorage.getItem("userToken");
        const updatedToken = `Bearer ${userToken} `;
        const authAxios = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            authorization: updatedToken,
          },
        });
        const response = await authAxios.put(
          `${process.env.REACT_APP_BASE_URL}/applyToScholarship/${userId}/${id}`
          // `${API_BASE_URL}/applyToScholarship/${userId}/${id}`
        );
        if (response.data.message === "Applied sucessfully") {
          setShowPopupSubmitted(true);
        }
        if (
          response.data.message ===
          "You have already applied for this scholarship"
        ) {
          // setShowPopupSubmitted(false);
          // setShowPopupSubmitted(true)

          toast(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          handleClose();
        }

        // write the code for modal show applied success fully
      } catch (error) {
        console.error(error);
      }
    }
  };

  const closeModal = () => {
    setShowPopupConfirm(false)
  };

  return (
    <>
      <div style={{ marginTop: "5.9rem" }}>
        <div className="container my-5 ">
          <div className="my-2">
            {/* <Link
              to="/pathstudyscholarship"
              className="text-dark text-decoration-none"
            >
              <i className="bi bi-arrow-left "></i> All Path2Study Scholarships
            </Link> */}
            <button
              onClick={handleBackNavigation}
              className="text-dark text-decoration-none btn"
            >
              <i className="bi bi-arrow-left "></i> Back
            </button>
          </div>
          <div className="row bg-light ">
            <div className="col-md-8">
              <div className="px-md-5 px-2 pt-5 bg-light ">
                <div className="row ">
                  <span className="fs-2 mb-md-5 mb-2">
                    Path2study Scholarship
                    {/* Texas Chapter of the Wildlife Society <br />
                  Undergraduate Scholarship */}
                    {/* {scholarship?.scholarshipName} - ${scholarship?.amount} */}
                  </span>
                  {/* <div></div> */}
                  <span className="fs-4 fw-light text ">
                    {scholarship?.scholarshipName} -{" "}
                    <span className="text-primary">
                    {scholarship?.currency ? scholarship?.currency : "$"} {formatAmount(scholarship?.amount)}
                    </span>
                    {/*<span
                        className=""
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                      >
                        <BsInfoCircle className="py-1 mt-1 ms-2" size={25} />
                      </span> 
                     <div>
                      <div className="position-relative">
                        {hover && (
                          <div className="">
                            <div className="card" >
                              <span className="fs-6 p-2">
                                By applying to the scholarship you've granted us
                                your consent to add the winners testimonial on
                                our website.
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div> */}
                  </span>
                  <span className="mt-2">
                    {scholarship?.description}
                    {/* {scholarship?.minimumRequirements} */}
                  </span>

                  <div className="d-flex flex-column mt-2">
                    <div className="d-flex">
                      <div>
                        <p>Deadline:-</p>
                        <p>Eligibility:-</p>
                      </div>
                      <div className="ms-4">
                        <p>
                          {scholarship?.Deadline &&
                            convertISOToHumanReadable(scholarship?.Deadline)}
                        </p>

                        <p>Open for all</p>
                        {/* <p>December 18, 2023</p>
                      <p>03</p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <span className="fs-4 fw-light text">
                    Eligibility Requirements
                  </span>
                  <div className="d-flex ">
                    {/* dynamic data what info provided will shown here */}
                    <div>
                      <>
                        {SplittedText?.map((item, index) => (
                          <p key={index}>{item}</p>
                        ))}
                      </>
                    </div>
                  </div>
                </div>
                <div>
                  {/* new code  */}
                  <button
                    // variant="outline-primary"
                    className="btn btn-primary my-4 button-custom"
                    // onClick={handleApply} // commented for not opening quetionaire changed logic apply
                    onClick={() => setShowPopupConfirm(true)}
                    // onClick={handleConfirmModal}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
            {/* Destop version */}
            <div className="col-6 col-md-4 d-none d-md-flex align-items-center ">
              <div
                className="scholarshipinformation-card card bg-light border p-2"
                style={{ marginTop: "7rem" }}
              >
                <img
                  src={require("../assests/img/scholinfo.png")}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">
                    <span className="fs-3 text">FIND THE BEST</span> <br />{" "}
                    <span className="fs-3 text-primary">EDUCATION LOANS</span>{" "}
                    <br />
                    <span className="fs-3 text">FOR YOUR </span>
                    <span className="fs-3 text-primary">DEGREE</span>
                  </h5>
                  <p className="card-text mb-3">
                    Explore array of loan options tailored <br /> to fund your
                    educational journey <br />
                    effortlessly.
                  </p>
                  <Link
                    to="/loan"
                    className="btn btn-outline-primary w-100 "
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Explore Loans Options
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Mobile version */}
          <div className="d-md-none flex-column ">
            <div
              className="scholarshipinformation-card card bg-light "
              style={{ marginTop: "2rem" }}
            >
              <img
                src={require("../assests/img/scholinfo.png")}
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title">
                  FIND THE BEST EDUCATION LOANS FOR YOUR DEGREE
                </h5>
                <p className="card-text">
                  Explore array of loan options tailored to fund your
                  educational journey effortlessly.
                </p>
                <Link
                  to="/loan"
                  className="btn btn-outline-primary w-100 "
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --------modal ----------*/}
      <Modal show={showPopupSubmitted} onHide={handleClose} centered>
        <Modal.Body>
          <div className="text-center">
            {/* <FcApproval  size={120} /> */}
            <img
              src={require("../assests/img/Featuredicon.png")}
              className="img-fluid"
              width={"15%"}
              height={"25%"}
            />
          </div>
          <h3 className="text-center">Application Submitted Successfully</h3>
          <p className="text-center fs-6" style={{ color: "#667085" }}>
            Congratulations! Your scholarship application has been successfully
            submitted. You have successfully applied to the scholarship by
            PATH2STUDY. To track the status visit your profile page.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            button
            type="button"
            class="btn btn-secondary px-4"
            onClick={handleCloseInAfterApply}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/*  confirm modal */}
      <Modal show={showPopupConfirm} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Submit Application</Modal.Title>
          <button
            type="button"
            className="btn-close close-button-info "
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </Modal.Header>

        {/* <Modal.Body> */}
        <p className="px-3">
          <p>
            Are you sure you want to apply to the{" "}
            <span className="fw-semibold">
              "{scholarship?.scholarshipName}"
            </span>{" "}
            ?
          </p>
          {/* <p>Are you sure you want to apply for the scholarship?</p> */}
        </p>
        {/* </Modal.Body> */}
        <Modal.Footer>
          <button className="btn btn-outline-secondary " onClick={handleClose}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleApply}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScholarshipInformation;
