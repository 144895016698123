export function formatDate(inputDate) {
    // Parse the input date string
    const parts = inputDate.split('-');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
  
    // Create a new Date object using the parsed values
    const formattedDate = new Date(`${year}-${month}-${day}`);
  
    // Get month name
    const monthName = formattedDate.toLocaleString('default', { month: 'long' });
  
    // Get day and year
    const formattedDay = formattedDate.getDate();
    const formattedYear = formattedDate.getFullYear();
  
    // Construct the final formatted date string
    const result = `${monthName} ${formattedDay} ${formattedYear}`;
  
    return result;
  }


  export function convertISOToHumanReadable(isoDateString) {
    // Parse the ISO date string to create a Date object
    const date = new Date(isoDateString);
  
    // Define options for formatting
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
  
    // Format the Date object using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  
    return formattedDate;
  }
