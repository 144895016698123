import React, { useEffect, useState } from "react";
// import CityModal from "./CityModal";
// import { API_BASE_URL } from "../appConfig";
import axios from "axios";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/pagination";
// import profileImage from "../adminPortal/assets/img/companyLogo.png";
import "../style/citybar.css";

const CityBar = () => {
  const [cardData, setCardData] = useState([]);
  const [selectedCity, setSelectedCity] = useState(""); // State to track the selected city
  const [openModal, setOpenModal] = useState(false);

  const handleImageOpen = (cityname) => {
    // console.log("Clicked the image", cityname);
    setOpenModal(true);
    // setSelectedCity(citydata);

    const fetchCityData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/getcities?cityName=${cityname}`
          // `${API_BASE_URL}/getcities?cityName=${cityname}`
        );
        // console.log("api hit", response.data.loans.map(item=> item.isActive ===true));

        setSelectedCity(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCityData();
  };

  useEffect(() => {
    const fetchCityData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getcities`);
        // const response = await axios.get(`${API_BASE_URL}/getcities`);
        // console.log("api hit", response.data.loans.map(item=> item.isActive ===true));
        // console.log("city dta", response.data);
        setSelectedCity(response.data);
        // console.log("selectedCity", selectedCity);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCityData();
  }, []);

  return (
    <>
      <div>
        <div className="container my-4 ">
          {/* <h2 className=" text-primary">POPULAR CITIES</h2> <h2 className="fs-4 text ">TO STUDY</h2>  */}
          <span className="fs-3 text fw-semibold text-primary">
            POPULAR CITIES
          </span>{" "}
          <span className="fs-3 text ">TO STUDY</span>
          {/* <button
        type="button"
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#myModal"
        onClick={() => handleImageOpen()}
      >
        Open Modal
      </button> */}
          {/* <div className="row row-cols-1 row-cols-md-3 g-4"> */}
          {/*  remaining responsive mobile */}
          <div className="d-flex flex-wrap justify-content-between ">
            <div className="col-12 col-lg-3">
              <div className=" h-100 text-center ">
                <button
                  onClick={() => handleImageOpen("CHICAGO")}
                  className="btn btn-outline-light"
                  data-bs-toggle="modal"
                  data-bs-target={`#citymodal`}
                  key={`trigger`}
                  // data-bs-target={`#citymodal-${item._id}`}
                  // key={`trigger-${item._id}`}
                >
                  <img
                    src={require("../assests/img/chocago.png")}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </button>
              </div>
            </div>

            <div className="col-12 col-lg-3 mt-3  mt-lg-0">
              <div className=" h-100">
                <button
                  onClick={() => handleImageOpen("NEW YORK")}
                  className="btn btn-outline-light"
                  data-bs-toggle="modal"
                  data-bs-target={`#citymodal`}
                  key={`trigger`}
                >
                  <img
                    src={require("../assests/img/newyork.png")}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </button>
              </div>
            </div>

            <div className="col-12 col-lg-3 mt-3  mt-lg-0">
              <div className=" h-100">
                <button
                  onClick={() => handleImageOpen("SAN FRANCISCO")}
                  className="btn btn-outline-light"
                  data-bs-toggle="modal"
                  data-bs-target={`#citymodal`}
                  key={`trigger`}
                >
                  <img
                    src={require("../assests/img/sanfancisco.png")}
                    className="card-img-top img-fluid  "
                    alt="..."
                  />
                </button>
              </div>
            </div>

            <div className="col-12 col-lg-3 mt-3 mt-lg-0 ">
              <div className=" h-100">
                <button
                  onClick={() => handleImageOpen("LOS ANGELES")}
                  className="btn btn-outline-light"
                  data-bs-toggle="modal"
                  data-bs-target={`#citymodal`}
                  key={`trigger`}
                >
                  <img
                    src={require("../assests/img/lossangeles.png")}
                    className="card-img-top img-fluid  "
                    alt="..."
                  />
                </button>
              </div>
            </div>
          </div>

          {/* mobile swipeer for city bar */}
          {/* <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        autoplay={{ delay: 5000 }}
        className="d-lg-none d-block"
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
        breakpoints={{
          280: { slidesPerView: 1 },
          350: { slidesPerView: 2 },
          480: { slidesPerView: 2 },
          576: { slidesPerView: 3 },
          768: { slidesPerView: 3 },
          992: { slidesPerView: 3 },
          1200: { slidesPerView: 4 },
          1400: { slidesPerView: 4 },
        }}
      >
        <div className="d-flex flex-wrap">
          {cardData?.map((card, i) => (
            <SwiperSlide key={i} className="">
              <div id="card-mobile" className=" border rounded-5 border-1 ">
                {card.user !== null ? (
                  <img
                    // src={card.user === null ?( profileImage) : (card.user?.profileImg !== null ? card.user?.profileImg : profileImage )}
                    src={
                      card.user?.profileImg !== null && card.user?.profileImg
                        ? card.user?.profileImg
                        : profileImage
                    }
                    className=" rounded-5 images"
                    height={275}
                    width={350}
                    alt="Profile Image 1 "
                  />
                ) : (
                  "no usr found"
                )}

                <img
                  src={require("../assests/img/BG (1).png")}
                  alt=""
                  className="img-fluid images-mobile rounded-5"
                />

                <div className="images-mobile px-3 col-12">
                  <div className="d-flex flex-wrap justify-content-between">
                    <h6 className="text-white col-8 mb-0">
                      {card.scholarship.scholarshipName}
                    </h6>
                    <h6 className="text-white col-4 text-end mb-0">
                      Won ${card.scholarship.amount}
                    </h6>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between mt-1">
                    <p className="text-white col-9 mb-0">
                      {card.scholarship.scholarshipProvider}
                    </p>
                    <p className="text-white col-3 text-end mb-0 pb-0">
                      {" "}
                      {new Date(
                        card.scholarship.createdAt
                      ).toLocaleDateString()}
                    </p>
                  </div>
                  <p className="text-white mt-0 mt-1">
                    Applicants :&nbsp;
                    <span className="">
                      {card.scholarship.appliedUsers.length}
                    </span>
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper> */}
        </div>
      </div>

      {/* {openModal && <CityModal citydata={selectedCity} />} */}
      {/* cousing Cannot read property 'backdrop' of undefined error */}

      <div
        className="modal fade"
        id="citymodal"
        tabIndex="-1"
        aria-labelledby="citymodalLabel"
        // id={`citymodal-${citydata._id}`}
        // aria-labelledby={`citymodalLabel${citydata._id}`}
        key={`modal-${selectedCity._id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <img
                      src={selectedCity.img}
                      // src={require("../assests/img/newyorkmodal.png")}

                      // src={banner.length > 0 ? banner[0] : ""}
                      alt="logo ov city"
                      className="img-fluid "
                    />
                  </div>
                  <div className="col-md-6">
                    <p className="fw-semibold text-uppercase mt-4 fs-1">
                      {selectedCity?.cityName}
                    </p>
                    <span className="">{selectedCity?.description}</span>
                    <div className="mt-3">
                    <li>{selectedCity?.top5Universities?.uni1}</li>
                    <li>{selectedCity?.top5Universities?.uni2}</li>
                    <li>{selectedCity?.top5Universities?.uni3}</li>
                    <li>{selectedCity?.top5Universities?.uni4}</li>
                    <li>{selectedCity?.top5Universities?.uni5}</li>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CityBar;
//   npm i swiper
