import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../styles/ListApplicants.css";
import DatePicker from "react-datepicker";
// import { API_BASE_URL } from "../../appConfig";
function ListAppliacnt() {
  let adminId = localStorage.getItem("adminId");
  let adminToken = localStorage.getItem("adminToken");
  const headers = {
    authorization: `bearer ${adminToken}`,
  };
  const [visitors, setVisitors] = useState([]);
  const [download, setDownload] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [listLoader, setListLoader] = useState(true);
  // {
  //   userName : 'Vijay',
  //   date : new Date()
  // }

  const location = useLocation();
  const loan = location.state;
 

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/getVisiters/${adminId}?loanId=${loan._id}`,
        {
          headers: headers,
        }
      )

      .then((response) => {
        setVisitors(response.data);
        // console.log("location",location.state)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setListLoader(false);
      });
  }, []);

  const [currentPageUserManagment, setCurrentPageUserManagment] = useState(1);
  const recordsPerPageUserManagment = 10;

  // Calculate the index range for the current page
  const startIndexUserManagment =
    (currentPageUserManagment - 1) * recordsPerPageUserManagment;
  const endIndexUserManagment =
    startIndexUserManagment + recordsPerPageUserManagment;

  // Slice the data based on the current page
  const visibleDataUserManagment = visitors?.slice(
    startIndexUserManagment,
    endIndexUserManagment
  );

  // Calculate the total number of pages
  const totalPagesUserManagment = Math.ceil(
    visitors?.length / recordsPerPageUserManagment
  );

  const forSerialNumberUserManagemnt =
    recordsPerPageUserManagment * (currentPageUserManagment - 1);

  // Function to handle page change
  const handlePageChangeUserManagment = (newPageTwo) => {
    setCurrentPageUserManagment(newPageTwo);
  };

  const handleDownloadClick = () => {
    window.open(
      `https://server-dot-path2study.el.r.appspot.com/admin/getVisiters/${adminId}?loanId=${loan._id}&XL=1&startDate=${startDate}&endDate=${endDate}`,
      "_blank"
    );
  };

  return (
    <>
      {listLoader ? (
        <div>
          <div className="col-10  py-3 px-3">
            <div className="text-center mt-3">
              {/* <div className="spinner-border text-secondary" role="status">
                  <div className="sr-only text-center">Loading...</div>
                </div> */}
              <div className="loader-container">
                <div className="loader"></div>

                <div>Please wait while we fetch the latest data for you</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {visitors?.length === 0 ? (
            <div className="col-12">
              {visitors && (
                <h6 className="text-center py-5 p-3">
                  Currently, no users have visited "{loan.bankName}" page. Check
                  back later for updated visitors data.
                </h6>
              )}
            </div>
          ) : (
            <div
              className="d-flex flex-wrap justify-content-between p-3"
              style={{ height: "70vh" }}
            >
              <div className="shadow rounded-3 col-8 p-2 border">
                <div className="d-flex justify-content-between ">
                  <table className="table table-hover">
                    <thead>
                      <tr className="text-center">
                        <td className="text-center table-heading">S.No</td>
                        <td className="text-center table-heading">Username</td>
                        {/* <th>Time</th> */}

                        <td className="text-center table-heading">
                          Clicked Date
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {visibleDataUserManagment.map((visit, i) => {
                        return (
                          <tr className="text-center" key={i}>
                            <td>{i + 1}</td>
                            <td>{visit.userName}</td>
                            {/* <td>{new Date(visit.date)}</td> */}
                            <td>
                              {new Date(visit.date).toLocaleDateString(
                                "en-US",
                                {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                }
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {totalPagesUserManagment > 1 ? (
                  <div className="d-flex justify-content-between mx-3">
                    <button
                      disabled={currentPageUserManagment === 1}
                      onClick={() =>
                        handlePageChangeUserManagment(
                          currentPageUserManagment - 1
                        )
                      }
                      className="peginationButton custom-button fs-4 rotateArrow"
                    >
                      ↼
                    </button>
                    <div>
                      Page {currentPageUserManagment} of{" "}
                      {totalPagesUserManagment}
                    </div>
                    <button
                      disabled={
                        currentPageUserManagment === totalPagesUserManagment
                      }
                      onClick={() =>
                        handlePageChangeUserManagment(
                          currentPageUserManagment + 1
                        )
                      }
                      className="custom-button fs-4"
                    >
                      ⇀
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="col-4 px-1 ">
                {visitors.length > 0 && (
                  <>
                    <div class="card shadow rounded-3 ">
                      
                      <div className="p-3">
                        {visitors.bankLogo ? (
                          <img
                            src={visitors[0].bankLogo}
                            className="img-fluid rounded-top"
                            alt="..."
                          />
                        ) : (
                          <img
                            src={require("../assets/img/placeLoan.png")}
                            className="img-fluid rounded-top"
                            alt="..."
                          />
                        )}
                      </div>

                      <div class="card-body mt-0 pt-0">
                        <hr></hr>
                        <h5 class="card-title">{visitors[0].bankName}</h5>
                        <div className="d-flex flex-wrap justfy-content-between">
                          <p className="text-muted col-4 ">Total Visits:</p>
                          <p className="col-8 ">{visitors.length}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between ">
                          <div className="col-6 pe-2">
                            <DatePicker
                              className="form-control"
                              selected={startDate}
                              maxDate={new Date()}
                              dateFormat="MM/dd/yyyy"
                              onChange={(date) => setStartDate(date)}
                            />
                          </div>
                          -
                          <div className="col-6 px-2">
                            <DatePicker
                              className="form-control"
                              selected={endDate}
                              maxDate={new Date()}
                              dateFormat="MM/dd/yyyy"
                              onChange={(date) => setEndDate(date)}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                          <button
                            onClick={handleDownloadClick}
                            className="text-center rounded-3 border-0  download-reports mt-2 w-100 p-2"
                          >
                            Download Reports
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ListAppliacnt;
