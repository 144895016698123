import React, { useEffect, useState } from "react";
import AdminSideBar from "./AdminSideBar";
import { LuGoal, LuUsers2 } from "react-icons/lu";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { GiSandsOfTime } from "react-icons/gi";
import "../styles/Result.css";
import { FaCircleUser } from "react-icons/fa6";
// import { formatAmount } from "../hooks/useFormattedAmount";
import { formatAmount } from "../../hooks/useFormattedAmount";
import BreadCrumb from "./BreadCrumb";
// import { API_BASE_URL } from "../../appConfig";
function Result() {
  const [currency, setCurrency] = useState('');
  const [winnerData, setWinnerData] = useState();
  const [data, setData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [id, setId] = useState();
  const [getWinner, setGetWinner] = useState();
  const navigate = useNavigate();
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [show, setShow] = useState(false);
  const [secShow, setSecShow] = useState(false);
  const [loading, setLoading] = useState(true);
  // for modal of declare results

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSecShow = () => setSecShow(true);
  const handleSecClose = () => setSecShow(false);

  const handleShowResultsModal = () => setShowResultsModal(true);
  const handleCloseResultsModal = () => setShowResultsModal(false);

  const adminId = localStorage.getItem("adminId");
  let adminToken = localStorage.getItem("adminToken");
  const headers = {
    authorization: `bearer ${adminToken}`,
  };

  const FilteredPath2study = getWinner?.filter((scholarship) => {
    return scholarship.scholarshipProvider === "Path2Study";
  });
  const FilteredPath2studyExpire = getWinner?.filter((scholarship) => {
    return (
      scholarship.scholarshipProvider === "Path2Study" &&
      scholarship.status === "expired" &&
      scholarship.winnerChosen === false
    );
  });

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    getWinnerapi();
  }, []);

  const getWinnerapi = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getAllScholarships/${adminId}`, {
        headers: headers,
      })
      .then((response) => {
        setGetWinner(response.data.scholarships);
        setCurrency(response.data.currency)
      })
      .catch((err) => {
        // console.log("error in api--", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // function for viewapplicants
  const handleViewApplicants = (data, choose_id) => {
    if (data.appliedUsers && data.appliedUsers.length > 0) {
      navigate("/admin/leader-board/result-management", {
        state: { ...data, scholarshipId: choose_id },
      });
    } else {
      handleSecShow();
    }
  };
  // function for declareresult
  const handleDeclare = (data, choose_id) => {
    if (data.appliedUsers && data.appliedUsers.length > 0) {
      navigate("/admin/leader-board/result-management", {
        state: { ...data, scholarshipId: choose_id },
      });
    }
    // navigate("/admin/leaderboard/winner", {
    // state: { ...data, scholarshipId: choose_id },
    // });
    else {
      //  alert("not enough applicants")
      handleShow();
      //  handleSecShow();
    }
  };

  // api for specific winner
  const handleViewResults = (choose, idForSchlorship) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/getWinner/${adminId}?scholarshipId=${idForSchlorship}`,
        { headers: headers }
      )
      .then((response) => {
        
        setData(response.data.winners);
        setCurrency(response.data.currency)

      })
      .catch((error) => {
        // console.log("get winner api", error);
      });
    handleShowResultsModal();
  };

  // api for getting 3 winner
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/getWinner/${adminId}`, {
        headers: headers,
      })
      .then((response) => {
        setWinnerData(response.data.winners);
        setCurrency(response.data.currency)
      })
      .catch((error) => {
        console.log("get3 winner api", error);
      });
  }, []);

  //filted array

  //  FilteredPath2study.filter((choose) =>
  //   choose.scholarshipName
  const filteredScholarshipsPath2study =
  
    FilteredPath2study;

  // peginaion for All Path2study scholarship

  // Calculate the total number of pages

  //for row serial number
  // totalPagespath2study-(totalPagespath2study-currentPagpath2studye)

  const finalFilterToShow = filteredScholarshipsPath2study?.filter((choose) => {
    // Your filter condition goes here
    // For example, if you want to include items with status "live"
    return choose.status === "live";
  });

  //new pegination
  //pegination for path2study
  const [
    currentPagpath2studyeLeaderBoard,
    setCurrentPagpath2studyeLeaderBoard,
  ] = useState(1);
  const recordsPerPagepath2study = 9;

  // Calculate the index range for the current page
  const startIndexpath2study =
    (currentPagpath2studyeLeaderBoard - 1) * recordsPerPagepath2study;
  const endIndexpath2study = startIndexpath2study + recordsPerPagepath2study;

  // Slice the data based on the current page
  const visibleDatapath2studyLeaderBoard = finalFilterToShow?.slice(
    startIndexpath2study,
    endIndexpath2study
  );

  // Calculate the total number of pages
  const totalPagespath2studyLedaerBoard = Math.ceil(
    finalFilterToShow?.length / recordsPerPagepath2study
  );

  //for row serial number
  // totalPagespath2study-(totalPagespath2study-currentPagpath2studye)

  const forSerialNumber =
    recordsPerPagepath2study * (currentPagpath2studyeLeaderBoard - 1);

  // Function to handle page change
  const handlePageChangePathToStudyLeaderBoard = (newPageOne) => {
    setCurrentPagpath2studyeLeaderBoard(newPageOne);
  };

  //

  // for filter
  const filteredScholarships = searchQuery
    ? FilteredPath2study?.filter((row) => {
        // Check if the properties exist before using them

        const scholarshipmatches =
          row.courseName &&
          row.scholarshipName.toLowerCase().includes(searchQuery.toLowerCase());

        // Return true if either country or course matches the live filter
        return scholarshipmatches;
      })
    : FilteredPath2study;

  //    //new pegination
  // //pegination for path2study
  const [
    currentPagpath2studyeLeaderBoardTwo,
    setCurrentPagpath2studyeLeaderBoardTwo,
  ] = useState(1);
  const recordsPerPagepath2studyTwo = 9;

  // // Calculate the index range for the current page
  const startIndexpath2studyTwo =
    (currentPagpath2studyeLeaderBoardTwo - 1) * recordsPerPagepath2studyTwo;
  const endIndexpath2studyTwo =
    startIndexpath2studyTwo + recordsPerPagepath2studyTwo;

  const filteredWinnersPath2studyTwo = FilteredPath2study?.filter(
    (choose) => choose?.winnerChosen === true
  );
  // // Slice the data based on the current page
  const visibleDatapath2studyLeaderBoardTwo =
    filteredWinnersPath2studyTwo?.slice(
      startIndexpath2studyTwo,
      endIndexpath2studyTwo
    );

  // // Calculate the total number of pages
  const totalPagespath2studyLedaerBoardTwo = Math.ceil(
    visibleDatapath2studyLeaderBoardTwo?.length / recordsPerPagepath2studyTwo
  );

  const forSerialNumberTwo =
    totalPagespath2studyLedaerBoardTwo *
    (currentPagpath2studyeLeaderBoardTwo - 1);

  // // Function to handle page change
  const handlePageChangePathToStudyLeaderBoardTwo = (newPageOneTwo) => {
    setCurrentPagpath2studyeLeaderBoardTwo(newPageOneTwo);
  };


  return (
    <div className="result">
      <div className="d-flex">
        <div className="col-2">
          <AdminSideBar />
        </div>
        {loading ? (
          <div className="col-10 py-3 px-3 bg_resu">
            <div className="text-center mt-3">
              {/* <div className="spinner-border text-secondary" role="status">
                  <div className="sr-only text-center">Loading...</div>
                </div> */}
              <div className="loader-container">
                <div className="loader"></div>
                <div>Please wait while we fetch the latest data for you</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-10 py-3 px-3 bg_resu">
            <div className="d-flex bg_resu ">
              <BreadCrumb className="" />
              <FaCircleUser size={25} className="mt-3 me-2" />
              <div className="mt-3 text-muted pe-4 cursor-pointer p-1">
                {localStorage.getItem("adminName")}
              </div>
            </div>
            <hr className="my-1" />
            <div className="d-flex">
              <div className="col-8 bg_resu  py-3">
                <div className="rounded-3 shadow bg_innerresult mx-2 p-2  ">
                  <div className="">
                    <h5 className="mt-3 p-1">Result Management</h5>

                    {FilteredPath2studyExpire?.length > 0 ? (
                      <div className="w-100  rounded-3 bg_reinn p-3">
                        <div className="d-flex justify-content-between pt-3">
                          <h5>Declare Results</h5>
                        </div>

                        <div
                          className="row overflow-auto"
                          style={{ height: "450px" }}
                        >
                          {FilteredPath2study &&
                            FilteredPath2study?.map((choose) => {
                              const idForSchlorship = choose._id;
                              if (
                                choose.status === "expired" &&
                                choose.winnerChosen === false
                              ) {
                                return (
                                  <>
                                    {/* <div className=""> */}

                                    <div className="col-6 pb-2 py-3">
                                      <div className="card p-3 ">
                                        <div className="text-end p-1">
                                          <div className="badge bg-danger rounded-1">
                                            {choose.status}
                                          </div>
                                        </div>

                                        <div className="d-flex flex-wrap justify-content-between">
                                          <h4 className="mx-2 text-primary">
                                          {currency ? currency : "$"}{formatAmount(choose?.amount)}
                                            {/* ${choose.amount} */}
                                          </h4>

                                          <div className="d-flex flex-wrap ">
                                            <i className="bi bi-clock "></i>
                                            <p className="px-2">
                                              {new Date(
                                                choose.Deadline
                                              ).toLocaleDateString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                              })}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-evenly">
                                          <i className="bi bi-mortarboard-fill col-1 "></i>
                                          <small className="col-10 text-truncate d-inline-block fs-5">
                                            {choose.scholarshipName}
                                          </small>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-evenly ">
                                          <i className="bi bi-send-arrow-up-fill col-1"></i>
                                          <small className="col-10 mb-1 pb-0">
                                            Provider :{" "}
                                            {choose.scholarshipProvider}
                                          </small>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-evenly ">
                                          <i className="bi bi-journal-bookmark-fill col-1"></i>
                                          <small className="col-10 text-truncate ">
                                            Course name :{" "}
                                            {choose?.courseName?.map(
                                              (element, i) => {
                                                if (
                                                  i <
                                                  choose?.courseName.length - 1
                                                ) {
                                                  return (
                                                    <span className="">
                                                      {element},
                                                    </span>
                                                  );
                                                } else {
                                                  return (
                                                    <span className="">
                                                      {element}
                                                    </span>
                                                  );
                                                }
                                              }
                                            )}
                                          </small>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-evenly">
                                          <LuUsers2 className="me-2" />
                                          <small className="col-10 ">
                                            Total Applicants :{" "}
                                            {choose.appliedUsers
                                              ? choose.appliedUsers.length
                                              : 0}
                                          </small>
                                        </div>

                                        <button
                                          className="btn border w-100 mt-2 p-1 "
                                          id="hover-button"
                                          onClick={() =>
                                            handleDeclare(
                                              choose,
                                              idForSchlorship
                                            )
                                          }
                                        >
                                          Declare Winners
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            })}
                        </div>
                        {/* ) : null
                 } */}
                      </div>
                    ) : (
                      <div className="w-100  rounded-3 bg_reinn p-3">
                        <div className="d-flex justify-content-between pt-3">
                          <h5>Declare Results</h5>
                        </div>

                        <div
                          className="row overflow-auto"
                          style={{ height: "450px" }}
                        >
                          <div className=" d-flex align-items-center justify-content-center">
                            <h5 className="text-center">
                              No results to declare !<br /> The scholarships has
                              not crossed the deadline yet.
                            </h5>
                          </div>
                        </div>
                        {/* ) : null
                 } */}
                      </div>
                    )}
                  </div>

                  {/* modal for declare result if applicant zero popup */}

                  {show === true ? (
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Title className=" mt-3 mx-4">
                        Not Enough Applicants
                      </Modal.Title>
                      <p className="px-2 mx-3 mt-2">
                        You cannot declare a winner of this Scholarship because
                        there are not enough applications. You can extend
                        deadline to allow more applications.{" "}
                      </p>
                      {/* </Modal.Body> */}
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Cancel
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  ) : (
                    ""
                  )}

                  {/* modal for view applicants if applicants is zero */}

                  {secShow === true ? (
                    <Modal show={secShow} onHide={handleSecClose}>
                      <Modal.Title className="mt-3 mx-4">
                        Not Enough Applicants
                      </Modal.Title>
                      {/* <Modal.Body> */}
                      <p className="px-2 mx-3 mt-2">
                        {" "}
                        There are not enough applications. You can extend
                        deadline to allow more applications.{" "}
                      </p>
                      {/* </Modal.Body> */}
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleSecClose}>
                          Cancel
                        </Button>
                        {/* <Button variant="primary" onClick={handleClose}>
                  Save Changes
                </Button> */}
                      </Modal.Footer>
                    </Modal>
                  ) : (
                    ""
                  )}

                  {/* second div */}
                </div>
              </div>

              <div className="col-4 bg_resu py-3  ">
                {winnerData?.length == 0 ? (
                  <div
                    style={{ height: "600px" }}
                    className="rounded-2 shadow bg_innerresult pb-4   p-2  "
                  >
                    <h5 className="mx-4 mt-3 ">Recent 3 Winners</h5>
                    <div className="overflow-auto">
                      <div className="d-flex   mt-5 pt-5 ">
                        {" "}
                        <div className="mt-5 pt-5 text-center mx-2">
                          <h5>There are currently no declared winners yet.</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="rounded-2 shadow bg_innerresult pb-4   p-2  ">
                    <h5 className="mx-4 mt-3 ">Recent 3 Winners</h5>
                    <div className="overflow-auto" style={{ height: "520px" }}>
                      {winnerData &&
                        winnerData?.map((items, index) => {
                          // console.log("wineerid",winnerid);
                          if (index <= 2)
                            return (
                              <>
                                <div className="rounded-3 bg-white mt-3 col-10 mx-4  ">
                                  <div className="shadow rounded-3 p-3 ">
                                    <h6 className="mt-3">
                                      {items?.scholarship?.scholarshipName}
                                    </h6>
                                    <hr />
                                    <div className=" d-flex justify-content-between mt-2">
                                      <div className="col-5">
                                        <p className="dates">Start Date</p>
                                      </div>
                                      <div className="col-6">
                                        <p className="dates">
                                          {new Date(
                                            items?.scholarship?.createdAt
                                          ).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                          })}
                                        </p>
                                      </div>
                                    </div>
                                    <div className=" d-flex justify-content-between mt-2  ">
                                      <div className="col-5">
                                        <p className="dates">End Date</p>
                                      </div>
                                      <div className="col-6">
                                        <p className="dates">
                                          {new Date(
                                            items?.scholarship?.Deadline
                                          ).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                          })}
                                        </p>
                                      </div>
                                    </div>
                                    <div className=" d-flex justify-content-between mt-2 ">
                                      <div className="col-5 ">
                                        <p className="applicants">
                                          Total Applicants
                                        </p>
                                      </div>
                                      <div className="col-6">
                                        <p className="applicants">
                                          {" "}
                                          {items.scholarship?.appliedUsers
                                            ? items.scholarship?.appliedUsers
                                                .length
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                    <div className=" d-flex justify-content-between mt-2">
                                      <div className="col-5">
                                        <p className="applicants">
                                          Scolarship Amount
                                        </p>
                                      </div>
                                      <div className="col-6">
                                        <p className="applicants">
                                        {currency ? currency : "$"}
                                          {formatAmount(
                                            items?.scholarship.amount
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <div className=" mt-2">
                                      <div>
                                        <p className="applicants text-muted">
                                          Declared winner{" "}
                                        </p>
                                      </div>
                                      <div className=" d-flex justify-content-around">
                                        <div className="col-3">
                                          <div
                                            className={
                                              items?.user?.profileImg
                                                ? "winner_background"
                                                : ""
                                            }
                                          >
                                            {items?.user?.profileImg ? (
                                              <div>
                                                <img
                                                  src={items?.user?.profileImg}
                                                  className="rounded-circle"
                                                  alt=""
                                                  height={50}
                                                  width={50}
                                                />
                                              </div>
                                            ) : (
                                              <div>
                                                <FaCircleUser size={50} />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className=" my-lg-3">
                                          <h6>{items?.user?.name}</h6>
                                        </div>
                                        <div className=" my-lg-3">
                                          <h6 className=" text-end">
                                          {currency ? currency : "$"}
                                            {formatAmount(
                                              items?.scholarship.amount
                                            )}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                        })}
                    </div>
                  </div>
                )}

                {/* MODAL POP FOR VIEW RESULTS WINNER */}
                {/* map for winnerdata */}
                <Modal show={showResultsModal} onHide={handleCloseResultsModal}>
                  <Modal.Body>
                    {data &&
                      data?.map((item, index) => {
                        return (
                          <>
                            <div key={index}>
                              <div className=" rounded-3 bg-white  mt-3">
                                <div className="">
                                  <h6 className="fs-5">
                                    {item?.scholarship?.scholarshipName}
                                  </h6>
                                  <hr />
                                  <div className=" d-flex justify-content-between mt-2">
                                    <div className="col-5">
                                      <p className="dates">Start Date</p>
                                    </div>
                                    <div className="col-6">
                                      <p className="dates">
                                        {item &&
                                          new Date(
                                            item?.scholarship.createdAt
                                          )?.toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                          })}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" d-flex justify-content-between mt-2">
                                    <div className="col-5">
                                      <p className="dates">End Date</p>
                                    </div>
                                    <div className="col-6">
                                      <p className="dates">
                                        {item &&
                                          new Date(
                                            item?.scholarship.Deadline
                                          )?.toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                          })}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" d-flex justify-content-between mt-2">
                                    <div className="col-5">
                                      <p className="applicants">
                                        Total Applicants
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <p className="applicants">
                                        {item?.scholarship.appliedUsers?.length}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" d-flex justify-content-between mt-2">
                                    <div className="col-5">
                                      <p className="applicants">
                                        Scholarship Amount
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <p className="applicants">
                                      {currency ? currency : "$"}
                                        {formatAmount(item?.scholarship.amount)}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" mt-2">
                                    <div>
                                      <p className="applicants">
                                        Declared winner{" "}
                                      </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <div className="col-7 d-flex justify-content-around">
                                        <div
                                          className={
                                            item?.user?.profileImg
                                              ? "winner_background"
                                              : ""
                                          }
                                        >
                                          {item?.user?.profileImg ? (
                                            <div>
                                              <img
                                                src={
                                                  // require("../assets/img/winner.png")
                                                  item?.user?.profileImg
                                                }
                                                className=" rounded-circle"
                                                alt=""
                                                style={{
                                                  height: "70px",
                                                  width: "70px",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <div>
                                              <FaCircleUser size={50} />
                                            </div>
                                          )}
                                        </div>
                                        <div className="my-lg-3 me-3">
                                          <h6 className="me-3">
                                            {item?.user?.name}
                                          </h6>
                                        </div>
                                      </div>
                                      {/* <div className="my-lg-3 bg-warning">
                                      <h6 className="">{item?.user?.name}</h6>
                                    </div> */}
                                      <div className="my-lg-3 me-5 ">
                                        <h6 className=" me-5 px-4">
                                          ${item?.scholarship.amount}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                        // console.log("----",item);
                      })}
                  </Modal.Body>
                  <div className="d-flex justify-content-center p-2 py-3">
                    <button
                      onClick={handleCloseResultsModal}
                      className="btn btn-primary w-25"
                    >
                      Close
                    </button>
                  </div>
                </Modal>

                {/* Modal Popup for Declare results when  */}
              </div>
            </div>

            <div className="bg-white rounded-3 shadow p-2">
              <div className="bg_reinn p-2 rounded-3">
                <div className="d-flex  justify-content-between mt-2">
                  <h5 className="col-6 mt-3">
                    All Path2study scholarship :
                    {visibleDatapath2studyLeaderBoard == 0 ? null : (
                      <span>&nbsp;{finalFilterToShow?.length}</span>
                    )}
                  </h5>

                 
                  {/* <div className="form-group has-search mx-3">
                  <span className="fa fa-search form-control-feedback mt-2 mx-1"></span>
                  <input
                    type="text"
                    className=" form-control rounded-pill bg-white mt-2 mx-2"
                    placeholder="Search by scholarship"
                    value={searchQuery}
                    onChange={handleSearch}
                    style={{
                      border: "revert-layer", // Remove default border on focus
                      outline: "none", // Remove default outline on focus
                      boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                </div> */}
                </div>

                {/* all path2study scholarship */}

                <div className="row">
                  {
                    // FilteredPath2study.filter((choose) =>
                    //   choose.scholarshipName
                    //     .toLowerCase()
                    //     .includes(searchQuery.toLowerCase())
                    // )

                    visibleDatapath2studyLeaderBoard == 0 ? (
                      <div className="text-center my-3">
                        There are currently no scholarships available.
                      </div>
                    ) : (
                      visibleDatapath2studyLeaderBoard?.map((choose) => {
                        const idForSchlorship = choose._id;
                        // console.log("Id for schlorship one" + idForSchlorship);
                        // if (choose.status === "live") {
                        return (
                          // <div className="d-flex justify-content-between" >

                          <div className="col-4 my-1 mt-2 px-1 py-1">
                            <div className="card p-3 mx-2">
                              <div className="text-end p-1">
                                <div className="badge bg-primary rounded-1">
                                  {choose.status}
                                </div>
                              </div>

                              <div className="d-flex flex-wrap justify-content-between">
                                <h4 className="mx-2 text-primary">
                                  {/* ${choose.amount} */}
                                  {currency ? currency : "$"}
                                  {formatAmount(choose.amount)}
                                </h4>

                                <div className="d-flex flex-wrap ">
                                  <i className="bi bi-clock "></i>
                                  <p className="px-2">
                                    {new Date(
                                      choose.Deadline
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    })}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex flex-wrap justify-content-evenly">
                                <i className="bi bi-mortarboard-fill col-1 "></i>
                                <small className="col-10 text-truncate d-inline-block fs-5">
                                  {choose?.scholarshipName}
                                </small>
                              </div>
                              <div className="d-flex flex-wrap justify-content-evenly ">
                                <i className="bi bi-send-arrow-up-fill col-1"></i>
                                <small className="col-10 mb-1 pb-0 ">
                                  Provider : {choose?.scholarshipProvider}
                                </small>
                              </div>
                              <div className="d-flex flex-wrap justify-content-evenly ">
                                <i className="bi bi-journal-bookmark-fill col-1"></i>
                                <small className="col-10 text-truncate ">
                                  Course name :{" "}
                                  {choose?.courseName?.map((element, i) => {
                                    if (i < choose?.courseName.length - 1) {
                                      return (
                                        <span className="">{element},</span>
                                      );
                                    } else {
                                      return (
                                        <span className="">{element}</span>
                                      );
                                    }
                                  })}
                                </small>
                              </div>
                              <div className="d-flex flex-wrap justify-content-evenly ">
                                <LuUsers2 className="me-2" />
                                <small className="col-10">
                                  Total Applicants :{" "}
                                  {choose?.appliedUsers
                                    ? choose?.appliedUsers?.length
                                    : 0}
                                </small>
                              </div>
                              <button
                                className="btn border w-100 mt-2"
                                id="hover-button"
                                onClick={() =>
                                  handleViewApplicants(choose, idForSchlorship)
                                }
                              >
                                View Applicants
                              </button>
                            </div>
                          </div> // {/* </div> */}
                        );
                        // }
                      })
                    )
                  }

                  {/* Pagination controls */}
                  {totalPagespath2studyLedaerBoard > 1 ? (
                    <div className="d-flex justify-content-between mt-3">
                      <button
                        disabled={currentPagpath2studyeLeaderBoard === 1}
                        onClick={() =>
                          handlePageChangePathToStudyLeaderBoard(
                            currentPagpath2studyeLeaderBoard - 1
                          )
                        }
                        className="custom-button fs-3"
                      >
                        ↼
                      </button>
                      <div>
                        Page {currentPagpath2studyeLeaderBoard} of{" "}
                        {totalPagespath2studyLedaerBoard}
                      </div>
                      <button
                        disabled={
                          currentPagpath2studyeLeaderBoard ===
                          totalPagespath2studyLedaerBoard
                        }
                        onClick={() =>
                          handlePageChangePathToStudyLeaderBoard(
                            currentPagpath2studyeLeaderBoard + 1
                          )
                        }
                        className="custom-button fs-3"
                      >
                        ⇀
                      </button>
                    </div>
                  ) : null}
                </div>

                {/* for no result found */}

                {/* Pagination controls */}
              </div>
            </div>

            <div className="bg-white mt-3 p-2 rounded-3">
              <div className="bg_reinn p-2 rounded-3">
                <h4 className="p-2">History</h4>
                <div className="row">
                  {filteredWinnersPath2studyTwo?.length == 0 ? (
                    <div className="text-center my-3">
                      The winner is not yet declared to any of the scholarship.
                    </div>
                  ) : (
                    filteredWinnersPath2studyTwo?.map((choose) => {
                      const idForSchlorship = choose._id;

                      if (choose?.winnerChosen === true) {
                        return (
                          <>
                            {/* <div className="d-flex justify-content-between"> */}
                            <div className="col-4 my-1 mt-2 px-1">
                              <div className="card p-3 mx-2">
                                <div className="text-end p-1">
                                  <div className="badge bg-success rounded-1">
                                    {/* {choose.status} */}
                                    Winner declared
                                  </div>
                                </div>

                                <div className="d-flex flex-wrap justify-content-between">
                                  <h4 className="text-primary px-2">
                                  {currency ? currency : "$"}{formatAmount(choose?.amount)}
                                  </h4>

                                  <div className="d-flex flex-wrap ">
                                    <i className="bi bi-clock "></i>
                                    <p className="px-2">
                                      {new Date(
                                        choose?.Deadline
                                      )?.toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      })}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-evenly">
                                  <i className="bi bi-mortarboard-fill col-1 "></i>
                                  <small className="col-10 text-truncate d-inline-block fs-5">
                                    {choose?.scholarshipName}
                                  </small>
                                </div>
                                <div className="d-flex flex-wrap justify-content-evenly ">
                                  <i className="bi bi-send-arrow-up-fill col-1"></i>
                                  <small className="col-10 mb-1 pb-0">
                                    Provider: {choose?.scholarshipProvider}
                                  </small>
                                </div>
                                <div className="d-flex flex-wrap justify-content-evenly ">
                                  <i className="bi bi-journal-bookmark-fill col-1"></i>
                                  <small className="col-10 text-truncate">
                                    Course:{" "}
                                    {choose?.courseName?.map((element, i) => {
                                      if (i < choose?.courseName.length - 1) {
                                        return (
                                          <span className="">{element},</span>
                                        );
                                      } else {
                                        return (
                                          <span className="">{element}</span>
                                        );
                                      }
                                    })}
                                  </small>
                                </div>
                                <div className="d-flex flex-wrap justify-content-evenly ">
                                  <div>
                                    <LuUsers2 className="me-2" />
                                  </div>
                                  <small className="col-10 ">
                                    Total Applicants:{" "}
                                    {choose?.appliedUsers
                                      ? choose?.appliedUsers?.length
                                      : 0}
                                  </small>
                                </div>
                                <button
                                  className="btn border w-100 mt-2"
                                  id="hover-button"
                                  // onClick={() =>
                                  //   handleViewResults(choose, idForSchlorship)
                                  // }
                                  onClick={() =>
                                    handleViewApplicants(
                                      choose,
                                      idForSchlorship
                                    )
                                  }
                                >
                                  View Results
                                </button>
                              </div>
                            </div>
                            {/* </div> */}
                          </>
                        );
                      }
                    })
                  )}

                  {/* Pagination controls */}

                  {/* Pagination controls */}
                  {/* {totalPagespath2studyLedaerBoardTwo > 1 ? (
                  <div className="d-flex justify-content-between">
                    <button
                      disabled={currentPagpath2studyeLeaderBoardTwo === 1}
                      onClick={() =>
                        handlePageChangePathToStudyLeaderBoardTwo(
                          currentPagpath2studyeLeaderBoardTwo - 1
                        )
                      }
                      className="custom-button fs-3"
                    >
                      ↼
                    </button>
                    <div>
                      Page {currentPagpath2studyeLeaderBoardTwo} of{" "}
                      {totalPagespath2studyLedaerBoardTwo}
                    </div>
                    <button
                      disabled={
                        currentPagpath2studyeLeaderBoardTwo ===
                        totalPagespath2studyLedaerBoardTwo
                      }
                      onClick={() =>
                        handlePageChangePathToStudyLeaderBoardTwo(
                          currentPagpath2studyeLeaderBoardTwo + 1
                        )
                      }
                      className="custom-button fs-3"
                    >
                      ⇀
                    </button>
                  </div>
                ) : null} */}
                </div>

                {/* pegination controls */}
                {/* Pagination controls */}
                {totalPagespath2studyLedaerBoardTwo > 1 ? (
                  <div className="d-flex justify-content-between mt-3">
                    <button
                      disabled={currentPagpath2studyeLeaderBoardTwo === 1}
                      onClick={() =>
                        handlePageChangePathToStudyLeaderBoard(
                          currentPagpath2studyeLeaderBoardTwo - 1
                        )
                      }
                      className="custom-button fs-3"
                    >
                      ↼
                    </button>
                    <div>
                      Page {currentPagpath2studyeLeaderBoardTwo} of{" "}
                      {totalPagespath2studyLedaerBoardTwo}
                    </div>
                    <button
                      disabled={
                        currentPagpath2studyeLeaderBoardTwo ===
                        totalPagespath2studyLedaerBoardTwo
                      }
                      onClick={() =>
                        handlePageChangePathToStudyLeaderBoard(
                          currentPagpath2studyeLeaderBoardTwo + 1
                        )
                      }
                      className="custom-button fs-3"
                    >
                      ⇀
                    </button>
                  </div>
                ) : null}
                {/* section for no result found */}
                {/* {FilteredPath2study &&
                FilteredPath2study?.filter((choose) =>
                  choose?.scholarshipName
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                ).length === 0 && (
                  <div className="text-center mt-3">
                    <p>No Search Results Found</p>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        )}

        {/* section All Path2study scholarship end */}
      </div>
    </div>
  );
}

export default Result;
