import React, {  useContext } from "react";
import { Form, Button  } from "react-bootstrap";
import axios from "axios";
import { ModalContext } from "../../contexts/ModalContext";

// import { API_BASE_URL } from "../../appConfig";

import Row from "react-bootstrap/Row";
import * as formik from "formik";
import * as yup from "yup";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { redirect } from "react-router-dom";

const ForgetPass1 = () => {
  const { handleNext, data, closeModal } = useContext(ModalContext);

  const { Formik } = formik;
  const otpSchema = yup.object({
    email: yup.string().matches( /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email').required('Email is Required'),
  });

 
  const handlePostRequest = async (values) => {
    const postData = { email: values.email, userType: "Basic" };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/generateOtp`,
        // `${API_BASE_URL}/generateOtp`,
        // "https://path2study-server-dot-ize-testenv.uc.r.appspot.com/generateOtp",
        postData
      );
      // console.log("Response:", response.data);
      if( response.data=== 'OTP sent to your entered email'){
        toast('OTP sent to your entered email', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      // Handle the response or update the state as needed
      handleNext(postData);
    } catch (error) {
      console.error("Error:", error);
      // Handle errors, if any
      if (error) {
        toast(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };


  return (
    <>
      
        <div className="card mx-md-4 shadow p-md-3 p-2 mb-3 bg-body rounded-lg">
          {/* new version  */}
          <Formik
            validationSchema={otpSchema}
            onSubmit={handlePostRequest}
            initialValues={{
              email: "",
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="">Email </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="username@domain.com"
                      name="email"
                      required
                      value={values.email}
                      isInvalid={touched.email && !!errors.email}
                      onChange={handleChange}
                      style={{
                        fontWeight: "400",
                        border: "revert-layer", // Remove default border on focus
                        outline: "none", // Remove default outline on focus
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Button
                  variant="primary"
                  type="submit"
                  className="d-grid gap-2 col-6 mx-auto m-1 w-100"
                >
                  Send OTP
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      {/* </Modal> */}
    </>
  );
};

export default ForgetPass1;
