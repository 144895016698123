import React, { useEffect } from "react";
import "../styles/DashBoard.css";
import { FaGraduationCap } from "react-icons/fa";
import AdminSideBar from "./AdminSideBar";
import { TiCloudStorageOutline } from "react-icons/ti";
import { LuUsers2 } from "react-icons/lu";
import { CiBank } from "react-icons/ci";
import { GrRadialSelected } from "react-icons/gr";
import axios from "axios";
import { useState } from "react";
import { PiArrowUDownLeft } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";
import Button from "react-bootstrap/Button";
import { BackNavigation } from "./BackNavigation";
import Modal from "react-bootstrap/Modal";
import { FaCircleUser } from "react-icons/fa6";
import { IoMdArrowDropdown } from "react-icons/io";
import { formatAmount } from "../../hooks/useFormattedAmount";
// import { Breadcrumb } from 'react-bootstrap';
import BreadCrumb from "./BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
// import { API_BASE_URL } from "../../appConfig";
import moment from "moment-timezone";

// import {Ba}
const DashBoard = () => {
  //state to change the color of icon if it is active
  const [currency, setCurrency] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [winnerData, setWinnerData] = useState([]);

  const [updateData, setUpdateData] = useState([]);
  const [imageIndex, setImageIndex] = useState(null);
  const [previewImage, setPreviewImage] = useState({});
  const [uploadImage, setUploadImage] = useState({});
  const [imageShow, setImageShow] = useState(false);
  const [imageShow2, setImageShow2] = useState(false);
  const [imageShow3, setImageShow3] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [bashBoardData, setBashBoardData] = useState();

  const [forSaveButton, setForSavButton] = useState(false);
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const previewURL = URL.createObjectURL(file);
    setPreviewImage(previewURL);
    setUploadImage(e.target.files[0]);

    setForSavButton(true);
  };

  const handleToggleActive = () => {
    setIsActive(!isActive);
  };

  // const formDataDashboard = new FormData();

  let adminId = localStorage.getItem("adminId");
  let adminToken = localStorage.getItem("adminToken");

  const handleSaveClick = async (e) => {
    // console.log("updated data", updateData);
    if (updateData) {
      // formDataDashboard.append("file", uploadImage);
      updateData.splice(selectedImageIndex, 1, uploadImage);
      // const dataToSent = updateData.map(data => console.log('see data', data.indexOf(data)))
      setSaveModal(false);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/admin/addbanners/${adminId}?addIndex=${selectedImageIndex}`,
          updateData,
          {
            headers: {
              authorization: `bearer ${adminToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        toast.success("New banner image added succesfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setPreviewImage(null);

        const fetData = () => {
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/getbanners`)
            .then((response) => {
              setUpdateData(response.data);
              setCurrency(response.data.currency);
            })

            .catch((error) => {
              console.log(error);
            });
        };
        fetData();

        // setTimeout(() => {
        //   window.location.reload()
        // }, 2500);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      alert(
        "You can only upload a maximum of 3 banner images. Delete one image ?"
      );
    }
  };

  useEffect(() => {
    const headers = {
      authorization: `bearer ${adminToken}`,
    };
    const fetData = () => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/getbanners`)
        .then((response) => {
          setUpdateData(response.data);
          setCurrency(response.data.currency);
        })

        .catch((error) => {
          console.log(error);
        });
    };
    fetData();

    const bashBoardData = () => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/admin/dashboard/${adminId}`, {
          headers: headers,
        })
        .then((response) => {
          setBashBoardData(response.data);
          setCurrency(response.data.currency);
        })

        .catch((error) => {
          console.log(error);
        });
    };

    bashBoardData();
  }, []);

  const [saveModal, setSaveModal] = useState(false);

  const handleModalSaveClick = () => {
    setSaveModal(true);
  };

  const handleSaveClose = () => {
    setSaveModal(false);
  };

  const [showmodal, setShowModal] = useState();

  const handleModal1Click = async (i) => {
    setShowModal(true);
    handleIndex(i);
  };

  const handleImageClick = async (index) => {
    setImageShow(true);
    setImageShow2(false);
    setImageShow3(false);
    setSelectedImageIndex(index);
  };

  const handleImageClick2 = async (index) => {
    setImageShow2(true);
    setImageShow3(false);
    setImageShow(false);

    setSelectedImageIndex(index);
  };

  const handleImageClick3 = async (index) => {
    setImageShow3(true);
    setImageShow2(false);
    setImageShow(false);
    setSelectedImageIndex(index);
  };

  const handleIndex = (index) => {
    setSelectedImageIndex(index);
  };

  const handleClose = () => setShowModal(false);

  const handleDeleteClick = async () => {
    try {
      let adminId = localStorage.getItem("adminId");
      let adminToken = localStorage.getItem("adminToken");

      const headers = {
        authorization: `bearer ${adminToken}`,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/addbanners/${adminId}?index=${selectedImageIndex}`,
        {},
        {
          headers: headers,
        }
      );
      setShowModal(false);
      toast.success("Banner image deleted successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      axios
        .get(`${process.env.REACT_APP_BASE_URL}/getbanners`)
        .then((response) => {
          setUpdateData(response.data);
          setCurrency(response.data.currency);
        })

        .catch((error) => {
          console.log(error);
        });
      // window.location.reload();
      // console.log("Deleted successfully:", response.data);
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  const headers = {
    authorization: `bearer ${adminToken}`,
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/getWinner/${adminId}`, {
        headers: headers,
      })
      .then((response) => {
        // setBashBoardData(response.data);

        setWinnerData(response.data.winners);
        setCurrency(response.data.currency);
      })
      .catch((error) => {
        // setBashBoardData(response.data);

        console.log(error);
      });

    // .catch((error)=>{
    //   console.log(error)
    // })
  }, []);

  const [editPermission, setEditPermission] = useState(false);
  const [finalEdited, setFinalEdited] = useState(null);

  const [selectedCityData, setSelectedCityData] = useState("");
  const [currentCity, setCurrentCity] = useState("CHICAGO");

  //for city section
  const handleCitychange = (e) => {
    const city = e.target.value;
    setCurrentCity(city);

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getcities?cityName=${city}`)
      .then((response) => {
        {
          // setCityeditedData(response.data)
          setSelectedCityData(response.data);
          setCurrency(response.data.currency);
          // console.log(responce.data);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const city = "CHICAGO";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getcities?cityName=${city}`)
      .then((response) => {
        {
          setSelectedCityData(response.data);
          setCurrency(response.data.currency);
          // setCityeditedData(responce.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleUnivercityChange = (e) => {
    {
      const { name, value } = e.target;

      // selectedCityData.top5Universities?.name==value

      setSelectedCityData((prevData) => ({
        ...prevData,
        top5Universities: {
          ...prevData.top5Universities,
          [name]: value,
        },
      }));

      setFinalEdited((prevDataOne) => ({
        ...prevDataOne,
        [name]: value,
      }));
    }
  };

  const saveChangesunivercity = () => {
    let adminId = localStorage.getItem("adminId");
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/admin/updateUniversity/${adminId}`,
        {
          // cityName: currentCity,
          // finalEdited,
          cityName: currentCity,
          uni1: finalEdited?.uni1,
          uni2: finalEdited?.uni2,
          uni3: finalEdited?.uni3,
          uni4: finalEdited?.uni4,
          uni5: finalEdited?.uni5,
          // finalEdited,
        },
        { headers: headers }
      )
      .then((responce) => {
        toast.success("All the changes have been saved successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setTimeout(window.location.reload(),3000)
        // window.location.reload();
        setEditPermission(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //  session part
  const date = new Date();
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [unAvailibilityDate, setunAvailibilityDate] = useState([]);
  // const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [selectedDate, setSelectedDate] = useState(date.toLocaleDateString());

  // moment(new Date()).format('YYYY-MM-DD')

  // const today = date.toLocaleDateString();
  const today = new Date().toISOString().split("T")[0];

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    const selectedDate = new Date(value);
    setSelectedDate(selectedDate || today);
    setSelectedTimeSlots([]);
    // setFilteredSlotsMainFinal(filteredSlotsMain.filter(()))
  };

  console.log("selectedDate", selectedDate);

  const [fetchDateData, setFetchDateData] = useState(null);
  const [startTime, setStartTime] = useState([]);
  const [startTimeUTC, setStartTimeUTC] = useState([]);
  const [endTime, setEndTime] = useState([]);
  const [TimeSlots, setTimeSlots] = useState([]);
  const [chicagoTimes, setChicagoTimes] = useState([]);
  const [Btndesable, setBtndesable] = useState(true);

  // useEffect(() => {
  //   const fetchDateData = async () => {
  //     try {
  //       const formattedDate = selectedDate?.toISOString().split("T")[0]; // Format date as 'YYYY-MM-DD'
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BASE_URL}getAvailableSlots/${formattedDate}`
  //       );
  //       // console.log("response", response);
  //       setFetchDateData(response.data.availableSlotsForWeek);
  //       const startTime = response.data.availableSlotsForWeek.map(
  //         (item) => item.start
  //       );

  //       const endTime = response.data.availableSlotsForWeek.map(
  //         (item) => item.end
  //       );
  //       setEndTime(endTime);
  //       setStartTime(startTime);
  //       setStartTimeUTC(startTime);
  //       // const TimeSlots = [startTime, endTime];
  //       // setTimeSlots(TimeSlots);

  //       // console.log("TimeSlots", TimeSlots);

  //       // function convertToChicagoTime(startTimeArray, endTimeArray) {
  //       //   const chicagoTimeStart = [];
  //       //   const chicagoTimeEnd = [];
  //       //   // Convert start times to America/Chicago time zone
  //       //   startTimeArray.forEach((startTime) => {
  //       //     const chicagoStartTime = moment(startTime)
  //       //       .tz("America/Chicago")
  //       //       .format("MMMM Do YYYY hh:mm A");
  //       //     chicagoTimeStart.push(chicagoStartTime);
  //       //   });
  //       //   // Convert end times to America/Chicago time zone
  //       //   endTimeArray?.forEach((endTime) => {
  //       //     const chicagoEndTime = moment(endTime)
  //       //       .tz("America/Chicago")
  //       //       .format("MMMM Do YYYY hh:mm A");
  //       //     chicagoTimeEnd.push(chicagoEndTime);
  //       //   });

  //       //   return [chicagoTimeStart, chicagoTimeEnd];
  //       //   // return {start:[chicagoTimeStart], end:[chicagoTimeEnd]};
  //       // }

  //       // const chicagoTimes = convertToChicagoTime(startTime, endTime);
  //       // // setChicagoTimes(chicagoTimes);
  //       // // console.log("chicagoTimes", chicagoTimes);
  //       // const chicagoTime = startTime.map((timestamp) => {
  //       //   return moment(timestamp)
  //       //     .tz("America/Chicago")
  //       //     .format("MMMM Do YYYY hh:mm A");
  //       // });
  //       // console.log("startchicagoTime", chicagoTime);
  //       // const chicagoTimeEnd = endTime.map((timestamp) => {
  //       //   return moment(timestamp)
  //       //     .tz("America/Chicago")
  //       //     .format("MMMM Do YYYY hh:mm A");
  //       // });
  //       // console.log("endchicagoTime", chicagoTimeEnd);
  //       // setStartTime(chicagoTime);
  //       // setEndTime(chicagoTimeEnd);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   if (selectedDate) {
  //     // Add condition to ensure selectedDate is not null or undefined
  //     fetchDateData();
  //   }
  // }, [selectedDate]);

  // Function to handle checkbox changes
  // const handleCheckboxChange = (date, timeSlot) => {
  //   setBtndesable(false);
  //   setSelectedTimeSlots((prevState) => {
  //     const isSelected = prevState[date]
  //       ? prevState[date].includes(timeSlot)
  //       : false;
  //     if (isSelected) {
  //       // If already selected, remove from selection
  //       const updatedSelection = prevState[date].filter(
  //         (slot) => slot !== timeSlot
  //       );
  //       return {
  //         ...prevState,
  //         [date]: updatedSelection.length > 0 ? updatedSelection : null,
  //       };
  //     } else {
  //       // If not selected, add to selection
  //       return {
  //         ...prevState,
  //         [date]: prevState[date] ? [...prevState[date], timeSlot] : [timeSlot],
  //       };
  //     }
  //   });
  // };
  // const handleCheckboxChange = (date, timeSlot) => {
  //   setSelectedTimeSlots((prevState) => {
  //     const isSelected = prevState[date]
  //       ? prevState[date].includes(timeSlot)
  //       : false;
  //     if (isSelected) {
  //       // If already selected, remove from selection
  //       const updatedSelection = prevState[date].filter(
  //         (slot) => slot !== timeSlot
  //       );
  //       return {
  //         ...prevState,
  //         [date]: updatedSelection.length > 0 ? updatedSelection : null,
  //       };
  //     } else {
  //       // If not selected, add to selection
  //       return {
  //         ...prevState,
  //         [date]: prevState[date] ? [...prevState[date], timeSlot] : [timeSlot],
  //       };
  //     }
  //   });
  // };
  // console.log("startTimeUTC", startTimeUTC);
  console.log("selectedTimeSlots", selectedTimeSlots);
  // console.log("Btndesable", Btndesable);

  const handleSubmit = () => {
    try {
      // Send the POST request
      const responseObject = selectedTimeSlots;
      // Extract the array from the object
      // const arrayFromResponse = responseObject[selectedDate];
      const postData = responseObject[selectedDate];
      // console.log("arrayFromResponse", arrayFromResponse);
      console.log("postData", postData, !postData);
      const extractDatefromSelecteddate = selectedDate;
      const formateddate = selectedDate.toLocaleDateString();

      console.log("extractDatefromSelecteddate", extractDatefromSelecteddate);
      console.log("formateddate", formateddate);
      const timeZone = "America/Chicago";

      // const utcTimeSlots = convertArrayToTimeZone(
      //   formateddate,
      //   postData,
      //   timeZone
      // ); // Adjusted the parameters
      // console.log(utcTimeSlots);

      // return;
      if (postData == [] || !postData) {
        setBtndesable(false);
        toast.error("please select the Time-slot", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}bookMeetingForAdmin/`,
            postData
          )
          .then((response) => {
            // Handle success
            console.log("POST request successful:", response.data);
            toast.success(response.data.msg, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setTimeout(() => {
              window.location.reload();
            }, 3000);
            window.scroll(0, 0);
          })
          .catch((error) => {
            // Handle error
            console.error("Error making POST request:", error);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //for 3 days format
  const [IsLoading, setIsLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [indexOfDate, setIndexOfDate] = useState();
  const [filteredSlotsMain, setFilteredSlotsMain] = useState([]);
  const [filteredSlotsMainFinal, setFilteredSlotsMainFinal] = useState([]);
  const [filteredDay, setFilteredDay] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState([]);
  const [slotsByDay, setslotsByDay] = useState([]);
  const [ActiveCard, setActiveCard] = useState(""); // border
  const [ActiveCardTime, setActiveCardTime] = useState(""); // border
  const [currentTime] = useState(moment().format("LT"));
  const [calendarTime] = useState(moment().calendar());

  useEffect(() => {
    // Filter available slots based on selected day
    const filteredday = filteredSlotsMain.map((slot) => slot.day);
    setFilteredDay(filteredday);
  }, [selectedDay, filteredSlotsMain]);

  // main working
  useEffect(() => {
    // Filter available slots based on selected day
    const filteredSlotsnew = filteredSlotsMain.filter(
      (slot) => slot.day === selectedDay
    );
    const slotsByDaynew = filteredSlotsMain.filter(
      (slot) => slot.day === selectedDay
    )[0]?.slots;
    // const slotsByDay=filteredSlotsnew[0].slots
    setslotsByDay(slotsByDaynew);
    setFilteredSlots(filteredSlotsnew);
    // console.log("filteredSlots",filteredSlotsnew)
  }, [selectedDay, filteredSlotsMain]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // setError(null);
      try {
        const formattedDate = selectedDate?.toISOString().split("T")[0]; // Format
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}getAvailableSlotsForAdmin/${formattedDate}`
          )
          .then((response) => {
            setIsLoading(false);
            // New object/array in the required format
            // const availableSlotsForWeek = {};
            const dates = response.data.availableSlotsForWeek;
            // setAvailableSlots(dates);
            console.log(
              "response.data.availableSlotsForWeek",
              response.data.availableSlotsForWeek
            );
            setFilteredSlotsMain(dates);
            // const dateConvertedtoLocal = dates
            //   .map((item) => item.start)
            //   .map((utcDate) => {
            //     const localDateTime = moment.utc(utcDate).tz("America/Chicago"); // Change timezone to Chicago
            //     const localday = localDateTime.format("dddd");
            //     const endDate = localDateTime
            //       .clone()
            //       .add(30, "minutes")
            //       .format("MMMM Do YYYY, h:mm a");
            //     return {
            //       day: localday,
            //       startdate: localDateTime.format("MMMM Do YYYY, h:mm a"),
            //       enddate: endDate,
            //       // originaldatebybackend: utcDate,
            //     };
            //   });

            // console.log("dateConvertedtoLocal", dateConvertedtoLocal);

            // const transformedData = Object.values(
            //   dateConvertedtoLocal.reduce(
            //     (acc, { day, startdate, enddate }) => {
            //       const date = startdate.split(",")[0]; // Extract date without time
            //       if (!acc[date]) {
            //         acc[date] = { day, date, slots: [] };
            //       }
            //       acc[date].slots.push({
            //         starttime: startdate.split(",")[1].trim(),
            //         endtime: enddate.split(",")[1].trim(),
            //       });
            //       return acc;
            //     },
            //     {}
            //   )
            // );

            // setFilteredSlotsMain(transformedData); // Set filtered slots
          })

          .catch((error) => {
            console.error("Error fetching available slots:", error);
          });
      } catch (error) {
        // setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // Usage
    // const today = new Date();
    // const formattedDate = formatDate(new Date());
    // const selectedDateNew = formatDate(selectedDate);
    // console.log(
    //   "formattedDate",
    //   formattedDate,
    //   "selectedDateNew",
    //   selectedDateNew
    // ); // Output: "2024-05-06"

    // formattedDate === selectedDateNew ? setIndexOfDate(0) : setIndexOfDate(0);
  }, [selectedDate]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     // setError(null);
  //     try {
  //       const formattedDate = selectedDate?.toISOString().split("T")[0]; // Format
  //       axios
  //         .get(
  //           `${process.env.REACT_APP_BASE_URL}getAvailableSlotsForAdmin/${formattedDate}`
  //         )
  //         .then((response) => {
  //           setIsLoading(false);
  //           // New object/array in the required format
  //           // const availableSlotsForWeek = {};
  //           const dates = response.data.availableSlotsForWeek;
  //           // setAvailableSlots(dates);
  //           console.log("response.data.availableSlotsForWeek", response.data.availableSlotsForWeek);

  //           const dateConvertedtoLocal = dates
  //             .map((item) => item.start)
  //             .map((utcDate) => {
  //               const localDateTime = moment.utc(utcDate).tz("America/Chicago"); // Change timezone to Chicago
  //               const localday = localDateTime.format("dddd");
  //               const endDate = localDateTime
  //                 .clone()
  //                 .add(30, "minutes")
  //                 .format("MMMM Do YYYY, h:mm a");
  //               return {
  //                 day: localday,
  //                 startdate: localDateTime.format("MMMM Do YYYY, h:mm a"),
  //                 enddate: endDate,
  //                 // originaldatebybackend: utcDate,
  //               };
  //             });

  //           console.log("dateConvertedtoLocal", dateConvertedtoLocal);

  //           const transformedData = Object.values(
  //             dateConvertedtoLocal.reduce(
  //               (acc, { day, startdate, enddate }) => {
  //                 const date = startdate.split(",")[0]; // Extract date without time
  //                 if (!acc[date]) {
  //                   acc[date] = { day, date, slots: [] };
  //                 }
  //                 acc[date].slots.push({
  //                   starttime: startdate.split(",")[1].trim(),
  //                   endtime: enddate.split(",")[1].trim(),
  //                 });
  //                 return acc;
  //               },
  //               {}
  //             )
  //           );

  //           setFilteredSlotsMain(transformedData); // Set filtered slots
  //         })
  //       //   .then(function sortByStartTime(schedule, dateString) {
  //       //     const dateToCompare = new Date(dateString);
  //       //     return schedule.slots.sort((a, b) => {
  //       //         const startTimeA = parseTime(a.starttime);
  //       //         const startTimeB = parseTime(b.starttime);
  //       //         if (startTimeA > startTimeB) {
  //       //             return 1;
  //       //         } else if (startTimeA < startTimeB) {
  //       //             return -1;
  //       //         } else {
  //       //             // If start times are equal, compare end times
  //       //             const endTimeA = parseTime(a.endtime);
  //       //             const endTimeB = parseTime(b.endtime);
  //       //             if (endTimeA > endTimeB) {
  //       //                 return 1;
  //       //             } else if (endTimeA < endTimeB) {
  //       //                 return -1;
  //       //             } else {
  //       //                 // If start and end times are equal, maintain original order
  //       //                 return 0;
  //       //             }
  //       //         }
  //       //     });

  //       //     function parseTime(timeString) {
  //       //         const [hour, minute] = timeString.split(':').map(Number);
  //       //         return hour * 60 + minute;
  //       //     }
  //       // })
  //         .catch((error) => {
  //           console.error("Error fetching available slots:", error);
  //         });
  //     } catch (error) {
  //       // setError(error.message);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchData();

  //   function formatDate(date) {
  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero indexed
  //     const day = String(date.getDate()).padStart(2, "0");

  //     return `${year}-${month}-${day}`;
  //   }
  //   // Usage
  //   // const today = new Date();
  //   const formattedDate = formatDate(new Date());
  //   const selectedDateNew = formatDate(selectedDate);
  //   console.log(
  //     "formattedDate",
  //     formattedDate,
  //     "selectedDateNew",
  //     selectedDateNew
  //   ); // Output: "2024-05-06"

  //   formattedDate === selectedDateNew ? setIndexOfDate(0) : setIndexOfDate(0);

  // }, [selectedDate]);

  console.log("indexOfDate", indexOfDate);
  console.log("filteredSlotsMain", filteredSlotsMain);

  const handleCheckboxChange = (selectedDate, timeSlot) => {
    const slotStarttime = timeSlot.start;
    console.log("selectedDate in handleCheckboxChange", selectedDate);
    const isSelected =
      selectedTimeSlots[selectedDate] &&
      selectedTimeSlots[selectedDate].includes(slotStarttime);

    if (isSelected) {
      // If already selected, remove from selectedTimeSlots
      const updatedSelectedTimeSlots = {
        ...selectedTimeSlots,
        [selectedDate]: selectedTimeSlots[selectedDate].filter(
          (time) => time !== slotStarttime
        ),
      };
      setBtndesable(true);
      setSelectedTimeSlots(updatedSelectedTimeSlots);
    } else {
      // If not selected, add to selectedTimeSlots
      const updatedSelectedTimeSlots = {
        ...selectedTimeSlots,
        [selectedDate]: selectedTimeSlots[selectedDate]
          ? [...selectedTimeSlots[selectedDate], slotStarttime]
          : [slotStarttime],
      };
      setSelectedTimeSlots(updatedSelectedTimeSlots);
      setBtndesable(false);
    }
  };

  // function convertToTimeZone(dateTimeString, timeZone) {
  //   const utcDateTime = moment.utc(dateTimeString, "YYYY-MM-DD h:mm:ss a");
  //   const dateTimeInTimeZone = utcDateTime.tz("America/Chicago").format();
  //   return dateTimeInTimeZone;
  // }

  // function convertArrayToTimeZone(timeArray, timeZone) {
  //   // Initialize an array to store the UTC time strings
  //   const utcTimeArray = [];

  //   // Check if timeArray is an array
  //   if (Array.isArray(timeArray)) {
  //     // Iterate over each time in the array
  //     for (const timeString of timeArray) {
  //       // Parse the time string using moment.js, specifying the format and time zone
  //       const utcDateTime = moment.tz(timeString, "h:mm:ss a", timeZone).utc();

  //       // Convert the time to UTC format
  //       const utcTimeString = utcDateTime.format();

  //       // Push the UTC time string to the array
  //       utcTimeArray.push(utcTimeString);
  //     }
  //   } else {
  //     // If timeArray is not an array, assume it's a single time string
  //     const utcDateTime = moment.tz(timeArray, "h:mm:ss a", timeZone).utc();
  //     const utcTimeString = utcDateTime.format();
  //     utcTimeArray.push(utcTimeString);
  //   }

  //   return utcTimeArray;
  // }

  const Userselected = `${selectedDate.toLocaleDateString()} ${
    selectedTimeSlots[selectedDate]
  }`;
  console.log("Userselected", Userselected);
  // const utcDateTime = convertToTimeZone(Userselected);
  // function convertArrayToTimeZone(dateString, timeArray, timeZone) {
  //   // Initialize an array to store the UTC time strings
  //   const utcTimeArray = [];

  //   // Parse the date string using moment.js
  //   const date = moment(dateString, "DD/MM/YYYY");

  //   // Check if timeArray is an array
  //   if (Array.isArray(timeArray)) {
  //     // Iterate over each time in the array
  //     for (const timeString of timeArray) {
  //       // Combine date with time
  //       const dateTimeString = date.format("DD/MM/YYYY") + " " + timeString;
  //       // Parse the combined date-time string using moment.js, specifying the format and time zone
  //       const utcDateTime = moment
  //         .tz(dateTimeString, "DD/MM/YYYY h:mm:ss a", timeZone)
  //         .utc();

  //       // Convert the time to UTC format
  //       const utcTimeString = utcDateTime.format();

  //       // Push the UTC time string to the array
  //       utcTimeArray.push(utcTimeString);
  //     }
  //   } else {
  //     // If timeArray is not an array, assume it's a single time string
  //     const dateTimeString = date.format("DD/MM/YYYY") + " " + timeArray;
  //     const utcDateTime = moment
  //       .tz(dateTimeString, "DD/MM/YYYY h:mm:ss a", timeZone)
  //       .utc();
  //     const utcTimeString = utcDateTime.format();
  //     utcTimeArray.push(utcTimeString);
  //   }

  //   return utcTimeArray;
  // }

  // Early return if loading

  if (IsLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="DashBoard">
      {/* DashBoard */}
      <div className="">
        <div className="d-flex justify-content">
          <div className="col-2">
            <AdminSideBar />
          </div>
          <div className="col-10 backggroundForDashboard p-3">
            <div className="d-flex">
              <BreadCrumb />
              <div className="px-4 py-3">
                <div className="d-flex justify-content-between">
                  <FaCircleUser size={25} className="mt-1 mx-2" />
                  <div className="align-middle mt-2">
                    {localStorage.getItem("adminName")}
                  </div>
                </div>
              </div>
            </div>
            <hr className=" my-1" />

            {bashBoardData && (
              <div className="d-flex justify-content-between mt-3">
                <div className="col-4 pe-3 ">
                  <div className="rounded-3 bg-white p-3">
                    <div className="">
                      <div className="">
                        <p style={{ color: "#9EA7B8" }}>
                          <LuUsers2 size={37} />
                        </p>
                        <div className="d-flex justify-content">
                          <div className="col-8 text-start">
                            <h5>Total Registered Users</h5>
                          </div>

                          <div className="col-4 text-end">
                            <h5>{bashBoardData?.totalRegisteredUsers}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 pe-3 ">
                  <div className="rounded-3 bg-white p-3">
                    <div className="">
                      <div className="">
                        <p style={{ color: "#9EA7B8" }}>
                          <CiBank size={37} />
                        </p>
                        <div className="d-flex justify-content">
                          <div className="col-8 text-start">
                            <h5>Total Added Lenders</h5>
                          </div>

                          <div className="col-4 text-end">
                            <h5>{bashBoardData?.totalBanks}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4  ">
                  <div className="rounded-3 bg-white p-3">
                    <div className="">
                      <div className="">
                        <p style={{ color: "#9EA7B8" }}>
                          <FaGraduationCap size={37} />
                        </p>
                        <div className="d-flex justify-content">
                          <div className="col-8 text-start">
                            <h5>Total Scholarships</h5>
                          </div>

                          <div className="col-4 text-end">
                            <h5>{bashBoardData?.totalScholarships}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* //Apllications receved section */}
            <div className=" mt-3 backgroundForMainContainer p-3">
              <h3>Add Universities</h3>

              <div className="d-flex justify-content-between"></div>

              <div className=" rounded-3 ">
                <div className="d-flex">
                  <div className="col-8 rounded-3 bg-white p-3">
                    <div className="d-flex justify-content-start">
                      <div className="p-1 rounded-3   col-6">
                        <p>Select the city you want to add university in</p>
                        <form className="">
                          <select
                            name="cars"
                            id="cars"
                            className="p-2  shadow-none form-select rounded-3 col-12"
                            //shadow-none  form-select rounded-3 col-12
                            onChange={(e) => handleCitychange(e)}
                            // onChange={(e) => setSelectedCity(e.target.value)}
                            style={{ top: "300%" }}
                          >
                            <option value="CHICAGO">CHICAGO</option>
                            <option value="NEW YORK">NEW YORK</option>
                            <option value="SAN FRANCISCO">SAN FRANCISCO</option>
                            <option value="LOS ANGELES">LOS ANGELES</option>
                          </select>

                          {/* <input type="submit" value="Submit" /> */}
                        </form>
                        <ToastContainer />

                        <div className="d-flex justify-content-between">
                          <h3 className="mt-3">Top Universities</h3>
                          {!editPermission ? (
                            <button
                              type="button"
                              className="btn btn-outline-secondary mt-3 px-5 h-50 btn-sm"
                              onClick={() => setEditPermission(!editPermission)}
                            >
                              Edit
                            </button>
                          ) : null}
                        </div>

                        {editPermission ? (
                          <div>
                            <div className="forBackgroundColorInput p-3">
                              <label>University 1</label>
                              <input
                                className="form-control mt-2   shadow-none  input forInputFiled"
                                type="text"
                                name="uni1"
                                // value={"Harvard University"}

                                value={
                                  selectedCityData?.top5Universities?.uni1
                                    ? selectedCityData?.top5Universities?.uni1
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                      selectedCityData?.top5Universities?.uni1?.slice(
                                        1
                                      )
                                    : ""
                                }
                                // value={selectedCityData?.top5Universities?.uni1}
                                onChange={handleUnivercityChange}
                                readOnly={!editPermission}
                              />
                              <label className="mt-1">University 2</label>
                              <input
                                className="form-control  shadow-none  input  forInputFiled"
                                type="text"
                                name="uni2"
                                value={
                                  selectedCityData?.top5Universities?.uni2
                                    ? selectedCityData?.top5Universities?.uni2
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                      selectedCityData?.top5Universities?.uni2?.slice(
                                        1
                                      )
                                    : ""
                                }
                                // value={selectedCityData?.top5Universities?.uni2}
                                onChange={handleUnivercityChange}
                                // value={"Harvard University"}
                                readOnly={!editPermission}
                              />
                              <label className="mt-1">University 3</label>
                              <input
                                className="form-control shadow-none  input forInputFiled"
                                type="text"
                                name="uni3"
                                value={
                                  selectedCityData?.top5Universities?.uni3
                                    ? selectedCityData?.top5Universities?.uni3
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                      selectedCityData?.top5Universities?.uni3?.slice(
                                        1
                                      )
                                    : ""
                                }
                                // value={selectedCityData?.top5Universities?.uni3}
                                onChange={handleUnivercityChange}
                                // value={"Harvard University"}
                                readOnly={!editPermission}
                              />
                              <label className="mt-1">University 4</label>
                              <input
                                className="form-control shadow-none  input forInputFiled"
                                type="text"
                                name="uni4"
                                value={
                                  selectedCityData?.top5Universities?.uni4
                                    ? selectedCityData?.top5Universities?.uni4
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                      selectedCityData?.top5Universities?.uni4?.slice(
                                        1
                                      )
                                    : ""
                                }
                                // value={selectedCityData?.top5Universities?.uni4}
                                onChange={handleUnivercityChange}
                                // value={"Harvard University"}
                                readOnly={!editPermission}
                              />
                              <label className="mt-1">University 5</label>
                              <input
                                className="form-control shadow-none  input forInputFiled"
                                type="text"
                                name="uni5"
                                // value={"Harvard University"}
                                // onChange={handleUnivercityChange}
                                value={
                                  selectedCityData?.top5Universities?.uni5
                                    ? selectedCityData?.top5Universities?.uni5
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                      selectedCityData?.top5Universities?.uni5?.slice(
                                        1
                                      )
                                    : ""
                                }
                                // value={selectedCityData?.top5Universities?.uni5}
                                onChange={handleUnivercityChange}
                                readOnly={!editPermission}
                              />

                              <button
                                type="button"
                                className="mt-3 py-2  btn btn-primary editButton ForRowfont p-0 px-4"
                                onClick={() => saveChangesunivercity()}
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="mt-3">
                            <p>
                              1. {selectedCityData?.top5Universities?.uni1}{" "}
                            </p>
                            <p>
                              2. {selectedCityData?.top5Universities?.uni2}{" "}
                            </p>
                            <p>
                              3. {selectedCityData?.top5Universities?.uni3}{" "}
                            </p>
                            <p>
                              4. {selectedCityData?.top5Universities?.uni4}{" "}
                            </p>
                            <p>
                              5. {selectedCityData?.top5Universities?.uni5}{" "}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="col-6 ms-3 ">
                        <div className=" h-100 text-center ">
                          <img
                            src={
                              // require('../assests/img/bostondashboard.png')
                              selectedCityData?.img
                            }
                            className="card-img-top img-fluid px-4"
                            alt="..."
                          />
                          <p className="p-3" style={{ textAlign: "justify" }}>
                            {" "}
                            {selectedCityData?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="  rounded-5 ms-3  winner-Section">
                      {winnerData.length !== 0 ? (
                        <div className=" rounded-3 bg-white p-3  mh-50">
                          <div className="mt-3">
                            <h4 className="mt-3 "> Recent 3 Winners</h4>
                          </div>
                          <div
                            className="overflow-y-scroll"
                            style={{ maxHeight: "520px" }}
                            git
                          >
                            {winnerData &&
                              winnerData?.slice(0, 3).map((winner, index) => (
                                <div key={index} className="mb-3">
                                  {winner && winner?.scholarship ? (
                                    <div className=" shadow p-3  rounded-3">
                                      <h6>
                                        {winner?.scholarship?.scholarshipName}
                                      </h6>
                                      <hr />
                                      <div className=" d-flex justify-content-between mt-2">
                                        <div className="col-5">
                                          <p className="dates">Start Date</p>
                                        </div>
                                        <div className="col-6">
                                          <p className="dates">
                                            {winner &&
                                              new Date(
                                                winner?.scholarship?.createdAt
                                              )?.toLocaleDateString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                              })}
                                          </p>
                                        </div>
                                      </div>
                                      <div className=" d-flex justify-content-between mt-2">
                                        <div className="col-5">
                                          <p className="dates">End Date</p>
                                        </div>
                                        <div className="col-6">
                                          <p className="dates">
                                            {winner &&
                                              new Date(
                                                winner?.scholarship?.Deadline
                                              )?.toLocaleDateString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                              })}
                                          </p>
                                        </div>
                                      </div>
                                      <div className=" d-flex justify-content-between mt-2">
                                        <div className="col-5">
                                          <p className="applicants">
                                            Total Applicants
                                          </p>
                                        </div>
                                        <div className="col-6">
                                          <p className="applicants">
                                            {winner &&
                                              winner?.scholarship?.appliedUsers
                                                ?.length}
                                          </p>
                                        </div>
                                      </div>
                                      <div className=" d-flex justify-content-between mt-2">
                                        <div className="col-5">
                                          <p className="applicants">
                                            Scholarship Amount
                                          </p>
                                        </div>
                                        <div className="col-6">
                                          <p className="applicants">
                                            {currency ? currency : "$"}
                                            {formatAmount(
                                              winner?.scholarship?.amount
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className=" mt-2">
                                        <div>
                                          <p className="applicants">
                                            Declared winner{" "}
                                          </p>
                                        </div>
                                        <div className=" d-flex justify-content-start">
                                          <div className="col-2">
                                            <div
                                              className={
                                                winner?.user?.profileImg
                                                  ? " winner_background"
                                                  : ""
                                              }
                                            >
                                              {/* winner_background */}
                                              {winner?.user?.profileImg ? (
                                                <div>
                                                  <img
                                                    src={
                                                      winner?.user?.profileImg
                                                    }
                                                    className=" rounded-circle"
                                                    alt=""
                                                    height={"50px"}
                                                    width={"50px"}
                                                  />
                                                </div>
                                              ) : (
                                                <div className="">
                                                  <FaCircleUser
                                                    size={50}
                                                    className="fill-rule oddeven"
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="mx-3 my-lg-3">
                                            <h6>{winner?.user?.name}</h6>
                                          </div>
                                          <div className="mx-3 my-lg-3">
                                            <h6 className=" text-end">
                                              {currency ? currency : "$"}
                                              {formatAmount(
                                                winner?.scholarship?.amount
                                              )}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : // <p>
                                  //   Error: Winner or scholarship data is
                                  //   missing.
                                  // </p>
                                  null}
                                </div>
                              ))}
                          </div>
                        </div>
                      ) : (
                        <div className=" rounded-3 bg-white p-3  mh-50">
                          <div
                            className="overflow-y-scroll"
                            style={{ height: "420px" }}
                          >
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ height: "100%" }}
                            >
                              <h5 className="text-center pt-5 mt-5">
                                There are currently no declared winners yet.
                              </h5>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/*banner section  */}
              <div className="d-flex justify-content-between mt-3 ">
                <div className="col-6  rounded-3 pe-2">
                  <div
                    className="shadow p-3 bg-white rounded-3"
                    style={{ height: "100%" }}
                  >
                    <div className="">
                      <div className="" style={{ width: "100%" }}>
                        <h4>Banner Image</h4>

                        <h6 className="text-secondary">Add New Banner Image</h6>

                        <div className="file-upload-box ">
                          <label
                            htmlFor="fileInput"
                            className="file-label text-primary cursor-pointer"
                          >
                            {/* {selectedFile ? selectedFile.name : 'Choose a file'} */}
                            <div className="text-center">
                              <TiCloudStorageOutline size={35} />
                            </div>
                            <p className="text-dark">
                              Browse and choose the files you want to upload
                              from your system.{" "}
                            </p>
                          </label>
                          <input
                            type="file"
                            id="fileInput"
                            className="file-input"
                            onChange={handleFileChange}
                            accept="image/*"
                          />
                        </div>
                        <h5 className="mt-2">Preview</h5>
                        {previewImage && (
                          <div className="mt-2">
                            <img
                              id="fileInput"
                              src={previewImage === null ? null : previewImage}
                              alt=""
                              width="100%"
                              height="auto"
                              className="preview"
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-6 mt-2">
                        <button
                          onClick={handleModalSaveClick}
                          className="btn btn-primary w-100"
                          disabled={!forSaveButton}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6  rounded-3  ">
                  <div className="bg-white rounded-3 p-3">
                    <h5>Current Banner Image</h5>
                    <div
                      className={
                        imageShow === true
                          ? "dasboard-img-container1 border-primary border border-4 rounded-3"
                          : "dasboard-img-container1  rounded-3"
                      }
                    >
                      {updateData && updateData.length > 0 && (
                        <img
                          src={updateData[0]}
                          alt=""
                          width={"100%"}
                          height={150}
                          className="dashboard-image1 rounded-3 p-1 pb-3 mt-1"
                        />
                      )}
                      <PiArrowUDownLeft
                        size={25}
                        className="bg-white rounded-2 dashboard-image1"
                        onClick={() => handleImageClick(0)}
                      />
                      <RxCross2
                        size={26}
                        onClick={() => handleModal1Click(0)}
                        className="bg-white rounded-2 dashboard-image1"
                      />
                    </div>

                    <div
                      className={
                        imageShow2 === true
                          ? "dasboard-img-container1 border-primary border border-4 rounded-3"
                          : "dasboard-img-container1  rounded-3"
                      }
                    >
                      {updateData && updateData.length > 1 && (
                        <img
                          src={updateData[1]}
                          alt=""
                          width={"100%"}
                          height={150}
                          className="dashboard-image1 rounded-3 p-1 pb-3 mt-1"
                        />
                      )}
                      <PiArrowUDownLeft
                        size={25}
                        className="bg-white rounded-2 dashboard-image1"
                        onClick={() => handleImageClick2(1)}
                      />
                      <RxCross2
                        size={26}
                        onClick={() => handleModal1Click(1)}
                        className="bg-white rounded-2 dashboard-image1"
                      />
                    </div>

                    <div
                      className={
                        imageShow3 === true
                          ? "dasboard-img-container1 border-primary border border-4 rounded-3"
                          : "dasboard-img-container1  rounded-3"
                      }
                    >
                      {updateData && updateData.length > 2 && (
                        <img
                          src={updateData[2]}
                          alt=""
                          width={"100%"}
                          height={150}
                          className="dashboard-image1 rounded-3 p-1 pb-3 mt-1"
                        />
                      )}
                      <PiArrowUDownLeft
                        size={25}
                        className="bg-white rounded-2 dashboard-image1"
                        onClick={() => handleImageClick3(2)}
                      />
                      <RxCross2
                        size={26}
                        onClick={() => handleModal1Click(2)}
                        className="bg-white rounded-2 dashboard-image1"
                      />
                    </div>

                    <p className="mt-2">
                      This is the Current banner image live on the home page of
                      the website. The dimension of the image is 1400 x 720
                    </p>
                  </div>
                </div>
              </div>

              {/* -----------------------one to one session-------------------- */}
              <div className=" shadow my-3 p-3 bg-body rounded-3 col-6">
                <h3>Manage 1:1 Session Availability</h3>
                <div className=" backgroundForMainContainer  rounded-2  px-4">
                  <label htmlFor="unAvailibility" className="form-label mt-3">
                    Select the date of unavailibility
                  </label>
                  <input
                    required
                    type="date"
                    name="unAvailibilityDate"
                    id="unAvailibilityDate"
                    value={
                      selectedDate
                        ? selectedDate.toISOString().split("T")[0]
                        : ""
                    }
                    onChange={handleDateChange}
                    className="form-control border-2 w-100  no-clear-button "
                    style={{
                      fontWeight: "400",
                      border: "revert-layer",
                      outline: "none",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      marginBottom: ".5rem",
                      paddingLeft: ".5rem",
                    }}
                  />
                  {filteredSlotsMain?.length >= 1 ? (
                    <div
                      className=""
                      style={{ height: "350px", overflow: "auto" }}
                    >
                      {filteredSlotsMain?.length >= 1 ? (
                        filteredSlotsMain?.map((timeSlot) => (
                          <div
                            key={timeSlot.start}
                            className="form-control d-flex align-items-center w-100 mt-1"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              id={`${selectedDate}-${timeSlot}`}
                              className="me-2 "
                              checked={
                                selectedTimeSlots[selectedDate] &&
                                selectedTimeSlots[selectedDate].includes(
                                  timeSlot.start
                                  // timeSlot.starttime
                                )
                              }
                              onChange={() =>
                                handleCheckboxChange(selectedDate, timeSlot)
                              }
                              style={{
                                fontWeight: ".5rem",
                                width: "15px",
                                height: "15px",
                              }}
                            />
                            <label
                              className=""
                              htmlFor={`${selectedDate}-${timeSlot}`}
                            >
                              {console.log(timeSlot.start.length)}

                              {moment(timeSlot.start)
                                .tz("America/Chicago")
                                .format("hh:mm A")}
                            </label>
                          </div>
                        ))
                      ) : (
                        <option value="" disabled>
                          Please try next day.
                        </option>
                      )}
                    </div>
                  ) : (
                    <>
                      <div className=" ">
                        <p className=" py-3">
                          All the slots for this day is booked.
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <br />
                <button
                  type="button"
                  disabled={Btndesable}
                  onClick={() => handleSubmit()}
                  className="btn btn-primary w-100 mb-3"
                >
                  Confirm
                </button>
              </div>
            </div>
            {/* </div> */}

            {/* -----------------------delete modal index-1-------------------- */}

            {showmodal === true ? (
              <div>
                <Modal show={showmodal} onHide={handleClose}>
                  <Modal.Header closeButton className="p-0 px-3 ">
                    <Modal.Title className="mb-0 pb-0 mt-3">
                      Delete Banner ?
                    </Modal.Title>
                  </Modal.Header>

                  <p className="px-3 ">
                    Are you sure you want to delete this banner image ?
                  </p>

                  <Modal.Footer className="border-0">
                    <Button
                      variant="secondary"
                      className="px-4"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      className="px-4"
                      onClick={handleDeleteClick}
                    >
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            ) : (
              ""
            )}

            <Modal show={saveModal} onHide={handleSaveClose}>
              <Modal.Header closeButton className="p-0 px-3">
                <Modal.Title className="mb-0 pb-0 mt-3">
                  {" "}
                  Save Banner ?
                </Modal.Title>
              </Modal.Header>
              {/* <Modal.Body className="mt-0 pt-0"> */}
              <p className="px-3 pt-0">
                {" "}
                Are you sure you want to save this banner image ?
              </p>

              {/* </Modal.Body> */}
              <Modal.Footer className="border-0">
                <Button
                  variant="secondary"
                  className="px-4"
                  onClick={handleSaveClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="px-4"
                  onClick={handleSaveClick}
                >
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
