import React from "react";
import { useState } from "react";
import AdminSideBar from "./AdminSideBar";
import "../styles/EditSchlorship.css";
import { useLocation, useNavigate } from "react-router-dom";
import { FaGraduationCap } from "react-icons/fa";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { GoDotFill } from "react-icons/go";

import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import BreadCrumb from "./BreadCrumb";
import { FaCircleUser } from "react-icons/fa6";
import {
  CountryDropdown,
  RegionDropdown,
  // CountryRegionData,
} from "react-country-region-selector";
import { RxCrossCircled } from "react-icons/rx";
import { format } from "date-fns";
// import { API_BASE_URL } from "../../appConfig";
const EditSchlorship = () => {
  const [currency, setCurrency] = useState('');
  // to delete modal
  const [showDelete, setShowDelete] = useState(false);
  const editlocation = useLocation();

  const { tablerow, addtionalData } = editlocation.state || {};
  const [isClickedEditOne, setIsClickedEditOne] = useState(
    tablerow.scholarshipName === "path2study" ? false : true
  );

  const [isClickedEditTwo, setIsClickedEditTwo] = useState(
    tablerow.scholarshipName === "path2study" ? true : false
  );

  const [urlError, setUrlError] = useState("");
  const [amountError, setAmountError] = useState("");

  //To navigate
  const editNavigate = useNavigate();
  const handleDivClickEditOne = () => {
    setIsClickedEditOne(true);
    setIsClickedEditTwo(false);
    editNavigate("/admin/scholarship-management");
  };
  const handleDivClickEditTwo = () => {
    setIsClickedEditTwo(true);
    setIsClickedEditOne(false);
    editNavigate("/admin/scholarship-management");
  };

  const [editedData, setEditedData] = useState(tablerow);
  const [recreateData, setRecreateData] = useState(tablerow);
  const [finalEdit, setFinalEdit] = useState({});

  const [ethinicityOtherInput, setEthinicityOtherInput] = useState(() => {
    if (
      editedData.ethnicity !== "American Indian or Alaska Native" &&
      editedData.ethnicity !== "Asian" &&
      editedData.ethnicity !== "Black or African American" &&
      editedData.ethnicity !== "Native Hawaiian or Pacific Islander" &&
      editedData.ethnicity !== "White/Caucasian"
    ) {
      return editedData.ethnicity;
    } else {
      return "";
    }
  });

  const [forInputEhincity, setForInputEhincity] = useState(() => {
    if (
      editedData.ethnicity !== "American Indian or Alaska Native" &&
      editedData.ethnicity !== "Asian" &&
      editedData.ethnicity !== "Black or African American" &&
      editedData.ethnicity !== "Native Hawaiian or Pacific Islander" &&
      editedData.ethnicity !== "White/Caucasian"
    ) {
      return editedData.ethnicity;
    } else {
      return "";
    }
  });

  const status = editedData.status;

  console.log("editedData",editedData)

  const handleInputEditChange = (e) => {
    const { name, value } = e.target;
    
    if (
      status
      //  === "upcoming"
    ) {
      if (name === "redirectionalUrl") {
        setUrlError("");

        setEditedData((prevData) => ({
          ...prevData,
          [name]: value,
        }));

        setFinalEdit((prevDataOne) => ({
          ...prevDataOne,
          [name]: value,
        }));
      } else if (name === "amount") {
        const regex = /^(\d*\.?\d{0,9}|\.\d{1,9})?$/;

        if (regex.test(e.target.value)) {
          setEditedData((prevData) => ({
            ...prevData,
            [name]: value,
          }));

          setFinalEdit((prevDataOne) => ({
            ...prevDataOne,
            [name]: value,
          }));
          setAmountError("");
        } else {
          setAmountError("Enter valid amount");
        }
      } else if (e.target.name === "currency") {
        console.log("formData.currency",editedData.currency)
        setCurrency(e.target.value)
        setEditedData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      } else {
        setEditedData((prevData) => ({
          ...prevData,
          [name]: value,
        }));

        setFinalEdit((prevDataOne) => ({
          ...prevDataOne,
          [name]: value,
        }));
      }
    }

    const status1 = status === "live" || status === "expired";
    if (
      // status1 &&
      e.target.name
      // === "Deadline"
    ) {
      setFinalEdit((prevDataOne) => ({
        ...prevDataOne,
        [name]: value,
      }));
      setEditedData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  //code to check other high scholl education
  const data_array = [
    "High School",
    "Under Graduate",
    "Post Graduate",
    "MBA",
    "PhD",
  ];

  // Check if any element is not equal to the specified strings
  const indicesOne = editedData?.currentEducationalLevel.filter(
    (element) => !data_array.includes(element)
  );
  const [indices, setIndices] = useState(indicesOne);
  const handleInputChange = (event) => {
    const updatedIndices = [...indices];
    updatedIndices[0] = event.target.value;
    setIndices(updatedIndices);
    setInputValue(event.target.value);
  };

  const [inputValue, setInputValue] = useState(
    indices.length > 0 ? indices[0] : ""
  );

  let adminToken = localStorage.getItem("adminToken");
  const headers = {
    authorization: `bearer ${adminToken}`,
  };

  let adminId = localStorage.getItem("adminId");

  //for modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const handleDiscard = () => {
    // setEditedData(tablerow);
    setShowDelete(false);

    let scholarshipId = editedData._id;

    let adminId = localStorage.getItem("adminId");
    let adminToken = localStorage.getItem("adminToken");
    const headers = {
      authorization: `bearer ${adminToken}`,
    };
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/admin/deleteScholarship/${adminId}/${scholarshipId}`,

        { headers: headers }
      )
      .then((response) => {
        toast.success("scholarship deleted succesfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          editNavigate("/admin/scholarship-management/");
        }, 3000);
      })

      .catch((error) => {});
  };

  const forInputField = {
    fontWeight: "400",
    border: "revert-layer",
    outline: "none",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderWidth: "1px",
  };

  //for recreate modal
  const [showRecreate, setShowRecreate] = useState(false);

  const handleRecreateDataChange = (event) => {
    // setRecreateData({...recreateData,[e.target.name]:e.target.value });

    const { name, value } = event.target;
    const updatedData = { ...recreateData };
    delete updatedData._id;
    delete updatedData.winnerChosen;
    delete updatedData.isDeleted;
    delete updatedData.status;
    delete updatedData.updatedAt;
    delete updatedData.createdAt;
    delete updatedData.__v;
    delete updatedData.appliedUsers;

    setRecreateData((prevData) => ({
      ...updatedData,
      [name]: value,
    }));
  };

  const handleCreate = () => {
    const filteredObjectTosendRecreate = Object.fromEntries(
      Object.entries(recreateData).filter(([key, value]) => value !== "")
    );
    if (recreateData?.ethnicity === "other") {
      delete filteredObjectTosendRecreate?.ethnicity;
      filteredObjectTosendRecreate.ethnicity = ethinicityOtherInput;
    }

    if (checkGender.genderOne) {
      UpdatedgenderStatus.push("Male");
    }
    if (checkGender.genderTwo) {
      UpdatedgenderStatus.push("Female");
    }
    if (checkGender.genderThree) {
      UpdatedgenderStatus.push("Other");
    }

    if (checkEducation.educationOne) {
      schlorshipAvailabelArrrayTwo.push("High School");
    }
    if (checkEducation.educationTwo) {
      schlorshipAvailabelArrrayTwo.push("Under Graduate");
    }
    if (checkEducation.educationThree) {
      schlorshipAvailabelArrrayTwo.push("Post Graduate");
    }
    if (checkEducation.educationFour) {
      schlorshipAvailabelArrrayTwo.push("MBA");
    }
    if (checkEducation.educationFive) {
      schlorshipAvailabelArrrayTwo.push("PhD");
    }

    if (checkEducation.educationSix) {
      schlorshipAvailabelArrrayTwo.push(indices[0]);
    }

    if (checkCitizenShip.first) {
      citizenShipArray.push("US Citizen");
    }
    if (checkCitizenShip.second) {
      citizenShipArray.push("Permanent Resident (Green Card Holder)");
    }
    if (checkCitizenShip.third) {
      citizenShipArray.push("Nonimmigrant");
    }

    filteredObjectTosendRecreate.gender = UpdatedgenderStatus;

    filteredObjectTosendRecreate.citizenshipStatus = citizenShipArray;

    const courseNameString = filteredObjectTosendRecreate?.courseName;

    let arr = courseNameString !== "" ? courseNameString?.split(",") : [];

    // Now 'arr' will be an array containing the substrings separated by commas

    if (filteredObjectTosendRecreate.courseName !== "")
      filteredObjectTosendRecreate.courseName = arr?.map((item) =>
        item?.trim()
      );
    filteredObjectTosendRecreate.currentEducationalLevel =
      schlorshipAvailabelArrrayTwo;
    delete filteredObjectTosendRecreate._id;

    if (filteredObjectTosendRecreate.scholarshipProvider == "") {
      filteredObjectTosendRecreate.scholarshipProvider = "Anonymous";
    }
    if (filteredObjectTosendRecreate?.hasOwnProperty("scholarshipProvider")) {
      if (filteredObjectTosendRecreate.scholarshipProvider == "") {
        filteredObjectTosendRecreate.scholarshipProvider = "Anonymous";
      }
    }

    if (filteredObjectTosendRecreate.ethnicity == "Select") {
      delete filteredObjectTosendRecreate.ethnicity;
      filteredObjectTosendRecreate.ethnicity = "ALL";
    }

    if (selectedCountryTwo !== "") {
      filteredObjectTosendRecreate.country = selectedCountryTwo;
    } else {
      filteredObjectTosendRecreate.country = "ALL";
    }

    filteredObjectTosendRecreate.state = stateArrayTwo;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/addScholarship/${adminId}`,
        filteredObjectTosendRecreate,
        { headers: headers }
      )

      .then((responce) => {
        setShowRecreate(false);

        toast.success("New scholarship added succesfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate("/admin/scholarship-management/");
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancelCreate = () => {
    setRecreateData(tablerow);
    setShowRecreate(false);
  };

  const ConvertedArray =
    (() => {
      try {
        return JSON.parse(editedData?.gender);
      } catch (error) {
        // Handle the parsing error, or you can just return the original string
        return editedData?.gender;
      }
    })() || editedData.gender;

  const [checkGender, setCheckGender] = useState({
    genderOne:
      Array.isArray(ConvertedArray) &&
      ConvertedArray?.some((element) => {
        return element === "Male";
      }),
    genderTwo:
      Array.isArray(ConvertedArray) &&
      ConvertedArray?.some((element) => {
        return element === "Female";
      }),
    genderThree:
      Array.isArray(ConvertedArray) &&
      ConvertedArray?.some((element) => {
        return element === "Other";
      }),
  });

  const convertedArrayTwo =
    (() => {
      try {
        return JSON.parse(editedData.currentEducationalLevel);
      } catch (error) {
        // Handle the parsing error, or you can just return the original string
        return editedData.currentEducationalLevel;
      }
    })() || editedData.currentE;

  const [checkEducation, setCheckEducation] = useState({
    educationOne:
      convertedArrayTwo &&
      Array.isArray(convertedArrayTwo) &&
      convertedArrayTwo.some((element) => element === "High School"),

    educationTwo:
      convertedArrayTwo &&
      Array.isArray(convertedArrayTwo) &&
      convertedArrayTwo.some((element) => element === "Under Graduate"),

    educationThree:
      convertedArrayTwo &&
      Array.isArray(convertedArrayTwo) &&
      convertedArrayTwo.some((element) => element === "Post Graduate"),

    educationFour:
      convertedArrayTwo &&
      Array.isArray(convertedArrayTwo) &&
      convertedArrayTwo.some((element) => element === "MBA"),

    educationFive:
      convertedArrayTwo &&
      Array.isArray(convertedArrayTwo) &&
      convertedArrayTwo.some((element) => element === "PhD"),

    educationSix: indices.length > 0,
  });

  const UpdatedgenderStatus = [];
  const schlorshipAvailabelArrrayTwo = [];

  const removeState = (i) => {
    setStateArray((prevArray) => {
      const newArray = [...prevArray];
      newArray.splice(i, 1);
      return newArray;
    });
  };
  const [stateArray, setStateArray] = useState(editedData.state);
  const [stateArrayTwo, setStateArrayTwo] = useState(editedData.state);
  const [selectedCountry, setSelectedCountry] = useState(editedData.country);
  const [selectedCountryTwo, setSelectedCountryTwo] = useState(
    editedData.country
  );
  const [selectedState, setSelectedState] = useState("");
  const [selectedStateTwo, setSelectedStateTwo] = useState("");

  const handleCountryChange = (country) => {
    setSelectedCountry(country);

    setSelectedState(""); // Reset state when country changes

    setStateArray([]);
  };
  const handleCountryChangeTwo = (country) => {
    setSelectedCountryTwo(country);

    setSelectedStateTwo(""); // Reset state when country changes

    setStateArrayTwo([]);
  };
  const handleStateChange = (state) => {
    if (!stateArray.includes(state)) {
      setSelectedState(state);
      setStateArray((prevArray) => [...prevArray, state]);
    }
  };

  const handleStateChangeTwo = (state) => {
    if (!stateArray.includes(state)) {
      setSelectedStateTwo(state);
      setStateArrayTwo((prevArray) => [...prevArray, state]);
    }
  };
  const citizenShipArray = [];
  const ConvertedArrayCitizenShip =
    (() => {
      try {
        return JSON.parse(editedData?.citizenshipStatus);
      } catch (error) {
        // Handle the parsing error, or you can just return the original string
        return editedData?.citizenshipStatus;
      }
    })() || editedData.citizenshipStatus;
  const [checkCitizenShip, setCheckCitizenShip] = useState({
    first:
      ConvertedArrayCitizenShip &&
      Array.isArray(ConvertedArrayCitizenShip) &&
      ConvertedArrayCitizenShip.some((element) => element === "US Citizen"),

    // false,
    second:
      ConvertedArrayCitizenShip &&
      Array.isArray(ConvertedArrayCitizenShip) &&
      ConvertedArrayCitizenShip.some(
        (element) => element === "Permanent Resident (Green Card Holder)"
      ),
    // false,
    third:
      ConvertedArrayCitizenShip &&
      Array.isArray(ConvertedArrayCitizenShip) &&
      ConvertedArrayCitizenShip.some((element) => element === "Nonimmigrant"),
    // false
  });

  const saveChanges = () => {
    setShow(false);

    let scholarshipId = editedData._id;

    if (checkGender.genderOne) {
      UpdatedgenderStatus.push("Male");
    }
    if (checkGender.genderTwo) {
      UpdatedgenderStatus.push("Female");
    }
    if (checkGender.genderThree) {
      UpdatedgenderStatus.push("Other");
    }

    if (checkEducation.educationOne) {
      schlorshipAvailabelArrrayTwo.push("High School");
    }
    if (checkEducation.educationTwo) {
      schlorshipAvailabelArrrayTwo.push("Under Graduate");
    }
    if (checkEducation.educationThree) {
      schlorshipAvailabelArrrayTwo.push("Post Graduate");
    }
    if (checkEducation.educationFour) {
      schlorshipAvailabelArrrayTwo.push("MBA");
    }
    if (checkEducation.educationFive) {
      schlorshipAvailabelArrrayTwo.push("PhD");
    }

    if (checkEducation.educationSix) {
      schlorshipAvailabelArrrayTwo.push(indices[0]);
    }

    if (checkCitizenShip.first) {
      citizenShipArray.push("US Citizen");
    }
    if (checkCitizenShip.second) {
      citizenShipArray.push("Permanent Resident (Green Card Holder)");
    }
    if (checkCitizenShip.third) {
      citizenShipArray.push("Nonimmigrant");
    }

    const filteredObjectTosendEdit = Object.fromEntries(
      Object.entries(finalEdit).filter(([key, value]) => value !== "")
    );

    filteredObjectTosendEdit.gender =
      // JSON.stringify(
      UpdatedgenderStatus;
    // );
    // UpdatedgenderStatus;

    // console.log(finalEdit.gender);
    filteredObjectTosendEdit.currentEducationalLevel =
      // JSON.stringify(
      schlorshipAvailabelArrrayTwo;
    // );

    filteredObjectTosendEdit.citizenshipStatus =
      // JSON.stringify(
      citizenShipArray;
    // );
    // schlorshipAvailabelArrrayTwo;

    filteredObjectTosendEdit.state = stateArray;

    let arr = filteredObjectTosendEdit?.courseName?.split(",");

    delete filteredObjectTosendEdit.courseName;
    if (filteredObjectTosendEdit.courseName !== "")
      filteredObjectTosendEdit.courseName = arr?.map((item) => item?.trim());

    if (selectedCountry !== "") {
      filteredObjectTosendEdit.country = selectedCountry;
    } else {
      filteredObjectTosendEdit.country = "ALL";
    }

    if (!filteredObjectTosendEdit.hasOwnProperty("highschoolUniversity")) {
      if (editedData.highschoolUniversity == "") {
        filteredObjectTosendEdit.highschoolUniversity = "ALL";
      }
    }

    if (editedData.scholarshipProvider == "") {
      filteredObjectTosendEdit.scholarshipProvider = "Anonymous";
    }
    if (filteredObjectTosendEdit.hasOwnProperty("scholarshipProvider")) {
      if (filteredObjectTosendEdit.scholarshipProvider == "") {
        filteredObjectTosendEdit.scholarshipProvider = "Anonymous";
      }
    }
    if (filteredObjectTosendEdit.ethnicity == "Select") {
      delete filteredObjectTosendEdit.ethnicity;
      filteredObjectTosendEdit.ethnicity = "ALL";
    }

    if (
      !forInputEhincity == "" &&
      editedData.ethnicity !== "American Indian or Alaska Native" &&
      editedData.ethnicity !== "Asian" &&
      editedData.ethnicity !== "Black or African American" &&
      editedData.ethnicity !== "Native Hawaiian or Pacific Islander" &&
      editedData.ethnicity !== "White/Caucasian"
    ) {
      delete filteredObjectTosendEdit.ethnicity;

      filteredObjectTosendEdit.ethnicity = forInputEhincity;
    }

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/admin/updateScholarship/${adminId}/${scholarshipId}`,
        filteredObjectTosendEdit,
        { headers: headers }
      )
      .then((response) => {
        console.log(response);

        toast.success("scholarship updated succesfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate("/admin/scholarship-management/");
        }, 3000);
      })
      .catch((error) => {});
  };

  return (
    <div className="EditSchlorship">
      <div className="">
        <div className="d-flex justify-content">
          <div className="col-2">
            <AdminSideBar />
          </div>
          <div className="col-10 py-3">
            <div className="px-3">
              <div className="d-flex">
                <BreadCrumb />
                <div className="py-3">
                  <div className="d-flex justify-content-between">
                    <FaCircleUser size={25} className="mt-1 mx-2" />
                    <div className="align-middle mt-2">
                      {localStorage.getItem("adminName")}
                    </div>
                  </div>
                </div>
              </div>
              <hr className=" mx-3" />
            </div>
            {/* -------------code to reuse-------- */}
            <div className="d-flex justify-content-between px-3 mt-2">
              <div className="col-4 pe-3 ">
                <div className="rounded-3 bg-white p-3">
                  <div className="">
                    <div className="">
                      <p>
                        <FaGraduationCap size={37} />
                      </p>
                      <div className="d-flex justify-content">
                        <div className="col-8 text-start">
                          <h5>Total Scholarships</h5>
                        </div>

                        <div className="col-4 text-end">
                          <h5>{addtionalData.TotalSchlorship}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 pe-3 ">
                <div
                  className="rounded-3 p-3 "
                  onClick={handleDivClickEditOne}
                  style={{
                    border: `2px solid ${
                      editedData.scholarshipProvider === "Path2Study"
                        ? "#4572bf"
                        : "white"
                    }`,
                    backgroundColor: `${
                      editedData.scholarshipProvider === "Path2Study"
                        ? "#F6FDFF"
                        : "white"
                    }`,
                    padding: "10px",
                    cursor: "pointer",
                    height: "100%",
                  }}
                  // style={{ height: "100%" }}
                >
                  <div className="d-flex justify-content-between">
                    <div className="p-2">
                      <h5
                        className="
                       mt-4"
                      >
                        Path2study Scholarships
                      </h5>
                    </div>

                    <div className="p-2">
                      <h5 className="mt-4">
                        {addtionalData.pathToStudySchlorship}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 ">
                <div
                  className="rounded-3 p-3"
                  onClick={handleDivClickEditTwo}
                  style={{
                    border: `2px solid ${
                      editedData.scholarshipProvider !== "Path2Study"
                        ? "#4572bf"
                        : "white"
                    }`,
                    backgroundColor: `${
                      editedData.scholarshipProvider !== "Path2Study"
                        ? "#F6FDFF"
                        : "white"
                    }`,
                    padding: "10px",
                    cursor: "pointer",
                    height: "100%",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div className="p-2">
                      <h5 className="mt-4">Other Provider’s Scholarships</h5>
                    </div>

                    <div className="p-2">
                      <h5 className="mt-4">{addtionalData.OtherSchlorship}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* -------------code to reuse-------- */}

            {/* edit  schlorship section */}
            <div className="px-3">
              <div className=" py-3 mt-3 px-5 rounded-3 schlorshipBackground">
                <div className="d-flex justify-content-between pt-1">
                  <div className="col-3">
                    <h3 className="">Edit Scholarship</h3>
                  </div>

                  <div
                    className="col-2 d-flex align-items-center"
                    style={{ backgroundColor: "#E9EFFF" }}
                  >
                    <p
                      style={{ margin: 0 }}
                      className={` d-inline-block ${
                        editedData.status === "live"
                          ? "text-primary"
                          : editedData.status === "expired"
                          ? "text-danger"
                          : editedData.status === "upcoming"
                          ? "text-warning"
                          : ""
                      }`}
                    >
                      <GoDotFill size={23} className="mt-1" />
                    </p>
                    <h6
                      className="mt-2"
                      style={{
                        margin: 0,
                        marginLeft: 5,
                        backgroundColor: "#E9EFFF",
                        textAlign: "center",
                      }}
                    >
                      {editedData.status?.charAt(0).toUpperCase() +
                        editedData.status?.slice(1)}
                    </h6>
                  </div>
                </div>
                <div className="d-flex justify-content-between rounded-3 bg-white mt-3 p-5">
                  <div className="col-6">
                    <div>
                      <form action="">
                        {/* dataToEdit */}
                        <label>Scholarship Name</label>
                        <input
                          className="form-control shadow-none  input forInputFiled"
                          type="text"
                          name="scholarshipName"
                          defaultValue={editedData.scholarshipName}
                          onChange={handleInputEditChange}
                          readOnly={
                            editedData?.status === "expired" &&
                            editedData?.winnerChosen === true
                          }
                          style={forInputField}
                        />
                        <div className="form-group mt-3">
                        <label>Currency:</label>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="currencyRupees"
                              value="₹"
                              name="currency"
                              onChange={handleInputEditChange}
                              checked={
                                editedData?.currency === "₹" ||
                                editedData?.currency === "rupees"
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="currencyRupees"
                            >
                              Rupees
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="currencyDollar"
                              value="$"
                              name="currency"
                              onChange={handleInputEditChange}
                              checked={
                                editedData?.currency === "$" ||
                                editedData?.currency === "doller"
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="currencyDollar"
                            >
                              Dollar
                            </label>
                          </div>
                        </div>

                        <label className="mt-3">Scholarship Amount</label>

                        <input
                          className="form-control shadow-none  input forInputFiled"
                          type="text"
                          name="amount"
                          defaultValue={editedData?.amount}
                          onChange={handleInputEditChange}
                          // readOnly={editedData?.status !== "upcoming"}
                          readOnly={
                            editedData?.status === "expired" &&
                            editedData?.winnerChosen === true
                          }
                          style={forInputField}
                        />
                        {amountError ? (
                          <h6 className="text-danger mt-3">{amountError}</h6>
                        ) : (
                          ""
                        )}
                        <label className="mt-3">Course Name </label>
                        <input
                          className="form-control shadow-none  input forInputFiled"
                          type="text"
                          name="courseName"
                          defaultValue={editedData?.courseName}
                          onChange={handleInputEditChange}
                          // readOnly={editedData?.status !== "upcoming"}
                          readOnly={
                            editedData?.status === "expired" &&
                            editedData?.winnerChosen === true
                          }
                          style={forInputField}
                        />

                        <label className="mt-3">
                          High School or University Name{" "}
                        </label>
                        <input
                          className="form-control shadow-none  input forInputFiled"
                          type="text"
                          placeholder="Enter High School or University Name"
                          style={forInputField}
                          name="highschoolUniversity"
                          value={editedData.highschoolUniversity}
                          readOnly={
                            editedData?.status === "expired" &&
                            editedData?.winnerChosen === true
                          }
                          // value={
                          //   formData?.courseName
                          //     ? formData?.courseName?.charAt(0)?.toUpperCase() +
                          //       formData?.courseName?.slice(1)
                          //     : ""
                          // }
                          onChange={handleInputEditChange}
                        />
                        <label className="mt-3">Start Date</label>
                        <input
                          className="form-control shadow-none  input forInputFiled"
                          type="date"
                          data-date-format="MMMM DD YYYY"
                          id="exampleDate"
                          name="startDate"
                          defaultValue={editedData?.createdAt}
                          onChange={handleInputEditChange}
                          // readOnly={editedData?.status !== "upcoming"}
                          readOnly={
                            editedData?.status === "expired" &&
                            editedData?.winnerChosen === true
                          }
                          style={forInputField}
                        />
                        <p className="mt-1 mb-0 pb-0 text-success">
                          {/* {editedData.startDate
                            ? 
                            new Date(
                                editedData.startDate
                              )?.toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              })
                            :
                             new Date(
                                editedData.createdAt
                              )?.toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              })} */}

                          {new Date(editedData.startDate)?.toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </p>
                        <label className="mt-3">Deadline</label>
                        <input
                          className="form-control shadow-none  input forInputFiled"
                          type="date"
                          data-date-format="MMMM DD YYYY"
                          id="exampleDate"
                          name="Deadline"
                          defaultValue={editedData?.Deadline}
                          onChange={handleInputEditChange}
                          readOnly={
                            editedData?.status === "expired" &&
                            editedData?.winnerChosen === true
                          }
                          style={forInputField}
                        />
                        <p className="mt-1 mb-0 pb-0 text-success">
                          {/* {
                          new Date(editedData?.Deadline)
                            .toISOString()
                            .split("T")[0]
                        } */}
                          {editedData?.Deadline &&
                            new Date(editedData?.Deadline)?.toLocaleDateString(
                              "en-US",
                              {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              }
                            )}
                        </p>

                        {editedData.scholarshipProvider === "Path2Study" ? (
                          <div>
                            <label htmlFor="" className="mt-2">
                              Scholarship Description
                            </label>
                            <textarea
                              className="form-control shadow-none  input forInputFiled"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="description"
                              defaultValue={editedData?.description}
                              onChange={handleInputEditChange}
                              readOnly={
                                editedData?.status === "expired" &&
                                editedData?.winnerChosen === true
                              }
                              // readOnly={editedData?.status !== "upcoming"}
                              style={forInputField}
                            ></textarea>
                            <label htmlFor="" className="mt-3">
                              Eligibility Requirements
                            </label>
                            <textarea
                              className="form-control shadow-none  input forInputFiled"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              defaultValue={editedData?.minimumRequirements}
                              name="minimumRequirements"
                              onChange={handleInputEditChange}
                              readOnly={
                                editedData?.status === "expired" &&
                                editedData?.winnerChosen === true
                              }
                              // readOnly={editedData?.status !== "upcoming"}
                              style={forInputField}
                            ></textarea>
                          </div>
                        ) : null}
                        {/* <label className="mt-3">Country </label>
                      <input
                        class="form-control shadow-none  input forInputFiled"
                        type="text"
                        name="Country"
                        value={editedData?.Country}
                        onChange={handleInputEditChange}
                        readOnly={editedData?.status !== "upcoming"}
                        style={forInputField}
                      /> */}

                        <div className="mt-3">
                          {/* Select Country */}
                          <label>Country</label>
                          <CountryDropdown
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            disabled={
                              editedData?.status === "expired" &&
                              editedData?.winnerChosen === true
                            }
                            classes="form-select"
                            style={forInputField}
                          />

                          {/* Display selected country */}
                          {/* {selectedCountry && (
                          <div>
                            <p className="mt-2">
                              Selected Country: {selectedCountry}
                            </p>
                          </div>
                        )} */}

                          {/* Select State based on the selected country */}
                          <div className="mt-3">
                            <label>State</label>
                            {selectedCountry && (
                              <RegionDropdown
                                country={selectedCountry}
                                value={selectedState}
                                onChange={handleStateChange}
                                style={forInputField}
                                disabled={
                                  editedData?.status === "expired" &&
                                  editedData?.winnerChosen === true
                                }
                                className="form-select"
                              />
                            )}
                          </div>

                          {/* Display selected state */}

                          <div>
                            {/* <p className="mt-3">Selected States:</p> */}

                            <div className="form-group">
                              {/* <label for="exampleFormControlTextarea1">
                                Example textarea
                              </label> */}
                            </div>
                            {
                              <p className="mt-3 border rounded-3 p-3">
                                {stateArray?.map((element, i) => {
                                  return (
                                    <span className="mx-2">
                                      {element}
                                      <RxCrossCircled
                                        size={16}
                                        //  onClick={(i)=>{
                                        // }}
                                        onClick={() => removeState(i)}
                                        className="mt-0"
                                      />
                                    </span>
                                  );
                                })}
                              </p>
                            }
                          </div>
                        </div>

                        <p className="mt-3 mb-0 pb-0">
                          Scholarship Available for All
                        </p>

                        <form className="">
                          <select
                            name="availableToAll"
                            id="availableToAll"
                            className="p-2 forBackgroundColorInput shadow-none form-select input rounded-3 col-12"
                            onChange={handleInputEditChange}
                            defaultValue={editedData?.availableToAll}
                            disabled={
                              editedData?.status === "expired" &&
                              editedData?.winnerChosen === true
                            }
                            // disabled={editedData?.status !== "upcoming"}
                            // onChange={(e) => handleChangeAdd(e)}
                            // onChange={(e) => handleCitychange(e)}
                            // onChange={(e) => setSelectedCity(e.target.value)}
                            style={{ top: "300%" }}
                          >
                            <option>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>

                          {/* <input type="submit" value="Submit" /> */}
                        </form>

                        <div className="mt-3 d-flex justify-content-between">
                          <div className="mt-2 col-3">
                            <label>Gender</label>
                            <br />
                            <input
                              type="checkbox"
                              id="vehicle71"
                              // name="genderOne"
                              className="me-2"
                              checked={checkGender.genderOne}
                              disabled={
                                editedData?.status === "expired" &&
                                editedData?.winnerChosen === true
                              }
                              onClick={() =>
                                setCheckGender((prevData) => ({
                                  ...prevData,
                                  ["genderOne"]: !checkGender.genderOne,
                                }))
                              }
                              // value="male"
                              // checked={genderStatus.genderOne}
                              // onClick={() => handleGenderChange("genderOne")}
                            />
                            <label for="vehicle71"> Male</label>
                            <br />
                            <input
                              type="checkbox"
                              id="vehicle72"
                              // name="genderTwo"
                              className="me-2"
                              checked={checkGender.genderTwo}
                              disabled={
                                editedData?.status === "expired" &&
                                editedData?.winnerChosen === true
                              }
                              onClick={() =>
                                setCheckGender((prevData) => ({
                                  ...prevData,
                                  ["genderTwo"]: !checkGender.genderTwo,
                                }))
                              }
                              // value="female"
                              // checked={genderStatus.genderTwo}
                              // onClick={() => handleGenderChange("genderTwo")}
                            />
                            <label for="vehicle72">Female</label>
                            <br />
                            <input
                              type="checkbox"
                              id="vehicle73"
                              // name="genderThree"
                              className="me-2"
                              checked={checkGender.genderThree}
                              disabled={
                                editedData?.status === "expired" &&
                                editedData?.winnerChosen === true
                              }
                              onClick={() =>
                                setCheckGender((prevData) => ({
                                  ...prevData,
                                  ["genderThree"]: !checkGender.genderThree,
                                }))
                              }
                              // value="other"
                              // checked={genderStatus.genderThree}
                              // onClick={() => handleGenderChange("genderThree")}
                            />
                            <label for="vehicle73">Other</label>
                            <br />
                            <br />
                          </div>
                          <div className="mt-2 col-9">
                            <label>Citizenship</label>
                            <br />
                            <input
                              type="checkbox"
                              id="vehicle21"
                              // name="genderOne"
                              className="me-2"
                              value="US Citizen"
                              checked={checkCitizenShip.first}
                              disabled={
                                editedData?.status === "expired" &&
                                editedData?.winnerChosen === true
                              }
                              onClick={() =>
                                setCheckCitizenShip((prevData) => ({
                                  ...prevData,
                                  ["first"]: !checkCitizenShip.first,
                                }))
                              }
                              // checked={checkGender.genderOne}
                              // onClick={() =>
                              //   setCheckGender((prevData) => ({
                              //     ...prevData,
                              //     ["genderOne"]: !checkGender.genderOne,
                              //   }))
                              // }
                              // value="male"
                              // checked={genderStatus.genderOne}
                              // onClick={() => handleGenderChange("genderOne")}
                            />
                            <label for="vehicle21">US Citizen</label>

                            <div className="d-flex">
                              <input
                                type="checkbox"
                                id="vehicle22"
                                value="Permanent Resident (Green Card Holder)"
                                // name="genderTwo"
                                checked={checkCitizenShip.second}
                                disabled={
                                  editedData?.status === "expired" &&
                                  editedData?.winnerChosen === true
                                }
                                onClick={() =>
                                  setCheckCitizenShip((prevData) => ({
                                    ...prevData,
                                    ["second"]: !checkCitizenShip.second,
                                  }))
                                }
                                className="me-2"
                                // checked={checkGender.genderTwo}
                                // onClick={() =>
                                //   setCheckGender((prevData) => ({
                                //     ...prevData,
                                //     ["genderTwo"]: !checkGender.genderTwo,
                                //   }))
                                // }
                                // value="female"
                                // checked={genderStatus.genderTwo}
                                // onClick={() => handleGenderChange("genderTwo")}
                              />
                              <label for="vehicle22" className="col-12">
                                Permanent Resident (Green Card Holder)
                              </label>
                            </div>

                            <input
                              type="checkbox"
                              id="vehicle23"
                              value="Nonimmigrant                          "
                              // name="genderThree"
                              className="me-2"
                              checked={checkCitizenShip.third}
                              disabled={
                                editedData?.status === "expired" &&
                                editedData?.winnerChosen === true
                              }
                              onClick={() =>
                                setCheckCitizenShip((prevData) => ({
                                  ...prevData,
                                  ["third"]: !checkCitizenShip.third,
                                }))
                              }
                              // checked={checkGender.genderThree}
                              // onClick={() =>
                              //   setCheckGender((prevData) => ({
                              //     ...prevData,
                              //     ["genderThree"]: !checkGender.genderThree,
                              //   }))
                              // }
                              // value="other"
                              // checked={genderStatus.genderThree}
                              // onClick={() => handleGenderChange("genderThree")}
                            />
                            <label for="vehicle23">Nonimmigrant</label>
                          </div>
                        </div>

                        <div className="mt-1">
                          <label>Current Education Level</label>
                          <br />

                          <input
                            type="checkbox"
                            id="vehicle1"
                            // name="educationOne"
                            className="me-2"
                            checked={checkEducation.educationOne}
                            disabled={
                              editedData?.status === "expired" &&
                              editedData?.winnerChosen === true
                            }
                            onClick={() =>
                              setCheckEducation((prevData) => ({
                                ...prevData,
                                ["educationOne"]: !checkEducation.educationOne,
                              }))
                            }
                            // value="high school"
                            // checked={status.checkOne}
                            // onClick={() => handleUpdateStatus("checkOne")}
                          />
                          <label for="vehicle1">High School</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle2"
                            // name="EducationTwo"
                            className="me-2"
                            checked={checkEducation.educationTwo}
                            disabled={
                              editedData?.status === "expired" &&
                              editedData?.winnerChosen === true
                            }
                            onClick={() =>
                              setCheckEducation((prevData) => ({
                                ...prevData,
                                ["educationTwo"]: !checkEducation.educationTwo,
                              }))
                            }
                            // value="under graduation"
                            // checked={status.checkTwo}
                            // onClick={() => handleUpdateStatus("checkTwo")}
                          />
                          <label for="vehicle2">Under Graduate</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle3"
                            // name="EducationThree"
                            className="me-2"
                            checked={checkEducation.educationThree}
                            disabled={
                              editedData?.status === "expired" &&
                              editedData?.winnerChosen === true
                            }
                            onClick={() =>
                              setCheckEducation((prevData) => ({
                                ...prevData,
                                ["educationThree"]:
                                  !checkEducation.educationThree,
                              }))
                            }
                            // value="post graduation"
                            // checked={status.checkThree}
                            // onClick={() => handleUpdateStatus("checkThree")}
                          />
                          <label for="vehicle3">Post Graduate</label>
                          <br />

                          <input
                            type="checkbox"
                            id="vehicle4"
                            // name="EducationFour"
                            className="me-2"
                            checked={checkEducation.educationFour}
                            disabled={
                              editedData?.status === "expired" &&
                              editedData?.winnerChosen === true
                            }
                            onClick={() =>
                              setCheckEducation((prevData) => ({
                                ...prevData,
                                ["educationFour"]:
                                  !checkEducation.educationFour,
                              }))
                            }
                            // value="mba"
                            // checked={status.checkFour}
                            // onClick={() => handleUpdateStatus("checkFour")}
                          />
                          <label for="vehicle4">MBA</label>
                          <br />

                          <input
                            type="checkbox"
                            id="vehicle5"
                            // name="EducationFour"
                            className="me-2"
                            checked={checkEducation.educationFive}
                            disabled={
                              editedData?.status === "expired" &&
                              editedData?.winnerChosen === true
                            }
                            onClick={() =>
                              setCheckEducation((prevData) => ({
                                ...prevData,
                                ["educationFive"]:
                                  !checkEducation.educationFive,
                              }))
                            }
                            // value="phd"
                            // checked={status.checkFive}
                            // onClick={() => handleUpdateStatus("checkFive")}
                          />
                          <label for="vehicle5">PhD</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle6"
                            // name="EducationFour"
                            className="me-2"
                            checked={checkEducation.educationSix}
                            disabled={
                              editedData?.status === "expired" &&
                              editedData?.winnerChosen === true
                            }
                            onClick={() =>
                              setCheckEducation((prevData) => ({
                                ...prevData,
                                ["educationSix"]: !checkEducation.educationSix,
                              }))
                            }
                            // value="phd"
                            // checked={status.checkFive}
                            // onClick={() => handleUpdateStatus("checkFive")}
                          />
                          <label for="vehicle6">Other</label>

                          {checkEducation.educationSix ? (
                            // indices.length > 0 ? (
                            <input
                              className="form-control shadow-none input forInputFiled"
                              type="text"
                              name="scholarshipProvider"
                              value={inputValue}
                              onChange={handleInputChange}
                              style={forInputField}
                            />
                          ) : // ) : null
                          null}

                          <br />
                        </div>
                        {/* <div className="mt-3">
                          <label>Cultural Identity</label>

                          {console.log(
                            "editedData?.culturalIdentity",
                            editedData?.culturalIdentity
                          )}

                          <form className="">
                            <select
                              name="culturalIdentity"
                              id="culturalIdentity"
                              className="p-2 forBackgroundColorInput shadow-none form-select  input rounded-3 col-12"
                              // disabled={editedData?.status !== "upcoming"}
                              // onChange={(e) => handleCitychange(e)}
                              // onChange={(e) => setSelectedCity(e.target.value)}
                              onChange={handleInputEditChange}
                              defaultValue={editedData?.culturalIdentity || ""}
                              style={{ top: "300%" }}
                              // onChange={(e) => handleChangeAdd(e)}
                            >
                              <option>Select</option>
                              <option value="US citizen or GC holder with US cosigner">
                                US citizen or GC holder with US cosigner
                              </option>
                              <option value="US citizen or GC holder with no US cosigner">
                                US citizen or GC holder with no US cosigner
                              </option>
                              <option value="Non-US citizen">
                                Non-US citizen
                              </option>
                            </select>

                            {/* <input type="submit" value="Submit" /> */}
                        {/* </form>
                        </div> */}

                        <div className="mt-1">
                          <div className="mt-1">
                            <label>Ethnicity</label>

                            <form className="">
                              {/* <select
                                name="ethnicity"
                                id="ethnicity"
                                className="p-2 forBackgroundColorInput shadow-none form-select  input rounded-3 col-12"
                                onChange={handleInputEditChange}
                                // disabled={editedData?.status !== "upcoming"}
                                defaultValue={editedData?.ethnicity || ""}
                                style={{ top: "300%" }}
                              >
                                <option>Select</option>
                                <option value="American Indian or Alaska Native">
                                  American Indian or Alaska Native
                                </option>
                                <option value="Asian">Asian</option>
                                <option value="Black or African American">
                                  Black or African American
                                </option>
                                <option value="Native Hawaiian or Pacific Islander">
                                  Native Hawaiian or Pacific Islander
                                </option>
                                <option value="White/Caucasian">
                                  White/Caucasian
                                </option>
                                <option value="other">
                                  Other
                                </option>
                              </select> */}
                              <select
                                name="ethnicity"
                                id="ethnicity"
                                className="p-2 forBackgroundColorInput shadow-none form-select input rounded-3 col-12"
                                onChange={handleInputEditChange}
                                disabled={
                                  editedData?.status === "expired" &&
                                  editedData?.winnerChosen === true
                                }
                                defaultValue={
                                  editedData?.ethnicity &&
                                  [
                                    "American Indian or Alaska Native",
                                    "Asian",
                                    "Black or African American",
                                    "Native Hawaiian or Pacific Islander",
                                    "White/Caucasian",
                                    "other",
                                  ].includes(editedData?.ethnicity)
                                    ? editedData?.ethnicity
                                    : "other"
                                }
                                style={{ top: "300%" }}
                              >
                                <option>Select</option>
                                <option value="American Indian or Alaska Native">
                                  American Indian or Alaska Native
                                </option>
                                <option value="Asian">Asian</option>
                                <option value="Black or African American">
                                  Black or African American
                                </option>
                                <option value="Native Hawaiian or Pacific Islander">
                                  Native Hawaiian or Pacific Islander
                                </option>
                                <option value="White/Caucasian">
                                  White/Caucasian
                                </option>
                                <option value="other">Other</option>
                              </select>

                              {/* <input type="submit" value="Submit" /> */}
                            </form>

                            {/* {
                               editedData?.ethnicity==''
                            } */}
                            {/* <input /> */}
                            {editedData?.ethnicity &&
                              ![
                                "American Indian or Alaska Native",
                                "Asian",
                                "Black or African American",
                                "Native Hawaiian or Pacific Islander",
                                "White/Caucasian",
                              ].includes(editedData?.ethnicity) && (
                                <input
                                  type="text"
                                  name="otherEthnicity"
                                  id="otherEthnicity"
                                  className="form-control shadow-none  input forInputFiled mt-3"
                                  // onChange={handleInputEditChange}
                                  readOnly={
                                    editedData?.status === "expired" &&
                                    editedData?.winnerChosen === true
                                  }
                                  value={forInputEhincity}
                                  onChange={(e) => {
                                    setForInputEhincity(e.target.value);
                                  }}
                                  placeholder="Specify Other Ethnicity"
                                  // You can add other attributes or styles as needed
                                />
                              )}
                          </div>

                          <label className="mt-3">GPA</label>
                          <input
                            className="form-control shadow-none  input forInputFiled"
                            type="number"
                            placeholder="Enter Scholarship Gpa"
                            name="gpaRequired"
                            defaultValue={editedData.gpaRequired}
                            readOnly={
                              editedData?.status === "expired" &&
                              editedData?.winnerChosen === true
                            }
                            // readOnly={editedData?.status !== "upcoming"}
                            onChange={handleInputEditChange}
                            style={forInputField}
                          />
                        </div>

                        {editedData.scholarshipProvider !== "Path2Study" ? (
                          <div>
                            <label className="mt-3">
                              Scholarship Provider{" "}
                            </label>
                            <input
                              className="form-control shadow-none  input forInputFiled"
                              type="text"
                              name="scholarshipProvider"
                              defaultValue={editedData?.scholarshipProvider}
                              onChange={handleInputEditChange}
                              style={forInputField}
                            />
                          </div>
                        ) : (
                          <div>
                            <label className="mt-3">
                              Scholarship Provider{" "}
                            </label>
                            <input
                              className="form-control shadow-none  input forInputFiled"
                              type="text"
                              name="scholarshipProvider"
                              defaultValue={editedData?.scholarshipProvider}
                              onChange={handleInputEditChange}
                              style={forInputField}
                              readOnly={
                                editedData.scholarshipProvider == "Path2Study"
                              }
                            />
                          </div>
                        )}

                        {editedData.scholarshipProvider !== "Path2Study" ? (
                          <div>
                            <label className="mt-3">
                              Add redirection URL link of the scholarship{" "}
                            </label>

                            <input
                              class="form-control shadow-none  input  forInputFiled"
                              type="text"
                              name="redirectionalUrl"
                              defaultValue={editedData?.redirectionalUrl}
                              onChange={handleInputEditChange}
                              readOnly={
                                editedData?.status === "expired" &&
                                editedData?.winnerChosen === true
                              }
                              // readOnly={editedData?.status !== "upcoming"}
                            />
                          </div>
                        ) : (
                          <div></div>
                        )}

                        <h6 className="text-danger mt-2">{urlError}</h6>
                      </form>
                      <ToastContainer />
                    </div>
                  </div>
                  <div className="col-5 mx-5">
                    {/* <input class="form-control" type="text" placeholder="Default input"/> */}
                    <h3>Preview</h3>
                    <div className="mt-2 border rounded-3 p-3">
                      {/* <h2 className="text-primary">$ {editedData?.amount}</h2> */}
                      <div className="d-flex justify-content-between">
                        <h2 className="text-primary">{currency ? currency : "$"} {editedData?.amount}</h2>
                        <div className="d-flex justify-content-between">
                          <i className="bi bi-clock"></i>
                          <p className="ms-2">
                            {
                              editedData.Deadline
                                ? new Date(
                                    editedData.Deadline
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  })
                                : "No Deadline Available" // Or any other fallback message
                            }
                          </p>
                        </div>
                      </div>

                      <div className="">
                        <div className="d-flex  justify-content-between">
                          <div className="col-2">
                            <FaGraduationCap size={27} />
                          </div>
                          <div className="col-10">
                            <h5
                              className="toAvoidOverflow"
                              style={{
                                display: "inline",
                                overflowWrap: "break-word",
                              }}
                            >
                              {" "}
                              {finalEdit?.scholarshipName
                                ? editedData?.scholarshipName
                                : editedData?.scholarshipName}
                            </h5>
                          </div>
                        </div>

                        <div className="d-flex  justify-content-between mt-1">
                          <div className="col-2">
                            {/* <IoSend size={30} /> */}
                            <h5>
                              {" "}
                              <i className="bi bi-send-arrow-up-fill col-6" />
                            </h5>
                          </div>
                          <div className="col-10">
                            <p
                              style={{
                                display: "inline",
                                overflowWrap: "break-word",
                              }}
                            >
                              Provider : {editedData?.scholarshipProvider}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex  justify-content-between">
                          <div className="col-1">
                            {/* <PiBookLight size={30} /> */}
                            <h5>
                              <i className="bi bi-journal-bookmark-fill" />
                            </h5>
                          </div>
                          <div className="col-10">
                            <p
                              style={{
                                display: "inline",
                                overflowWrap: "break-word",
                              }}
                            >
                              Course name : {editedData?.courseName}
                            </p>
                          </div>
                        </div>
                      </div>

                      <button
                        type="button"
                        className="btn form-control btn-outline-primary btn-sm btn-block"
                      >
                        Apply Now
                      </button>
                    </div>
                    <p className="mt-3 fs-7">
                      This is the how your newly added scholarship card will
                      appear to the users of the website.
                    </p>
                    <div className="my-5">
                      <div className="d-flex justify-content-between">
                        <div className="col-6 pe-1">
                          <button
                            type="button"
                            className="btn form-control btn-secondary"
                            onClick={() => setShowDelete(true)}
                            // {handleDiscard}

                            // disabled
                          >
                            Delete
                          </button>
                        </div>
                        <div className="col-6 px-1 pe-0">
                          {/* ---- modal -----*/}
                          {/* {
                          editedData?.status === "expired" &&
                          editedData?.winnerChosen === true 
                          ?
                           (
                            <button
                              onClick={handleShow}
                              className="btn form-control btn-primary"
                            >
                              Save changes
                            </button>
                          ) : (
                            <button
                              onClick={handleShow}
                              className="btn form-control btn-primary"
                              disabled={
                                urlError !== "" || amountError !== ""
                                // ||
                                // !finalEdit
                              }
                            >
                              Save changes
                            </button>
                          )} */}
                          {!(
                            editedData?.status === "expired" &&
                            editedData?.winnerChosen === true
                          ) && (
                            <button
                              onClick={handleShow}
                              className="btn form-control btn-primary"
                              disabled={urlError !== "" || amountError !== ""}
                            >
                              Save Changes
                            </button>
                          )}

                          {/* <button
                            onClick={handleShow}
                            className="btn form-control btn-primary"
                            disabled={
                              urlError !== "" || amountError !== ""
                              // ||
                              // !finalEdit
                            }
                          >
                            Save changes
                          </button> */}

                          {editedData?.status === "expired" &&
                            editedData?.winnerChosen === true && (
                              <button
                                className="btn col-12 btn-primary"
                                onClick={() => setShowRecreate(true)}
                              >
                                Recreate Scholarship
                              </button>
                            )}

                          {/* <ToastContainer /> */}

                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>Confirm save changes</Modal.Title>
                            </Modal.Header>

                            <p className="px-3">
                              All the scholarship information will be saved. Do
                              you want to proceed?
                            </p>

                            {/* </Modal.Body> */}
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Cancel
                              </Button>
                              <Button variant="primary" onClick={saveChanges}>
                                Save Changes
                              </Button>
                            </Modal.Footer>
                          </Modal>

                          <Modal show={showDelete} onHide={handleClose}>
                            <Modal.Header
                              closeButton
                              onClick={() => setShowDelete(false)}
                            >
                              <Modal.Title>Delete scholarship?</Modal.Title>
                            </Modal.Header>
                            {/* <Modal.Body> */}{" "}
                            <p className="px-3">
                              Are you sure you want to delete this scholarship?
                              Once deleted, this action cannot be undone.
                            </p>
                            {/* </Modal.Body> */}
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={() => setShowDelete(false)}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => handleDiscard()}
                              >
                                Delete
                              </Button>
                            </Modal.Footer>
                          </Modal>

                          {/* modal for recreate */}

                          <Modal
                            show={showRecreate}
                            onHide={() => setShowRecreate(false)}
                          >
                            <Modal.Header
                              closeButton
                              onClick={() => setShowRecreate(false)}
                            >
                              <Modal.Title>Recreate Scholarship</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <form action="">
                                {/* dataToEdit */}
                                <label>Scholarship Name</label>
                                <input
                                  className="form-control shadow-none  input forInputFiled"
                                  type="text"
                                  name="scholarshipName"
                                  value={
                                    recreateData?.scholarshipName
                                      ? recreateData?.scholarshipName
                                          ?.charAt(0)
                                          ?.toUpperCase() +
                                        recreateData?.scholarshipName?.slice(1)
                                      : ""
                                  }
                                  // value={recreateData.scholarshipName}
                                  onChange={(event) =>
                                    handleRecreateDataChange(event)
                                  }
                                  // readOnly={editedData?.status !== "upcoming"}
                                  style={forInputField}
                                />
                                <label className="mt-3">
                                  Scholarship Amount
                                </label>

                                <input
                                  className="form-control shadow-none  input forInputFiled"
                                  type="text"
                                  name="amount"
                                  value={recreateData?.amount}
                                  onChange={(event) =>
                                    handleRecreateDataChange(event)
                                  }
                                  // readOnly={editedData?.status !== "upcoming"}
                                  style={forInputField}
                                />
                                {amountError ? (
                                  <h6 className="text-danger mt-3">
                                    {amountError}
                                  </h6>
                                ) : (
                                  ""
                                )}

                                <label className="mt-3">
                                  High School or University Name{" "}
                                </label>
                                <input
                                  className="form-control shadow-none  input forInputFiled"
                                  type="text"
                                  placeholder="Enter High School or University Name"
                                  style={forInputField}
                                  name="highschoolUniversity"
                                  value={recreateData.highschoolUniversity}
                                  onChange={(event) =>
                                    handleRecreateDataChange(event)
                                  }
                                />
                                <label className="mt-3">Course Name </label>
                                <input
                                  className="form-control shadow-none input forInputFiled"
                                  type="text"
                                  name="courseName"
                                  defaultValue={recreateData?.courseName}
                                  onChange={(event) =>
                                    handleRecreateDataChange(event)
                                  }
                                  style={forInputField}
                                />

                                <label className="mt-3">Start Date</label>
                                <input
                                  className="form-control shadow-none  input forInputFiled"
                                  type="date"
                                  data-date-format="DD MMMM YYYY"
                                  id="exampleDate"
                                  name="startDate"
                                  value={recreateData?.createdAt}
                                  onChange={(event) =>
                                    handleRecreateDataChange(event)
                                  }
                                  // readOnly={editedData?.status !== "upcoming"}
                                  style={forInputField}
                                />
                                <p className="mt-1 mb-0 pb-0 text-success">
                                  {recreateData.startDate &&
                                    new Date(
                                      recreateData.startDate
                                    )?.toLocaleDateString("en-US", {
                                      month: "2-digit",
                                      day: "2-digit",
                                      year: "numeric",
                                    })}
                                </p>
                                <label className="mt-3">Deadline</label>
                                <input
                                  className="form-control shadow-none  input forInputFiled"
                                  type="date"
                                  data-date-format="DD MMMM YYYY"
                                  id="exampleDate"
                                  name="Deadline"
                                  value={recreateData?.Deadline}
                                  onChange={(event) =>
                                    handleRecreateDataChange(event)
                                  }
                                  style={forInputField}
                                />
                                <p className="mt-1 mb-0 pb-0 text-success">
                                  {recreateData?.Deadline &&
                                    new Date(
                                      recreateData?.Deadline
                                    )?.toLocaleDateString("en-US", {
                                      month: "2-digit",
                                      day: "2-digit",
                                      year: "numeric",
                                    })}
                                </p>
                                {editedData.scholarshipProvider ===
                                "Path2Study" ? (
                                  <div>
                                    <label htmlFor="" className="mt-2">
                                      Scholarship Description
                                    </label>
                                    <textarea
                                      className="form-control shadow-none  input forInputFiled"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      name="description"
                                      value={
                                        recreateData?.description
                                          ? recreateData?.description
                                              ?.charAt(0)
                                              ?.toUpperCase() +
                                            recreateData?.description?.slice(1)
                                          : ""
                                      }
                                      // value={recreateData?.description}
                                      onChange={(event) =>
                                        handleRecreateDataChange(event)
                                      }
                                      // readOnly={editedData?.status !== "upcoming"}
                                      style={forInputField}
                                    ></textarea>
                                    <label htmlFor="" className="mt-3">
                                      Eligibility Requirements
                                    </label>
                                    <textarea
                                      className="form-control shadow-none  input forInputFiled"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      value={
                                        recreateData?.minimumRequirements
                                          ? recreateData?.minimumRequirements
                                              ?.charAt(0)
                                              ?.toUpperCase() +
                                            recreateData?.minimumRequirements?.slice(
                                              1
                                            )
                                          : ""
                                      }
                                      // value={recreateData?.minimumRequirements}
                                      name="minimumRequirements"
                                      onChange={(event) =>
                                        handleRecreateDataChange(event)
                                      }
                                      // readOnly={editedData?.status !== "upcoming"}
                                      style={forInputField}
                                    ></textarea>

                                    <div className="mt-3">
                                      {/* Select Country */}
                                      <label>Country</label>
                                      <CountryDropdown
                                        value={selectedCountryTwo}
                                        onChange={handleCountryChangeTwo}
                                        classes="form-select"
                                        style={forInputField}
                                      />

                                      {/* Select State based on the selected country */}
                                      <div className="mt-3">
                                        <label>State</label>
                                        {selectedCountryTwo && (
                                          <RegionDropdown
                                            country={selectedCountryTwo}
                                            value={selectedStateTwo}
                                            onChange={handleStateChangeTwo}
                                            style={forInputField}
                                            className="form-select"
                                          />
                                        )}
                                      </div>

                                      {/* Display selected state */}

                                      <div>
                                        {/* <p className="mt-3">Selected States:</p> */}

                                        <div className="form-group"></div>
                                        {
                                          <p className="mt-3 border rounded-3 p-3">
                                            {stateArrayTwo?.map(
                                              (element, i) => {
                                                return (
                                                  <span className="mx-2">
                                                    {element}
                                                    <RxCrossCircled
                                                      size={16}
                                                      //  onClick={(i)=>{
                                                      // }}
                                                      onClick={() =>
                                                        removeState(i)
                                                      }
                                                      className="mt-0"
                                                    />
                                                  </span>
                                                );
                                              }
                                            )}
                                          </p>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                <p className="mt-3 mb-0 pb-0">
                                  Scholarship Available for all
                                </p>

                                <form className="">
                                  <select
                                    name="availableToAll"
                                    id="availableToAll"
                                    className="p-2 forBackgroundColorInput shadow-none form-select input rounded-3 col-12"
                                    onChange={(event) =>
                                      handleRecreateDataChange(event)
                                    }
                                    value={recreateData?.availableToAll}
                                    // onChange={(e) => setSelectedCity(e.target.value)}
                                    style={{ top: "300%" }}
                                  >
                                    <option>Select</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                  </select>

                                  {/* <input type="submit" value="Submit" /> */}
                                </form>

                                <div className="mt-3 d-flex justify-content-between">
                                  <div className="mt-2 col-3">
                                    <label>Gender</label>
                                    <br />
                                    <input
                                      type="checkbox"
                                      id="vehicle71"
                                      // name="genderOne"
                                      className="me-2"
                                      checked={checkGender.genderOne}
                                      onClick={() =>
                                        setCheckGender((prevData) => ({
                                          ...prevData,
                                          ["genderOne"]: !checkGender.genderOne,
                                        }))
                                      }
                                    />
                                    <label for="vehicle71"> Male</label>
                                    <br />
                                    <input
                                      type="checkbox"
                                      id="vehicle72"
                                      // name="genderTwo"
                                      className="me-2"
                                      checked={checkGender.genderTwo}
                                      onClick={() =>
                                        setCheckGender((prevData) => ({
                                          ...prevData,
                                          ["genderTwo"]: !checkGender.genderTwo,
                                        }))
                                      }
                                    />
                                    <label for="vehicle72">Female</label>
                                    <br />
                                    <input
                                      type="checkbox"
                                      id="vehicle73"
                                      // name="genderThree"
                                      className="me-2"
                                      checked={checkGender.genderThree}
                                      onClick={() =>
                                        setCheckGender((prevData) => ({
                                          ...prevData,
                                          ["genderThree"]:
                                            !checkGender.genderThree,
                                        }))
                                      }
                                    />
                                    <label for="vehicle73">Other</label>
                                    <br />
                                    <br />
                                  </div>
                                  <div className="mt-2 col-9">
                                    <label>Citizenship</label>
                                    <br />
                                    <input
                                      type="checkbox"
                                      id="vehicle21"
                                      // name="genderOne"
                                      className="me-2"
                                      value="US Citizen"
                                      checked={checkCitizenShip.first}
                                      onClick={() =>
                                        setCheckCitizenShip((prevData) => ({
                                          ...prevData,
                                          ["first"]: !checkCitizenShip.first,
                                        }))
                                      }
                                    />
                                    <label for="vehicle21">US Citizen</label>

                                    <div className="d-flex">
                                      <input
                                        type="checkbox"
                                        id="vehicle22"
                                        value="Permanent Resident (Green Card Holder)"
                                        // name="genderTwo"
                                        checked={checkCitizenShip.second}
                                        onClick={() =>
                                          setCheckCitizenShip((prevData) => ({
                                            ...prevData,
                                            ["second"]:
                                              !checkCitizenShip.second,
                                          }))
                                        }
                                        className="me-2"
                                      />
                                      <label for="vehicle22" className="col-12">
                                        Permanent Resident (Green Card Holder)
                                      </label>
                                    </div>

                                    <input
                                      type="checkbox"
                                      id="vehicle23"
                                      value="Nonimmigrant                          "
                                      // name="genderThree"
                                      className="me-2"
                                      checked={checkCitizenShip.third}
                                      onClick={() =>
                                        setCheckCitizenShip((prevData) => ({
                                          ...prevData,
                                          ["third"]: !checkCitizenShip.third,
                                        }))
                                      }
                                    />
                                    <label for="vehicle23">Nonimmigrant</label>
                                  </div>
                                </div>

                                <div className="mt-1">
                                  <label>Current Education Level</label>
                                  <br />

                                  <input
                                    type="checkbox"
                                    id="vehicle1"
                                    // name="educationOne"
                                    className="me-2"
                                    checked={checkEducation.educationOne}
                                    onClick={() =>
                                      setCheckEducation((prevData) => ({
                                        ...prevData,
                                        ["educationOne"]:
                                          !checkEducation.educationOne,
                                      }))
                                    }
                                  />
                                  <label for="vehicle1">High School</label>
                                  <br />
                                  <input
                                    type="checkbox"
                                    id="vehicle2"
                                    // name="EducationTwo"
                                    className="me-2"
                                    checked={checkEducation.educationTwo}
                                    onClick={() =>
                                      setCheckEducation((prevData) => ({
                                        ...prevData,
                                        ["educationTwo"]:
                                          !checkEducation.educationTwo,
                                      }))
                                    }
                                  />
                                  <label for="vehicle2">Under Graduate</label>
                                  <br />
                                  <input
                                    type="checkbox"
                                    id="vehicle3"
                                    // name="EducationThree"
                                    className="me-2"
                                    checked={checkEducation.educationThree}
                                    onClick={() =>
                                      setCheckEducation((prevData) => ({
                                        ...prevData,
                                        ["educationThree"]:
                                          !checkEducation.educationThree,
                                      }))
                                    }
                                  />
                                  <label for="vehicle3">Post Graduate</label>
                                  <br />

                                  <input
                                    type="checkbox"
                                    id="vehicle4"
                                    // name="EducationFour"
                                    className="me-2"
                                    checked={checkEducation.educationFour}
                                    onClick={() =>
                                      setCheckEducation((prevData) => ({
                                        ...prevData,
                                        ["educationFour"]:
                                          !checkEducation.educationFour,
                                      }))
                                    }
                                  />
                                  <label for="vehicle4">MBA</label>
                                  <br />

                                  <input
                                    type="checkbox"
                                    id="vehicle5"
                                    // name="EducationFour"
                                    className="me-2"
                                    checked={checkEducation.educationFive}
                                    onClick={() =>
                                      setCheckEducation((prevData) => ({
                                        ...prevData,
                                        ["educationFive"]:
                                          !checkEducation.educationFive,
                                      }))
                                    }
                                  />
                                  <label for="vehicle5">PhD</label>
                                  <br />

                                  <input
                                    type="checkbox"
                                    id="vehicle6"
                                    // name="EducationFour"
                                    className="me-2"
                                    checked={checkEducation.educationSix}
                                    onClick={() =>
                                      setCheckEducation((prevData) => ({
                                        ...prevData,
                                        ["educationSix"]:
                                          !checkEducation.educationSix,
                                      }))
                                    }
                                  />
                                  <label for="vehicle6">Other</label>

                                  {checkEducation.educationSix ? (
                                    // indices.length > 0 ? (
                                    <input
                                      className="form-control shadow-none input forInputFiled"
                                      type="text"
                                      name="scholarshipProvider"
                                      value={inputValue}
                                      onChange={handleInputChange}
                                      style={forInputField}
                                    />
                                  ) : // ) : null
                                  null}
                                  <br />
                                </div>

                                <div className="mt-3">
                                  <div className="mt-3">
                                    <label>Ethnicity</label>

                                    <form className="">
                                      <select
                                        name="ethnicity"
                                        id="ethnicity"
                                        className="p-2 forBackgroundColorInput shadow-none form-select  input rounded-3 col-12"
                                        onChange={(event) =>
                                          handleRecreateDataChange(event)
                                        }
                                        value={recreateData?.ethnicity || ""}
                                        style={{ top: "300%" }}
                                      >
                                        <option value="Select">Select</option>
                                        <option value="American Indian or Alaska Native">
                                          American Indian or Alaska Native
                                        </option>
                                        <option value="Asian">Asian</option>
                                        <option value="Black or African American">
                                          Black or African American
                                        </option>
                                        <option value="Native Hawaiian or Pacific Islander">
                                          Native Hawaiian or Pacific Islander
                                        </option>
                                        <option value="White/Caucasian">
                                          White/Caucasian
                                        </option>
                                        <option value="other">Other</option>
                                      </select>

                                      {/* <input type="submit" value="Submit" /> */}
                                    </form>
                                    {recreateData?.ethnicity == "other" ? (
                                      <input
                                        className="form-control shadow-none  input forInputFiled mt-3"
                                        type="text"
                                        placeholder="Enter ethinicity"
                                        name="ethnicity"
                                        value={
                                          recreateData.ethinicityOtherInput
                                        }
                                        onChange={(e) => {
                                          setEthinicityOtherInput(
                                            e.target.value
                                          );
                                        }}
                                        style={forInputField}
                                      />
                                    ) : null}
                                  </div>

                                  <label className="mt-3">GPA</label>
                                  <input
                                    className="form-control shadow-none  input forInputFiled"
                                    type="number"
                                    placeholder="Enter Scholarship Gpa"
                                    name="gpaRequired"
                                    value={recreateData.gpaRequired}
                                    // readOnly={editedData?.status !== "upcoming"}
                                    onChange={(event) =>
                                      handleRecreateDataChange(event)
                                    }
                                    style={forInputField}
                                  />
                                </div>

                                <label className="mt-3">
                                  Scholarship Provider{" "}
                                </label>
                                <input
                                  className="form-control shadow-none  input forInputFiled"
                                  type="text"
                                  name="scholarshipProvider"
                                  value={recreateData?.scholarshipProvider}
                                  onChange={(event) =>
                                    handleRecreateDataChange(event)
                                  }
                                  readOnly={
                                    editedData.scholarshipProvider ===
                                    "Path2Study"
                                  }
                                  // onChange={handleInputEditChange}
                                  // readOnly
                                  style={forInputField}
                                />

                                {editedData.scholarshipProvider !==
                                "Path2Study" ? (
                                  <div>
                                    <label className="mt-3">
                                      Add redirection URL link of the
                                      scholarship{" "}
                                    </label>

                                    <input
                                      class="form-control shadow-none  input  forInputFiled"
                                      type="text"
                                      name="redirectionalUrl"
                                      value={recreateData?.redirectionalUrl}
                                      onChange={(event) =>
                                        handleRecreateDataChange(event)
                                      }
                                      // readOnly={editedData?.status !== "upcoming"}
                                    />
                                  </div>
                                ) : (
                                  <div></div>
                                )}

                                <h6 className="text-danger mt-2">{urlError}</h6>
                              </form>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                // onClick={() => setShowRecreate(false)}
                                onClick={() => handleCancelCreate()}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() => handleCreate()}
                              >
                                Create
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSchlorship;
