import React from "react";
import "../style/HomeOverlap.css";
// 5 5 fields
const P2sIdealChoice = () => {
  return (
    <div>
      <div>
        <div className="container hide-br">
          <span className="fs-3 text">HERE’S WHY</span>{" "}
          <span className="fs-3 text-primary">PATH2STUDY</span>{" "}
          <span className="fs-3 text">SHOULD BE</span>{" "}
          <span className="fs-3 text-primary">
            <br /> YOUR IDEAL CHOICE!
          </span>
          {/* HERE’S WHY PATH2STUDY SHOULD BE YOUR IDEAL CHOICE! */}
          
          <div className="d-flex flex-wrap justify-content-around  align-items-center mt-3">
            {/* align Item end */}
            <div className="col-lg-3 col-md-5 int-pad ">
              <img
                src={require("../assests/img/usav1.png")}
                alt=""
                className="img-fluid mb-4"
              />
            </div>
            <div className="col-lg-7 col-md-5 ending-pad int-pad">
              <div className="d-flex">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    // width="25"
                    // height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                  {/* Path2Study has 20+ years of US education system & students loan experience team to help you  */}
                  Path2Study has a team with 20+ years of experience in US
                  education system & student loans to help you
                </p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                  Scholarships and loan information under one platform at no
                  cost
                </p>
              </div>

              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">Multiple loan options to choose from</p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                  {/* Personal tailored made guidance at no cost */}
                  Free access to hundreds of scholarships worth millions+
                  dollars
                </p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                  Personal tailored made guidance at no cost
                </p>
              </div>
              <p></p>
            </div>
            <div className="col-lg-3 col-md-5 int-pad d-block d-md-none custome-height" >
              <img
                src={require("../assests/img/usright.png")}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-7 col-md-5 starting-pad int-pad">
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                  Schedule 20-30 minutes personalized guidance session for free
                </p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                  {/* 24*7 whatsapp support */}
                  100+ Institution Engagement
                </p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                  Chance to win up to $12,500 scholarship sponsored by
                  Path2Study
                </p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                  In-depth knowledge of industry best practices
                </p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <p className="col-11">
                  Highly effective and skilled team to help
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-5 int-pad d-none d-md-block">
              <img
                src={require("../assests/img/usright.png")}
                alt=""
                className="img-fluid"
              />
            </div>
            {/* <div className="col-lg-3 col-md-5 int-pad ">
            <img
              src="/static/media/process-3.3374a0cb46093ed9b56a.png"
              alt=""
              className="img-fluid"
            />
          </div> */}
            {/* <div className="col-lg-7 col-md-5 ending-pad int-pad">
            <h3 className="secondary-light-txt mb-3">Oh wait! There's more.</h3>
            <p>
              We continuously optimize your IT solutions to ensure that they are
              functioning at their best.
            </p>
            <p>
              Our team is dedicated to improving the performance and
              effectiveness of your solution to help you achieve your business
              objectives.
            </p>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default P2sIdealChoice;
