import {  Form, Button, ModalFooter } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../contexts/ModalContext";
import SignInModal from "./SignInModall";
import * as formik from "formik";
import * as yup from "yup";
import axios from "axios";
// import { Link } from "react-router-dom";
// import { API_BASE_URL } from "../../appConfig";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// import ForgetPassSteps from "./ForgetPassSteps";
// import Overlays from "./OverLays";

//google login
import { jwtDecode } from "jwt-decode";
import { auth, googleAuthProvider } from "../../firebase";
import { signInWithPopup } from "firebase/auth";
import {  toast } from "react-toastify";
// import { redirect, resolvePath } from "react-router-dom";

const SignUpModal = () => {
  // const [showSigninModal, setShowSigninModal] = useState(false);
  const {
    isOpen,
    steps,
    setIsOpen,
    signUpOpen,
    setSignUpOpen,
    closeModal,
    handleNext,
    showSigninModal,
    setShowSigninModal,
    allCloseModal,
    openForget,
    showForget,
    setShowForget,
    data,
    setData,
  } = useContext(ModalContext);

  const SignupSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Username is Required")
      .matches(
        /^[a-zA-Z\s\-,.'"]+$/, // Allow letters, spaces, hyphens, commas, periods, and apostrophes
        "Name must contain only letters, spaces, and common punctuation"
      )
      .notOneOf([yup.ref("email")], "Name cannot be an email"), // Check against email field,
    email: yup
      .string()
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email")
      .required("Email is Required"),
  });


  const handlePostRequest = async (values) => {
    const postData = {
      name: values.name,
      email: values.email,
      userType: "user",
      register: "true",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/generateOtp`,
        // `${API_BASE_URL}/generateOtp`,
        // "https://path2study-server-dot-ize-testenv.uc.r.appspot.com/generateOtp",
        postData
      );
      // console.log("Response:", response.data);
      if (response.data.message === "OTP sent to your entered email") {
        toast("OTP sent to your entered email", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      // Handle the response or update the state as needed
      handleNext(postData);
    } catch (error) {
      // console.error("Error:", error);
      // Handle errors, if any
      if (error) {
        toast(error.response.data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  // original
  const handleLoginButton = (e) => {
    e.preventDefault();
    closeModal();
  };

  // new implimentaion
  const { Formik } = formik;

  // console.log("signup page--Loginmodal---> ", showSigninModal);
  // console.log("signup page --signUpOpen,---> ", signUpOpen,);
  // console.log("isopen---> signup", isOpen);
  // console.log("openForget---> signup", showForget);
  const [countryByBackend, setCountryByBackend] = useState(""); // actual country location by viswas
  const [stateBackend, setStateByBackend] = useState(""); // actual country location by ip
  const [cityByBackend, setCityByBackend] = useState(""); // actual country location by ip
  const [pincodeByBackend, setPincodeByBackend] = useState(""); // actual country location by ip
  useEffect(() => {
    const getLocation = () => {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          
          // https://server-dot-path2study.el.r.appspot.com/getCountry
          const LatLong = {
            latitude: latitude,
            longitude: longitude,
          };
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/getCountry`,
            // `${API_BASE_URL}/getCountry`,
            LatLong
          );
          setCountryByBackend(response.data.country);
          setStateByBackend(response.data.state);
          setCityByBackend(response.data.city);
          setPincodeByBackend(response.data.pincode);
        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    };

    // Trigger permission request when component mounts
    getLocation();
  }, []);

  const handleSignUpWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const postData = {
        googleEmail: result.user.email,
        name: result.user.displayName,
        register: "yes",
        country: countryByBackend,
        state: stateBackend,
        city: cityByBackend,
        pincode: pincodeByBackend,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/userLogin`,
        postData
      );
      // const response = await axios.post(`${API_BASE_URL}/userLogin`, postData);
      // return
      // console.log("Response: ---in g login", response.data);
      if (response.data.message === "User logged in successfully") {
        const userType = jwtDecode(response.data.tokenData.token);
        const UserId = localStorage.setItem(
          "userId",
          response.data.tokenData.userId
        );
        const UserToken = localStorage.setItem(
          "userToken",
          response.data.tokenData.token
        );
        const UserType = localStorage.setItem("userType", userType.userType);
        toast(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        allCloseModal();
        // Introduce a delay before redirecting to "/home"
        setTimeout(() => {
          window.location.href = "/user/userprofile";
        }, 3000); // 1000 milliseconds (1 second) delay
      }
      if (response.data.message === "This user already exist. Please log in") {
        toast(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        allCloseModal();
      }
    } catch (error) {
      // console.error(error);
      if (error) {
        toast(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      allCloseModal();
      window.location.href = "/";
    }
  };
  return (
    <>
      <>
        <div className="card mx-md-4 shadow p-3  bg-body rounded-lg mb-3">
          <img
            src={require("../../assests/img/create1.png")}
            className="rounded mx-auto d-block w-50"
            alt="logocreate"
          />
          {/* <Form> */}
          <Formik
            validationSchema={SignupSchema}
            onSubmit={handlePostRequest}
            initialValues={{
              name: data.name || "",
              email: data.email || "",
              emailOTP: "",
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="validationFormik01">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      placeholder="Name"
                      type="text"
                      name="name"
                      value={values.name || data.name}
                      onChange={handleChange}
                      required
                      // isValid={touched.name && !!errors.name}
                      isInvalid={!!errors.name}
                      style={{
                        fontWeight: "400",
                        border: "revert-layer", // Remove default border on focus
                        outline: "none", // Remove default outline on focus
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                        textTransform: "capitalize", // Capitalize first letter of the placeholder text
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="mt-2">Email </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="username@domain.com"
                      name="email"
                      required
                      value={values.email}
                      isInvalid={!!errors.email}
                      onChange={handleChange}
                      style={{
                        fontWeight: "400",
                        border: "revert-layer", // Remove default border on focus
                        outline: "none", // Remove default outline on focus
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <div className="d-grid gap-2">
                  <Button variant="primary" type="submit">
                    Next{">>"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>

          {/* </Form> */}
          <button
            className="d-flex justify-content-center align-items-center  bg-body border rounded p-1 my-3"
            onClick={handleSignUpWithGoogle}
          >
            {/* custom-style */}
            {/* <button className="btn btn-light border"> */}
            <img
              src={require("../../assests/img/google1.png")}
              className="me-2"
              alt=""
            />
            <span className="">Sign up with Google</span>
            {/* </button> text fs-6*/}
            {/* Additional buttons or elements can be added here */}
          </button>

          {/* Login button */}
          <span className="d-flex justify-content-center">
            Already have an account?
            <span
              className="cursor-pointer text-primary ms-1 "
              // data-bs-toggle="modal" data-bs-target="#secondModal" data-bs-dismiss="modal"
              onClick={(e) => handleLoginButton(e)}
            >
              Log In
            </span>
          </span>
        </div>
      </>

      {showSigninModal && (
        <SignInModal
          onHide={closeModal}
          handleClose={closeModal}
          allCloseModal={allCloseModal}
        />
      )}
    </>
  );
};

export default SignUpModal;
