import React from "react";
import Result from "./Result";

const LeaderBoard = () => {
  return (
    <div>
      <Result />
    </div>
  );
};

export default LeaderBoard;
