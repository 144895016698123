import "bootstrap-icons/font/bootstrap-icons.css";
import React, { memo, useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import { useHistory } from 'react-router-dom';
import { convertISOToHumanReadable } from "../hooks/convertdate";
// import { API_BASE_URL } from "../appConfig";
import { useParams } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import NotShowData from "./NotShowData";
import { FaArrowRightLong } from "react-icons/fa6";
import { formatAmount } from "../hooks/useFormattedAmount";

export const ScholarshipCard = ({ card }) => {
  const [scholarship, setScholarship] = useState("");
  const [currency, setCurrency] = useState('');
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  const { id } = useParams();
  //  for p2s schol
  const handleKnowMore = (id) => {
    // console.log('alay bhava',id)
    const resetValuesforQuationaire = {
      ethnicity: "",
      citizenshipStatus: "",
      currentEducationalLevel: "",
      highschoolUniversity: "",
      tentativeHighschoolGraduationDate: "",
      MajorFieldOfstudy: "",
      modeOfStudy: "",
      sourcesOfFinancial: "",
      currentGpa: "",
      existingFinancialAid: "",
      annualFamilyIncome: "",
      involvement: "",
      recognitions: "",
      leadership: "",
      academicRecognitions: "",
      academicPrograms: "",
      academicAchievement: "",
      scholarshipId: "",
    };
    handleClick();
    // setCurrentStep(0);
    // setQData(resetValuesforQuationaire);
    getScholData(id);
  };

  const handleSchlorship = () => {
    window.scrollTo(0, 0);
  };

  const getScholData = async (id) => {
    try {
      const userId = localStorage.getItem("userId");
      const userToken = localStorage.getItem("userToken");
      const updatedToken = `Bearer ${userToken} `;
      // console.log('updatedToken',updatedToken)
      const authAxios = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        // baseURL: API_BASE_URL,
        headers: {
          authorization: updatedToken,
        },
      });
      const response = await authAxios.get(
        `${process.env.REACT_APP_BASE_URL}/getAllScholarships/${userId}?scholarshipId=${id}`
        // `${API_BASE_URL}/getAllScholarships/${userId}?scholarshipId=${id}`
        // `${API_BASE_URL}/getAllScholarships?scholarshipId=${id}`
      );
      setScholarship(response.data);
      setCurrency(response.data.currency)
      // console.log('usercontext data get by api ',response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const StatusComponent = ({ status }) => {
    switch (status) {
      case "live":
        // return <span className="">Live</span>;
        break;
      case "expired":
        // return <span className=" badge bg-danger ">Expire</span>;
        break;
      case "upcomming":
        // return <span className=" badge bg-warning ">comingsoon</span>;
        break;
      default:
        // Handle other cases if needed
        break;
    }
  };

  return (
    <>
      <div key={card._id} className="col-lg-4 col-12  ">
        <div className="border rounded-4 p-3 mt-2 mb-3">
          <div className="text-end">
            {/* <StatusComponent status={card.status} />{" "} */}
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            {/* <h4 className="text-primary">${card.amount}</h4> */}
            <h4 className="text-primary">{card.currency ? card.currency : "$"} {formatAmount(card.amount)}</h4>
            <div className="d-flex flex-wrap ">
              <i className="bi bi-clock "></i>
              <p className="px-2">
                {card?.Deadline && convertISOToHumanReadable(card?.Deadline)}
              </p>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <i className="bi bi-mortarboard-fill col-1 "></i>
            <small className="col-11 text-truncate d-inline-block fs-5">
              {card.scholarshipName}
            </small>
          </div>
          <div className="d-flex flex-wrap justify-content-between ">
            <i className="bi bi-send-arrow-up-fill col-1 mt-1"></i>
            <small className="col-11 mb-1 pb-0 mt-1">
              Provider :{card.scholarshipProvider}
            </small>
          </div>
          <div className="d-flex flex-wrap justify-content-between ">
            <i className="bi bi-journal-bookmark-fill col-1 mt-1"></i>
            <small className="col-11 mt-1 text-truncate">
              Course name:{" "}
              {Array.isArray(card.courseName) && card.courseName.length > 0
                ? card.courseName.join(", ")
                : "All"}
            </small>
          </div>
          {/* <Link to={card.redirectionalUrl} target="_blank">
          <button className="btn btn-outline-secondary rounded w-100 me-3" onClick={handleSchlorship}>Apply Now</button>
        </Link> */}
          <Link to={`/scholarshipinfo/${card._id}`}>
            <button
              type="button"
              className="btn btn-outline-secondary w-100 z-3 mt-2 position-relative"
              // onClick={() => handleKnowMore(card._id)}
              onClick={handleClick}
            >
              Know More
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

const RecommendedScholarship = memo(function RecommendedScholarship({
  filteredData,
}) {
  const [cardData, setCardData] = useState([]);
  const { isUserLoggedIn, userCountry, setUserCountry } =
    useContext(UserContext);
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  // console.log("filteredData",filteredData)
  // console.log("cardData",cardData)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const userToken = localStorage.getItem("userToken");
        const updatedToken = `Bearer ${userToken} `;
        // console.log('updatedToken',updatedToken)
        const authAxios = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          // baseURL: API_BASE_URL,
          headers: {
            authorization: updatedToken,
          },
        });
        const response = await authAxios.get(
          `${process.env.REACT_APP_BASE_URL}/getAllScholarships/${userId}`
          // `${API_BASE_URL}/getAllScholarships/${userId}`
        );
        // console.log(
        //   "api hit in the recommendation in path2 study schol",
        //   response.data
        // );
        // setCardData(response.data.scholarships); // Assuming data is an array
        // checked the data and filtered by
        if (response.data?.scholarships && response.data?.scholarship > 0) {
          const filtered = response.data.scholarships.filter(
            (item) =>
              item.scholarshipProvider === "Path2Study" &&
              item.status !== "expired" &&
              item.isDeleted !== true
          );
          setCardData(filtered);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // old direct get the data withouct check for userLogged in
    // fetchData();
    if (isUserLoggedIn) {
      fetchData();
    }
  }, []);

  return (
    <>
      {isUserLoggedIn && isUserLoggedIn !== undefined ? (
        <div className="container g-2 mt-4 ">
          {(filteredData && filteredData.length) !== 0 && (
            <div className="container">
              <span className="fs-3 text fw-semibold text-primary ">
                Path2Study
              </span>{" "}
              <span className="fs-3 text">SCHOLARSHIPS</span>
            </div>
          )}
          <div className="container mt-4 ">
            <div className="row">
              {/* {filteredData && filteredData.length > 0 && (
                <>
                  <div className="text-center border pt-5  rounded-4">
                    <span className="fs-3 text-dark ">
                      Thank you for completing your profile on Path2Study.
                      Currently, there are{" "}
                      <span className="text-primary"> no matches</span> based on
                      your profile.
                    </span>
                    <p className="text-dark py-5 px-2">
                      Note: Consider updating your profile for better matching
                      opportunities. Ignore this message if your profile
                      information is already updated.
                    </p>
                  </div>
                </>
              )} */}
              {
                filteredData ? (
                  filteredData.length > 0 ? (
                    filteredData.map((card) => (
                      <ScholarshipCard card={card} key={card._id} />
                    ))
                  ) : (
                    <div className="text-center">
                      {/* jevha country ani requirment not match  */}
                      {filteredData.isProfileComlete !== true ? (
                        <>
                          <div
                            className="d-flex justify-content-center  "
                            style={{
                              marginTop: "4rem",
                              marginBottom: "2rem",
                            }}
                          >
                            <div className="text-primary border p-5 rounded-4 mx-1">
                              <p className="fs-3">
                                {" "}
                                <span className="text-primary">
                                  Kindly complete your profile to checkout
                                  scholarships curated for you!
                                </span>
                              </p>
                              <Link
                                to="/user/userprofile"
                                className="text-decoration-underline fs-5 profile-link-button"
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                Complete Profile
                                <FaArrowRightLong
                                  style={{ verticalAlign: "middle" }}
                                  className="ms-2 mt-1"
                                />
                              </Link>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-center border pt-5  rounded-4">
                            <span className="fs-3 text-dark ">
                              Thank you for completing your profile on
                              Path2Study. Currently, there are{" "}
                              <span className="text-primary"> no matches</span>{" "}
                              based on your profile.
                            </span>
                            <p className="text-dark py-5 px-2">
                              Note: Consider updating your profile for better
                              matching opportunities. Ignore this message if
                              your profile information is already updated.
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  )
                ) : null
                // (
                //   (console.log("not of filterdata cardData else", cardData),
                //   cardData.map((card) => (
                //     <ScholarshipCard card={card} key={card._id} />
                //   )))
                // )
              }
            </div>
          </div>
        </div>
      ) : (
        <NotShowData />
      )}
    </>
  );
});

export default RecommendedScholarship;
