import React from "react";
import { Modal } from "react-bootstrap";
// import { RxCross1 } from "react-icons/rx";
import "../style/CulturalIdentityPopup.css";
import { useNavigate } from "react-router-dom";

export default function CulturalIdentityPopup({
  openmodalLoan,
  setopenmodalLoan,
  handleRadioChange,
  handleLoanReq,
  culturalIdentity,
}) {
  //   console.log("openmodalLoan", openmodalLoan);
  const navigate = useNavigate()
  return (
    <div>
      {openmodalLoan && (
        <Modal
          show={true}
          backdrop="static" // not close on click outer the modal
        >
          <div className="position-relative cultural-modal">
            <div className="modal-header">
              <h1 className="modal-title fs-5 mt-3" id="staticBackdropLabel">
                Please tell us your Cosigner Preferences so we can curate our loans
                options for you. Select an option from below
              </h1>
            </div>
            <div className="modal-body">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="culturalIdentity"
                  id="flexRadioDefault1"
                  value="US citizen or GC holder with US cosigner"
                  onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  US citizen or GC holder with US cosigner
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="radio"
                  id="flexRadioDefault2"
                  value="US citizen or GC holder with no US cosigner"
                  name="culturalIdentity"
                  onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  US citizen or GC holder with no US cosigner
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="culturalIdentity"
                  id="flexRadioDefault3"
                  value="Non-US citizen"
                  onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault3">
                  Non-US citizen
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary px-5"
                disabled={culturalIdentity === ""}
                onClick={handleLoanReq}
              >
                Save
              </button>
            </div>

            {/* Close Btn  */}
            <button
              type="button"
              className="btn-close slider-btn"
              aria-label="Close"
              onClick={() => {
                setopenmodalLoan(false);
                navigate('/loan')
              }}
            >
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
{
  /* <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Please tell us your cultural identity so we can curate our
                  loans options for you. Select an option from below
                </h1>
              </div>
              <div className="modal-body">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="culturalIdentity"
                    id="flexRadioDefault1"
                    value="US citizen or GC holder with US cosigner"
                    onChange={handleRadioChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    US citizen or GC holder with US cosigner
                  </label>
                </div>
                <div className="form-check ">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="flexRadioDefault2"
                    value="US citizen or GC holder with no US cosigner"
                    name="culturalIdentity"
                    onChange={handleRadioChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    US citizen or GC holder with no US cosigner
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="culturalIdentity"
                    id="flexRadioDefault3"
                    value="Non-US citizen"
                    onChange={handleRadioChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault3"
                  >
                    Non-US citizen
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleLoanReq}
                >
                  Save
                </button>
              </div>
            </div> */
}
