import React, { useState, useRef, createRef } from "react";
import AdminSideBar from "./AdminSideBar";
import "../styles/Addblogs.css";
import { TiCloudStorageOutline } from "react-icons/ti";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import BreadCrumb from "./BreadCrumb";
import { FaCircleUser } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BsInfoCircle } from "react-icons/bs";
// import { MdEdit } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
// import { v4 as uuidv4 } from "uuid";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import "react-image-crop/dist/ReactCrop.css";

import "react-image-crop/dist/ReactCrop.css";
//import { API_BASE_URL } from "../../appConfig";
const Addblogs = () => {
  const [addBlogFormData, setAddBlogFormData] = useState({
    coverImage: "",
    bannerImage: "",
    titleOfTheBlog: "",
    paragraphAHeading: "",
    paragraphA: "",
    paragraphBHeading: "",
    paragraphB: "",
    paragraphCHeading: "",
    paragraphC: "",
    author: "",
    datePublish: "",
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [coverImage, setCoverImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);

  const [imageName, setImageName] = useState();
  const [imageName2, setImageName2] = useState();

  const [textInput, setTextInput] = useState("");

  const handleInputChnageAddBlog = (e) => {
    setAddBlogFormData({
      ...addBlogFormData,
      [e.target.name]: e.target.value,
    });

    const newText = e.target.value;
    // Replace newline characters with <br> tag
    const formattedText = newText.replace(/\n/g, "<br>");
    setTextInput(formattedText);
  };

  function captureEnter(event) {
    if (event.keyCode === 13) {
        document.getElementById("exampleFormControlTextarea1").value =
        document.getElementById("exampleFormControlTextarea1").value + "<br/>";

        document.getElementById("paragraphB").value =
        document.getElementById("paragraphB").value + "<br/>";
        
        document.getElementById("paragraphC").value =
        document.getElementById("paragraphC").value + "<br/>";
      return false;
    } else {
      return true;
    }
  }

  console.log("paraText---->", textInput);

  const [showImageCrop, setShowImageCrop] = useState(false);

  const handleImageChangeCoverImage = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    // console.log("Selected file handle onchange :", file); // Check the selected file
    if (file) {
      setCoverImage({ file, imageUrl: URL.createObjectURL(file) });
      setShowImageCrop(true);
      setImageHide1(true);
      setImageName(file.name);

      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file); // Read the selected file
    }
  };

  const handleImageChangeBannerImage = (e) => {
    e.preventDefault();
    // const file = e.target.files[0];
    // console.log("Selected file:", file); // Check the selected file
    // if (file) {
    //   setBannerImage({ file, imageUrl: URL.createObjectURL(file) });
    //   setShowImage2Crop(true);
    //   setImageHide2(true);
    //   setImageName2(file);
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     setImage2(reader.result);
    //   };
    //   reader.readAsDataURL(file); // Read the selected file
    // }

    e.preventDefault();
    const file = e.target.files[0];
    // console.log("Selected file handle onchange :", file); // Check the selected file
    if (file) {
      setBannerImage({ file, imageUrl: URL.createObjectURL(file) });
      setShowImage2Crop(true);
      setImageHide2(true);
      setImageName2(file.name);

      // console.log("image1", imageName2);
      const reader = new FileReader();
      reader.onload = () => {
        setImage2(reader.result);
      };
      reader.readAsDataURL(file); // Read the selected file
    }
  };

  let adminToken = localStorage.getItem("adminToken");
  let adminId = localStorage.getItem("adminId");

  const dataTosend = {
    addBlogFormData,
    coverImage,
    bannerImage,
  };
  const headers = {
    authorization: `bearer ${adminToken}`,
    "Content-Type": "multipart/form-data",
  };

  const addblogNavigate = useNavigate();

  // const handleSubmitAddBlog = () => {
  //   console.log("in handleSubmitAddBlog------->", dataTosend.coverImage);

  //   axios
  //     .post(
  //       `https://server-dot-path2study.el.r.appspot.com/admin/addblog/${adminId}`,
  //       {
  //         coverImage: cropData,
  //         bannerImage: bannerImage,
  //         titleOfTheBlog: addBlogFormData.titleOfTheBlog,
  //         paragraphAHeading: addBlogFormData.paragraphAHeading,
  //         paragraphA: addBlogFormData.paragraphA,
  //         paragraphBHeading: addBlogFormData.paragraphBHeading,
  //         paragraphB: addBlogFormData.paragraphB,
  //         paragraphCHeading: addBlogFormData.paragraphCHeading,
  //         paragraphC: addBlogFormData.paragraphC,
  //         author: addBlogFormData.author,
  //         createdAt: addBlogFormData.datePublish,
  //       },
  //       { headers: headers }
  //     )
  //     .then((responce) => {
  //       toast.success("New blog added succesfully", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       console.log(responce);
  //       setShow(false);

  //       setTimeout(() => {
  //         addblogNavigate("/admin/blogs-management");
  //       }, 3000);
  //     })

  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const handleSubmitAddBlog = () => {
    // console.log("in handleSubmitAddBlog------->", dataTosend.coverImage);

    // Convert base64 to Blob for cover image
    const timestamp1 = new Date().getTime();
    const randomFileName1 = `${timestamp1}.png`;
    const byteCharacters1 = atob(cropData?.split(",")[1]);
    const byteNumbers1 = new Array(byteCharacters1?.length);
    for (let i = 0; i < byteCharacters1?.length; i++) {
      byteNumbers1[i] = byteCharacters1?.charCodeAt(i);
    }
    const byteArray1 = new Uint8Array(byteNumbers1);
    const blob1 = new Blob([byteArray1], { type: "image/png" });
    const file1 = new File([blob1], randomFileName1, {
      type: blob1.type,
    });

    // Convert base64 to Blob for banner image
    const timestamp2 = new Date().getTime();
    const randomFileName2 = `${timestamp2}.png`;
    const byteCharacters2 = atob(cropData2?.split(",")[1]);
    const byteNumbers2 = new Array(byteCharacters2?.length);
    for (let i = 0; i < byteCharacters2?.length; i++) {
      byteNumbers2[i] = byteCharacters2?.charCodeAt(i);
    }
    const byteArray2 = new Uint8Array(byteNumbers2);
    const blob2 = new Blob([byteArray2], { type: "image/png" });
    const file2 = new File([blob2], randomFileName2, {
      type: blob2.type,
    });

    // Update your form data with file objects
    const formData = new FormData();
    formData.append("coverImage", file1);
    formData.append("bannerImage", file2);
    formData.append("titleOfTheBlog", addBlogFormData.titleOfTheBlog);
    formData.append("paragraphAHeading", addBlogFormData.paragraphAHeading);
    formData.append("paragraphA", addBlogFormData.paragraphA);
    formData.append("paragraphBHeading", addBlogFormData.paragraphBHeading);
    formData.append("paragraphB", addBlogFormData.paragraphB);
    formData.append("paragraphCHeading", addBlogFormData.paragraphCHeading);
    formData.append("paragraphC", addBlogFormData.paragraphC);
    formData.append("author", addBlogFormData.author);
    formData.append("createdAt", addBlogFormData.datePublish);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/addblog/${adminId}`,
        formData,
        { headers: headers }
      )
      .then((responce) => {
        toast.success("New blog added succesfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShow(false);

        setTimeout(() => {
          addblogNavigate("/admin/blogs-management");
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDiscard = () => {
    setCropData(null);
    setCropData2(null);
    setAddBlogFormData({
      coverImage: "",
      bannerImage: "",

      titleOfTheBlog: "",
      paragraphAHeading: "",
      paragraphA: "",
      paragraphBHeading: "",
      paragraphB: "",
      paragraphCHeading: "",
      paragraphC: "",
      author: "",
      datePublish: "",
    });

    setShowDiscardblog(false);
    window.location.reload();
  };

  // Hide Cover Image Section //

  const [imageHide1, setImageHide1] = useState();
  const [imageHide2, setImageHide2] = useState();

  const [hover, setHover] = useState(false);
  const [hoverBanner, setHoverBanner] = useState(false);

  const [showDiscardblog, setShowDiscardblog] = useState(false);

  //css
  const forInputField = {
    fontWeight: "400",
    border: "revert-layer",
    outline: "none",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderWidth: "1px",
  };

  // cropper //

  const [image, setImage] = useState(coverImage);
  const [cropData, setCropData] = useState("");

  const cropperRef = createRef();

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setShowImageCrop(false);
    }
  };

  // cropper 2//
  const [image2, setImage2] = useState(bannerImage);
  const [cropData2, setCropData2] = useState("");
  const cropperRef2 = createRef();
  const [showImage2Crop, setShowImage2Crop] = useState();
  const getCropData2 = () => {
    if (typeof cropperRef2.current?.cropper !== "undefined") {
      setCropData2(cropperRef2.current?.cropper.getCroppedCanvas().toDataURL());
      setShowImage2Crop(false);
    }
  };

  const handleImageCropClose = () => {
    setShowImageCrop(false); // Update the state to hide the popup
    setCoverImage(null); // Reset coverImage state to null when modal is closed
    setImageHide1(false); // Update imageHide1 state to show the "Add cover-image" button section
    window.location.reload();
  };

  const handleImage2CropClose = () => {
    setShowImage2Crop(false); // Update the state to hide the popup
    setBannerImage(null); // Reset bannerImage state to null when modal is closed
    setImageHide2(false); // Update imageHide2 state to show the "Add banner-image" button section
    window.location.reload();
  };

  // date format //
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  return (
    <div
      style={{ backgroundColor: "#EFF2F5" }}
      className="Addblogs   pageBackroundforBothPage"
    >
      <div className="">
        <div className="d-flex justify-content">
          <div className="col-2">
            <AdminSideBar />
          </div>
          <div className="col-10  py-3  rounded-3">
            <div className="px-3">
              <div className="d-flex ">
                <BreadCrumb />
                <div className="px-1 py-3">
                  <div className="d-flex justify-content-between">
                    <FaCircleUser size={25} className="mt-1 mx-2" />
                    <div className="align-middle mt-2">
                      {localStorage.getItem("adminName")}
                    </div>
                  </div>
                </div>
              </div>
              <hr className=" my-1" />
            </div>
            <div className="px-3">
              <div className="forBackgroundColor rounded-3 mt-5  p-3">
                <h3 className="py-2">+ Add New Blog</h3>
                <div className="d-flex justify-content">
                  <div className="col-5">
                    <div className="bg-white rounded-3 p-5">
                      <label>Title of the blog</label>

                      <input
                        className="form-control forInputFiledAddblog"
                        type="text"
                        placeholder="Title"
                        name="titleOfTheBlog"
                        style={forInputField}
                        value={addBlogFormData.titleOfTheBlog}
                        onChange={handleInputChnageAddBlog}
                      />
                      <label className="mt-3">
                        Add cover image of the blog
                        <span
                          className="pt-2 align-middle"
                          onMouseEnter={() => setHover(true)}
                          onMouseLeave={() => setHover(false)}
                        >
                          <BsInfoCircle className="py-1" size={23} />
                        </span>
                      </label>

                      <div>
                        <div className="position-relative">
                          {hover && (
                            <div className="absolute-content-info">
                              <div className="box-style-info text-white">
                                <ul
                                  className="my-2 px-3"
                                  style={{ textAlign: "justify" }}
                                >
                                  Cover image will appear on the blog feed.
                                  Choose a relevant image with 320x200
                                  resolution, high-quality, and eye-catching to
                                  attract more readers to your post.
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {imageHide1 === true ? (
                        <div
                          className="rounded-3 mb-3 addcover-container"
                          // style={{
                          //   backgroundImage: `url(${cropData})`,
                          //   backgroundRepeat: "no-repeat",
                          //   backgroundSize: "cover",
                          //   height: "280px",

                          // }}
                        >
                          <img
                            src={cropData}
                            height={200}
                            width="100%"
                            className="rounded"
                          />
                          <div className="text-end">
                            <label
                              htmlFor="fileInputTwo"
                              className="bg-warning col-1 addcover-click cursor-pointer rounded"
                            >
                              {" "}
                              <AiTwotoneEdit fill="" size={25} />
                            </label>
                          </div>
                          <input
                            type="file"
                            id="fileInputTwo"
                            className="file-input"
                            accept="image/*"
                            name="coverImage"
                            style={forInputField}
                            onChange={(e) => handleImageChangeCoverImage(e)}
                          />
                        </div>
                      ) : (
                        <>
                          <div className="file-upload-box p-xl-5">
                            <label className="file-label text-primary cursor-pointer">
                              <div className="text-center">
                                <TiCloudStorageOutline size={35} />
                              </div>
                              <p className="text-dark text-xl-center">
                                Browse and choose the files you want to upload
                                from your system.{" "}
                              </p>
                            </label>
                          </div>
                          <input
                            type="file"
                            id="fileInputTwo"
                            className="file-input"
                            accept="image/*"
                            name="coverImage"
                            style={forInputField}
                            onChange={(e) => handleImageChangeCoverImage(e)}
                          />

                          <label
                            htmlFor="fileInputTwo"
                            className="btn btn-primary mt-2 mb-3"
                          >
                            Add cover-image
                          </label>
                        </>
                      )}

                      <br className="d-none d-xl-block"></br>

                      <label className="">
                        Add banner image of the blog
                        <span
                          className="pt-2 align-middle"
                          onMouseEnter={() => setHoverBanner(true)}
                          onMouseLeave={() => setHoverBanner(false)}
                        >
                          <BsInfoCircle className="py-1" size={23} />
                        </span>
                      </label>
                      <div className="position-relative">
                        {hoverBanner && (
                          <div className="absolute-content-info">
                            <div className="box-style-info text-white">
                              <ul
                                className="my-2 px-3"
                                style={{ textAlign: "justify" }}
                              >
                                Banner image will appear inside the blog. Choose
                                an eye-catching image with 820x380 resolution,
                                to attract more readers to your post.
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>

                      {imageHide2 === true ? (
                        <>
                          <div
                            className="rounded-3 addbanner-container"
                            // style={{
                            //   backgroundImage: `url(${cropData2})`,
                            //   backgroundRepeat: "no-repeat",
                            //   backgroundSize: "cover",
                            //   height: "280px",
                            // }}
                          >
                            <img
                              src={cropData2}
                              height={200}
                              width="100%"
                              className="rounded"
                            />
                            <div className="text-end ">
                              <label
                                htmlFor="fileInput"
                                className="bg-warning addbanner-click col-1 cursor-pointer rounded"
                              >
                                {" "}
                                <AiTwotoneEdit fill="" size={25} />
                              </label>
                            </div>
                          </div>
                          <input
                            type="file"
                            id="fileInput"
                            className="file-input"
                            accept="image/*"
                            name="bannerImage"
                            onChange={(e) =>
                              handleImageChangeBannerImage(e, setBannerImage)
                            }
                          />
                        </>
                      ) : (
                        <>
                          <div className="file-upload-box p-xl-5">
                            <label className="file-label text-primary cursor-pointer">
                              <div className="text-center">
                                <TiCloudStorageOutline size={35} />
                              </div>
                              <p className="text-dark text-xl-center">
                                Browse and choose the files you want to upload
                                from your system.
                              </p>
                            </label>
                          </div>
                          <input
                            type="file"
                            id="fileInput"
                            className="file-input"
                            accept="image/*"
                            name="bannerImage"
                            onChange={(e) =>
                              handleImageChangeBannerImage(e, setBannerImage)
                            }
                          />
                          <label
                            htmlFor="fileInput"
                            className="btn btn-primary mt-2 mb-3"
                          >
                            {" "}
                            Add banner-image
                          </label>
                          <br></br>
                        </>
                      )}

                      {bannerImage && (
                        <p className="mt-1 text-primary">{bannerImage.name}</p>
                      )}

                      <label className="">Paragraph (A) heading </label>
                      <input
                        className="form-control forInputFiledAddblog"
                        type="text"
                        placeholder="Paragraph A heading"
                        name="paragraphAHeading"
                        style={forInputField}
                        value={addBlogFormData.paragraphAHeading}
                        onChange={handleInputChnageAddBlog}
                      />

                      <div>
                        <label htmlFor="" className="mt-3">
                          Paragraph (A)
                        </label>
                        <textarea
                          className="form-control forInputFiledAddblog"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          name="paragraphA"
                          style={forInputField}
                          maxLength={3000}
                          value={addBlogFormData.paragraphA}
                          onKeyPress={captureEnter}
                          onChange={handleInputChnageAddBlog}
                        ></textarea>

                        <p className="mt-1">Max character limit: 3000 words</p>

                        <label className="mt-3">Paragraph (B) heading </label>
                        <input
                          className="form-control forInputFiledAddblog"
                          type="text"
                          placeholder="Paragraph B heading"
                          name="paragraphBHeading"
                          style={forInputField}
                          value={addBlogFormData.paragraphBHeading}
                          onChange={handleInputChnageAddBlog}
                        />
                        <label htmlFor="" className="mt-3">
                          Paragraph (B)
                        </label>
                        <textarea
                          className="form-control forInputFiledAddblog"
                          id="paragraphB"
                          rows="5"
                          name="paragraphB"
                          style={forInputField}
                          maxLength={3000}
                          value={addBlogFormData.paragraphB}
                          onChange={handleInputChnageAddBlog}
                          onKeyPress={captureEnter}
                        ></textarea>

                        <p className="mt-1">Max character limit: 3000 words</p>
                        {/*  b paragraph */}

                        <label className="mt-3">Paragraph (C) heading </label>
                        <input
                          className="form-control forInputFiledAddblog"
                          type="text"
                          placeholder="Paragraph C heading"
                          name="paragraphCHeading"
                          style={forInputField}
                          value={addBlogFormData.paragraphCHeading}
                          onChange={handleInputChnageAddBlog}
                        />

                        <label htmlFor="" className="mt-3">
                          Paragraph (C)
                        </label>
                        <textarea
                          className="form-control forInputFiledAddblog"
                          id="paragraphC"
                          rows="3"
                          name="paragraphC"
                          style={forInputField}
                          maxLength={3000}
                          value={addBlogFormData.paragraphC}
                          onChange={handleInputChnageAddBlog}
                          onKeyPress={captureEnter}
                        ></textarea>

                        <ToastContainer />

                        <p className="mt-1">Max character limit: 3000 words</p>
                      </div>
                    </div>
                  </div>

                  {/* preview section */}

                  <div className="col-3 bg-white rounded-3">
                    <div className="bg-white rounded-3 pt-5">
                      <label>Publish date</label>
                      <input
                        className="form-control forInputFiledAddblog"
                        type="date"
                        id="exampleDate"
                        placeholder="Enter Publish date"
                        name="datePublish"
                        style={forInputField}
                        value={addBlogFormData.datePublish}
                        onChange={handleInputChnageAddBlog}
                      />

                      <label className="mt-3">Author</label>
                      <input
                        className="form-control forInputFiledAddblog"
                        type="text"
                        placeholder="Enter the author"
                        name="author"
                        style={forInputField}
                        value={addBlogFormData.author}
                        onChange={handleInputChnageAddBlog}
                      />
                    </div>
                  </div>

                  <div className="col-4 bg-white border-0 rounded-3 pt-5 px-5">
                    <h6> PREVIEW</h6>

                    {/* <div
                    className="rounded m-0 p-0 "
                    style={{
                      backgroundImage: `url(${cropData})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      height: "150px",
                    }}
                  ></div> */}
                    {cropData === "" ? (
                      ""
                    ) : (
                      <img
                        src={cropData}
                        // className="img-fluid"
                        height={150}
                        width="100%"
                        alt=""
                      />
                    )}
                    <h5 className="mt-3">{addBlogFormData.titleOfTheBlog}</h5>
                    <p>
                      {new Date(
                        addBlogFormData?.datePublish
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}{" "}
                      &nbsp; • {addBlogFormData?.author}
                    </p>

                    <div className="">
                      <p className="text-muted">
                        This is the how your newly added blog card will appear
                        to the users of the website.
                      </p>
                    </div>
                    <div className="d-flex flex-wrap justify-content-between">
                      <div className="col-6 pe-1">
                        <button
                          type="button"
                          className="w-100 p-2 border border-muted rounded"
                          onClick={() => setShowDiscardblog(true)}
                        >
                          Discard
                        </button>
                      </div>
                      <div className="col-6 px-1">
                        <button
                          type="button"
                          className="w-100 p-2 border-primary border bg-white rounded text-primary"
                          onClick={() => {
                            setShow(true);
                          }}
                          disabled={
                            !addBlogFormData.titleOfTheBlog.trim() ||
                            // !addBlogFormData.paragraphAHeading.trim() ||
                            // !addBlogFormData.paragraphA.trim() ||
                            !addBlogFormData.author ||
                            !addBlogFormData.datePublish.trim() ||
                            cropData === "" ||
                            cropData2 === ""
                            // ||
                            // !addBlogFormData.cropData
                          }
                        >
                          Save
                        </button>
                      </div>
                    </div>

                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add new blog?</Modal.Title>
                      </Modal.Header>
                      {/* <Modal.Body> */}
                      <p className="px-3">
                        New blog information will be added. Are you sure you
                        want to proceed?
                      </p>
                      {/* </Modal.Body> */}
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => handleSubmitAddBlog()}
                        >
                          Confirm
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    {/* discard blog popup */}
                    <Modal
                      show={showDiscardblog}
                      onHide={() => setShowDiscardblog(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Discard changes?</Modal.Title>
                      </Modal.Header>
                      {/* <Modal.Body> */}
                      <p className="mx-3">
                        All the changes will be discarded. Are you sure you want
                        to proceed?
                      </p>
                      {/* </Modal.Body> */}
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowDiscardblog(false)}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="primary"
                          // onClick={handleDiscard}
                          onClick={() => handleDiscard()}
                        >
                          Confirm
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>

                  <Modal show={showImage2Crop} onHide={handleImage2CropClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Crop Banner Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <div className="px-5">
                          <Cropper
                            ref={cropperRef2}
                            style={{ height: "200px", width: "100%" }}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={image2}
                            viewMode={1}
                            minCropBoxHeight={380}
                            minCropBoxWidth={820}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            guides={true}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleImage2CropClose}
                      >
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={getCropData2}>
                        Crop Image
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <Modal show={showImageCrop} onHide={handleImageCropClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Crop Cover Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <div className="px-5">
                          <Cropper
                            ref={cropperRef}
                            style={{ height: "200px", width: "100%" }}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={image}
                            viewMode={1}
                            minCropBoxHeight={200}
                            minCropBoxWidth={384}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            guides={true}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleImageCropClose}
                      >
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={getCropData}>
                        Crop Image
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addblogs;
