import React, { useContext } from "react";
import { Modal, Form } from "react-bootstrap";

import "../../style/signUpmodal.css"
import axios from "axios";
import { ModalContext } from "../../contexts/ModalContext";
// import { API_BASE_URL } from "../../appConfig";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import * as formik from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

const VerificationModal = () => {
  const { handleNext, data, handlePrevious } = useContext(ModalContext);

  const { Formik } = formik;

  const otpSchema = yup.object({
    emailOTP: yup
      .string()
      .length(6, "OTP must be exactly 6 digit")
      .matches(/^[0-9]+$/, "OTP must contain only numeric digits")
      .required("OTP is required"),
  });

  const handleResendOtp = async () => {
    const { email } = data;
    const postData = { email, register: "true" };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/generateOtp`,
        // `${API_BASE_URL}/generateOtp`,
        // "https://path2study-server-dot-ize-testenv.uc.r.appspot.com/generateOtp",
        postData
      );
      // console.log("Response:", response.data);
      if (response.data === "OTP sent to your entered email") {
        toast("OTP sent to your entered email", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // allCloseModal();
      }
    } catch (error) {
      console.error("Error: in generate otp", error);
      // Handle errors, if any
    }
  };
  const handleVerifyRequest = async (values) => {
    const { email } = data;
    const postData = { emailOTP: values.emailOTP, email: email };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/verifyOtp`, postData);
      // const response = await axios.post(`${API_BASE_URL}/verifyOtp`, postData);
      handleNext(postData);
    } catch (error) {
      // console.error("Error: in verify api", error);
      // Handle errors, if any
      if (error) {
        toast(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  return (
    <>
      <Modal.Body>
        <div className="card mx-md-4 shadow p-3 mb-3 bg-body rounded-lg">
          <img
            src={require("../../assests/img/otp.png")}
            className="rounded mx-auto d-block"
            alt="logocreate"
          />
          <p className="fs-6  mt-3">
            We have sent a verification code on your email. Please check your
            inbox or spam folder. Enter the verification code below to create
            your account.
          </p>

          {/* new form with validation */}

          <Formik
            validationSchema={otpSchema}
            onSubmit={handleVerifyRequest}
            initialValues={{
              emailOTP: "",
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="validationFormik01">
                    <Form.Label>Enter Verification Code</Form.Label>
                    <Form.Control
                      placeholder="- - - - - -"
                      type="text"
                      name="emailOTP"
                      value={values.emailOTP}
                      onChange={handleChange}
                      isInvalid={!!errors.emailOTP}
                      style={{
                        fontWeight: "500",
                        border: "revert-layer", // Remove default border on focus
                        outline: "none", // Remove default outline on focus
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.emailOTP}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <a
                    className="text-primary d-grid justify-content-end mt-1 pe-auto cursor-pointer text-decoration-underline"
                    onClick={handleResendOtp}
                  >
                    Resend Code
                  </a>
                </Row>

                {/* <div className="d-grid gap-2">
                  <Button variant="primary" type="submit">
                    Verify Email
                  </Button>
                </div> */}
                <div className="d-flex justify-content-evenly ">
                  <button
                    className="btn btn-outline-primary d-flex w-50 justify-content-center mx-2 custom-smaller-button align-items-center"
                    onClick={handlePrevious}
                  >
                    <span className="">
                      <i className="bi bi-arrow-left "></i> Back
                    </span>
                  </button>
                  <button
                    // variant="primary"
                    className="d-flex btn btn-primary text-wrap  w-50 justify-content-center "
                    type="submit"
                    // onClick={handleNext}
                  >
                    <span>
                    Verify Email
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
      {/* </Modal> */}
    </>
  );
};

export default VerificationModal;

/* <form>
            <Form.Group controlId="formBasicName">
              <Form.Label className="m-2 fw-light ">
                Enter Verification Code
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="- - - - - -"
                value={formik.values.emailOTP}
                onChange={formik.handleChange}
                name="emailOTP"
              />
            </Form.Group>
            <a
              className="text-primary d-grid justify-content-end mt-1 pe-auto"
              onClick={handleResendOtp}
            >
              Resend Code
            </a>
          </form>
          <Button
            variant="primary"
            className="d-grid gap-2 col-6 mx-auto w-100 mt-3"
            onClick={handleVerifyRequest}
          >
            Verify Email
          </Button> */
