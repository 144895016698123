import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { LuPencilLine } from "react-icons/lu";
import { Modal, Button } from "react-bootstrap";
import BreadCrumb from "./BreadCrumb";
import { FaCircleUser, FaL } from "react-icons/fa6";
function AboutBank() {
  const nevigate = useNavigate();
  const [splitText, setSplitText] = useState([]);
  const [showModal, setShowModal] = useState(false);

  // const handleCloseModal = () => setShowModal(false);
  // const handleShowModal = () => setShowModal(true);

  // const handleClick = (getLoanData) => {
  //   nevigate("/admin/loanmanagement/editbank", { state: getLoanData });
  // };
  //
  // Location
  const location = useLocation();
  const getLoanData = location.state;


  const handleClick = () => {
    // nevigate("/admin/loan-management/edit-lender", { state: getLoanData });
    nevigate("/admin/loan-management/edit-lender", {
      state: getLoanData,
    });
    window.scrollTo(0, 0);
  };


  // for getloan split data
  // const bankLogo = getLoanData?.bankLogo

  useEffect(() => {
    if (getLoanData && getLoanData?.aboutTheLoan) {
      // Split each line when \n is encountered
      const aboutTheLoanLines = getLoanData.aboutTheLoan.split("\n");

      // Filter out empty lines
      const filteredLines = aboutTheLoanLines.filter(
        (line) => line.trim() !== ""
      );

      // Set the split lines to the state
      setSplitText(filteredLines);
    }
  }, []);

  return (
    <>
    
      {/* <div className="d-flex">
        <BreadCrumb className="" />
        <div className="mt-3 text-muted pe-2 cursor-pointer">
          {localStorage.getItem("adminName")}
        </div>
      </div> */}

      <div className="shadow ">
        <div className="">
          {/* for right text */}
          {/* <div className="col-5 ">
            <div className="d-flex flex-wrap justify-content-between ">
              <h5 className="mt-3 p-1">Discover Loans</h5>
              <button
                className="rounded-2 border text-end btn btn-outline-primary mt-3 p-1 px-2"
                onClick={handleClick}
              >
                <LuPencilLine className="fs-5 mt-1" />
                Edit Details
              </button>
            </div>
          </div> */}
          {/* <div className=""></div> */}

          {/* <div className="col-2 ">
            <div className="d-flex flex-wrap justify-content-between">
              <div>
                <p className="text-muted p-2">Variable Rate</p>
                <p className="text-muted p-2">Fixed Rate</p>
              </div>
              <div>
                <p className="p-2">{getLoanData?.variableInterestRate || ""}</p>
                <p className="p-2">{getLoanData?.fixedInterestRate || ""}</p>
              </div>
            </div>
          </div> */}

          {/* for about the bank content */}
        </div>

        <div className="container p-4">
          <div className="d-flex justify-content-between gap-3">
            <div className="col-8 ">
              <div className="d-flex justify-content-between">
                <h5 className="">{getLoanData?.bankName}</h5>
                <div className="col-4">
                  <button
                    style={{ color: "#01477A", backgroundColor: "#F9FAFC" }}
                    className=" pt-1 pb-1 mt-0 mb-0 btn border-muted border "
                    onClick={handleClick}
                  >
                    <LuPencilLine className="fs-5 " size={15} />
                    &nbsp;Edit Details
                  </button>
                </div>
              </div>

              <div className="d-flex  justify-content-between">
                <p className="text-muted col-2 ">Variable Rates:</p>

                <p className="col-9 px-4">
                  {getLoanData?.variableInterestRate}
                </p>
              </div>

              <div className="d-flex  justify-content-between mt-2">
                <p className="text-muted col-2 ">Fixed Rates:</p>

                <p className="col-9 px-4">{getLoanData?.fixedInterestRate}</p>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <p className="col-2 text-muted ">About the Loan:</p>

                {/* <ul
                  style={{ textAlign: "justify" }}
                  className="mb-0 col-9 pe-5"
                >
                  {splitText.map((line, index) => (
                    <li key={index}>{line}</li>
                  ))}
                </ul> */}
                <p style={{ textAlign: "justify" }} className="col-9 px-4">
                  {getLoanData?.aboutTheLoan}
                </p>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <p className="col-2 text-muted ">About the Lender:</p>
                <p style={{ textAlign: "justify" }} className="col-9 px-4">
                  {getLoanData?.aboutTheBank}
                </p>
              </div>

              <div className="d-flex justify-content-between mt-2 ">
                <p className="col-3 text-muted ">Disclosure:</p>
                <p style={{ textAlign: "justify" }} className="col-9 px-4">
                  {getLoanData?.disclosure}
                </p>
              </div>

              <div className="d-flex justify-content-between mt-2 ">
                <p className="col-3 text-muted ">Redirection URL link:</p>
                <p className="col-9 px-4 text-wrap">{getLoanData?.redirectionalUrl}</p>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <p className="col-3 text-muted ">Country:</p>
                <p className="col-9 px-4">{getLoanData?.country}</p>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <p className="col-3 text-muted ">Cultural Identity:</p>
                <p className="col-9 px-4">
                  {getLoanData?.culturalIdentity.map((element, index) => {
                    return <li>{element}</li>;
                  })}
                </p>
              </div>
            </div>

            {/* <div className="col-4 pe-3">
              <h5>PREVIEW</h5>
              <div className="card rounded-3">
                <div className="text-center">
                  <img
                    src={getLoanData?.bankLogo}
                    alt=""
                    // width="100%"
                    // height="200px"
                    className="rounded-top img-fluid"
                    name="bankLogo"
                  />
                </div>

               

                <div className="p-3">
                  <h5 className="">{getLoanData?.bankName}</h5>
                  <hr />

                  <div className="d-flex justify-content-between">
                    <div className="col-5">
                      <p>Variable Rates:</p>
                      <p>Fixed Rates:</p>
                    </div>

                    <div className="col-7">
                      <p>{getLoanData?.variableInterestRate}</p>
                      <p className="">{getLoanData?.fixedInterestRate}</p>
                    </div>
                  </div>

                  <div className="">
                    <ul
                      className="mb-0 overflow-auto"
                      style={{ height: "97px" }}
                    >
                      {splitText &&
                        splitText.map((line, index) => (
                          <li key={index}>{line}</li>
                        ))}
                    </ul>
                  </div>

                  <div>
                    <span className="d-flex justify-content-end text-decoration-underline  me-1 cursor-pointer">
                      Know more &gt;&gt;
                    </span>
                  </div>

                  <div className="d-flex justify-content-evenly mt-2">
                    <div className="col-6 pe-1">
                      <button className="btn border rounded w-100">
                        Disclosure
                      </button>
                    </div>
                    <div className="col-6 px-1">
                      <button className="w-100  btn btn-primary">
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <p
                style={{ textAlign: "justify" }}
                className="mt-3 text-secondary"
              >
                This is the how your newly added lender card will appear to the
                users of the website.
              </p>
            </div> */}

            <div className="col-4 pe-4">
              <h5 className="m-0">PREVIEW</h5>
              <div class="card rounded">
                <div className="d-flex p-3 align-items-center justify-content-center flex-wrap rounded">
                  {getLoanData?.bankLogo ? (
                    <img
                      src={getLoanData?.bankLogo}
                      alt=""
                      className="rounded-top img-fluid "
                      name="bankLogo"
                    />
                  ) : (
                    <img
                      src={require("../assets/img/placeLoan.png")}
                      alt=""
                      className="rounded-top img-fluid "
                      name="bankLogo"
                    />
                  )}
                </div>
                <div class="card-body pt-0 mt-0">
                  <h5 class="card-title">{getLoanData?.bankName}</h5>

                  <hr></hr>
                  <div className="d-flex justify-content-between">
                    <label className="text-muted col-6">Variable Rates:</label>
                    <h6 className="col-6">
                      {getLoanData?.variableInterestRate}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label className="text-muted col-6">Fixed Rates:</label>
                    <h6 className="col-6">{getLoanData?.fixedInterestRate}</h6>
                  </div>
                  <div>
                    <p
                      class="card-text overflow-auto"
                      style={{ height: "97px" }}
                    >
                      <ul>
                        <li>{getLoanData?.aboutTheLoan}</li>
                      </ul>
                    </p>
                  </div>
                  <div>
                    <span
                      className="d-flex justify-content-end text-decoration-underline  me-1 cursor-pointer "
                      disabled
                    >
                      Know More &gt;&gt;
                    </span>
                  </div>
                  <div className=" d-flex justify-content-between">
                    <div className="col-6 pe-1">
                      <div className="btn border w-100">Disclosure</div>
                    </div>
                    <div className="col-6 px-1">
                      <div className="btn btn-primary w-100">Apply Now</div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-secondary mt-3">
                This is the how your newly added lender card will appear to the
                users of the website.
              </p>
              {/* <div className="d-flex justify-content-between mt-3 ">
                <div className="col-6 pe-1">
                  <button className="btn border w-100">Disclosure</button>
                </div>

                <div className="col-6 px-1">
                  <button className="btn btn-primary w-100">Apply Now</button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>More Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
         {getLoanData?.aboutTheLoan || ''}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
      
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default AboutBank;
