import React from "react";

import bgImage from "../assests/img/bg_loanssecure.png";

const LoansSecuringFinancialAidUSA = () => {
  return (
    <>
      <div
        className="container custome-height "
        // style={{ marginTop: "6rem", marginBottom: "3rem" }}
      >
        <div className="card mb-3 border border-white ">
          <div className="row">
            <div className="col-md-6 me-o pe-0">
              <img
                src={require("../assests/img/loanssecure.png")}
                className="img-fluid h-100 w-100"
                alt="..."
              />
            </div>

            <div className="col-md-6 ms-o ps-0">
              <div
                className="card-body text-sm-start ms-0 ps-0 "
                style={{
                  height: "100%",
                  backgroundImage: "url(" + bgImage + ")",
                }}
              >
                <h1 className="text-primary mb-0 p-3 fs-1">
                  SECURING FINANCIAL AID
                </h1>
                <div className="p-lg-4 px-3">
                  <h5 className="text-justify fw-normal lh-base">
                    Explore the ins and outs of securing financial aid for the
                    students planning to pursue their education. Gain insights
                    into student loans and essential financial assistance to
                    make your education dream a reality. Learn about student
                    loans and other forms of financial support that can help you
                    achieve your educational goals.
                  </h5>
                  <h5 className="text-justify fw-normal lh-base">
                    {" "}
                    Path2Study is a gateway to your educational ambitions that
                    will help you delve into the world of financial aid and
                    student loans to bolster your academic journey. Uncover the
                    eligibility, application process, interest rates
                    and many other information empowering you to pursue your
                    educational ambitions with confidence and support.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoansSecuringFinancialAidUSA;
