import "../style/navbar.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LinkContainer } from "react-router-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ModalContext } from "../contexts/ModalContext";
import MultiStepModal from "./Signup/MultiStepModal";
import SignInModal from "./Signup/SignInModall";
import {  useNavigate, useLocation, NavLink } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { CountryContext } from "../contexts/CountryContext";


function NavBar() {
  const { countryByGoogle } = useContext(CountryContext);
  const location = useLocation();
  const { data, isUserLoggedIn } = useContext(UserContext);

  const [userName, setUserName] = useState("");
  const [showOffcanvas, setShowOffcanvas] = useState("");
  const [IsDropdownActive, setIsDropdownActive] = useState(false);
  const [IsDropdownActiveResources, setIsDropdownActiveResources] =
    useState(false);
  const {
    steps,
    closeModal,
    openModal,
    isOpen,
    showSigninModal,
    allCloseModal,
  } = useContext(ModalContext);

  const handleClick = () => {
    window.scrollTo(0, 0);
    setShowOffcanvas(false);
    const offcanvasNavbar = document.getElementById(
      "offcanvasNavbar-expand-lg"
    );
    if (offcanvasNavbar) {
      offcanvasNavbar.classList.remove("show");
    }
  };

  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();

  const userProfile = () => {
    navigate("/user/userprofile");
    handleClick();
  };

  useEffect(() => {
    setUserName(data.name);
  }, [isUserLoggedIn, data.name]);

  const { country, selectedCountry, setSelectedCountry } =
    useContext(CountryContext);

  useEffect(() => {
    const ScholarshipsNode = document.getElementsByClassName("Scholarships");
    const Resources = document.getElementsByClassName("Resources");
    if (ScholarshipsNode) {
      if (
        location.pathname === "/pathstudyscholarship" ||
        location.pathname === "/otherscholarship"
      ) {
        setIsDropdownActive(true);
      } else {
        setIsDropdownActive(false);
      }
    }
    if (Resources) {
      if (
        location.pathname === "/faq" ||
        location.pathname === "/blogs"
      ) {
        setIsDropdownActiveResources(true);
      } else {
        setIsDropdownActiveResources(false);
      }
    }
  }, [location]);


  return (
    <>
      <Container>
        {["lg"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            className="bg-body-tertiary py-0 "
            // sticky="top"
            fixed="top"
          >
            <Container fluid>
              <Navbar.Brand className="text-primary fw-bold  ms-md-5">
                {/* style={{textShadow:"1px 1px 3px grey"}} */}
                <LinkContainer to="/">
                  <Nav.Link>
                    {/* Path2Study  */}
                    <img
                      src={require("../assests/img/newlogo.png")}
                      className="img-fluid  "
                      alt="..."
                      style={{ width: "70px", height: "70px" }}
                    />
                  </Nav.Link>
                </LinkContainer>
              </Navbar.Brand>
              
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                id="CloseFunction"
                onClick={() => {
                  setShowOffcanvas(true);
                }}
              />
              <Navbar.Offcanvas
                show={showOffcanvas}
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                // className="custom-offcanvas"
              >
                <Offcanvas.Header closeButton={false}>
                  <button
                    onClick={handleClick}
                    className="btn-close close-button "
                  ></button>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body >
                 
                  <div>
                    <select
                      id="country"
                      name="country"
                      className="ms-md-3 ps-md-3 pe-4 ms-2 btn btn-light border opacity-75"
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      value={selectedCountry}
                    >
                      {countryByGoogle === "United States" ? (
                        <option value="india">India</option>
                      ) : (
                        <>
                          {countryByGoogle && (
                            <option value={countryByGoogle}>
                              {countryByGoogle}
                            </option>
                          )}
                        </>
                      )}
                      <option value="usa">USA</option>
                      {!countryByGoogle && <option value="india">India</option>}
                    </select>
                  </div>


                  <Nav className="justify-content-end flex-grow-1 pe-md-3 ">
                    <LinkContainer
                      to="/home"
                      className="px-md-3 px-3"
                      active={location.pathname === "/home"}
                      onClick={handleClick}
                    >
                      <Nav.Link>Home</Nav.Link>
                    </LinkContainer>

                    <LinkContainer
                      to="/loan"
                      className="px-md-3 px-3"
                      active={location.pathname === "/loan"}
                      onClick={handleClick}
                    >
                      <Nav.Link>Student Loans</Nav.Link>
                    </LinkContainer>
                    <NavDropdown
                      title="Scholarships"
                      id={`offcanvasNavbarDropdown-expand-${expand}`}
                      active={IsDropdownActive ? true : false}
                      className="Scholarships px-md-3 px-3"
                    >
                      <NavDropdown.Item
                        as={NavLink}
                        to="/pathstudyscholarship"
                        active={location.pathname === "/pathstudyscholarship"}
                        onClick={handleClick}
                      >
                        Path2Study Scholarships
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/otherscholarship"
                        active={location.pathname === "/otherscholarship"}
                        onClick={handleClick}
                      >
                        Other Scholarships
                      </NavDropdown.Item>
                    </NavDropdown>

                    <LinkContainer
                      to="/about"
                      className="px-md-3 px-3"
                      active={location.pathname === "/about"}
                      onClick={handleClick}
                    >
                      <Nav.Link>About Us</Nav.Link>
                    </LinkContainer>


                    <NavDropdown
                      title="Resources"
                      id={`offcanvasNavbarDropdown-expand-${expand}`}
                      active={IsDropdownActiveResources ? true : false}
                      className="Resources px-md-3 px-3"
                      // style={{ marginBottom: "15px" }}
                    >
                      <NavDropdown.Item
                        as={NavLink}
                        to="/faq"
                        active={location.pathname === "/faq"}
                        onClick={handleClick}
                      >
                        FAQs
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/blogs"
                        active={location.pathname === "/blogs"}
                        onClick={handleClick}
                      >
                        Blogs
                      </NavDropdown.Item>
                    </NavDropdown>

                    <LinkContainer
                      to="/contact"
                      className="px-md-3 px-3"
                      active={location.pathname === "/contact"}
                      onClick={handleClick}
                    >
                      <Nav.Link>Contact Us</Nav.Link>
                    </LinkContainer>
                  </Nav>
                  {isUserLoggedIn ? (
                    <Button
                      variant="outline-secondary"
                      onClick={userProfile}
                      className="me-md-5 ms-3 ms-md-0 newButton"
                      // style={{ marginBottom: "10px" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="15"
                        viewBox="0 0 20 15"
                        fill="none"
                      >
                        <path
                          opacity="0.2"
                          d="M14 6.75C14 7.64002 13.7361 8.51004 13.2416 9.25007C12.7471 9.99009 12.0443 10.5669 11.2221 10.9075C10.3998 11.2481 9.49501 11.3372 8.6221 11.1635C7.74918 10.9899 6.94736 10.5613 6.31802 9.93198C5.68868 9.30264 5.2601 8.50082 5.08647 7.62791C4.91283 6.75499 5.00195 5.85019 5.34254 5.02792C5.68314 4.20566 6.25991 3.50285 6.99994 3.00839C7.73996 2.51392 8.60999 2.25 9.5 2.25C10.6935 2.25 11.8381 2.72411 12.682 3.56802C13.5259 4.41193 14 5.55653 14 6.75Z"
                          fill="#00559F"
                        />
                        <path
                          d="M16.7365 14.9062C15.6657 13.0549 14.0154 11.7274 12.0896 11.0981C13.0422 10.531 13.7823 9.66686 14.1963 8.63841C14.6103 7.60996 14.6752 6.47405 14.3811 5.40513C14.087 4.33621 13.4502 3.39338 12.5684 2.72142C11.6866 2.04947 10.6086 1.68555 9.49998 1.68555C8.39134 1.68555 7.31335 2.04947 6.43156 2.72142C5.54976 3.39338 4.91293 4.33621 4.61884 5.40513C4.32476 6.47405 4.38968 7.60996 4.80366 8.63841C5.21763 9.66686 5.95775 10.531 6.91037 11.0981C4.98451 11.7267 3.33427 13.0542 2.26341 14.9062C2.22414 14.9702 2.1981 15.0415 2.18681 15.1158C2.17552 15.19 2.17922 15.2658 2.19769 15.3386C2.21616 15.4114 2.24902 15.4798 2.29435 15.5397C2.33967 15.5996 2.39653 15.6498 2.46157 15.6874C2.52662 15.7249 2.59853 15.7491 2.67306 15.7584C2.74759 15.7678 2.82324 15.7621 2.89554 15.7417C2.96784 15.7213 3.03532 15.6867 3.09401 15.6398C3.15269 15.5929 3.2014 15.5347 3.23724 15.4687C4.56193 13.1793 6.90334 11.8125 9.49998 11.8125C12.0966 11.8125 14.438 13.1793 15.7627 15.4687C15.7986 15.5347 15.8473 15.5929 15.9059 15.6398C15.9646 15.6867 16.0321 15.7213 16.1044 15.7417C16.1767 15.7621 16.2524 15.7678 16.3269 15.7584C16.4014 15.7491 16.4733 15.7249 16.5384 15.6874C16.6034 15.6498 16.6603 15.5996 16.7056 15.5397C16.7509 15.4798 16.7838 15.4114 16.8023 15.3386C16.8207 15.2658 16.8244 15.19 16.8131 15.1158C16.8019 15.0415 16.7758 14.9702 16.7365 14.9062ZM5.56248 6.74996C5.56248 5.9712 5.79341 5.20992 6.22607 4.5624C6.65872 3.91488 7.27368 3.4102 7.99316 3.11218C8.71264 2.81416 9.50435 2.73619 10.2681 2.88812C11.0319 3.04005 11.7335 3.41506 12.2842 3.96573C12.8349 4.5164 13.2099 5.21799 13.3618 5.98179C13.5137 6.74559 13.4358 7.53729 13.1378 8.25678C12.8397 8.97626 12.3351 9.59121 11.6875 10.0239C11.04 10.4565 10.2787 10.6875 9.49998 10.6875C8.45603 10.6863 7.45516 10.2711 6.71698 9.53296C5.97879 8.79478 5.56359 7.79391 5.56248 6.74996Z"
                          fill="#00559F"
                        />
                      </svg>
                      {userName ? userName : ""}
                      {/* {data.name ? data.name : "not available"} */}
                    </Button>
                  ) : (
                    <Button
                      variant="outline-secondary"
                      onClick={openModal}
                      className="me-5 ms-3"
                    >
                      Log In
                    </Button>
                    
                  )}
                  
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}

        {isOpen && (
          <MultiStepModal
            steps={steps}
            // onHide={closeModal}
            // handleClose={closeModal}
            allCloseModal={allCloseModal}
          />
        )}

        {showSigninModal && !isOpen && (
          <SignInModal
            onHide={closeModal}
            handleClose={closeModal}
            allCloseModal={allCloseModal}
          />
        )}
      </Container>
    </>
  );
}

export default NavBar;
