import React, { useState } from "react";
import "./../styles/AdminProfile.css";
import { useLocation, useNavigate } from "react-router-dom";
import AdminSideBar from "./AdminSideBar";
import axios from "axios";
import { useEffect } from "react";
import { FaCircleUser } from "react-icons/fa6";

import { parsePhoneNumberFromString } from "libphonenumber-js";
import BreadCrumb from "./BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import { Form } from "react-bootstrap";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

// import { API_BASE_URL } from "../../appConfig";

// import { API_BASE_URL } from "../../appConfig";
const AdminProfile = () => {
  const [remainingData, setRemainingData] = useState({});

  const [editProfile, setEditProfile] = useState(true);
  const [loanData, setLoanData] = useState([{}]);

  const location = useLocation();
  // console.log(location.state?.myData);
  const userData = location.state?.myData;
  const [otherUserType, setOtherUserType] = useState(userData?.yourIdentity);
  const [otherEthnicity, setOtherEthinicity] = useState(userData?.ethnicity);
  const [forRendering, setForRendering] = useState(userData);
  const [editedUserData, setEditedUserData] = useState({
    // userData,
    // other fields...
    // userData,
  });

  const [dataAfterEdit, setDataAfterEdit] = useState(userData);

  const adminId = localStorage.getItem("adminId");

  //TO GET REMAINING DATA ON THIS PAGE
  let adminToken = localStorage.getItem("adminToken");
  // console.log("admin token", adminToken);
  useEffect(() => {
    const headers = {
      authorization: `bearer ${adminToken}`,
    };
    axios
      .get(
        // `https://server-dot-path2study.el.r.appspot.com/getUser/${userData._id}`,
        `${process.env.REACT_APP_BASE_URL}/admin/getAllUsers/${adminId}}?userId=${userData._id}`,
        {
          headers: { authorization: `bearer ${adminToken}` },
        }
      )
      .then((responce) => {
        setRemainingData(responce.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // No of loans applied data fetch
  useEffect(() => {
    const userID = userData._id;
    const headers = {
      authorization: `bearer ${adminToken}`,
    };
    axios
      .get(
        // `https://server-dot-path2study.el.r.appspot.com/getUser/${userData._id}`,
        `${process.env.REACT_APP_BASE_URL}/admin/getVisiters/${adminId}?userId=${userID}`,
        {
          headers: { authorization: `bearer ${adminToken}` },
        }
      )
      .then((responce) => {
        setLoanData(responce.data);
        // setRemainingData(responce.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // const parsedPhoneNumber =userData?.contactNumber;

  // if (!parsedPhoneNumber) {

  //   console.log("in if block"  +parsedPhoneNumber)
  //   return (
  //     <div>
  //       <p>Invalid phone number</p>
  //     </div>
  //   );
  // }

  // const countryCode = parsedPhoneNumber.countryCallingCode;
  // const phoneNumberWithoutCode = parsedPhoneNumber.nationalNumber;
  const [finalEdited, setFinalaEdited] = useState();
  const handleInputChange = (fieldName, value) => {
    setForRendering((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setEditedUserData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    setFinalaEdited((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));


    setDataAfterEdit((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    // setDataAfterEdit()
  };

  // const adminId=localStorage.getItem("adminId")

  const [selectedCountry, setSelectedCountry] = useState(userData.country);
  const [selectedState, setSelectedState] = useState(userData.state);
  const handleCountryChange = (country) => {
    setSelectedCountry(country);

    setSelectedState(""); // Reset state when country changes
  };

  const handleStateChange = (state) => {
    setSelectedState(state);
  };

  const navigate = useNavigate();
  const updateProfileData = () => {
    const updatedFields = Object.keys(editedUserData).reduce((acc, key) => {
      if (editedUserData[key] !== userData[key]) {
        acc[key] = editedUserData[key];
      }
      return acc;
    }, {});

    // const [selectedCountry, setSelectedCountry] = useState(userData.country);
    // const [selectedState,
    editedUserData.country = selectedCountry;
    editedUserData.state = selectedState;

    // if (forRendering?.yourIdentity === "other") {
    //   editedUserData.yourIdentity = otherUserType;
    // }

    if (editedUserData?.ethnicity === "other") {
      forRendering.ethnicity = otherEthnicity;
    }

    if (
      forRendering?.yourIdentity !== "Parent" &&
      forRendering?.yourIdentity !== "Student" &&
      forRendering?.yourIdentity !== "Legal Guardian"
    ) {
      editedUserData.yourIdentity = otherUserType;
    }

    // "Parent",
    //                               "Student",
    //                               "Legal Guardian",

    if (
      forRendering?.ethnicity !== "American Indian or Alaska Native" &&
      forRendering?.ethnicity !== "Asian" &&
      forRendering?.ethnicity !== "Black or African American" &&
      forRendering?.ethnicity !== "Native Hawaiian or Pacific Islander" &&
      forRendering?.ethnicity !== "White/Caucasian"
    ) {
      editedUserData.ethnicity = otherEthnicity;
    }
    // "American Indian or Alaska Native",
    //                             "Asian",
    //                             "Black or African American",
    //                             "Native Hawaiian or Pacific Islander",
    //                             "White/Caucasian",

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/admin/updateUser/${adminId}/${userData._id}`,
        editedUserData,
        {
          headers: { authorization: `bearer ${adminToken}` },
        }
      )
      .then((responce) => {
        toast.success("user updated succesfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setEditedUserData(responce.data.updated);

        setTimeout(() => {
          navigate("/admin/user-management");
          window.scrollTo(0, 0);
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const forInputField = {
    fontWeight: "400",
    border: "revert-layer",
    outline: "none",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderWidth: "1px",
  };
  return (
    <div className=" vh-100 AdminProfile">
      <div className="">
        <div className="d-flex justify-content-100">
          <div className="col-2">
            <AdminSideBar />
          </div>
          <div className="col-10  page-background py-3 px-3">
            <div className="d-flex">
              <BreadCrumb />
              <div className="px-4 py-3">
                <div className="d-flex justify-content-between">
                  <FaCircleUser size={25} className="mt-1 mx-2" />
                  <div className="align-middle mt-2">
                    {localStorage.getItem("adminName")}
                  </div>
                </div>
              </div>
            </div>
            <hr className=" my-1" />
            <div className="mt-3 profile-container-background rounded-3 p-3 shadow-md">
              <div className="d-flex justify-content-evenly">
                {/* { userData?.profileImg?<div></div>:<div></div>} */}
                <ToastContainer />
                <div className="col-2 px-3 p-2">
                  <div className="col-12">
                    {userData && userData?.profileImg ? (
                      <div>
                        <div className="col-12 text-center">
                          <img
                            src={userData && userData?.profileImg}
                            className="rounded-circle"
                            alt=""
                            width={140}
                            height={140}
                          />
                        </div>

                        <div className="text-center mt-3">
                          <h4 className="text-center toSetName">
                            {userData?.name}
                          </h4>
                        </div>
                      </div>
                    ) : (
                      // <FaCircleUser size={104} className="rounded-circle" />
                      <div>
                        <FaCircleUser size={135} className="rounded-circle" />
                        <div className="text-center mt-3 col-11">
                          <h4 className="text-center toSetName">
                            {userData?.name}
                          </h4>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-10">
                  <div className="bg-white rounded-3 mt-2">
                    <div className="d-flex justify-content-evenly p-3">
                      <div className="col-4">
                        <p className="heading-color">Name</p>
                        {userData?.name ? (
                          <div>
                            <p>{userData?.name}</p>
                          </div>
                        ) : (
                          <div>
                            <h4 className="">-</h4>
                          </div>
                        )}
                      </div>
                      <div className="col-4">
                        <p className="heading-color">Email ID</p>
                        {userData?.email ? (
                          <div>
                            <p>{userData?.email}</p>
                          </div>
                        ) : (
                          <div>
                            <h4 className="">-</h4>
                          </div>
                        )}
                      </div>
                      <div className="col-4 text-center">
                        <p className="heading-color">Phone Number</p>
                        {/* {
                           console.log({countryCode})
                         
                        }
                         {
                      
                           console.log({phoneNumberWithoutCode})
                        } */}
                        {userData?.contactNumber ? (
                          <div>
                            <p>{userData?.contactNumber}</p>
                            {/* <p>Country Code: {countryCode}</p>
                            <p>Phone Number: {phoneNumberWithoutCode}</p> */}
                          </div>
                        ) : (
                          <div>
                            <h4 className="">-</h4>
                          </div>
                        )}

                        {/* <p>+91 8094593755</p> */}
                      </div>
                    </div>
                  </div>
                  {/* PROFILE INFROMATION SECTION */}
                  <div className="bg-white rounded-3 mt-2">
                    <div className="p-3 d-flex">
                      <div className="col-3">
                        {" "}
                        <h5 className="heading-color">Profile Information</h5>
                      </div>

                      <div className="text-end col-9">
                        {/* <button className="bg-primary border-none" onClick={() => setEditProfile(!editProfile)}>
                          Edit profile
                        </button> */}
                        {editProfile ? (
                          <button
                            type="button"
                            class="btn btn-primary btn-md"
                            onClick={() => setEditProfile(!editProfile)}
                          >
                            {" "}
                            Edit profile
                          </button>
                        ) : null}
                      </div>
                    </div>
                    <div className="d-flex p-3">
                      <div className="col-6">
                        <div>
                          {editProfile ? (
                            <div>
                              <p className="heading-color">
                                Choose Your User Type
                              </p>
                              <p>{userData?.yourIdentity || "-"}</p>
                            </div>
                          ) : (
                            <div className="mx-2">
                              <label className="heading-color">
                                Choose Your User Type
                              </label>
                              <select
                                defaultValue={
                                  forRendering.hasOwnProperty("yourIdentity")
                                    ? [
                                        "Parent",
                                        "Student",
                                        "other",
                                        "select",
                                        "Legal Guardian",
                                      ].includes(forRendering?.yourIdentity)
                                      ? forRendering?.yourIdentity
                                      : "other"
                                    : "select"
                                }
                                // defaultValue={userData.yourIdentity || "select"}
                                onChange={(e) => {
                                  handleInputChange(
                                    "yourIdentity",
                                    e.target.value
                                  );
                                }}
                                className="p-0 form-select ps-2 py-1"
                                // className="p-2  shadow-none form-select rounded-3 col-12"
                                style={forInputField}
                              >
                                <option value="select">Select</option>
                                <option value="Legal Guardian">
                                  Legal Guardian
                                </option>

                                <option value="Parent">Parent</option>
                                <option value="Student">Student</option>
                                <option value="other">Other </option>
                              </select>
                              {console.log(editedUserData?.yourIdentity)}

                              {/* {userData.yourIdentity === "other" ? ( */}
                              {forRendering?.yourIdentity &&
                                ![
                                  "Parent",
                                  "Student",
                                  "Legal Guardian",
                                ].includes(forRendering?.yourIdentity) && (
                                  <input
                                    type="text"
                                    defaultValue={otherUserType}
                                    onChange={(e) =>
                                      setOtherUserType(e.target.value)
                                    }
                                    className="p-0 form-control p-1 ps-2 mt-2" // You might need to adjust the class based on your styling'
                                    style={forInputField}
                                  />
                                )}

                              {/* ) : null} */}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div>
                          {editProfile ? (
                            <div>
                              {" "}
                              <p className="heading-color">Country</p>
                              <p>{userData.country || "-"}</p>
                            </div>
                          ) : (
                            <div className="mx-2">
                              <label className="heading-color">Country</label>

                              <div className="">
                                <CountryDropdown
                                  value={selectedCountry}
                                  onChange={handleCountryChange}
                                  classes="form-select"
                                  style={forInputField}
                                />

                                {selectedCountry && (
                                  <div>
                                    {/* <p>Selected Country: {selectedCountry}</p> */}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex px-3">
                      <div className="col-6">
                        {editProfile ? (
                          <div>
                            <p className="heading-color">State</p>
                            <p>{userData.state || "-"}</p>
                          </div>
                        ) : (
                          <div className="mx-2">
                            <label className="heading-color">State</label>

                            <RegionDropdown
                              country={selectedCountry}
                              value={selectedState}
                              onChange={handleStateChange}
                              style={forInputField}
                              className="form-select"
                            />

                            {selectedState && (
                              <div>
                                {/* <p>Selected State: {selectedState}</p> */}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="col-6">
                        <div>
                          {editProfile ? (
                            <div>
                              {" "}
                              <p className="heading-color">City</p>
                              <p>{userData.city || "-"}</p>
                            </div>
                          ) : (
                            <div className="mx-2">
                              <label className="heading-color">City</label>
                              <input
                                type="text"
                                defaultValue={userData?.city}
                                onChange={(e) =>
                                  handleInputChange("city", e.target.value)
                                }
                                className="p-0 form-control p-1 ps-2" // You might need to adjust the class based on your styling'
                                style={forInputField}
                              />
                            </div>
                          )}
                        </div>

                        <div>{editProfile ? <div></div> : <div></div>}</div>
                      </div>
                    </div>
                    <div className="d-flex px-3 mt-3">
                      <div className="col-6">
                        <div>
                          <div>
                            {editProfile ? (
                              <div>
                                <p className="heading-color">Address</p>
                                <p>{userData.address || "-"}</p>
                              </div>
                            ) : (
                              <div className="heading-color mx-2">
                                <label>Address</label>
                                <input
                                  type="text"
                                  defaultValue={userData.address}
                                  onChange={(e) =>
                                    handleInputChange("address", e.target.value)
                                  }
                                  className="form-control p-0 p-1 ps-2"
                                  style={forInputField}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div>
                          {editProfile ? (
                            <div>
                              <p className="heading-color">Gender</p>
                              <p>{userData?.gender || "-"}</p>
                            </div>
                          ) : (
                            <div className="mx-2">
                              <label className="heading-color">Gender</label>
                              <select
                                defaultValue={userData.gender || "select"}
                                onChange={(e) => {
                                  handleInputChange("gender", e.target.value);
                                }}
                                className="p-0 form-select ps-2 py-1"
                                // className="p-2  shadow-none form-select rounded-3 col-12"
                                style={forInputField}
                              >
                                <option value="select" disabled>
                                  Select
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                              </select>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content p-3">
                      <div className="col-6">
                        <div className="">
                          {editProfile ? (
                            <div>
                              <p className="heading-color mt-2">
                                Citizenship status
                              </p>
                              <p>{userData?.citizenshipStatus || "-"}</p>
                            </div>
                          ) : (
                            <div className="mx-2">
                              <label className="heading-color">
                                Citizenship status
                              </label>
                              <select
                                defaultValue={
                                  userData.citizenshipStatus || "select"
                                }
                                onChange={(e) => {
                                  handleInputChange(
                                    "citizenshipStatus",
                                    e.target.value
                                  );
                                }}
                                className="form-select  p-0 m-0  ps-2 py-1"
                                style={forInputField}
                              >
                                <option value="select" disabled>
                                  Select
                                </option>
                                <option value="US Citizen">US Citizen</option>
                                <option value="Permanent Resident (Green Card Holder)">
                                  Permanent Resident (Green Card Holder)
                                </option>
                                <option value="Nonimmigrant">
                                  Nonimmigrant
                                </option>
                              </select>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-6">
                        {editProfile ? (
                          <div>
                            <p className="heading-color mt-2">
                              Cosigner Preferences
                            </p>
                            <p>{userData?.culturalIdentity || "-"}</p>
                          </div>
                        ) : (
                          <div className="mx-2">
                            <label className="heading-color">
                              Cosigner Preferences
                            </label>
                            <select
                              defaultValue={
                                userData?.culturalIdentity || "select"
                              }
                              className="form-select p-0 py-1 px-2"
                              onChange={(e) =>
                                handleInputChange(
                                  "culturalIdentity",
                                  e.target.value
                                )
                              }
                              style={forInputField}
                            >
                              <option value="select" disabled>
                                Select
                              </option>
                              <option
                                value="US citizen or GC holder with US cosigner"
                                // onClick={userData?.ethnicity : "American Indian or Alaska Native"}
                                // onClick={(e)=>{handleClick(e.target.value)}}
                              >
                                US citizen or GC holder with US cosigner
                              </option>
                              <option
                                value="US citizen or GC holder with no US cosigner"
                              >
                                US citizen or GC holder with no US cosigner
                              </option>
                              <option value="Non-US citizen">
                                Non-US citizen
                              </option>
                            </select>
                          </div>
                        )}
                        {/* </div> */}

                        {/* </div> */}
                      </div>
                    </div>
                    <div className="d-flex justify-content px-3">
                      <div className="col-6">
                        {editProfile ? (
                          <div>
                            <p className="heading-color">Ethnicity</p>
                            <p>{userData?.ethnicity || "-"}</p>
                          </div>
                        ) : (
                          <div className="mx-2">
                            <label className="heading-color">Ethnicity</label>
                            <select
                              // defaultValue={userData?.ethnicity || "select"}
                              defaultValue={
                                forRendering.hasOwnProperty("ethnicity")
                                  ? forRendering?.ethnicity &&
                                    [
                                      "American Indian or Alaska Native",
                                      "Asian",
                                      "Black or African American",
                                      "Native Hawaiian or Pacific Islander",
                                      "White/Caucasian",

                                      "other",
                                    ].includes(forRendering?.ethnicity)
                                    ? forRendering?.ethnicity
                                    : "other"
                                  : "select"
                              }
                              className="form-select p-0 py-1 px-2"
                              onChange={(e) =>
                                handleInputChange("ethnicity", e.target.value)
                              }
                              style={forInputField}
                            >
                              <option value="select">Select</option>
                              <option
                                value="American Indian or Alaska Native"
                                // onClick={userData?.ethnicity : "American Indian or Alaska Native"}
                                // onClick={(e)=>{handleClick(e.target.value)}}
                              >
                                American Indian or Alaska Native
                              </option>
                              <option value="Asian">Asian</option>
                              <option value="Black or African American">
                                Black or African American
                              </option>
                              <option value="Native Hawaiian or Pacific Islander">
                                Native Hawaiian or Pacific Islander
                              </option>
                              <option value="White/Caucasian">
                                White/Caucasian
                              </option>
                              <option value="other">Other</option>
                            </select>
                            {forRendering?.ethnicity &&
                              ![
                                "American Indian or Alaska Native",
                                "Asian",
                                "Black or African American",
                                "Native Hawaiian or Pacific Islander",
                                "White/Caucasian",
                                // "select",
                              ].includes(forRendering?.ethnicity) && (
                                <input
                                  type="text"
                                  defaultValue={otherEthnicity}
                                  onChange={(e) =>
                                    setOtherEthinicity(e.target.value)
                                  }
                                  // onChange={(e) =>
                                  //   handleInputChange("address", e.target.value)
                                  // }
                                  className="form-control p-0 p-1 ps-2 mt-2"
                                  style={forInputField}
                                />
                              )}
                          </div>
                        )}
                        {/* </div> */}

                        {/* </div> */}
                      </div>
                      <div className="col-6">
                        {editProfile ? (
                          <div>
                            <p className="heading-color">
                              Tentative high school graduation date
                            </p>
                            <p>
                              {userData?.tentativeHighschoolGraduationDate ||
                                "-"}
                            </p>
                          </div>
                        ) : (
                          <div className="mx-2">
                            <label className="heading-color">
                              Tentative high school graduation date
                            </label>

                            <Form.Group controlId="formBasictententativeHighschoolGraduationDate">
                              <input
                                placeholder="mm/yyyy"
                                type="month"
                                className="form-control rounded-3"
                                style={forInputField}
                                id="tentativeHighschoolGraduationDate"
                                // name="tentativeHighschoolGraduationDate"
                                value={
                                  finalEdited?.tentativeHighschoolGraduationDate
                                    ? finalEdited?.tentativeHighschoolGraduationDate
                                    : userData?.tentativeHighschoolGraduationDate
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    "tentativeHighschoolGraduationDate",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content p-3">
                      <div className="col-6">
                        <div className="">
                          {editProfile ? (
                            <div className="">
                              <p className="heading-color">
                                Current education level
                              </p>
                              {userData?.currentEducationalLevel ? (
                                <div>
                                  <p>{userData?.currentEducationalLevel}</p>
                                </div>
                              ) : (
                                <div>
                                  <p className="">-</p>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="">
                              <div className="mx-2">
                                <label className="heading-color">
                                  Current education level
                                </label>
                                <select
                                  defaultValue={
                                    userData.currentEducationalLevel || "select"
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      "currentEducationalLevel",
                                      e.target.value
                                    )
                                  }
                                  className="form-select  px-2 me-2"
                                  style={forInputField}
                                >
                                  <option value="select" disabled>
                                    Select
                                  </option>
                                  <option value="High School">
                                    High School
                                  </option>
                                  <option value="Under Graduate">
                                    Under Graduate
                                  </option>
                                  <option value="Post Graduate">
                                    Post Graduate
                                  </option>
                                  <option value="MBA">MBA</option>
                                  <option value="PhD">PhD</option>
                                  {/* Add more options as needed */}
                                </select>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        {editProfile ? (
                          <div>
                            <p className="heading-color">
                              Name of the high school/University attending
                            </p>
                            <p>{userData?.highschoolUniversity || "-"}</p>
                          </div>
                        ) : (
                          <div className="mx-2">
                            <label className="heading-color">
                              Name of the high school/University attending
                            </label>
                            <input
                              type="text"
                              defaultValue={userData?.highschoolUniversity}
                              onChange={(e) =>
                                handleInputChange(
                                  "highschoolUniversity",
                                  e.target.value
                                )
                              }
                              className="p-0 form-control p-1 ps-2" // You might need to adjust the class based on your styling'
                              style={forInputField}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content px-3">
                      <div className="col-6">
                        <div>
                          {editProfile ? (
                            <div>
                              <p className="heading-color">
                                Major/Field of study
                              </p>
                              <p>{userData?.MajorFieldOfstudy || "-"}</p>
                            </div>
                          ) : (
                            <div className="mx-2">
                              <label className="heading-color">
                                Major/Field of study
                              </label>
                              <input
                                type="text"
                                defaultValue={userData?.MajorFieldOfstudy}
                                onChange={(e) =>
                                  handleInputChange(
                                    "MajorFieldOfstudy",
                                    e.target.value
                                  )
                                }
                                className="form-control p-0 ps-2 p-1" // You might need to adjust the class based on your styling
                                style={forInputField}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        {editProfile ? (
                          <div>
                            <p className="heading-color">Current GPA</p>
                            <p>{userData?.currentGpa || "-"}</p>
                          </div>
                        ) : (
                          <div className="mx-2">
                            <label className="heading-color">Current GPA</label>
                            <input
                              type="number"
                              defaultValue={userData.currentGpa}
                              onChange={(e) =>
                                handleInputChange("currentGpa", e.target.value)
                              }
                              className="form-control p-0 p-1 ps-2" // You might need to adjust the class based on your styling
                              style={forInputField}
                            />
                          </div>
                        )}
                      </div>

                      {/* <div className="col-6">
                        <div>
                          <div>
                            <div>
                              {editProfile ? (
                                <div>
                                  <p className="heading-color">
                                    Annual family income
                                  </p>
                                  <p>
                                    {userData?.annualFamilyIncome
                                      ? userData?.annualFamilyIncome
                                      : "-"}
                                  </p>
                                </div>
                              ) : (
                                <div className="mx-2 mt-0">
                                  <label className="heading-color">
                                    Annual family income
                                  </label>
                                  <select
                                    defaultValue={
                                      userData?.annualFamilyIncome
                                        ? userData.annualFamilyIncome
                                        : "select"
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        "annualFamilyIncome",
                                        e.target.value
                                      )
                                    }
                                    className="form-select p-0 p-1 ps-2"
                                    style={forInputField}
                                  >
                                    <option value="select" disabled>
                                      Select
                                    </option>

                                    <option value="Below $20000">
                                      Below $20000
                                    </option>
                                    <option value="$20000-$40000">
                                      $20000-$40000
                                    </option>
                                    <option value="$40000-$60000">
                                      $40000-$60000
                                    </option>
                                    <option value="$60000-$80000">
                                      $60000-$80000
                                    </option>
                                    <option value="Above $80000">
                                      Above $80000
                                    </option>
                                  </select>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>

                    <div className="d-flex justify-content px-3">
                      {/* <div className="col-6 mt-3">
                        {editProfile ? (
                          <div>
                            <p className="heading-color">
                              Any existing financial aid or scholarships
                            </p>
                            <p>{userData?.existingFinancialAid || "-"}</p>
                          </div>
                        ) : (
                          <div className="mx-2">
                            <label className="heading-color">
                              Any existing financial aid or scholarships
                            </label>
                            <select
                              defaultValue={
                                userData.existingFinancialAid
                                  ? userData?.existingFinancialAid
                                  : "select"
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "existingFinancialAid",
                                  e.target.value
                                )
                              }
                              className="form-select p-0 p-1 ps-2"
                              style={forInputField}
                            >
                              <option value="select" disabled>
                                Select
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        )}
                      </div> */}
                      <div className="col-6">
                        {editProfile ? (
                          <div>
                            <p className="heading-color">Mode of study</p>
                            <p>{userData?.modeOfStudy || "-"}</p>
                          </div>
                        ) : (
                          <div className="mx-2">
                            <label className="heading-color mt-3">
                              Mode of study
                            </label>
                            <select
                              defaultValue={userData.modeOfStudy || "select"}
                              onChange={(e) =>
                                handleInputChange("modeOfStudy", e.target.value)
                              }
                              className="form-select p-0 p-1 ps-2" // You might need to adjust the class based on your styling
                              style={forInputField}
                            >
                              <option value="select" disabled>
                                Select
                              </option>
                              <option value="Full Time">Full Time</option>
                              <option value="Part Time">Part Time</option>
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="col-6">
                        {editProfile ? (
                          <div className="">
                            <p className="heading-color">
                              Involvement in clubs, sports, or
                              <br /> community service
                            </p>
                            <p>{userData?.involvement || "-"}</p>
                          </div>
                        ) : (
                          <div className="mx-2">
                            <label className="heading-color mt-3">
                              Involvement in clubs, sports, or community service
                            </label>
                            <input
                              type="text"
                              defaultValue={userData.involvement}
                              onChange={(e) =>
                                handleInputChange("involvement", e.target.value)
                              }
                              className="form-control p-0 p-1 ps-2"
                              style={forInputField}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content px-3">
                      <div className="col-6">
                        {editProfile ? (
                          <div>
                            <p className="heading-color ">Volunteer</p>
                            <p>{userData?.leadership || "-"}</p>
                          </div>
                        ) : (
                          <div className="mx-2 mt-3">
                            <label className="heading-color ">Volunteer</label>
                            <select
                              defaultValue={userData.leadership || "select"}
                              onChange={(e) =>
                                handleInputChange("leadership", e.target.value)
                              }
                              className="form-select p-0 p-1 ps-2"
                              style={forInputField}
                            >
                              <option value="select" disabled>
                                Select
                              </option>

                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="col-6">
                        {editProfile ? (
                          <div>
                            <p className="heading-color">
                              Recognitions or Awards for extracurricular
                            </p>
                            <p>{userData?.recognitions || "-"}</p>
                          </div>
                        ) : (
                          <div className="mx-2 mt-3">
                            <label className="heading-color">
                              Recognitions or Awards for extracurricular
                            </label>
                            <input
                              type="text"
                              defaultValue={userData.recognitions}
                              onChange={(e) =>
                                handleInputChange(
                                  "recognitions",
                                  e.target.value
                                )
                              }
                              className="form-control p-0 p-1 ps-2"
                              style={forInputField}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content px-3">
                      <div className="col-6">
                        <div>
                          {editProfile ? (
                            <div>
                              <p className="heading-color">
                                Participation in research or Academic programs
                              </p>
                              <p>{userData?.academicPrograms || "-"}</p>
                            </div>
                          ) : (
                            <div className="mx-2 mt-3">
                              <label className="heading-color">
                                Participation in research or Academic programs
                              </label>
                              <select
                                defaultValue={
                                  userData?.academicPrograms
                                    ? userData.academicPrograms
                                    : "select"
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    "academicPrograms",
                                    e.target.value
                                  )
                                }
                                className="form-select p-0 p-1 ps-2"
                                style={forInputField}
                              >
                                <option value="select" disabled>
                                  Select
                                </option>

                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-6">
                        {editProfile ? (
                          <div>
                            <p className="heading-color">
                              Notable academic achievements
                            </p>
                            <p>{userData?.academicAchievement || "-"}</p>
                          </div>
                        ) : (
                          <div className="mx-2 mt-3">
                            <label className="heading-color">
                              Notable academic achievements
                            </label>
                            <input
                              type="text"
                              defaultValue={userData.academicAchievement}
                              onChange={(e) =>
                                handleInputChange(
                                  "academicAchievement",
                                  e.target.value
                                )
                              }
                              className="form-control p-0 p-1 ps-2"
                              style={forInputField}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content px-3">
                      <div className="col-6">
                        {editProfile ? (
                          <div>
                            <p className="heading-color">
                              Honors, Awards, or Recognition
                            </p>
                            <p>{userData?.academicRecognitions || "-"}</p>
                          </div>
                        ) : (
                          <div className="mx-2 mt-3">
                            <label className="heading-color">
                              Honors, Awards, or Recognition
                            </label>
                            <input
                              type="text"
                              defaultValue={userData?.academicRecognitions}
                              onChange={(e) =>
                                handleInputChange(
                                  "academicRecognitions",
                                  e.target.value
                                )
                              }
                              className="form-control p-0 p-1 ps-2"
                              style={forInputField}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {!editProfile ? (
                      <div className="text-end my-2 mx-3">
                        {/* <button onClick={() => updateProfileData()}>
                          Save Changes
                        </button> */}

                        <button
                          type="button"
                          class="btn btn-secondary btn-md mb-3 mx-2"
                          onClick={() => setEditProfile(!editProfile)}
                        >
                          {" "}
                          Cancel
                        </button>

                        <button
                          type="button"
                          class="btn btn-primary btn-md mb-3 mx-2"
                          onClick={() => updateProfileData()}
                        >
                          {" "}
                          Save Changes
                        </button>
                      </div>
                    ) : null}
                  </div>

                  {/* NUMBER OF SCHOLORSHIP APPLIED */}
                  <div className="bg-white rounded-3 mt-2 p-3">
                    <div className="d-flex jutify-content">
                      <div className="col-10">
                        <h5 className="heading-color mt-3">
                          Number of Path2study Scholarships Applied
                        </h5>
                      </div>
                      <div className="col-1 text-end mt-3">
                        <h5>{userData?.appliedScholarships?.length}</h5>
                      </div>
                    </div>
                  </div>
                  {/* Number of Loans Applied */}
                  {loanData.length == 0 ? (
                    <div className="bg-white rounded-3 mt-2 p-3">
                      <div className="d-flex jutify-content">
                        <div className="col-10">
                          <h5 className="heading-color mt-2">
                            Number of Loans Applied
                          </h5>
                        </div>
                        <div className="col-1 mt-2 text-end">
                          <h5>{loanData.length}</h5>
                        </div>
                      </div>
                      {/* <div className="mt-3">
                          {loanData.map((loan) => (
                            <div key={loan.id}>
                              <div className="d-flex justify-content-between">
                                <div className="col-3">
                                  <p className="heading-color">{loan.bankName}</p>
                                </div>
                                <div className="col-9">
                                 
                                  <p>
                                    {new Date(loan.date).toLocaleDateString(
                                      "en-GB",
                                      {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      }
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
    
                        
                        </div> */}
                    </div>
                  ) : (
                    <div className="bg-white rounded-3 mt-2 p-3">
                      <div className="d-flex jutify-content">
                        <div className="col-10">
                          <h5 className="heading-color">
                            Number of Loans Applied
                          </h5>
                        </div>
                        <div className="col-1 text-end">
                          <h5>{loanData.length}</h5>
                        </div>
                      </div>
                      <div className="mt-3">
                        {loanData.map((loan) => (
                          <div key={loan.id}>
                            <div className="d-flex justify-content-between">
                              <div className="col-3">
                                <p className="heading-color">{loan.bankName}</p>
                              </div>
                              <div className="col-9">
                                {/* <p>{loan.date}</p> */}

                                <p>
                                  {new Date(loan.date).toLocaleDateString(
                                    "en-GB",
                                    {
                                      month: "2-digit",
                                      day: "2-digit",
                                      year: "numeric",
                                    }
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}

                        {/* <div className="d-flex justify-content">
                        <div className="col-2">
                          <p className="heading-color">Bank Name</p>
                        </div>
                        <div className="col-6">
                          <p>dd/mm/yyyy</p>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
