import React, { useContext } from "react";
// import "../style/about.css";
import "../style/contact.css";

// import pq10 from "../assests/img/pq10.png";
import TextField from "@mui/material/TextField";

import { useState, useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import {  toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

import PhoneInput from "react-phone-number-input";
import { UserContext } from "../contexts/UserContext";
import BlurWindow from "./BlurWindow";
import CreateGoogleMeet_v1 from "./CreateGoogleMeet_v1";
import CreateGoogleMeet from "./CreateGoogleMeet";



const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNo: "",
    message: "",
  });
  const [submitValidation, setSubmitValidation] = useState({
    forName: true,
    // forContact: true,
    forEmail: true,
    forMessage: true,
  });
  const [phoneNumber, setPhoneNumber] = useState(""); // for contact number with library
  const [valid, setValid] = useState(true);
  // const [errorElementName, setErrorElementName] = useState("");
  // const [errorElementContact, setErrorElementContact] = useState("");
  const [errorElementEmail, setErrorElementEmail] = useState("");

  // console.log(errorElementName, errorElementContact, errorElementEmail);

  const [showPopup, setShowPopup] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();

  const [timer, setTimer] = useState(3);

  const [count, setCount] = useState(9);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {
        clearInterval(intervalId); // Stop the countdown when it reaches 0
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [count]);

  const [redirect, setDirect] = useState(true);

  useEffect(() => {
    if (formSubmitted && redirect) {
      const redirectTimer = setTimeout(() => {
        // clearInterval(redirectTimer);
        setCount(count - 1);
        handleClose();

        navigate("/");
      }, 9000);

      return () => {
        clearTimeout(redirectTimer);
        window.scrollTo(0, 0);
      };
    }
  }, [formSubmitted, navigate]);

  const interval = setInterval(() => {
    setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
  }, 1500);

  const handleClose = () => {
    setShowPopup(false);
    setDirect(false);
  };


  const handleChangePhone = (value) => {
    setPhoneNumber(value);
    setValid(validatePhoneNumber(value));
    console.log(" final contact number send -->", value, formData.contactNo);
    setFormData({ ...formData, contactNo: value });
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  const handleChange = (event) => {

    const { name, value } = event.target;
    let nameValue = name;
    let valueValue = value;
    setFormData({ ...formData, [nameValue]: valueValue });
    // console.log(errorElementContact);

    /// ------------------FOR VALIDATION----------------------

    if (name === "fullName") {
      const errorElementNameOne = document.getElementById("nameError");
      const errorElementNameOneMobile =
        document.getElementById("nameErrorMobile");

      errorElementNameOne.innerHTML =
        valueValue.trim() === "" ? "Name is required" : "";
      errorElementNameOne.innerHTML =
        valueValue?.length < 3 ? "Name is atleast 3 character" : "";

      errorElementNameOneMobile.innerHTML =
        valueValue.trim() === "" ? "Name is required" : "";
      setSubmitValidation({
        ...submitValidation,
        forName: valueValue.trim() === "" ? true : false,
      });
    } else if (nameValue === "email") {
      const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const errorElementEmailTwo = document.getElementById("emailError");
      const errorElementEmailTwoMobile =
        document.getElementById("emailErrorMobile");
      setErrorElementEmail(!emailPattern.test(valueValue) ? false : true);
      errorElementEmailTwo.innerHTML = !emailPattern.test(valueValue)
        ? "Invalid email address"
        : "";

      errorElementEmailTwoMobile.innerHTML = !emailPattern.test(valueValue)
        ? "Invalid email address"
        : "";
      setSubmitValidation({
        ...submitValidation,
        forEmail: !emailPattern.test(valueValue) ? true : false,
      });
    } else if (nameValue === "message") {

      setSubmitValidation({
        ...submitValidation,
        forMessage: valueValue.trim() === "" ? true : false,
      });
    }
  };

  const [phonenumberEmptyError, setPhonenumberEmptyError] = useState("");
  const submitFormContact = async (e) => {
    e.preventDefault();

    if (!formData.contactNo || formData.contactNo === "") {
      setPhonenumberEmptyError("pleae enter phone number ");
      // console.log(phonenumberEmptyError);
      setValid(false);
      
    } else {
      const allFalse = Object.values(submitValidation).every(
        (value) => value === false
      );
      if (allFalse) {
        const config = {
          SecureToken: process.env.REACT_APP_SECURITY_TOKEN,
          To: "support@path2study.com",
          // From: formData.email,
          From: "support@path2study.com",
          Subject: `New enquiry received form ${formData.fullName}`,
          Body: `
            Full Name : ${formData.fullName} <br />
            Email Id : ${formData.email} <br />
            Phone Number : ${formData.contactNo} <br />
            Message : ${formData.message}
          `,
        };
        if (!process.env.BROWSER) {
          window.Email.send(config)
            .then((res) => {
              setFormSubmitted(true);
              setFormData({
                fullName: "",
                email: "",
                contactNo: "",
                message: "",
              });

              // console.log("Email sent successfully", res);
              setCount(10);
              setShowPopup(true);
              setPhoneNumber("");
            })
            .catch((err) => {
              toast.error("Opps! Something went wrong.", {
                position: "top-right", // Toast position
                autoClose: 2500, // Duration in milliseconds
                hideProgressBar: true, // Whether to display a progress bar
                closeOnClick: true, // Close toast on click
                pauseOnHover: true, // Pause the autoClose timer on hover
                draggable: true, // Enable dragging to dismiss the toast
                progress: undefined, // Custom progress bar component
              });
            });
        }
      }
      
    }

  };

  // User context
  const { isUserLoggedIn } = useContext(UserContext);

  return (
    <div className="mt-2 pt-5 pb-5 contact">
      <div className="pt-4">
        <div className="container abouthomesection mt-2 pt-3">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-md-5 col-12">
              <div className="home-banner-text-heading">
                <h1 className="text-primary">SUPPORTING YOUR</h1>
                <h1 className="">JOURNEY LIKE NO OTHER</h1>

                {/* //TO DISPALY none on mobile NONE */}
                <div className="d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                  <p className="mt-lg-2 mt-3">
                    We appreciate your interest in Path2Study, and we look
                    forward to <br /> assisting you on your academic journey.
                  </p>
                  <div className="d-flex flex-wrap justify-content-between">
                    <div className="col-md-1 col-sm-1 col-2">
                      <div className="mt-3">
                        <MdMail fill="#3771C8" className="" size={25} />
                      </div>
                      <div className="mt-3">
                        <FaPhoneAlt fill="#3771C8" className="" size={25} />
                      </div>
                      <div className="mt-4">
                        <FaLocationDot fill="#3771C8" className="" size={25} />
                      </div>
                    </div>
                    <div className="col-md-11 col-sm-11 col-10 ">
                      <div className="mt-3 cursor-pointer">
                        <a
                          href="mailto:support@path2study.com?subject=Subject%20Here&body=Body%20Text%20Here"
                          className="text-decoration-none hoverEffect"
                        >
                          support@path2study.com
                        </a>
                      </div>
                      <div className="pt-4 cursor-pointer">
                        <a
                          href="tel:+18478631388"
                          className="text-decoration-none hoverEffect "
                        >
                          +1 (847) 863-1388
                        </a>
                      </div>
                      <div className="pt-4">
                        <p>
                          <a
                            href="https://maps.app.goo.gl/X2wyf8X8TeaFq9Ld8"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-decoration-none hoverEffect"
                          >
                            875 N Michigan Ave, 31st Floor, Chicago, IL 60611
                          </a>
                          {/* 875 N Michigan Ave, 31st Floor, Chicago, IL 60611 */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isUserLoggedIn ? (
              <div className="col-md-6 col-12 mt-1 d-flex">
                <div className=" col-md-6 col-lg-5  mt-5 pt-5 p-3 ">
                  <img
                    src={require("../assests/img/laptop2.png")}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className=" col-md-6 col-lg-5 p-3">
                  <img
                    src={require("../assests/img/laptop1.png")}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="col-md-6 col-12">
                  {isUserLoggedIn ? <CreateGoogleMeet_v1 /> : <BlurWindow />}
                </div>
              </>
            )}
            
            {/* comment image */}
            {/* <div className="col-md-6 col-12 mt-1 d-flex">
              <div className=" col-md-6 col-lg-5  mt-5 pt-5 p-3 ">
                <img
                  src={require("../assests/img/laptop2.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className=" col-md-6 col-lg-5 p-3">
                <img
                  src={require("../assests/img/laptop1.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div> */}

            {/* <div className="col-md-6 col-12">
              {isUserLoggedIn ? <CreateGoogleMeet /> : <BlurWindow />}
            </div> */}

            {/* //To show on mobile */}
            <div className="d-block d-md-none d-xl-none d-xxl-none d-lg-none mt-4">
              <p style={{ textAlign: "justify" }} className="mt-lg-2">
                We appreciate your interest in Path2Study, and we look forward
                to assisting you on your academic journey.
              </p>
              <div className="d-flex flex-wrap justify-content-between">
                <div className="col-md-1 col-sm-1 col-1">
                  <div className="mt-3">
                    <MdMail fill="#3771C8" className="" size={25} />
                  </div>
                  <div className="mt-3">
                    <FaPhoneAlt fill="#3771C8" className="" size={25} />
                  </div>
                  <div className="mt-3">
                    <FaLocationDot fill="#3771C8" className="mt-2" size={25} />
                  </div>
                </div>
                <div className="col-md-11 col-sm-11 col-11 ">
                  <div className="mt-3 cursor-pointer">
                    <a
                      href="mailto:support@path2study.com?subject=Subject%20Here&body=Body%20Text%20Here"
                      className="text-decoration-none hoverEffect"
                    >
                      support@path2study.com
                    </a>
                  </div>
                  <div className="pt-3 cursor-pointer">
                    <a
                      href="tel:+18478631388"
                      className="text-decoration-none hoverEffect "
                    >
                      +1 (847) 863-1388
                    </a>
                  </div>
                  <div className="pt-4">
                    <p>875 N Michigan Ave, 31st Floor, Chicago, IL 60611</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* __________________ */}

      <div className="container mt-4 mt-lg-5">
        <div className=" justify-content-center   mt-lg-2">
          <div className="col-md-10">
            <h2 className="text-primary">
              STILL GOT A QUESTION? WE ARE HAPPY TO HELP YOU
            </h2>
            <p
              // style={{
              //   fontFamily: "Josefin Sans",
              //   textAlign: "justify",
              //   color: #4E4E4E,
              // }}
              className="font-weight-light mt-3"
            >
              If you have any specific inquiries or feedback, please fill out
              our online form, and our team will get back to you promptly.
            </p>
          </div>
        </div>
      </div>
      

      {/* -----------------------------------------------form section---------------------------------------------------------------------- */}
      <div className="container  ml-5 d-none d-sm-block">
        <div className="image3-container Cover-Image-Lapping-Section">
          <img
            src={require("../assests/img/blueimg.png")}
            alt="Image 1"
            className="image3"
          />
          <img
            src={require("../assests/img/ab20.png")}
            alt="Image 2"
            className="image3 "
          />
          <div className="border border-1 rounded-4 p-md-5 col-lg-11 bg-white image3 overflow-auto ">
            <h3 className="text-primary">WRITE US YOUR QUERY </h3>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitFormContact(e);
              }}
              id="contactForm"
            >
              <TextField
                id="standard-multiline-flexible"
                label={<CustomLabel text="Name" />}
                // multiline
                maxRows={4}
                variant="standard"
                style={{ width: "28vw" }}
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="mb-3 "
                required
              />
              <div
                style={{ color: "red", fontSize: "80%" }}
                className="error text-danger "
                id="nameError"
              ></div>
              <TextField
                id="standard-multiline-flexible"
                type="email"
                label={<CustomLabel text="Email" />}
                required
                maxRows={4}
                variant="standard"
                style={{ width: "28vw" }}
                // className="mb-4"
                name="email"
                value={formData.email}
                onChange={handleChange}
                // error={true}
              />
              <div
                style={{ color: "red", fontSize: "80%" }}
                className="error text-danger mb-3 mt-2"
                id="emailError"
              ></div>

              <label style={{ width: "89%", color: "" }}>
                <span className="text-secondary ">Contact No:</span>{" "}
                <span style={{ color: "grey" }}>*</span>
                <PhoneInput
                  country="US" // in
                  defaultCountry="US"
                  value={phoneNumber || formData.contactNo}
                  onChange={handleChangePhone}
                  inputProps={{
                    required: true,
                  }}
                  
                />
              </label>
              {!valid && (
                <p
                  className="text-danger mb-0"
                  style={{ color: "red", fontSize: "80%" }}
                >
                  Please enter a valid phone number.
                </p>
              )}
              <TextField
                id="outlined-multiline-flexible"
                label={<CustomLabel text="Your message" />}
                multiline
                maxRows={4}
                variant="standard"
                style={{ width: "28vw" }}
                value={formData.message}
                name="message"
                onChange={handleChange}
                required
                className=" mt-3"
              />
              <br />
              <div className="col-3 mt-3 pt-2">
                <button
                  className="bg-primary btn btn-primary px-5 my-lg-1"
                  // disabled={
                  //   !errorElementEmail ||
                  //   errorElementContact ||
                  //   !formData.fullName ||
                  //   !formData.email ||
                  //   !formData.contactNo ||
                  //   !formData.message
                  // }
                  type="submit"
                >
                  Send
                </button>
              </div>
            </form>

            {/* --------modal ----------*/}
            <Modal show={showPopup} onHide={handleClose} centered>
              {/* <Modal.Header closeButton>
          
            
              </Modal.Header> */}
              <Modal.Body className="p-4">
                
                <div className="text-center">
                  <img
                    src={require("../assests/img/Featuredicon.png")}
                    className="img-fluid"
                    width={"15%"}
                    height={"25%"}
                  />
                </div>
                <h3 className="text-center">Message sent successfully</h3>
                <p className="text-center fs-6" style={{ color: "#667085" }}>
                  Thank you for contacting us. We have received your message and
                  we assure that our team will contact you soon.{" "}
                </p>
                <h6 className="text-center mb-0 pb-0">
                  You will be redirected to the home page in {count} seconds...
                </h6>
              </Modal.Body>
             
            </Modal>
          </div>
        </div>
      </div>

      {/*  mobile form */}
      <div className="container hideinmobile mt-5  ourstory-container-contact">
        <div className="text-center ourstory-contact">
         
        </div>
        <div className=" bg-white border border-2 p-3 rounded rounded-3 ourstory-contact">
          <h5 className="mt-2 text-primary">WRITE US YOUR QUERY</h5>
          <form
            onSubmit={(e) => {
              submitFormContact(e);
              e.preventDefault();
            }}
            id="contactForm"
          >
            <TextField
              id="standard-multiline-flexible"
              label={<CustomLabel text="Name" />}
              // multiline
              maxRows={4}
              variant="standard"
              style={{ width: "95%" }}
              value={formData.fullName}
              name="fullName"
              onChange={handleChange}
              className="mb-2"
              required
            />{" "}
            <div
              style={{ color: "red", fontSize: "80%" }}
              className="error text-danger "
              id="nameErrorMobile"
            ></div>
            <br />
            <TextField
              id="standard-multiline-flexible"
              type="email"
              label={<CustomLabel text="Email" />}
              // multiline
              required
              maxRows={4}
              variant="standard"
              style={{ width: "95%" }}
              className="mb-2"
              value={formData.email}
              name="email"
              onChange={handleChange}
            />
            <div
              style={{ color: "red", fontSize: "80%" }}
              className="error text-danger  my-2 "
              id="emailErrorMobile"
            ></div>
            <br />
            
            <label style={{ width: "95%", color: "gray" }}>
              Contact No: <span style={{ color: "grey" }}>*</span>
              <PhoneInput
                country={"us"} // in
                value={phoneNumber || formData.contactNo}
                onChange={handleChangePhone}
                inputProps={{
                  required: true,
                }}
                // style={phoneCss}
              />
            </label>
            {!valid ? (
              <p
                className="text-danger"
                style={{ color: "red", fontSize: "80%" }}
              >
                Please enter a valid phone number.
              </p>
            ) : (
              ""
            )}
            <TextField
              id="standard-multiline-flexible"
              label={<CustomLabel text="Your message" />}
              multiline
              required
              maxRows={4}
              variant="standard"
              className="lg-mt-4 mt-3"
              style={{ width: "95%" }}
              value={formData.message}
              name="message"
              onChange={handleChange}
            />{" "}
            <span
              style={{ color: "red", fontSize: "80%" }}
              className="error text-danger mb-3"
              id="exampleFormControlTextarea1"
            ></span>
            <br />
            <div className="col-5 mt-3">
              <button
                
                type="submit"
                className="btn btn-primary w-100 my-3"
                style={{
                  backgroundColor: "#0C7449",
                }}
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* -------------------only for mobile end------------------ */}
    </div>
  );
};

const CustomLabel = ({ text }) => (
  <label style={{ fontFamily: "Josefin Sans" }}>{text}</label>
);

export default Contact;
