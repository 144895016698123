import React, { createContext, useState } from "react";
import createAccImg from "../assests/img/createAcc.png";
import lock from "../assests/img/lock.png";
import otp from "../assests/img/otp.png";
import VerificationModal from "../components/Signup/VerificationModal";
import CreatePasswordModal from "../components/Signup/CreatePasswordModal";
import SignUpModal from "../components/Signup/SignUpModal";
import ForgetPass1 from "../components/Signup/ForgetPass1";
import ForgetPass2 from "../components/Signup/ForgetPass2";
import ForgetPass3 from "../components/Signup/ForgetPass3";

const ModalContext = createContext();

const ModalProvider = ({ children }) => {

  const [showSigninModal, setShowSigninModal] = useState(false);// modal for login
  // const [showForget, setShowForget] = useState(false);// modal for forgetpass

  const [isOpen, setIsOpen] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [data, setData] = useState({
    name: "",
    email: "",
    emailOTP: "",
    password: "",
    confirmPassword: "",
    isAppliedForPromotion:false
  });

  const handleNext = (newData) => {
    if (currentStep < steps.length - 1) {
      setData((prev) => ({ ...prev, ...newData }));
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = (newData) => {
    if (currentStep > 0) {
      setData((prev) => ({ ...prev, ...newData }));
      setCurrentStep(currentStep - 1);
    }
  };

 

  // const openForget = () => {
  //   // Your login logic here
  //   console.log('called oen forget modal')
  //   setShowSigninModal(false);
  //   setIsOpen(false);
  //   setShowForget(true)
  //   // clooseLogin();
  // };
  
  const openModal = () => {
    // Your login logic here
    // setIsOpen(true);// original
    // setShowSigninModal(false);// original
    setIsOpen(false);
    setSignUpOpen(false)
    setShowSigninModal(true);
    // clooseLogin();
    // setShowForget(false)
    
  };
  const closeModal = () => {
    setIsOpen(false);
    setSignUpOpen(false)
    setShowSigninModal(true);
    // openLogin()
    // setShowForget(true)
  };
  
  const allCloseModal=()=>{
    setIsOpen(false);
    setSignUpOpen(false)
    setShowSigninModal(false);
    setData({
      name: "",
      email: "",
      emailOTP: "",
      password: "",
      confirmPassword: "",
      isAppliedForPromotion:false
    })
    setCurrentStep(0);
    // window.location.reload() // for resetting the modal start with 0
  }
  const logout = () => {
    // Your logout logic here
    setIsOpen(false);
  };

  // const openLogin = () => {
  //   // Your login logic here
  //   setShowSigninModal(true);
  // };
  // const clooseLogin = () => {
  //   setShowSigninModal(false);
  // };

  const steps = [
    {
      title: "Create Your Account",
      img: createAccImg,
      imgalt: "logocreate",
      component: <SignUpModal />,
    },
    {
      title: "Create Your Account",
      img: otp,
      imgalt: "logoOtp",

      component: <VerificationModal  />,
    },
    {
      title: "Create Your Account",
      img: lock,
      imgalt: "logolock",
      component: <CreatePasswordModal />,
    },
  ];

  const forgetpasssteps = [
    {
      title: "Forgot Password?",
      subtitle: "Don’t worry! We have got you covered. Enter your registered Email ID below to get the OTP. Once you enter the OTP you can reset your password.",
      // img: createAccImg,
      // imgalt: "logocreate",
      component: <ForgetPass1 />,
    },
    {
      title: "Forgot Password?",
      subtitle: "Don’t worry! We have got you covered. Enter your registered Email ID below to get the OTP. Once you enter the OTP you can reset your password.",
      
      // img: otp,
      // imgalt: "logoOtp",

      component: <ForgetPass2  />,
    },
    {
      title: "Reset Password",
      subtitle: "Create a new password to login to your account",

      // img: otp,
      // imgalt: "logoOtp",

      component: <ForgetPass3  />,
    },
    
  ];
  return (
    <ModalContext.Provider
      value={{
        openModal,
        isOpen,
        setIsOpen,
        logout,
        steps,
        closeModal,
        handleNext,
        handlePrevious,
        currentStep,
        setCurrentStep,
        showSigninModal,
        setShowSigninModal,
        data,
        setData,
        signUpOpen,
        allCloseModal,
        forgetpasssteps,

      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };


