import React, { useState } from "react";
import AboutBank from "./AboutBank";
import ListAppliacnt from "./ListAppliacnt";
import AdminSideBar from "./AdminSideBar";
// import Header from "./Header";
import "./../styles/AboutBankList.css";
import BreadCrumb from "./BreadCrumb";
import { FaCircleUser, FaL } from "react-icons/fa6";
function AboutBankList({}) {
  const [activeComponent, setActiveComponent] = useState();

  const [aboutActive, setAboutActive] = useState(true);

  const handleSwitch = (component) => {
    setActiveComponent(component);
  };
  let componentToRender;
  switch (activeComponent) {
    case "1":
      componentToRender = <AboutBank />;
      break;
    case "2":
      componentToRender = <ListAppliacnt />;
      break;
    default:
      componentToRender = null;
  }
  return (
    <div style={{ backgroundColor: "#F3F5F5" }} className="aboutBankList">
      {/* <div className="d-flex">
        <BreadCrumb className="" />
        <div className="mt-3 text-muted pe-2 cursor-pointer">
          {localStorage.getItem("adminName")}
        </div>
      </div> */}

      <div className="d-flex justify-content-between">
        <div className="col-2">
          <AdminSideBar />
        </div>

        <div className="col-10">
          {/* <div className="px-4">
           <Header />
           </div> */}
          <div className="px-3">
            <div className="d-flex mt-3">
              <BreadCrumb />
              <div className="px-4 py-3">
                <div className="d-flex justify-content-between">
                  <FaCircleUser size={25} className="mt-1 mx-2" />
                  <div className="align-middle mt-2">
                    {localStorage.getItem("adminName")}
                  </div>
                </div>
              </div>
            </div>
            <hr className=" my-1" />
          </div>
          <div className="px-4">
            <div
              style={{ backgroundColor: "#F9FAFC" }}
              className=" rounded-4 p-4"
            >
              <div className=" rounded-4 shadow-grey mt-3 bg-white  pt-3">
                <div style={{ backgroundColor: "#F3F5F6" }} className=" px-4  ">
                  <button
                    className={
                      aboutActive
                        ? "border-0 p-3 border-top border-start border-end"
                        : "border-0 p-3"
                    }
                    style={{
                      // border: `2px solid ${isClicked1 ? "#4572bf" : "white"}`,
                      backgroundColor: `${aboutActive ? "white" : "#F3F5F6"}`,
                      // padding: "10px",
                      // cursor: "pointer",
                      // height: "100%",
                    }}
                    onClick={() => setAboutActive(true)}
                  >
                    About the Lender
                  </button>
                  <button
                    className={
                      aboutActive
                        ? "border-0 p-3 "
                        : "border-0 p-3 border-top border-start border-end"
                    }
                    onClick={() => setAboutActive(false)}
                    style={{
                      // border: `2px solid ${isClicked1 ? "#4572bf" : "white"}`,
                      backgroundColor: `${aboutActive ? "#F3F5F6" : "white"}`,
                      // padding: "10px",
                      // cursor: "pointer",
                      // height: "100%",
                    }}
                  >
                    List of Visitors
                  </button>
                </div>
                <div className="">
                  {/* {componentToRender} */}
                  {aboutActive ? <AboutBank /> : <ListAppliacnt />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutBankList;
