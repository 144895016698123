import React, { useState, useEffect } from "react";
import AdminSideBar from "./AdminSideBar";
import BreadCrumb from "./BreadCrumb";
import { FaCircleUser } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import axios from "axios";
import "../styles/SubscribeUser.css";
// import { API_BASE_URL } from "../../appConfig";
const SubscribeUser = () => {
  const [users, setUsers] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [loadingSubscribers, setLoadingSubscribers] = useState(true);

  const adminToken = localStorage.getItem("adminToken");
  const adminId = localStorage.getItem("adminId");
  const headers = {
    authorization: `bearer ${adminToken}`,
  };

  const handleCheckboxChange = (e, index) => {
    const updatedUsers = [...users];
    updatedUsers[index].isAppliedForPromotion = e.target.checked;
    setUsers(updatedUsers);
  };

  React.useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/getAllUsers/${adminId}?isAppliedforPromotion=1`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingSubscribers(false);
      });
  }, []);

  const [livefilterBlog, setLivefilterBlog] = useState("");

  const blogTableAfterFilter = livefilterBlog
    ? users.filter((user) =>
        user?.name?.toLowerCase()?.includes(livefilterBlog?.toLowerCase())
      )
    : users;


  const afterFilter = blogTableAfterFilter.filter((user) => {
    return user.isAppliedForPromotion == true;
  });
  //pegination
  //  pegination for Blog
  const [currentBlog, setCurrentBlog] = useState(1);
  const recordsPerPageBlog = 10;

  // Calculate the index range for the current page
  const startIndexBlog = (currentBlog - 1) * recordsPerPageBlog;
  const endIndexBlog = startIndexBlog + recordsPerPageBlog;

  // Slice the data based on the current page
  const newVisibleFilter = users.filter((element) => {
    return element.isAppliedForPromotion === true;
  });

  const visibleDataUser = afterFilter?.slice(startIndexBlog, endIndexBlog);

  // Calculate the total number of pages
  const totalPagesBlog = Math.ceil(afterFilter?.length / recordsPerPageBlog);

  //for row serial number
  // totalPagesBlog-(totalPagesBlog-currentPagBlog)

  const forSerialNumber = recordsPerPageBlog * (currentBlog - 1);
  // Function to handle page change
  const handlePageChangeBlog = (newPageOne) => {
    setCurrentBlog(newPageOne);
  };

  const handleSearchInputChange = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setLivefilterBlog(searchQuery);
  };

  const handleDownload = () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/admin/AllUsersXL?isAppliedForPromotion=1&&startDate=${startDate}&&endDate=${endDate}`
    );
  };

  return (
    <>
      <div className="subscribeUser">
        <div className="d-flex justify-content-between">
          <div className="col-2">
            <AdminSideBar />
          </div>
          {loadingSubscribers ? (
            <div className="col-10 py-3 px-3">
              <div className="text-center mt-3">
                {/* <div className="spinner-border text-secondary" role="status">
                  <div className="sr-only text-center">Loading...</div>
                </div> */}
                <div className="loader-container">
                  <div className="loader"></div>
                  <div>Please wait while we fetch the latest data for you</div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{ backgroundColor: " #EFF2F5" }}
              className="col-10 py-3 px-3"
            >
              <div className="">
                <div className="d-flex">
                  <BreadCrumb />
                  <div className="px-4 py-3">
                    <div className="d-flex justify-content-between">
                      <FaCircleUser size={25} className="mt-1 mx-2" />
                      <div className="align-middle mt-2">
                        {localStorage.getItem("adminName")}
                      </div>
                    </div>
                  </div>
                </div>
                <hr className=" my-1" />
              </div>
              {/* table */}
              <div className="mt-5 ">
                <div
                  style={{ backgroundColor: "#F8F9FB" }}
                  className="p-3 rounded-3"
                >
                  <div className="d-flex flex-wrap justify-content-between ">
                    <h4 className="col-3 pt-2">Subscribed Users</h4>
                    <div className="form-group has-search  col-4 ">
                      <span className="fa fa-search form-control-feedback  "></span>
                      <input
                        type="text"
                        class="form-control fs-6 rounded-pill bg-white "
                        placeholder="Search by Username"
                        onChange={handleSearchInputChange}
                        value={livefilterBlog}
                        style={{
                          fontWeight: "400",
                          border: "revert-layer", // Remove default border on focus
                          outline: "none", // Remove default outline on focus
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                          borderWidth: "1px",
                        }}
                      />
                      {/* <span className="fa fa-search form-control-feedback "></span> */}
                    </div>
                    <div className="col-4">
                      <div className="d-flex align-items-center justify-content-between ">
                        <div className="col-6 pe-2">
                          <DatePicker
                            className="form-control"
                            selected={startDate}
                            maxDate={new Date()}
                            dateFormat="MM/dd/yyyy"
                            onChange={(date) => setStartDate(date)}
                          />
                        </div>
                        -
                        <div className="col-6 px-2">
                          <DatePicker
                            className="form-control"
                            selected={endDate}
                            maxDate={new Date()}
                            dateFormat="MM/dd/yyyy"
                            onChange={(date) => setEndDate(date)}
                          />
                        </div>
                      </div>
                      <div className="bg-sucess">
                        <div className=" my-2">
                          <button
                            onClick={handleDownload}
                            className="text-center rounded-3 border px-3   download-reports col-12  mt-2   p-2"
                          >
                            Download Reports
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {users?.length !== 0 ? (
                    <>
                      <div className="rounded-3 p-2 bg-white shadow-lg">
                        {blogTableAfterFilter.length !== 0 ? (
                          <>
                            <table className="table table-hover   custom-table">
                              <thead className="">
                                <tr className="text-center">
                                  <td className="headingloan-color ForRowfont py-3">
                                    S.No.
                                  </td>
                                  <td className="headingloan-color py-3">
                                    User Name
                                  </td>
                                  <td className="headingloan-color ForRowfont py-3">
                                    Registered Date
                                  </td>
                                  <td className="headingloan-color ForRowfont py-3">
                                    Registered Email
                                  </td>

                                  <td className="headingloan-color ForRowfont py-3">
                                    Subscribed
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {visibleDataUser.map((user, index) => (
                                  <tr>
                                    <td className="text-center forTextSize">
                                      {forSerialNumber + (index + 1)}
                                    </td>
                                    <td className="text-center forTextSize">
                                      {user.name}
                                    </td>
                                    <td className="text-center forTextSize">
                                      {new Date(
                                        user.createdAt
                                      ).toLocaleDateString("en-US", {
                                        month: "numeric",
                                        day: "numeric",
                                        year: "numeric",
                                      })}
                                    </td>
                                    <td className="text-center forTextSize">
                                      {user.email}
                                    </td>
                                    <td className="text-center forTextSize">
                                      <div className="form-check form-switch d-flex align-items-center justify-content-center">
                                        <input
                                          className="form-check-input border-none shadow-none"
                                          type="checkbox"
                                          role="switch"
                                          checked={
                                            user.isAppliedForPromotion === true
                                          }
                                          // onChange={(e) =>
                                          //   handleCheckboxChange(e, index)
                                          // }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>

                            {/* Pagination controls */}

                            {totalPagesBlog > 1 ? (
                              <div className="d-flex justify-content-between">
                                <button
                                  disabled={currentBlog === 1}
                                  onClick={() => {
                                    handlePageChangeBlog(currentBlog - 1);
                                  }}
                                  className="custom-button fs-3"
                                >
                                  ↼
                                </button>
                                <div>
                                  Page {currentBlog} of {totalPagesBlog}
                                </div>
                                <button
                                  disabled={currentBlog === totalPagesBlog}
                                  onClick={() => {
                                    handlePageChangeBlog(currentBlog + 1);
                                  }}
                                  className="custom-button fs-3"
                                >
                                  ⇀
                                </button>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <h6 className="text-center mt-1">
                            No search results found
                          </h6>
                        )}
                      </div>
                    </>
                  ) : (
                    <div
                      className="p-2 bg-white border rounded"
                      style={{
                        height: "60vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="text-center mt-1">
                        <h5>Currently there are no subscribed users</h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SubscribeUser;
