import React, { memo, useContext, useEffect, useState } from "react";
// import SearchBar from "./SearchBarScholarship";
import { Banner } from "./PathStudyScholarship";
import axios from "axios";
import { API_BASE_URL } from "../appConfig";
import { Link, useNavigate } from "react-router-dom";
import { convertISOToHumanReadable } from "../hooks/convertdate";
import { UserContext } from "../contexts/UserContext";
import { ModalContext } from "../contexts/ModalContext";
import NotShowData from "./NotShowData";
// import { FaArrowRightLong } from "react-icons/fa6";
import { CountryContext } from "../contexts/CountryContext";
import ScholarBanksFooterUSA from "./ScholarBanksFooterUSA";
import ScholarBanksFooterIND from "./ScholarBanksFooterIND";
import { FaArrowRightLong } from "react-icons/fa6";
import { formatAmount } from "../hooks/useFormattedAmount";

const OtherRecommendedScholarship = memo(function OtherRecommendedScholarship({
  filteredData,
}) {
  const [cardData, setCardData] = useState([]);
  const { isUserLoggedIn } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const { openModal } = useContext(ModalContext);
  const [currency, setCurrency] = useState("");
  // condition for checking user logged in or not
  useEffect(() => {
    if (!isUserLoggedIn) {
      openModal();
    }
  }, [isUserLoggedIn]);

  const [isProfileComplete, setIsProfileComplete] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const userId = localStorage.getItem("userId");
        const userToken = localStorage.getItem("userToken");
        const updatedToken = `Bearer ${userToken} `;
        const authAxios = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          // baseURL: API_BASE_URL,
          headers: {
            authorization: updatedToken,
          },
        });
        const response = await authAxios.get(
          `${process.env.REACT_APP_BASE_URL}/getAllScholarships/${userId}`,
          // `${API_BASE_URL}/getAllScholarships/${userId}`,
          {
            headers: {
              Authorization: `bearer ${userToken}`,
            },
          }
        );
        // setCurrency(response.data.currency);
        console.log(response.data)
        if (response.data?.isProfileComlete === false) {
          setIsProfileComplete(false);
        } else {
          setIsProfileComplete(true);
        }
        // setIsProfileComplete(response.data.isProfileComlete);
        if (response.data?.scholarships && response.data.scholarship > 0) {
          const filtered = response.data.scholarships.filter(
            (item) => item.scholarshipProvider !== "Path2Study"
          );
          setCardData(filtered);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {isUserLoggedIn && isUserLoggedIn != undefined ? (
        <div className="container g-2 mt-4">
          {(filteredData && filteredData.length) !== 0 && (
            <>
              <div className="container mb-2">
                <span className="fs-3 text text-primary fw-semibold sm-ms-2 ">
                LIST OF{" "}
                </span>{" "}
                <span className="fs-3 text ">SCHOLARSHIPS</span>
              </div>
            </>
          )}
          <div className="container md-mt-4">
            {(filteredData && filteredData.length) !== 0 && (
              <span className="d-flex justify-content-center my-lg-4 mb-2">
                Check out these list of scholarships curated just for you!
                {/* Check out these high school scholarships curated just for you! */}
              </span>
            )}
            <div className="row">
              {filteredData ? (
                filteredData.length > 0 ? (
                  filteredData.map((card, i) => (
                    // <ScholarshipCard card={card} key={card._id}/>
                    <div key={card._id} className="col-lg-4 col-12 ">
                      <div className="border rounded-4 p-3 mt-2 mb-3">
                        <div className="text-end">
                          {/* <StatusComponent status={card.status} />{" "} */}
                        </div>
                        <div className="d-flex flex-wrap justify-content-between">
                          {/* <h4 className="text-primary">${card.amount}</h4> */}
                          <h4 className="text-primary">
                            {card.currency ? card.currency : "$"} {formatAmount(card.amount)}
                          </h4>
                          <div className="d-flex flex-wrap ">
                            <i className="bi bi-clock "></i>
                            <p className="px-2">
                              {card?.Deadline &&
                                convertISOToHumanReadable(card?.Deadline)}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-between">
                          <i className="bi bi-mortarboard-fill col-1 "></i>
                          <small className="col-11 text-truncate d-inline-block fs-5">
                            {card.scholarshipName}
                          </small>
                        </div>
                        <div className="d-flex flex-wrap justify-content-between ">
                          <i className="bi bi-send-arrow-up-fill col-1 mt-1"></i>
                          <small className="col-11 mb-1 pb-0 mt-1">
                            Provider :{card.scholarshipProvider}
                          </small>
                        </div>
                        <div className="d-flex flex-wrap justify-content-between ">
                          <i className="bi bi-journal-bookmark-fill col-1 mt-1"></i>
                          {/* <small className="col-11 mt-1">
                            Course name :{card.courseName}
                          </small> */}
                          <small className="col-11 mt-1 text-truncate">
                            Course name:{" "}
                            {Array.isArray(card.courseName) &&
                            card.courseName.length > 0
                              ? card.courseName.join(", ")
                              : "All"}
                          </small>
                        </div>
                        <Link to={card.redirectionalUrl} target="_blank">
                          <button className="btn btn-outline-secondary rounded w-100 me-3 mt-2">
                            Apply Now
                          </button>
                        </Link>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    {/* {console.log('filteredData.isProfileComlete',filteredData.isProfileComlete)} */}
                    {/* {!isProfileComplete ? (
                      <>
                        <div
                          className="d-flex justify-content-center  "
                          style={{ marginTop: "6rem", marginBottom: "4.5rem" }}
                        >
                          <div className="text-primary border p-5 rounded-4 mx-1">
                            <p className="fs-3">
                              {" "}
                              <span className="text-primary">
                                Kindly complete your profile to checkout
                                scholarships curated for you!
                              </span>
                            </p>
                            <Link
                              to="/user/userprofile"
                              className="text-decoration-underline fs-5 profile-link-button"
                              onClick={() => window.scrollTo(0, 0)}
                            >
                              Complete Profile
                              <FaArrowRightLong
                                style={{ verticalAlign: "middle" }}
                                className="ms-2 mt-1"
                              />
                            </Link>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-center border pt-5  rounded-4">
                          <span className="fs-3 text-dark ">
                            Thank you for completing your profile on Path2Study.
                            Currently, there are{" "}
                            <span className="text-primary"> no matches</span>{" "}
                            based on your profile.
                          </span>
                          <p className="text-dark py-5 px-2">
                            Note: Consider updating your profile for better
                            matching opportunities. Ignore this message if your
                            profile information is already updated.
                          </p>
                        </div>
                      </>
                    )} */}
                  </div>
                )
              ) : (
                cardData.map((card, i) => (
                  // <ScholarshipCard card={card} key={card._id}/>
                  <div key={card._id} className="col-lg-4 col-12  ">
                    <div className="border rounded-4 p-3 mt-2 mb-3">
                      <div className="text-end">
                        {/* <StatusComponent status={card.status} />{" "} */}
                      </div>
                      <div className="d-flex flex-wrap justify-content-between">
                        <h4 className="text-primary">${card.amount}</h4>
                        <div className="d-flex flex-wrap ">
                          <i className="bi bi-clock "></i>
                          <p className="px-2">
                            {card?.Deadline &&
                              convertISOToHumanReadable(card?.Deadline)}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap justify-content-between">
                        <i className="bi bi-mortarboard-fill col-1 "></i>
                        <small className="col-11 text-truncate d-inline-block fs-5">
                          {card.scholarshipName}
                        </small>
                      </div>
                      <div className="d-flex flex-wrap justify-content-between ">
                        <i className="bi bi-send-arrow-up-fill col-1 mt-1"></i>
                        <small className="col-11 mb-1 pb-0 mt-1">
                          Provider :{card.scholarshipProvider}
                        </small>
                      </div>
                      <div className="d-flex flex-wrap justify-content-between ">
                        <i className="bi bi-journal-bookmark-fill col-1 mt-1"></i>
                        <small className="col-11 mt-1">
                          Course name :{card.courseName}
                        </small>
                      </div>
                      <Link to={card.redirectionalUrl} target="_blank">
                        <button className="btn btn-outline-secondary rounded w-100 me-3 mt-2">
                          Apply Now
                        </button>
                      </Link>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      ) : (
        <NotShowData />
      )}
    </>
  );
});

const OthreScholarshipPage = () => {
  // for apply now nevigation to loan page
  const nevigate = useNavigate();
  const { data, setData, isUserLoggedIn, userCountry, setUserCountry } =
    useContext(UserContext);
  const handleApplyNow = () => {
    nevigate("/loan");
  };
  const [isProfileComplete, setProfileComplete] = useState(false);
  const [noMatchFound, setNoMatchFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false); //  for loader
  // condition for checking user logged in or not
  // For search
  const [searchObj, setSearchObj] = useState({});
  const [scholarships, setScholarships] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const { selectedCountry, setSelectedCountry } = useContext(CountryContext);
  useEffect(() => {
    setIsLoading(true);
    const userId = localStorage.getItem("userId");
    const userToken = localStorage.getItem("userToken");
    const updatedToken = `Bearer ${userToken} `;
    // console.log('updatedToken',updatedToken)
    const authAxios = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      // baseURL: API_BASE_URL,
      headers: {
        authorization: updatedToken,
      },
    });
    authAxios
      .get(`${process.env.REACT_APP_BASE_URL}/getAllScholarships/${userId}`)
      // .get(`${API_BASE_URL}/getAllScholarships/${userId}`)
      .then((res) => {
        // console.log("result get scholarship ooother", res.data.scholarships);
        // console.log("result get scholarship ooother", res.data);
        // setScholarships(res.data.scholarships);
        if (res.data.isProfileComlete === false) {
          setProfileComplete(false);
          setIsLoading(false);
        } else {
          setProfileComplete(true);
        }

        if (res.data?.scholarships) {
          const filtered = res.data.scholarships.filter(
            (item) => item.scholarshipProvider !== "Path2Study"
            //  &&
            // item.Country?.toLowerCase() === selectedCountry?.toLowerCase()
            // console.log('item.scholarshipProvider === "Path2Study"', item.scholarshipProvider === "Path2Study")
          );
          // console.log("filtered", filtered);
          setScholarships(filtered);
          if (res.data.scholarships?.length === 0 || filtered.length === 0) {
            setNoMatchFound(true);
          } else {
            setNoMatchFound(false);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error get scholarship", err.response);
      });
  }, [selectedCountry]);

  return (
    <div>
      <div>
        <div className="container ">
          <Banner />
          {!isUserLoggedIn && <NotShowData />}
          {isUserLoggedIn && !isProfileComplete && isLoading && (
            <div className="loader-container-schol d-flex">
              {/* Loader */}
              <div className="loader-container-schol d-flex">
                <span className="loader-schol"></span>
                <p className="text-primary">
                  Hold tight! We're fetching the best possible options for you.
                </p>
              </div>
            </div>
          )}
          {isUserLoggedIn &&
            isProfileComplete &&
            !isLoading &&
            !noMatchFound &&
            scholarships.length > 0 && (
              <>
                <OtherRecommendedScholarship filteredData={scholarships} />
              </>
            )}
          {isUserLoggedIn && !isProfileComplete && !isLoading && (
            <>
              <div
                className="d-flex justify-content-center text-center "
                style={{
                  marginTop: "4rem",
                  marginBottom: "2rem",
                }}
              >
                <div className="text-primary border p-5 rounded-4 mx-1">
                  <p className="fs-3 ">
                    {" "}
                    <span className="text-primary">
                      Kindly complete your profile to checkout scholarships
                      curated for you!
                    </span>
                  </p>
                  <Link
                    to="/user/userprofile"
                    className="text-decoration-underline fs-5 profile-link-button "
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Complete Profile
                    <FaArrowRightLong
                      style={{ verticalAlign: "middle" }}
                      className="ms-2 mt-1"
                    />
                  </Link>
                </div>
              </div>
            </>
          )}

          {isUserLoggedIn &&
            isProfileComplete &&
            noMatchFound &&
            scholarships.length == 0 && (
              <>
                <div className="text-center border pt-5  rounded-4">
                  <span className="fs-3 text-dark ">
                    Thank you for completing your profile on Path2Study.
                    Currently, there are{" "}
                    <span className="text-primary"> no matches</span> based on
                    your profile.
                  </span>
                  <p className="text-dark py-5 px-2">
                    Note: Consider updating your profile for better matching
                    opportunities. Ignore this message if your profile
                    information is already updated.
                  </p>
                </div>
              </>
            )}
          {selectedCountry === "usa" ? (
            <ScholarBanksFooterUSA />
          ) : (
            <ScholarBanksFooterIND />
          )}
        </div>
      </div>
    </div>
  );
};

export default OthreScholarshipPage;

// Unique Countries
// const uniqueCountries = Array.from(
//   new Set(scholarships.map((data) => data?.Country?.toLowerCase()))
// );

// // Unique Courses
// const uniqueCourses = Array.from(
//   new Set(scholarships.map((data) => data?.courseName?.toLowerCase()))
// );

// Handle change
// const handleChange = (e) => {
//   const { name, value } = e.target;
//   setSearchObj({
//     ...searchObj,
//     [name]: value,
//   });
// };

// // Handle submit
// const handleSubmit = (e) => {
//   e.preventDefault();
//   // console.log('scholarship', scholarships);
//   console.log("Searched Objects", searchObj);
//   const filtered = scholarships.filter(
//     (data) =>
//       data?.Country?.toLowerCase() === searchObj.country &&
//       data?.courseName?.toLowerCase() === searchObj.course
//     // console.log('data', data.Country, searchObj.country , data.course, searchObj.course)
//   );

//   setFilteredData(filtered);
//   console.log("filtered data final", filtered);
// };

{
  /* search bar with functionality */
}
{
  /* <SearchBar
            uniqueCountries={uniqueCountries} 
            uniqueCourses={uniqueCourses}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          /> */
}
{
  /* {console.log(isProfileComplete)}
          {!isProfileComplete ? (
            <div className="text-center">
              <div
                className="d-flex justify-content-center  "
                style={{
                  marginTop: "6rem",
                  marginBottom: "4.5rem",
                }}
              >
                <div className="text-primary border p-5 rounded-4 mx-1">
                  <p className="fs-3">
                    {" "}
                    <span className="text-primary">
                      Kindly complete your profile to checkout scholarships
                      curated for you!
                    </span>
                  </p>
                  <Link
                    to="/user/userprofile"
                    className="text-decoration-underline fs-5 profile-link-button"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Complete Profile
                    <FaArrowRightLong
                      style={{ verticalAlign: "middle" }}
                      className="ms-2 mt-1"
                    />
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <>
              <OtherRecommendedScholarship filteredData={scholarships} />
            </>
          )} */
}

{
  /* {selectedCountry == "usa" ? (
            <ScholarBanksFooterUSA />
          ) : (
            <ScholarBanksFooterIND />
          )} */
}
{
  /* component for footer section in Otherscolarship */
}
{
  /* <div className="container">
            <div className="card mb-3 border border-white">
              <div className="row ">
                <div className="col-md-6">
                  <img
                    src={require("../assests/img/USA1.png")}
                    className="img-fluid "
                    alt="..."
                  />
                </div>
                <div className="col-md-6 mt-5">
                  <div className="card-body">
                    <h5 className="card-title fs-1">
                      <span className="fs-2 text fw-semibold">INSTANTLY</span>{" "}
                      <span className="fs-2 text fw-semibold text-primary">
                        FUND YOUR EDUCATION
                      </span>{" "}
                      <span className="fs-2 text fw-semibold">
                        <br />
                        WITH SUPPORT FROM
                      </span>{" "}
                      <span className="fs-2 text fw-semibold text-primary">
                        KEY LENDERS
                      </span>{" "}
                    </h5>
                    <div className="col-5">
                      <button
                        type="button"
                        className="btn btn-primary mt-4 d-md-block w-100 p-2"
                        onClick={handleApplyNow}
                      >
                        APPLY NOW
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */
}
