import React, { useContext, useEffect, useState } from "react";
import NavBar from "./components/NavBar";
import {  Outlet,useNavigate } from "react-router-dom";
import Footer from "./components/Footer";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import WhatsappConnect from "./components/whatsappConnect";
// import { SwitchModalProvider } from './components/Signup/SwichModalContext';
import { getAuth } from "firebase/auth";
import { signOut } from "firebase/auth";
import { UserContext } from "./contexts/UserContext";
const UserLayout = () => {
  const { isUserLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    const userId = localStorage.getItem('userId');

    const clearLocalStorage = () => {
      localStorage.clear();
    };
  
    const handleLogOut = () => {
      // console.log("clicked");
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          // console.log('signoout')
        })
        .catch((error) => {
          console.log('error')
        });
      if (isUserLoggedIn) {
        signOut(auth);
        clearLocalStorage();
        window.location.href = "/";
      }
      
    };
    // Check if token and userId are not null before making the API request
    if (token) {
      const headers = {
        authorization: `bearer ${token}`,
      };
  
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/getUser/${userId}`,
          // `https://server-dot-path2study.el.r.appspot.com/getUser/${userId}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          // console.log(error)
          // console.log(error.response.data)
          if(error.response.data.message == "Your token has expired! Please log in again."){
            handleLogOut()
          }
          if(error.response.data.message == "The user belonging to this token does no longer exist."){
            handleLogOut()
          }
        });
    } else {
      // localStorage.clear()
      // navigate("/"); // Redirect to "/" if token or userId is null
      console.log('user not logged in, please login for better experience and all feature')
    }
  }, []);
  
  return (
    <>
      {/* <SwitchModalProvider> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <NavBar />
      {/* Other components */}
      <Outlet />
      <div className="container-fluid mx-0">
        <Footer />
      </div>

      <div className="d-flex justify-content-end position-relative bottom-0 end-0 w-100">
        <img
          src={require("./assests/img/fotterimg1.png")}
          alt=""
          className="img-fluid w-55"
        />
      </div>

      {/* Whatsapp Connect  */}
      {/* <WhatsappConnect /> */}
    </>
  );
};

export default UserLayout;
