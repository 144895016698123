import React from "react";
import AdminSideBar from "./AdminSideBar";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import "../styles/AddScholarship.css";
import { FaGraduationCap } from "react-icons/fa";
// import { CiGlobe } from "react-icons/ci";
// import { PiBookLight } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import { IoSend } from "react-icons/io5";
// import { toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";

// import { RxStopwatch } from "react-icons/rx";
// import { MdOutlineWatchLater } from "react-icons/md";
import BreadCrumb from "./BreadCrumb";
import { FaCircleUser, FaL } from "react-icons/fa6";
import { format } from "date-fns";
// import Select from "react-select";
// import { countries } from "countries-list";
// import Select from "react-select-country-list";
// import { API_BASE_URL } from "../../appConfig";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

import { RxCrossCircled } from "react-icons/rx";

function AddScholarship() {
  const [currency, setCurrency] = useState('');
  const [showDiscard, setShowDiscard] = useState(false);

  const locationAdd = useLocation();
  const {
    otherProvidersLength,
    pathToStudyTableLength,
    schlorShipDataLength,
    isClicked1,
    isClicked2,
  } = locationAdd.state || {};
  // console.log("otherProvidersLength" + otherProvidersLength);

  const [isClickedEditOne, setIsClickedEditOne] = useState(isClicked1);
  const [isClickedEditTwo, setIsClickedEditTwo] = useState(isClicked2);
  const [urlError, setUrlError] = useState(" ");

  const [amountError, setAmountError] = useState(" ");

  const [show, setShow] = useState(false);

  const [checkOtherCurrentEducation, setCheckOtherCurrentEducation] =
    useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const handleClickOne = () => {
    setIsClickedEditOne(true);
    setIsClickedEditTwo(false);
    navigate("/admin/scholarship-management/");
  };
  const handleClickTwo = () => {
    setIsClickedEditOne(false);
    setIsClickedEditTwo(true);
    navigate("/admin/scholarship-management/");
    // scholarship-management
  };

  //for form
  const [formData, setFormData] = useState({
    scholarshipName: "",
    amount: "",
    currency: "",
    courseName: "",
    startDate: "",
    description: "",
    minimumRequirements: "",
    Deadline: "",
    scholarshipProvider: "",
    redirectionalUrl: "",
    Country: "",
    culturalIdentity: "",
    ethnicity: "",
    gpaRequired: "",
    availableToAll: "",
    highschoolUniversity: "",
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      scholarshipProvider: isClickedEditTwo
        ? "Path2Study"
        : prevFormData.scholarshipProvider,
    }));
  }, []);
  const [checkCitizenShip, setCheckCitizenShip] = useState({
    first: false,
    second: false,
    third: false,
  });

  const handleDiscard = () => {
    setFormData({
      scholarshipName: "",
      amount: "",
      currency: "",
      courseName: "",
      startDate: "",
      description: "",
      minimumRequirements: "",
      Deadline: "",
      scholarshipProvider: "",
      redirectionalUrl: "",
      // Country: "",
      // culturalIdentity: "",
      ethnicity: "",
      gpaRequired: "",
      availableToAll: "",
      highschoolUniversity: "",
    });

    setShowDiscard(false);

    setSelectedCountry("");
    setSelectedState("");
    setStateArray([]);

    setStatus({
      checkOne: false,
      checkTwo: false,
      checkThree: false,
      checkFour: false,
      checkFive: false,
    });

    setGenderStatus({
      genderOne: false,
      genderTwo: false,
      genderThree: false,
    });

    setCheckCitizenShip({
      first: false,
      second: false,
      third: false,
    });
    window.location.reload();
  };

  // console.log("citizen", checkCitizenShip);

  //code for checkbox
  const [status, setStatus] = useState({
    checkOne: false,
    checkTwo: false,
    checkThree: false,
    checkFour: false,
    checkFive: false,
    checkSix: false,
  });

  const [genderStatus, setGenderStatus] = useState({
    genderOne: false,
    genderTwo: false,
    genderThree: false,
  });

  const handleUpdateStatus = (variableName) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [variableName]: !prevStatus[variableName],
    }));
  };

  const handleGenderChange = (variableName) => {
    setGenderStatus((prevStatus) => ({
      ...prevStatus,
      [variableName]: !prevStatus[variableName],
    }));
  };

  const handleChangeAdd = (e) => {
    if (e.target.name === "startDate") {
      // const reversedDate = e.target.value.split("-").reverse().join("/");
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.split("/").reverse().join("-"),
      });
    } else if (e.target.name === "Deadline") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.split("/").reverse().join("-"),
      });
    } else if (e.target.name === "redirectionalUrl") {
      const urlRegex = /^https:\/\/.*/;

      setUrlError("");
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "amount") {
      const regex = /^(\d*\.?\d{0,9}|\.\d{1,9})?$/;

      if (regex.test(e.target.value)) {
        // Valid monetary amount, you can proceed with handling it
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
        setAmountError("");
      } else {
        setAmountError("Enter valid amount");
      }
    } else if (e.target.name === "ethnicity") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    
    } else if (e.target.name === "currency") {
      console.log("formData.currency",formData.currency)
      setCurrency(e.target.value)
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    } else {
      
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  let adminToken = localStorage.getItem("adminToken");
  let adminId = localStorage.getItem("adminId");
  const headers = {
    authorization: `bearer ${adminToken}`,
  };

  //to post data
  const genderArray = [];
  const schlorshipAvailabelArrray = [];

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [stateArray, setStateArray] = useState([]);

  const [ethinicityInput, setEthinicityInput] = useState("");
  const handleCountryChange = (country) => {
    setSelectedCountry(country);

    setSelectedState(""); // Reset state when country changes
  };

  const handleStateChange = (state) => {
    if (!stateArray.includes(state)) {
      setSelectedState(state);
      setStateArray((prevArray) => [...prevArray, state]);
    }
  };

  const citizenShips = [];

  const handleCitizenShipChange = (citizenshipType) => {
    setCheckCitizenShip((prevCheckCitizenShip) => ({
      ...prevCheckCitizenShip,
      [citizenshipType]: !prevCheckCitizenShip[citizenshipType],
    }));
  };

  const handleSubmitAdd = (e) => {
    if (genderStatus.genderOne) {
      genderArray.push("Male");
    }
    if (genderStatus.genderTwo) {
      genderArray.push("Female");
    }
    if (genderStatus.genderThree) {
      genderArray.push("Other");
    }

    if (status.checkOne) {
      schlorshipAvailabelArrray.push("High School");
    }
    if (status.checkTwo) {
      schlorshipAvailabelArrray.push("Under Graduate");
    }
    if (status.checkThree) {
      schlorshipAvailabelArrray.push("Post Graduate");
    }
    if (status.checkFour) {
      schlorshipAvailabelArrray.push("MBA");
    }
    if (status.checkFive) {
      schlorshipAvailabelArrray.push("PhD");
    }
    if (status.checkSix) {
      schlorshipAvailabelArrray.push(checkOtherCurrentEducation);
    }

    if (checkCitizenShip.first) {
      citizenShips.push("US Citizen");
    }
    if (checkCitizenShip.second) {
      citizenShips.push("Permanent Resident (Green Card Holder)");
    }
    if (checkCitizenShip.third) {
      citizenShips.push("Nonimmigrant");
    }

    e.preventDefault();
    setShow(false);
    // console.log(formData);

    // console.log("availableToAll");
    // console.log(formData.availableToAll);

    formData.gender =
      // JSON.stringify(
      genderArray;
    // );
    formData.currentEducationalLevel =
      // JSON.stringify(
      schlorshipAvailabelArrray;
    // );

    formData.country = selectedCountry;
    formData.citizenshipStatus =
      //  JSON.stringify(
      citizenShips;
    // );

    // console.log(formData.country);
    formData.state = stateArray;
    const filteredObjectTosend = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== "")
    );

    // stateArray

    // filteredObjectTosend
    // filteredObjectTosend.
    let arr = filteredObjectTosend?.courseName?.split(",");

    delete filteredObjectTosend.courseName;
    if (filteredObjectTosend.courseName !== "")
      filteredObjectTosend.courseName = arr?.map((item) => item?.trim()) || [];

    if (!filteredObjectTosend.hasOwnProperty("scholarshipProvider")) {
      filteredObjectTosend.scholarshipProvider = "Anonymous";
    }

    if (ethinicityInput !== "") {
      delete filteredObjectTosend.ethnicity;
      filteredObjectTosend.ethnicity = ethinicityInput;
    }
    // scholarshipProvider
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/addScholarship/${adminId}`,
        filteredObjectTosend,
        { headers: headers }
      )
      .then((response) => {
        // window.alert("Scholarship added succesfully");
        toast.success("New scholarship added succesfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate("/admin/scholarship-management/");
        }, 3000);

        setFormData({
          scholarshipName: "",
          amount: "",
          currency: "",
          courseName: "",
          startDate: "",
          description: "",
          minimumRequirements: "",
          Deadline: "",
          scholarshipProvider: "",
          redirectionalUrl: "",
          // Country: "",
          // culturalIdentity: "",
          ethnicity: "",
          gpaRequired: "",
          availableToAll: "",
        });
      })
      .catch((error) => {
        // alert("admin login error", error);

        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  // console.log("data after submission " + formData);

  const removeState = (i) => {
    setStateArray((prevArray) => {
      const newArray = [...prevArray];
      newArray.splice(i, 1);
      return newArray;
    });

    // stateArray
  };

  const forInputField = {
    fontWeight: "400",
    border: "revert-layer",
    outline: "none",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderWidth: "1px",
  };
  return (
    <div className="AddScholarship">
      <div className="">
        <div className="d-flex justify-content">
          <div className="col-2">
            <AdminSideBar />
          </div>
          <div className="col-10 py-3 px-3">
            <div className="d-flex">
              <BreadCrumb />
              <div className="px-4 py-3">
                <div className="d-flex justify-content-between">
                  <FaCircleUser size={25} className="mt-1 mx-2" />
                  <div className="align-middle mt-2">
                    {localStorage.getItem("adminName")}
                  </div>
                </div>
              </div>
            </div>
            <hr className=" my-1" />
            <div className="d-flex justify-content-between mt-3">
              <div className="col-4 pe-3 ">
                <div className="rounded-3 bg-white p-3">
                  <div className="">
                    <div className="">
                      <p>
                        <FaGraduationCap size={37} />
                      </p>
                      <div className="d-flex justify-content">
                        <div className="col-8 text-start">
                          <h5>Total Scholarships</h5>
                        </div>
                        <div className="col-4 text-end">
                          {/* otherProvidersLength, pathToStudyTableLength, schlorShipDataLength */}
                          <h5> {schlorShipDataLength}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 pe-3 ">
                <div
                  className="rounded-3 p-3"
                  onClick={handleClickOne}
                  style={{
                    border: `2px solid ${
                      isClickedEditTwo ? "#4572bf" : "white"
                    }`,
                    backgroundColor: `${
                      isClickedEditTwo ? "#F6FDFF" : "white"
                    }`,
                    padding: "10px",
                    cursor: "pointer",
                    height: "100%",
                  }}
                >
                  <div className="d-flex justify-content-between  py-3 px-3">
                    <div className="">
                      <h5 className="mt-3">Path2study Scholarships</h5>
                    </div>

                    <div className="mt-3">
                      <h5>{pathToStudyTableLength}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 ">
                <div
                  className="rounded-3 p-3"
                  onClick={handleClickTwo}
                  style={{
                    border: `2px solid ${
                      isClickedEditOne ? "#4572bf" : "white"
                    }`,
                    backgroundColor: `${
                      isClickedEditOne ? "#F6FDFF" : "white"
                    }`,
                    padding: "10px",
                    height: "100%",
                    cursor: "pointer",
                  }}
                >
                  <div className="d-flex justify-content-between py-3 px-3">
                    <div className="">
                      <h5 className="mt-3">Other Provider’s Scholarships</h5>
                    </div>

                    <div className="">
                      <h5 className="mt-3">{otherProvidersLength}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* add new schlorship section */}
            <div className=" py-3 mt-3 px-5 rounded-3 schlorshipBackground">
              <h3>Add New Scholarship</h3>
              <div className="d-flex justify-content-between rounded-3 bg-white mt-3 p-5">
                <div className="col-6">
                  {/* <label>Scholarship Name</label> */}
                  <div>
                    <form action="">
                      <label>Scholarship Name</label>
                      {/* <span className="text-danger">(Mandotory field)</span> */}
                      <input
                        className="form-control shadow-none  input forInputFiled"
                        type="text"
                        style={forInputField}
                        placeholder="Enter Scholarship Name"
                        name="scholarshipName"
                        // value={formData.scholarshipName}
                        value={
                          formData?.scholarshipName
                            ? formData?.scholarshipName
                                ?.charAt(0)
                                ?.toUpperCase() +
                              formData?.scholarshipName?.slice(1)
                            : ""
                        }
                        onChange={handleChangeAdd}
                      />
                      <div className="form-group mt-3">
                        <label>Currency </label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="currencyRupees"
                            value="₹"
                            name="currency"
                            onChange={handleChangeAdd}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="currencyRupees"
                          >
                            Rupees
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="currencyDollar"
                            value="$"
                            name="currency"
                            onChange={handleChangeAdd}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="currencyDollar"
                          >
                            Dollar
                          </label>
                        </div>
                      </div>
                      <label className="mt-3">Scholarship Amount</label>
                      {/* <span className="text-danger">(Mandotory field)</span> */}
                      <input
                        className="form-control shadow-none  input forInputFiled"
                        type="text"
                        placeholder="Enter Scholarship Amount"
                        style={forInputField}
                        name="amount"
                        value={formData.amount}
                        onChange={handleChangeAdd}
                      />
                      <div className="text-danger mt-3">{amountError}</div>
                      <label className="">Course Name </label>
                      <input
                        className="form-control shadow-none  input forInputFiled"
                        type="text"
                        placeholder="Enter Course Name"
                        style={forInputField}
                        name="courseName"
                        // value={formData.courseName}
                        value={
                          formData?.courseName
                            ? formData?.courseName?.charAt(0)?.toUpperCase() +
                              formData?.courseName?.slice(1)
                            : ""
                        }
                        onChange={handleChangeAdd}
                      />
                      <label className="mt-3">
                        High School or University Name{" "}
                      </label>
                      <input
                        className="form-control shadow-none  input forInputFiled"
                        type="text"
                        placeholder="Enter High School or University Name"
                        style={forInputField}
                        name="highschoolUniversity"
                        // name="courseName"
                        // value={formData.courseName}
                        value={
                          formData?.highschoolUniversity
                            ? formData?.highschoolUniversity
                                ?.charAt(0)
                                ?.toUpperCase() +
                              formData?.highschoolUniversity?.slice(1)
                            : ""
                        }
                        onChange={handleChangeAdd}
                      />
                      <label className="mt-3">Start Date</label>
                      <input
                        className="form-control shadow-none  input forInputFiled"
                        type="date"
                        id="exampleDate"
                        placeholder="Enter Start Date"
                        style={forInputField}
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChangeAdd}
                      />

                      <label className="mt-3">Deadline</label>
                      <input
                        className="form-control shadow-none  input forInputFiled"
                        type="date"
                        data-date-format="DD MMMM YYYY"
                        style={forInputField}
                        id="exampleDate"
                        placeholder="Enter Deadline"
                        name="Deadline"
                        value={formData.Deadline}
                        onChange={handleChangeAdd}
                      />
                      {isClickedEditTwo ? (
                        <div>
                          <label htmlFor="" className="mt-3">
                            Scholarship Description
                          </label>
                          <textarea
                            className="form-control shadow-none  input forInputFiled"
                            id="exampleFormControlTextarea1"
                            style={forInputField}
                            rows="3"
                            name="description"
                            value={
                              formData?.description
                                ? formData?.description
                                    ?.charAt(0)
                                    ?.toUpperCase() +
                                  formData?.description?.slice(1)
                                : ""
                            }
                            // value={formData.description}

                            onChange={handleChangeAdd}
                          ></textarea>
                          <label htmlFor="" className="mt-3">
                            Eligibility Requirements
                          </label>
                          <textarea
                            className="form-control shadow-none  input forInputFiled"
                            id="exampleFormControlTextarea1"
                            style={forInputField}
                            rows="3"
                            name="minimumRequirements"
                            value={
                              formData?.minimumRequirements
                                ? formData?.minimumRequirements
                                    ?.charAt(0)
                                    ?.toUpperCase() +
                                  formData?.minimumRequirements?.slice(1)
                                : ""
                            }
                            // value={formData.minimumRequirements}
                            onChange={handleChangeAdd}
                          ></textarea>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {/* <label className="mt-3">Country </label>
                      <input
                        className="form-control shadow-none  input forInputFiled"
                        type="text"
                        placeholder="Enter the country"
                        style={forInputField}
                        name="Country"
                        value={formData.Country}
                        onChange={handleChangeAdd}
                      /> */}

                      <div className="mt-3">
                        {/* Select Country */}
                        <label>Country</label>
                        <CountryDropdown
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          classes="form-select shadow-none"
                          style={forInputField}
                        />

                        {/* Display selected country */}

                        {/* Select State based on the selected country */}

                        <div className="mt-3">
                          <label className=""> State</label>
                          <RegionDropdown
                            country={selectedCountry}
                            value={selectedState}
                            onChange={handleStateChange}
                            style={forInputField}
                            className="form-select shadow-none"
                          />
                        </div>

                        {/* Display selected state */}
                        {/* {selectedState && ( */}
                        <div>
                          {/* <p className="mt-3">Selected States:</p> */}

                          <div className="form-group">
                            {/* <label for="exampleFormControlTextarea1">
                                Example textarea
                              </label> */}
                          </div>

                          {selectedState && (
                            <p className="mt-3 border rounded-3 p-3">
                              {stateArray?.map((element, i) => {
                                return (
                                  <span className="mx-2">
                                    {element}
                                    <RxCrossCircled
                                      size={20}
                                      //  onClick={(i)=>{
                                      // }}
                                      onClick={() => removeState(i)}
                                      className="mt-0"
                                    />
                                  </span>
                                );
                              })}
                            </p>
                          )}
                        </div>
                        {/* // )} */}
                      </div>

                      <p className="mt-3 mb-0 pb-0">
                        Scholarship Available for All
                      </p>

                      <form className="">
                        <select
                          name="availableToAll"
                          id="cars"
                          className="p-2 shadow-none   form-select input forBackgroundColorInput rounded-3 col-12"
                          onChange={(e) => handleChangeAdd(e)}
                          // onChange={(e) => handleCitychange(e)}
                          // onChange={(e) => setSelectedCity(e.target.value)}
                        >
                          <option> Select</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>

                        {/* <input type="submit" value="Submit" /> */}
                      </form>

                      <div className="mt-3 d-flex justify-content-between">
                        <div className="mt-3 col-3">
                          <label>Gender</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle71"
                            // name="genderOne"
                            className="me-2 mt-2"
                            // value="male"
                            checked={genderStatus.genderOne}
                            onClick={() => handleGenderChange("genderOne")}
                          />
                          <label for="vehicle71"> Male</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle72"
                            // name="genderTwo"
                            className="me-2"
                            // value="female"
                            checked={genderStatus.genderTwo}
                            onClick={() => handleGenderChange("genderTwo")}
                          />
                          <label for="vehicle72">Female</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle73"
                            // name="genderThree"
                            className="me-2"
                            // value="other"
                            checked={genderStatus.genderThree}
                            onClick={() => handleGenderChange("genderThree")}
                          />
                          <label for="vehicle73">Other</label>
                          <br />
                          <br />
                        </div>
                        <div className="mt-3 col-9">
                          <label>Citizenship</label>
                          <br />
                          <input
                            type="checkbox"
                            id="vehicle11"
                            // name="genderOne"
                            className="me-2 mt-2"
                            // value="US Citizen"
                            // onClick={() => handleCitizenShipChange("first")}
                            // checked={genderStatus.genderOne}
                            // onClick={() => handleGenderChange("genderOne")}
                            onClick={() => handleCitizenShipChange("first")}
                            checked={checkCitizenShip.first}
                          />
                          <label for="vehicle11" className="">
                            US Citizen
                          </label>
                          {/* <br /> */}
                          <div className="d-flex flex-wrap">
                            <div className="">
                              <input
                                type="checkbox"
                                id="vehicle12"
                                // name="genderTwo"
                                className="me-2"
                                onClick={() =>
                                  handleCitizenShipChange("second")
                                }
                                checked={checkCitizenShip.second}
                                // onClick={() =>
                                //   handleCitizenShipChange("second")
                                // }
                                // value="Permanent Resident (Green Card Holder)"
                                // checked={genderStatus.genderTwo}
                                // onClick={() => handleGenderChange("genderTwo")}
                              />
                            </div>
                            <label for="vehicle12" className="">
                              Permanent Resident (Green Card Holder)
                            </label>
                          </div>

                          <input
                            type="checkbox"
                            id="vehicle13"
                            // name="genderThree"
                            className="me-2"
                            // onClick={() => handleCitizenShipChange("third")}
                            onClick={() => handleCitizenShipChange("third")}
                            checked={checkCitizenShip.third}
                            // value="Nonimmigrant                          "
                            // checked={genderStatus.genderThree}
                            // onClick={() => handleGenderChange("genderThree")}
                          />
                          <label for="vehicle13">Nonimmigrant</label>
                          <br />
                          <br />
                        </div>
                      </div>

                      <div className="mt-1">
                        <label>Current Education Level</label>
                        <br />

                        <input
                          type="checkbox"
                          id="vehicle1"
                          // name="educationOne"
                          className="me-2"
                          // value="high school"
                          checked={status.checkOne}
                          onClick={() => handleUpdateStatus("checkOne")}
                        />
                        <label for="vehicle1">High School</label>
                        <br />
                        <input
                          type="checkbox"
                          id="vehicle2"
                          // name="EducationTwo"
                          className="me-2"
                          // value="under graduation"
                          checked={status.checkTwo}
                          onClick={() => handleUpdateStatus("checkTwo")}
                        />
                        <label for="vehicle2">Under Graduate</label>
                        <br />
                        <input
                          type="checkbox"
                          id="vehicle3"
                          // name="EducationThree"
                          className="me-2"
                          // value="post graduation"
                          checked={status.checkThree}
                          onClick={() => handleUpdateStatus("checkThree")}
                        />
                        <label for="vehicle3">Post Graduate</label>
                        <br />

                        <input
                          type="checkbox"
                          id="vehicle4"
                          // name="EducationFour"
                          className="me-2"
                          // value="mba"
                          checked={status.checkFour}
                          onClick={() => handleUpdateStatus("checkFour")}
                        />
                        <label for="vehicle4">MBA</label>
                        <br />

                        <input
                          type="checkbox"
                          id="vehicle5"
                          // name="EducationFour"
                          className="me-2"
                          // value="phd"
                          checked={status.checkFive}
                          onClick={() => handleUpdateStatus("checkFive")}
                        />
                        <label for="vehicle6">PhD</label>
                        <br />
                        <input
                          type="checkbox"
                          id="vehicle6"
                          // name="EducationFour"
                          className="me-2"
                          // value="phd"

                          checked={status.checkSix}
                          onClick={() => handleUpdateStatus("checkSix")}
                        />
                        <label for="vehicle6">Other</label>
                        {status.checkSix ? (
                          <div className="">
                            <input
                              className="form-control shadow-none  input forInputFiled"
                              type="text"
                              placeholder="Current Education Level"
                              onChange={(e) => {
                                setCheckOtherCurrentEducation(e.target.value);
                              }}
                            />
                          </div>
                        ) : null}

                        <br />
                        <br />
                      </div>

                      {/* <div>{console.log(selectedCountry)}</div> */}

                      <div className="mt-3">
                        <label>Ethnicity</label>

                        <form className="">
                          <select
                            name="ethnicity"
                            id="ethnicity"
                            className="p-2 shadow-none  form-select rounded-3 col-12"
                            // onChange={(e) => handleCitychange(e)}
                            // onChange={(e) => setSelectedCity(e.target.value)}
                            onChange={(e) => handleChangeAdd(e)}
                            style={{ top: "300%" }}
                          >
                            <option>Select</option>
                            <option value="American Indian or Alaska Native">
                              American Indian or Alaska Native
                            </option>
                            <option value="Asian">Asian</option>
                            <option value="Black or African American">
                              Black or African American
                            </option>
                            <option value="Native Hawaiian or Pacific Islander">
                              Native Hawaiian or Pacific Islander
                            </option>
                            <option value="White/Caucasian">
                              White/Caucasian
                            </option>
                            <option value="other">Other</option>
                          </select>

                          {/* <input type="submit" value="Submit" /> */}
                        </form>
                        {formData.ethnicity == "other" ? (
                          <div className="mt-3">
                            <input
                              className="form-control shadow-none  input forInputFiled"
                              type="text"
                              placeholder="Enter ethnicity"
                              style={forInputField}
                              name="ethnicity"
                              defaultValue={ethinicityInput}
                              onChange={(e) => {
                                setEthinicityInput(e.target.value);
                              }}
                              // onChange={handleChangeAdd}
                            />
                          </div>
                        ) : null}
                      </div>

                      <label className="mt-3">GPA</label>
                      <input
                        className="form-control shadow-none  input forInputFiled"
                        type="number"
                        placeholder="Enter Scholarship Gpa"
                        style={forInputField}
                        name="gpaRequired"
                        value={formData.gpaRequired}
                        onChange={handleChangeAdd}
                      />

                      <label className="mt-3">Scholarship Provider </label>
                      <input
                        className="form-control shadow-none  input forInputFiled"
                        type="text"
                        placeholder="Enter Scholarship Provider"
                        style={forInputField}
                        name="scholarshipProvider"
                        value={
                          isClickedEditTwo
                            ? "Path2Study"
                            : formData?.scholarshipProvider
                            ? formData?.scholarshipProvider
                                ?.charAt(0)
                                ?.toUpperCase() +
                              formData?.scholarshipProvider?.slice(1)
                            : ""
                          //  formData.scholarshipProvider
                        }
                        onChange={handleChangeAdd}
                        readOnly={isClickedEditTwo}
                      />

                      {!isClickedEditTwo ? (
                        <div>
                          <label className="mt-3 forlabel">
                            Add redirection URL link of the scholarship
                            {/* <span className="text-danger">(Mandotory field)</span> */}
                          </label>
                          <input
                            className="form-control shadow-none  input forInputFiled"
                            type="text"
                            placeholder="Enter redirectional Url"
                            name="redirectionalUrl"
                            style={forInputField}
                            value={formData.redirectionalUrl}
                            onChange={handleChangeAdd}
                            id="validationCustom01"
                          />
                          <div className="text-danger mt-2">{urlError}</div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </form>
                  </div>
                </div>
                <div className="col-5">
                  {/* <input class="form-control" type="text" placeholder="Default input"/> */}
                  <h3>Preview</h3>
                  <div className="mt-2 border rounded-3 p-3">
                    <div className="d-flex justify-content">
                      <h2 className="text-primary col-6">{currency ? currency : "$"}{formData.amount}</h2>
                      <p className="col-5 mt-2">
                        <div className="d-flex flex-wrap justify-content-betweeen">
                          <div className="">
                            {/* <MdOutlineWatchLater size={20} className="" /> */}
                            <i className="bi bi-clock mt-3"></i>
                          </div>
                          <div className="ms-1">
                            {new Date(formData.Deadline).toLocaleDateString(
                              "en-US",
                              { year: "numeric", month: "long", day: "numeric" }
                            )}
                          </div>
                        </div>
                      </p>
                    </div>

                    <div className="">
                      <div className="d-flex justify-content-between">
                        <div className="col-2">
                          <FaGraduationCap size={27} />
                        </div>
                        <div
                          className="col-10 mt-1"
                          style={{ overflowWrap: "break-word" }}
                        >
                          <h5>{formData.scholarshipName}</h5>
                        </div>
                      </div>
                      <div></div>
                      <div>
                        <div className="d-flex justify-content-between">
                          <div className="col-2">
                            {/* <IoSend size={33} /> */}
                            <h5>
                              {" "}
                              <i className="bi bi-send-arrow-up-fill col-6" />
                            </h5>
                          </div>
                          <div className="col-10">
                            Provider : {formData.scholarshipProvider}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="col-2">
                            {/* <PiBookLight size={33} /> */}
                            <h5>
                              <i className="bi bi-journal-bookmark-fill" />
                            </h5>
                          </div>
                          <div
                            className="col-10"
                            style={{ overflowWrap: "break-word" }}
                          >
                            Course name : {formData.courseName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="btn form-control btn-outline-primary btn-sm btn-block"
                    >
                      Apply Now
                    </button>
                  </div>
                  <p className="mt-3 fs-7">
                    This is the how your newly added scholarship card will
                    appear to the users of the website.
                  </p>
                  <div className="my-5">
                    <div className="d-flex justify-content-between">
                      <div className="col-5">
                        <button
                          type="button"
                          className="btn form-control btn-secondary"
                          // onClick={handleDiscard}
                          onClick={() => setShowDiscard(true)}
                        >
                          Discard
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn form-control btn-primary"
                          onClick={handleShow}
                          disabled={
                            // urlError !== "" ||
                            amountError !== "" ||
                            !formData.scholarshipName.trim() ||
                            // formData.Country.trim() ||
                            // !formData.scholarshipProvider.trim() ||
                            // ||
                            // formData.scholarshipProvider.trim()
                            // !formData.courseName.trim() ||
                            !formData.Deadline.trim() ||
                            !formData.startDate.trim() ||
                            !formData.amount.trim() ||
                            // ||
                            // formData.redirectionalUrl.trim()
                            (!isClickedEditTwo &&
                              (urlError !== "" ||
                                formData.redirectionalUrl.trim() === ""))

                            // || formData.scholarshipProvider.trim()
                          }
                        >
                          Add
                        </button>

                        <ToastContainer />

                        <Modal show={show} onHide={handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Add new scholarship?</Modal.Title>
                          </Modal.Header>
                          {/* <Modal.Body> */}
                          <p className="px-3">
                            {" "}
                            {isClickedEditTwo ? (
                              <div>
                                New scholarship information will be added to
                                Path2Study scholarship. Are you sure you want to
                                proceed?
                              </div>
                            ) : (
                              <div>
                                New scholarship information will be added to
                                Other provider's scholarship. Are you sure you
                                want to proceed?
                              </div>
                            )}
                          </p>
                          {/* </Modal.Body> */}
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                              Cancel
                            </Button>
                            <Button variant="primary" onClick={handleSubmitAdd}>
                              Confirm
                            </Button>
                          </Modal.Footer>
                        </Modal>

                        <div
                          className="modal show"
                          style={{ display: "block", position: "initial" }}
                        >
                          {/* modal for discard button */}

                          <Modal
                            show={showDiscard}
                            onHide={() => setShowDiscard(false)}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Discard changes?</Modal.Title>
                            </Modal.Header>
                            {/* <Modal.Body> */}
                            <p className="mx-3">
                              All the changes will be discarded. Are you sure
                              you want to proceed?
                            </p>
                            {/* </Modal.Body> */}
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={() => setShowDiscard(false)}
                              >
                                Cancel
                              </Button>

                              <Button variant="primary" onClick={handleDiscard}>
                                Confirm
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddScholarship;
