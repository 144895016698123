import React, { useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { ModalContext } from "../../contexts/ModalContext";

// import { API_BASE_URL } from "../../appConfig";
// import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import * as formik from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { redirect } from "react-router-dom";

const ForgetPass3 = () => {
  const { handleNext, data, closeModal, setShowSigninModal, allCloseModal } =
    useContext(ModalContext);
  const [showPassword, setShowPassword] = useState(false); // for eye button
  const { Formik } = formik;

  const passwordSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(/(?=.*[0-9])/, "Password must contain at least one number")
      .matches(
        /(?=.*[a-zA-Z])/,
        "Password must contain at least one alphabet character"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(
        /(?=.*[!@#$%^&*])/,
        "Password must contain at least one special character (!@#$%^&*)"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const handlePassword = async (values) => {
    const { email } = data;
    const { password } = values;
    const postData = { email, password }; // userType = "user"
    // console.log("password component", postData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/resetPassword`,
        // `${API_BASE_URL}/resetPassword`,
        // https://server-dot-path2study.el.r.appspot.com/resetPassword
        postData
      );
      // console.log("Response:", response.data);
      if (response.data.status) {
        // allCloseModal();
        setShowSigninModal(true);
      }
      handleNext(postData);
      if (
        response.data.status == true &&
        response.data.message == "Password has been successfully changed"
      ) {
        toast(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        if (true) {
          redirect("/home");
        }
        // closeModal();
        allCloseModal();
      }
    } catch (error) {
      // console.error("Error:", error);
      // Handle errors, if any
      if (error) {
        toast(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        if (true) {
          // console.log("redirected to login");
          redirect("/user/login");
        }
        closeModal();
      }
    }
  };

  return (
    <>
      <div className="card mx-md-4 shadow p-md-3 p-2 mb-3 bg-body rounded-lg">
        {/* new version  */}
        <Formik
          validationSchema={passwordSchema}
          onSubmit={handlePassword}
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group controlId="validationFormik01">
                  <Form.Label>Enter New Password</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      placeholder="xxxxxxxx"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password }
                      style={{
                        fontWeight: "400",
                        border: "revert-layer", // Remove default border on focus
                        outline: "none", // Remove default outline on focus
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                        // marginBottom:'1rem'
                      }}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>

                <Form.Group controlId="validationFormik02">
                  <Form.Label className="mt-2">Confirm Password</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      placeholder="xxxxxxxx"
                      type={showPassword ? "text" : "password"}
                      // type="password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      isInvalid={!!errors.confirmPassword}
                      style={{
                        fontWeight: "400",
                        border: "revert-layer", // Remove default border on focus
                        outline: "none", // Remove default outline on focus
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                      }}
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        // top: "54%",
                        right: "33px",
                        transform: "translateY(-102%)",
                      }}
                    >
                      {showPassword ? (
                        <i class="bi bi-eye" style={{ fontSize: "1.2rem" }}></i>
                      ) : (
                        <i
                          class="bi bi-eye-slash"
                          style={{ fontSize: "1.2rem" }}
                        ></i>
                      )}
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>

              <div className="d-grid gap-2">
                <Button
                  type="submit"
                  variant="primary"
                  // className="d-grid gap-2 col-6 mx-auto w-100 mt-3"
                  // onClick={handlePassword}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {/* <Button
            variant="light"
            className="d-grid gap-2 col-6 mx-auto w-100 mt-3 border"
          >
            Sign Up with Google
          </Button> */}
        {/* <p className="d-flex justify-content-center mt-3">
            Already have an account?{" "}
            <span className="text-primary ms-1"> Log In </span>
          </p> */}
      </div>
      {/* </Modal> */}
    </>
  );
};

export default ForgetPass3;

{
  /* <img
            src={require("../../assests/img/lock.png")}
            className="rounded mx-auto d-block"
            alt="logocreate"
          /> */
}
{
  /* old version */
}
{
  /* <form>
            <Form.Group controlId="formBasicPassword">
              <Form.Label className="m-2">Create Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="xxxxxxxx"
                value={formik.values.password}
                onChange={formik.handleChange}
                name="password"
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword1">
              <Form.Label className="m-2">Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="xxxxxxxx"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                name="confirmPassword"
              />
            </Form.Group>
          </form> */
}

{
  /* <Button
            variant="primary"
            className="d-grid gap-2 col-6 mx-auto w-100 mt-3"
            onClick={handlePassword}
          >
            Sign Up
          </Button> */
}
