import React from "react";
import "../style/ResourcesFaq.css";
import Faqs from "./Faqs";
import { Link } from "react-router-dom";

function ResourcesFaq() {
  return (
    <>
      <div className="container pt-md-5 mt-5 pt-3">
        <div className="card mb-3 border border-white pt-3">
          <div className="row g-6 ">
            <div className="col-md-6 col-12 mt-lg-5 mt-4">
              <div className="card-body p-0 ">
                <h5 className="card-title fs-1 mb-4  text-sm-start ">
                  <span className="fs-1 text  me-2">
                    NEVER STOP
                  </span>
                  <div>
                  <span className="fs-1 text  me-2">
                   EXPLORING 
                  </span>
                  :&nbsp;
                   <span className="fs-1 text-primary  me-2">
                        FIND
                  </span>
                  </div>
                  <div className="fs-1 text-primary  me-2">
                    YOUR ANSWERS
                  </div>
                  <div className="fs-1 text-primary  me-2">
                    TODAY
                  </div>
                </h5>
                
              </div>
            </div>

            <div className="col-md-6 col-12">
              <img
                src={require("../assests/img/abovefooter1.png")}
                className="img-fluid rounded-5"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
     <div className="pb-5">
     <Faqs  />
     </div>
    </>
  );
}
export default ResourcesFaq;
