import React from "react";
import "../style/About2.css";
import "../style/contact.css";


const About = () => {
  return (
    <>
      <div className="about-page">
        {/* -----------------Home Section--------------------- */}

        <div className="pt-4">
          <div className="container abouthomesection mt-5 pt-lg-5 pt-3">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-md-6 col-12">
                <div className="home-banner-text-heading">
                  <h1 className="">OUR STORY</h1>
                  <h1 className="text-primary">NURTURING DREAMS</h1>
                  <h1 className="text-primary">EMPOWERING FUTURES</h1>
                </div>
              </div>
              <div className="col-md-6 col-12 mt-lg-3 d-flex">
                <div className=" col-md-6 col-lg-5  mt-5 pt-5 p-3 ">
                  <img
                    src={require("../assests/img/homeprofile1.png")}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className=" col-md-6 col-lg-5 p-3">
                  <img
                    src={require("../assests/img/homeprofile2.png")}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ------------Cover-Image-Lapping-Section----------- */}

        <div>
          <div className="container">
            <div className="image2-container Cover-Image-Lapping-Section">
              <img
                src={require("../assests/img/path 2 study logo 8.png")}
                alt="Image 1"
                className=" image2 img-fluid"
              />

              <img
                src={require("../assests/img/blueimg.png")}
                className="image2"
              />
              <img
                src={require("../assests/img/ab2.png")}
                alt="Image 2"
                className="image2 "
              />
              {/* <img src={require("../assests/img/plogo.png")} alt="" className="img-fluid col-2 image2" /> */}

              <div className="border border-1 p-md-4 pt-md-3  rounded-4 ps-lg-4 pe-lg-4  pt-lg-3 pb-lg-3  bg-white  image2">
                <h3 className="text-primary mt-lg-3 ">OUR STORY</h3>
                <p style={{ textAlign: "justify" }} className="">
                  At Path2Study, we understand that education is the key to
                  unlocking a world of opportunities. Our mission is to empower
                  students by providing access to financial resources that makes
                  their educational dreams a reality. We are committed to
                  facilitating seamless solutions for students across the globe.
                  Accessibility to education is our commitment. Path2Study works
                  tirelessly to break down financial barriers, providing
                  students with the means to pursue their educational goals
                  regardless of their background or circumstances.Navigating
                  the complexities of student loans can be overwhelming. We are
                  here to guide you through every step of the process, offering
                  personalized assistance and addressing any concerns you may
                  have.
                </p>
              </div>
            </div>

            <div className="hidden-card-mobile">
              <div className="mt-4 ourstory-container">
                <div className="text-center col-12 px-4 ourstory">
                  <img
                    src={require("../assests/img/blueimg.png")}
                    alt=""
                    className="w-100 img-fluid m-0 p-0"
                  />

                  {/* border col-6 border-1 rounded-4 p-4 bg-white image */}
                </div>
                <div className="border border-1 p-3 pt-0 rounded-4 bg-white m-0 p-0 ourstory">
                  <h2 className="text-primary mt-4">OUR STORY</h2>

                  <p className="mt-2 ">
                    At Path2Study , we're on a mission to provide accessible
                    higher education to students. Our platform simplifies
                    scholarships and loans, offering transparency, trust, and
                    dedicated support from a professional team. We've designed a
                    user-friendly experience, so you can concentrate on your
                    studies while we manage the process. Your dreams are our
                    priority, and we're committed to your success. Join us on
                    the journey to a brighter future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* -----------------Countries-Cover-Section------------ */}

        <div className="container mt-2 mb-2 pt-md-5">
          <div className="">
            <div className="d-flex  countries-cover-section ">
              <div className="col-md-4 col-4 mt-3">
                <span className="text-center text-primary">100+</span>
                <p className="text-secondary text-center ">
                  SCHOLARSHIP OPPORTUNITIES
                </p>
              </div>
              <div className="vr"></div>
              <div className="col-md-4 col-4 mt-3">
                <span className="text-center text-primary">$1M+</span>
                <p className="text-secondary text-center">SCHOLARSHIP AMOUNT</p>
              </div>
              <div className="vr"></div>
              <div className="col-md-4 col-4 mt-3">
                <span className="text-center text-primary">1 : 1</span>
                <p className="text-secondary text-center">
                DEDICATED SUPPORT
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------Our-Values------------------------ */}

        <div className="container mt-lg-5 ">
          <h2 className="mb-4 text-primary">OUR VALUES</h2>
          <div className="d-flex flex-wrap">
            <div className="col-lg-4 col-12 rounded-top card-1-container ">
              <img
                src={require("../assests/img/reactangle-blueimg.png")}
                alt=""
                className="img-fluid mt-lg-1 mt-md-3   inclusivity-blue-img "
              />
              <div className="card inclusivity-card border rounded-5 h-100">
                <img
                  class="card-img-top rounded-top-5"
                  src={require("../assests/img/ab12.png")}
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h3 class="card-title text-center">Expertise</h3>
                  <p class="card-text text-center p-3">
                    With years of experience in the education, scholarships and
                    student loan industry, we have the knowledge and expertise
                    to help you make informed decisions about your financial
                    future
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0 col-12  card-1-container">
              <img
                src={require("../assests/img/reactangle-blueimg.png")}
                alt=""
                className="img-fluid mt-lg-1 mt-md-3  inclusivity-blue-img"
              />
              <div class="card border inclusivity-card rounded-5 h-100">
                <img
                  class="card-img-top rounded-top-5"
                  src={require("../assests/img/ab11.png")}
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h3 class="card-title text-center">Affiliate Partnership</h3>
                  <p class="card-text text-center p-3">
                    We have established strong affiliation with reputable
                    financial institutions, ensuring that you have access to a
                    wide range of loan options with competitive rates.
                  </p>
                </div>
              </div>
            </div>

            <div className=" col-lg-4 mt-4 mt-lg-0 col-12 card-1-container">
              <img
                src={require("../assests/img/reactangle-blueimg.png")}
                alt=""
                className="img-fluid mt-lg-1 mt-md-3  inclusivity-blue-img"
              />
              <div className="card border rounded-5 h-100 inclusivity-card ">
                <img
                  className="card-img-top rounded-top-5"
                  src={require("../assests/img/ab3.png")}
                  alt="Card image cap"
                />
                <div class="card-body" >
                  <h3 class="card-title text-center">
                    {" "}
                    Path2study Scholarships
                  </h3>
                  <p  class="card-text text-center  p-3">
                    Path2Study offers up to $12,500 a year scholarship to
                    students across various areas. We believe in helping
                    students by the support, guidance and help they require.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------OUR VISION------------------------ */}

        {/* <div className="container">
          <div class="alert alert-primary" role="alert">
            At our core, we aim to make quality education accessible worldwide
            and students' learning journeys seamless on a global scale.
          </div>
        </div> */}
        <div className="container mt-md-5 mt-5 ">
          <h2 className="text-primary mt-4 mb-4">OUR VISION</h2>

          <div className="position-relative">
            <div>
              <div className="d-flex flex-wrap justify-content-center align-items-center ">
                <div className="border border-1 bg-primary-subtle p-5 rounded-5 col-lg-10 ">
                  <h1 className="text-center our-mission-heading-font text-primary ">
                    At our core, we aim to make quality education accessible
                    worldwide and students' learning journeys seamless on a
                    global scale.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------Our-Mission------------------------ */}
        <div className="container mt-md-5 mt-5 ">
          <h2 className="text-primary mt-4 mb-4">OUR MISSION</h2>

          <div className="position-relative">
            <div>
              <div className="double-qoutes">
                <img
                  src={require("../assests/img/pq51.png")}
                  className="img-fluid col-7 col-md-12"
                  alt=""
                />
                {/* <FaQuoteLeft className="text-primary  d-none d-sm-block d-md-none d-lg-none  d-lg-block d-xl-none"  size={30} /> */}
              </div>
              <div className="d-flex flex-wrap justify-content-center align-items-center">
                <div className="border border-1 bg-white p-5 rounded-5 col-11 col-lg-10">
                  <h1 className="text-center our-mission-heading-font ">
                    Our mission is to empower everyone with the resources for
                    achieving their academic goals to live a life of their
                    dreams.
                  </h1>
                </div>
              </div>
              <div className="text-end double-qoutes-sec">
                <img
                  src={require("../assests/img/pq52.png")}
                  className="col-7 col-md-12"
                />
                {/* <FaQuoteRight className="text-primary img-fluid" size={30} /> */}
              </div>
            </div>
          </div>
        </div>

        {/* ------------------We-Click-Different----------------- */}

        <div className="container ">
          <div className="image-container">
            <img
              src={require("../assests/img/blueimg.png")}
              alt="Image 1"
              className="image"
            />
            <img
              src={require("../assests/img/aboutPath2Study5.png")}
              alt="Image 2"
              className="image"
            />
            <div className="border col-6 border-1 rounded-4 p-4 bg-white image">
              <h3 className="text-primary">WE CLICK DIFFERENT</h3>
              <p style={{ textAlign: "justify" }} className="mt-3">
                We are your dedicated partners in the pursuit of education. With
                a team of seasoned professionals passionate about making a
                positive impact, we strive to go beyond conventional lending
                processes. Our innovative approach, coupled with cutting edge
                technology, ensures that students have access to the best loan
                options tailored to their unique needs. Having free access to
                various scholarships will ease the burden of students and they
                will experience financial freedom. At Path2Study, we believe in
                the transformative power of education. By partnering with us,
                you become part of a community dedicated to making education
                accessible to everyone. Together, let’s shape a future where
                every student can pursue their dreams without the burden of
                financial constraints.
              </p>
            </div>
          </div>

          <div className="hidden-card-mobile">
            <div className="mt-5 ourstory-container2  ">
              <div className="text-center col-11 px-4  ourstory2">
                <img
                  src={require("../assests/img/blueimg3.png")}
                  alt=""
                  className="ourstoryblueimg"
                />
              </div>
              <div className="border bg-white border-1 rounded-5 p-4 ourstory2">
                <h2 className="text-primary">WE CLICK DIFFERENT</h2>
                <p style={{ textAlign: "justify" }} className="mt-3 ">
                  We are your dedicated partners in the pursuit of education.
                  With a team of seasoned professionals passionate about making
                  a positive impact, we strive to go beyond conventional lending
                  processes. Our innovative approach, coupled with cutting edge
                  technology, ensures that students have access to the best loan
                  options tailored to their unique needs. Having free access to
                  various scholarships will ease the burden of students and they
                  will experience financial freedom. At Path2Study, we believe
                  in the transformative power of education. By partnering with
                  us, you become part of a community dedicated to making
                  education accessible to everyone. Together, let’s shape a
                  future where every student can pursue their dreams without the
                  burden of financial constraints.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
