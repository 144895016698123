import "../style/loansAccordian.css";
import React, { useState } from "react";
import loansAccordian from "../assests/img/loansbgnew.png";
const ContaintInAccordian = [
  {
    Title: "Eligibility Criteria",
    points: [
      "Students must secure admission to a recognized institution.",
      "At Path2Study, you can explore all options at no cost. We offer information about lenders who offer student loans with or without a cosigner.",
    ],
  },

  {
    Title: "Types of Student Loans",
    points: [
      "Federal Loans: These loans have fixed interest rates and various repayment plans.",
      " Private Loans: These loans may have variable interest rates and require a credit check.",
    ],
  },
  {
    Title: "Application Process",
    points: [
      "Complete the Free Application for Federal Student Aid (FAFSA) for federal loans.",
      "Private lenders have their own application processes, including credit checks and documentation.",
    ],
  },
  {
    Title: "Loan Limits",
    points: [
      "Federal loans have annual and aggregate limits, while private loans may vary based on the lender.",
    ],
  },
  {
    Title: "Interest Rates",
    points: [
      "Federal loans often have lower fixed interest rates compared to private loans.",
      "Private loan interest rates may be influenced by the borrower's creditworthiness.",
    ],
  },
  {
    Title: "Repayment Plans",
    points: [
      "Federal loans offer flexible repayment plans, including income-driven options.",
      "Private loans may have fewer repayment plan choices, so it's essential to understand the terms.",
    ],
  },
  {
    Title: "Grace Period",
    points: [
      "Federal loans typically have a grace period after graduation before repayment begins.",
      "Private loans may or may not have a grace period, depending on the lender.",
    ],
  },
  {
    Title: "Loan Forgiveness and Discharge",
    points: [
      "Federal loans may qualify for forgiveness programs in certain situations.",
      "Private loans generally do not offer forgiveness options, but some lenders may provide discharge in specific cases.",
    ],
  },
  {
    Title: "Financial Literacy",
    points: [
      "Understand the terms of your loans, including interest accrual and repayment obligations.",
    ],
  },
];
const Accordian = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border border-primary rounded-3 p-2 mx-4 my-2 ">
      <div className="">
        <div
          className="d-flex justify-content-between"
          onClick={handleDropdown}
        >
          <div className="px-2 ">
            <h4 className="text-primary pt-2 ">{item.Title}</h4>
          </div>
          <div
            style={{ cursor: "pointer", marginBottom: "0px" }}
            className="pt-3"
          >
            <i
              className={`bi bi-caret-${
                isOpen ? "up" : "down"
              }-fill text-primary`}
            ></i>
          </div>
        </div>
        {isOpen && (
          <div className="px-3">
            <div style={{ listStyle: "none", padding: 0 }}>
              {item.points.map((point, index) => (
                <ul key={index} style={{ marginBottom: "5px" }}>
                  <li>{point}</li>
                </ul>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Loan_AccordianUSA = () => {
  return (
    <div className="container my-2">
      <div
        className="text-sm-start ms-0 ps-0 z-10"
        style={{
          height: "100%",
          width: "100%",
          backgroundImage: "url(" + loansAccordian + ")",
          backgroundColor: "lightgray",
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h1 className="text-primary p-4">
          UNLOCKING FINANCIAL AID FOR YOUR EDUCATION
        </h1>

        {/* <Accordian ContaintInAccordian={ContaintInAccordian} /> */}

        {ContaintInAccordian.map((item,i) => (
          <Accordian item={item} key={i}/>
        ))}
      </div>
    </div>
    // <div className="loan-background-container ">
    //   <h1 className="text-primary">
    //     Unlocking Financial Aid for Your Education in the USA
    //   </h1>

    //   {/* <Accordian ContaintInAccordian={ContaintInAccordian} /> */}
    //   {ContaintInAccordian.map((item) => (
    //     <Accordian item={item} />
    //   ))}
    // </div>
  );
};

export default Loan_AccordianUSA;

{
  /* {item.map(({ Title }) => (
            <React.Fragment key={Title}>
              <h3 className="text-primary">{Title}</h3>
              <i
                className="bi bi-caret-down-fill text-primary"
                onClick={handleDropdown}
              ></i>
            </React.Fragment>
          ))}
        </div>
        <div>
          {item.map(({ points }) => (
            <ul key={points.join("-")}>
              {points.map((point, index) => (
                <li key={index}>
                  <span>{point}</span>
                </li>
              ))}
            </ul>
          ))} */
}
