import React from "react";
import "../style/HomeOverlap.css";
const UsChoise = () => {
  return (
    <div>
      <div className="container hide-br">
        <span className="fs-3 text">HERE’S WHY</span>{" "}
        <span className="fs-3 text-primary">USA</span>{" "}
        <span className="fs-3 text">SHOULD BE</span>{" "}
        <span className="fs-3 text-primary hide-br">
          YOUR <br /> IDEAL CHOICE!
        </span>
        <div className="d-flex flex-wrap justify-content-around  align-items-center ">
            {/* align Item end */}
            <div className="col-lg-3 col-md-5 int-pad ">
              <img
                src={require("../assests/img/usleft1.png")}
                alt=""
                className="img-fluid my-4"
              />
            </div>
            <div className="col-lg-7 col-md-5 ending-pad int-pad">
              <div className="d-flex">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    // width="25"
                    // height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                  {/* Path2Study has 20+ years of US education system & students loan experience team to help you  */}
                  Internationally ranked universities with worldwide acknowledgment of academic quality
                </p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                Flexible education system for diverse needs
                </p>
              </div>

              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">A variety of courses and universities designed for students like
                you </p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                  {/* Personal tailored made guidance at no cost */}
                  Exceptional employability rates and abundant opportunities
                </p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                Vast internship and networking opportunities forging professional relationships for future career prospects
                </p>
              </div>
              <p></p>
            </div>
            <div className="col-lg-3 col-md-5 int-pad d-block d-md-none custome-height" >
              <img
                  src={require("../assests/img/uspoints.png")}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-7 col-md-5 starting-pad int-pad">
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                Pioneering in the world of technology with leading innovations and tech advancements
                </p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                  {/* 24*7 whatsapp support */}
                  Supportive amenities for international students with comprehensive services
                </p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                Vast opportunities for groundbreaking research with extensive avenues for innovative exploration
                </p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="col-11">
                Lively campus atmosphere with vibrant community fostering engagement and growth
                </p>
              </div>
              <div className="d-flex ">
                <div className="col-1 px-1 px-lg-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    //width="25"
                    //height="25"
                    viewBox="0 0 29 30"
                    fill="none"
                  >
                    <path
                      d="M26.3999 14.0982V15.2022C26.3984 17.7899 25.5605 20.3078 24.0111 22.3804C22.4617 24.453 20.2838 25.9692 17.8023 26.7029C15.3208 27.4366 12.6686 27.3485 10.2413 26.4517C7.81392 25.5549 5.74149 23.8975 4.33306 21.7267C2.92462 19.5558 2.25565 16.9879 2.42591 14.4058C2.59618 11.8237 3.59655 9.36577 5.27784 7.39867C6.95913 5.43156 9.23125 4.06064 11.7553 3.49036C14.2794 2.92007 16.9202 3.18099 19.2839 4.23418"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7998 14.0016L14.3998 17.6016L26.3998 5.60156"
                      stroke="#2167B1"
                      strokeWidth="2.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <p className="col-11">
                Cultural and recreational opportunities both on and off campus, enriching personal growth and exploration
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-5 int-pad d-none d-md-block">
              <img
                src={require("../assests/img/usright.png")}
                alt=""
                className="img-fluid"
              />
            </div>
            {/* <div className="col-lg-3 col-md-5 int-pad ">
            <img
              src="/static/media/process-3.3374a0cb46093ed9b56a.png"
              alt=""
              className="img-fluid"
            />
          </div> */}
            {/* <div className="col-lg-7 col-md-5 ending-pad int-pad">
            <h3 className="secondary-light-txt mb-3">Oh wait! There's more.</h3>
            <p>
              We continuously optimize your IT solutions to ensure that they are
              functioning at their best.
            </p>
            <p>
              Our team is dedicated to improving the performance and
              effectiveness of your solution to help you achieve your business
              objectives.
            </p>
          </div> */}
          </div>
      </div>
    </div>
  );
};

export default UsChoise;
