import React, { useEffect, useState } from "react";
import AdminSideBar from "./AdminSideBar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "../styles/Winner.css";
import { ToastContainer, toast } from "react-toastify";
import BreadCrumb from "./BreadCrumb";
import { FaCircleUser } from "react-icons/fa6";
import { MdLeaderboard } from "react-icons/md";
import { formatAmount } from "../../hooks/useFormattedAmount";
// import { API_BASE_URL } from "../../appConfig";
function Winner() {
  const [currency, setCurrency] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const Users = location.state.appliedUsers;

  const scholarshipId = location.state.scholarshipId;

  const toShow = location.state.winnerChosen;


  const totalApplicants = Users.length;

  const adminId = localStorage.getItem("adminId");
  let adminToken = localStorage.getItem("adminToken");

  const headers = {
    authorization: `bearer ${adminToken}`,
  };

  // const[chooseWinner,setChooseWinner]=useState();
  const [selectedUserId, setSelectedUserId] = useState();
  // const[selectedScholId,setselectedScholId]=useState();

  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleChooseWinner = (userId) => {
    const selectedUser = Users.find((user) => user._id === userId);
    setSelectedUser(selectedUser);
    setSelectedUserId(userId);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // const scholarshipstatus=location.state.status;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  useEffect(() => {
    const scholarshipstatus = location.state.status;
    if (scholarshipstatus === "live" || scholarshipstatus === "upcoming") {
      setIsButtonDisabled(true);
    }
  }, []);
  const scholarshipName = location.state.scholarshipName;
  const idForSchlorship = location.state._id;



  const amount = location.state.amount;

  // api for choosewinner
  const handleWinner = async (Users) => {

    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/chooseWinner/${adminId}`,
        {
          userId: selectedUserId,
          scholarshipId: scholarshipId,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        toast.success("Winner declared successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate("/admin/leader-board");
        }, 3000);

        // navigate("/admin/leaderboard");
      })
      .catch((error) => {
        console.log("error in post api", error);
      });
  };

  const [winnerData, setWinnerData] = useState();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/getWinner/${adminId}?scholarshipId=${idForSchlorship}`,
        { headers: headers }
      )
      .then((responce) => {
        setWinnerData(responce.data.winners[0]?.user?._id);
        setCurrency(responce.data.currency)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const winnerUserId = winnerData;

  const getUsersList = () => {
    
    window.open(
      `${process.env.REACT_APP_BASE_URL}/XLSheetAppliedUsers/${adminId}?scholarshipId=${scholarshipId}`,
      "_blank",
      "download"
    );
  };

  const updatedUsers = Users.map((item, i) => {
    return {
      ...item,
      // Add a new property to indicate if the row should have a special background
      isSelectedWinner: item._id === winnerData,
    };
  });

  // Find the index of the row that satisfies the condition
  const winnerIndex = updatedUsers.findIndex((item) => item.isSelectedWinner);

  // If a winner is found, move that row to the 0th index
  if (winnerIndex !== -1) {
    const winnerRow = updatedUsers.splice(winnerIndex, 1)[0];
    updatedUsers.unshift(winnerRow);
  }
  return (
    <div>
      <div className="d-flex">
        <div className="col-2">
          <AdminSideBar />
        </div>

       

        <div className="col-10  me-2 w_result py-3 px-3">
          <div className="d-flex">
            <BreadCrumb className="" />
            <FaCircleUser size={25} className="mt-3  me-2" />
            <div className="mt-3 text-muted pe-4 cursor-pointer p-1">
              {localStorage.getItem("adminName")}
            </div>
          </div>
          <hr className="my-1" />

          <h4 className="mt-3 mx-3">Result Management</h4>
          <div
            style={{ backgroundColor: "#FAFCFF" }}
            className="border-1 border-dark mt-4 mx-3"
          >
            <div className="col-10 bg_appli w-100 rounded-top-4 p-2 me-2">
              <div className="d-flex justify-content-between flex-wrap ">
                <div>
                  <h6 className="mt-2 mx-2">
                    Total Applicants : {totalApplicants}
                  </h6>
                  <h5 className="mt-2 mx-2">
                    Scholarship Name : {scholarshipName}
                  </h5>
                  <h5 className="mt-2 mx-2">{currency ? currency : "$"}{formatAmount(amount)}</h5>
                </div>
                <div className="  mt-3">
                  {/* <h5 className="p-2 fs-3 me-5 text-center">${amount}</h5> */}
                  <div className="d-flex">
                    
                  </div>

                  {/* {toShow ? ( */}
                  <div className="mt-2 px-3">
                    {" "}
                    <button
                      type="button"
                      class="btn btn-secondary cursor-pointer"
                      onClick={() => getUsersList()}
                    >
                      Download Applicants List
                    </button>
                  </div>
                  {/* ) : null} */}
                </div>
              </div>
            </div>

            <div className="bg-white pt-3 pb-3  rounded-bottom-4">
              <table className="table  ">
                <thead className="">
                  <tr className="text-center ">
                    {/* <th className="text-secondary">Select</th> */}
                    <td className="text-muted">S.No</td>
                    <td className="text-muted">User Id</td>
                    <td className="text-muted">User Name</td>
                    <td className="text-muted">Registered Email</td>
                    <td className="text-muted">GPA</td>
                    {/* <td className="text-muted">Family Income</td> */}
                    <td className="text-muted">Applied Date</td>

                    {toShow ? null : !isButtonDisabled ? (
                      <td className="text-muted">Action</td>
                    ) : null}
                  </tr>
                </thead>

                <tbody>
                  {updatedUsers?.map((item, i) => {
                    // const rowBackground=item._id==
                    return (
                      <tr className="text-center" key={i}>
                        {/* <td>
                      <input type="radio" name="flexRadioDefault" />
                    </td> */}
                        <td
                          style={
                            item._id === winnerData
                              ? { backgroundColor: "#E5ECF1", color: "#1459ba" }
                              : null
                          }

                          // className={` text-center ${
                          //   item._id === winnerData ? "bg-primary" : ""
                          // }`}
                        >
                          {i + 1}
                        </td>
                        <td
                          style={
                            item._id === winnerData
                              ? { backgroundColor: "#E5ECF1", color: "#1459ba" }
                              : null
                          }

                          // className={` text-center ${
                          //   item._id === winnerData ? "bg-primary" : ""
                          // }`}
                        >
                          {item._id}
                        </td>
                        <td
                          style={
                            item._id === winnerData
                              ? { backgroundColor: "#E5ECF1", color: "#1459ba" }
                              : null
                          }

                          // className={` text-center ${
                          //   item._id === winnerData ? "bg-primary" : ""
                          // }`}
                        >
                          {item.name}
                        </td>
                        <td
                          style={
                            item._id === winnerData
                              ? { backgroundColor: "#E5ECF1", color: "#1459ba" }
                              : null
                          }

                          // className={` text-center ${
                          //   item._id === winnerData ? "bg-primary" : ""
                          // }`}
                        >
                          {item.email}
                        </td>
                        <td
                          style={
                            item._id === winnerData
                              ? { backgroundColor: "#E5ECF1", color: "#1459ba" }
                              : null
                          }

                          // className={` text-center ${
                          //   item._id === winnerData ? "bg-primary" : ""
                          // }`}
                        >
                          {item.currentGpa}
                        </td>
                        {/* <td
                          style={item._id === winnerData ? { backgroundColor: "#E5ECF1",color:"#1459ba" } : null}

                          // className={` text-center ${
                          //   item._id === winnerData ? "bg-primary" : ""
                          // }`}
                        >
                          {item.annualFamilyIncome}
                        </td> */}
                        <td
                          style={
                            item._id === winnerData
                              ? { backgroundColor: "#E5ECF1", color: "#1459ba" }
                              : null
                          }

                          // className={` text-center ${
                          //   item._id === winnerData ? "bg-primary" : ""
                          // }`}
                        >
                          {new Date(item?.updatedAt)?.toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </td>
                        {toShow ? null : (
                          <td>
                            <div>
                              {!isButtonDisabled ? (
                                <button
                                  className="px-4 rounded-3 border border-secondary"
                                  id="hoverwin"
                                  onClick={() =>
                                    handleChooseWinner(item._id, i)
                                  }
                                  // disabled={isScholarshipActive()}
                                  // { scholarshipstatus ==='live' || 'upcoming' ? disabled : '' }
                                  // disabled={isButtonDisabled}
                                >
                                  Choose Winner
                                </button>
                              ) : null}
                            </div>
                          </td>
                        )}

                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <ToastContainer />
          </div>
        </div>

        {/* Modal for Choose Winner */}
        {/* <Modal show={showModal} onHide={handleCloseModal}>
       
          <Modal.Title className="mt-3 mx-4">Confirm Winner</Modal.Title>
          
          {Users.map((item,i)=>{
            return (
              <>
                <p className="px-2 mx-3 mt-2"key={i}>
                  Are you sure you want to declare {item.name} as a winner of {scholarshipName}. {item.name} will be reflected as a
                  scholarship winner in winners section.
                </p>
              </>
            );
          })}
          
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleWinner}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal> */}

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Title className="mt-3 mx-4">Confirm Winner</Modal.Title>
          {selectedUser && (
            <>
              <p className="px-2 mx-3 mt-2">
                Are you sure you want to declare {selectedUser.name} as a winner
                of {scholarshipName}. {selectedUser.name} will be reflected as a
                scholarship winner in the winners section.
              </p>
            </>
          )}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleWinner}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Winner;
