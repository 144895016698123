//  new version of routing
import { ModalProvider } from "./contexts/ModalContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./components/Home";
import Contact from "./components/Contact";
import About from "./components/About";
import PathStudyScholarship from "./components/PathStudyScholarship";
import ScholarshipInformation from "./components/ScholarshipInformation";
import OthreScholarshipPage from "./components/OthreScholarshipPage";
import AdminLogin from "./adminPortal/components/AdminLogin";
import AdminLayout from "./adminPortal/components/AdminLayout";
import UserLayout from "./UserLayout";
import DashBoard from "./adminPortal/components/DashBoard";
import ScholarshipManagment from "./adminPortal/components/ScholarshipManagment";
import LoanManagment from "./adminPortal/components/LoanManagment";
import LeaderBoard from "./adminPortal/components/LeaderBoard";
import UserManagment from "./adminPortal/components/UserManagment";
import NoPageFound from "./adminPortal/components/NoPageFound";
import UserTrackScholarship from "./components/User/UserTrackScholarship";
import ProfileLayout from "./components/User/ProfileLayout";
import { UserProvider } from "./contexts/UserContext";
import AdminProfile from "./adminPortal/components/AdminProfile";
import AddScholarship from "./adminPortal/components/AddScholarship";
import EditSchlorship from "./adminPortal/components/EditSchlorship";
import AddBank from "./adminPortal/components/AddBank";
import AboutBankList from "./adminPortal/components/AboutBankList";
import { CountryProvider } from "./contexts/CountryContext";
import EditBank from "./adminPortal/components/EditBank";
import Winner from "./adminPortal/components/Winner";
import BlogsManagement from "./adminPortal/components/BlogsManagement";
import Addblogs from "./adminPortal/components/Addblogs";
import Editblogs from "./adminPortal/components/Editblogs";
import MostPopular from "./components/MostPopular";
import Blogs from "./components/Blog";
import ResourcesFaq from "./components/ResourcesFaq";
import LoansPageV1 from "./components/LoansPage_v1";
import "./custom.scss";
import "./App.css";
import NewProfile from "./components/User/NewProfile";
import WinnersPage from "./adminPortal/components/WinnersPage";
import SubscribeUser from "./adminPortal/components/SubscribeUser";

export const AppRouter = () => (
  <div className="student-side">
    <CountryProvider>
      <UserProvider>
        <ModalProvider>
          <Router>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<UserLayout />}>
                <Route index element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/loan" element={<LoansPageV1 />} />
                <Route path="/about" element={<About />} />
                {/* <Route path="/contact" element={<Contact />} /> */}
                <Route
                  path="/contact"
                  element={
                    
                      <Contact />
                    
                  }
                />
                <Route
                  path="/pathstudyscholarship"
                  element={<PathStudyScholarship />}
                />
                <Route
                  // path="/scholarshipinfo"
                  path="/scholarshipinfo/:id"
                  element={<ScholarshipInformation />}
                />
                <Route
                  path="/otherscholarship"
                  element={<OthreScholarshipPage />}
                />
                <Route
                  path="/blogs/:title"
                  element={<MostPopular />}
                />
                
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/faq" element={<ResourcesFaq />} />

               

                <Route path="/user" element={<ProfileLayout />}>
                  <Route path="/user/userprofile" element={<NewProfile />} />
                 
                  <Route
                    path="/user/trackscholarship"
                    element={<UserTrackScholarship />}
                  />

                </Route>

               
                {/* </Route> */}
              </Route>
              {/* Admin routes */}
              <Route path="/admin" element={<AdminLayout />}>
                {/* <Route index /> */}
                <Route index element={<DashBoard />} />
                {/* <Route path="/admin/" element={<AdminLogin />} /> */}
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route path="/admin/dashboard" element={<DashBoard />} />
                <Route
                  path="/admin/scholarship-management"
                  element={<ScholarshipManagment />}
                />
                <Route
                  path="/admin/scholarship-management/add-scholarship"
                  element={<AddScholarship />}
                />
                <Route
                  path="/admin/scholarship-management/edit-scholarship"
                  element={<EditSchlorship />}
                />
                <Route
                  path="/admin/loan-management"
                  element={<LoanManagment />}
                />
                <Route path="/admin/leader-board" element={<LeaderBoard />} />

                <Route
                  path="/admin/leader-board/result-management"
                  element={<Winner />}
                />

                <Route
                  path="/admin/user-management"
                  element={<UserManagment />}
                ></Route>
                <Route
                  path="/admin/user-management/user-profile"
                  element={<AdminProfile />}
                />
                {/* Aishwarya */}
                <Route
                  path="/admin/loan-management"
                  element={<LoanManagment />}
                />
                <Route
                  path="/admin/loan-management/add-lender"
                  element={<AddBank />}
                />
                <Route
                  path="/admin/loan-management/about-lender"
                  element={<AboutBankList />}
                />
                <Route
                  path="/admin/loan-management/edit-lender"
                  element={<EditBank />}
                />
                <Route
                  path="/admin/subscribed-users"
                  element={<SubscribeUser />}
                />
              </Route>

              <Route
                path="/admin/blogs-management"
                element={<BlogsManagement />}
              />
              <Route
                path="/admin/blogs-management/add-blog"
                element={<Addblogs />}
              />
              <Route
                path="/admin/blogs-management/edit-blog"
                element={<Editblogs />}
              />
              <Route path="/admin/declared-winners" element={<WinnersPage />} />

              {/* Fallback for 404 */}
              <Route path="*" element={<NoPageFound />} />
            </Routes>
          </Router>
          {/* </QuestionnaireModalProvider> */}
        </ModalProvider>
      </UserProvider>
      {/* </GoogleOAuthProvider> */}
    </CountryProvider>
  </div>
);


