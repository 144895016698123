import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { ModalContext } from "../contexts/ModalContext";
import axios from "axios";
import { API_BASE_URL } from "../appConfig";

const NotShowData = () => {
  const path = window.location.pathname;
  // console.log(path);
  const { openModal } = useContext(ModalContext);
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    gender: "",
    ethnicity: "",
    culturalIdentity: "",
    currentEducationalLevel: "",
    highschoolUniversity: "",
    citizenshipStatus: "",
    tentativeHighschoolGraduationDate: "",
    MajorFieldOfstudy: "",
    modeOfStudy: "",
    currentGpa: "",
    sourcesOfFinancial: "",
    existingFinancialAid: "",
    annualFamilyIncome: "",
    involvement: "",
    recognitions: "",
    leadership: "",
    academicRecognitions: "",
    academicPrograms: "",
    academicAchievement: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const userToken = localStorage.getItem("userToken");
      const updatedToken = `Bearer ${userToken} `;
      // console.log('updatedToken',updatedToken)
      const authAxios = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        // baseURL: API_BASE_URL,
        headers: {
          authorization: updatedToken,
        },
      });
      try {
        const userId = localStorage.getItem("userId");
        const response = await authAxios.get(`/getUser/${userId}`);
        // const response = await authAxios.get(`/getUser/${userId}`);
        // setData(response.data); // old working
        setFormData(response.data);
        // console.log("set the userdata in effect", response.data);

        // console.log('usercontext data get by api ',response.data);
      } catch (error) {
        console.log(error.response);
      }
    };
    fetchUserData();
  }, []);
  return (
    <div className="container">
      <div
        className="d-flex align-items-center justify-content-center"
        // style={{ height: "100vh" }}
      >
        <div className="text-center">
          {path === "/loan" ? (
            <>
              <div className="d-md-flex justify-content-center   ">
                <div className=" text-center col-md-8 m-auto  px-2  ">
                  <div className="border py-5 rounded-4 ">
                    <span className="fs-3">
                      {" "}
                      <span className=" text-center ">
                        Kindly <span className="text-primary">login</span> to
                        access personalized loan options available for you!
                      </span>
                      <br />
                      <button
                        className="btn-primary btn px-md-5 mt-3  px-5"
                        onClick={openModal}
                      >
                        {" "}
                        Log In
                      </button>
                    </span>
                  </div>
                </div>

                <div className="position-sticky " >
                  <div className="d-none d-md-flex align-items-center " style={{ width: "25rem" }}>
                    <div className=" card bg-light rounded-3 mb-5 border border-0">
                      <div className="card-body ">
                        <span className="fs-2 text">
                          OUR{" "}
                          <span className="text-primary fw-semibold">
                            NUMBERS
                          </span>{" "}
                          ARE INTERESTING
                        </span>
                        <p className="card-text">
                          Explore array of loan options tailored <br /> to fund
                          your educational journey <br />
                          effortlessly.
                        </p>
                      </div>
                      <div className="row align-items-center justify-content-center">
                        <div className="col text-center">
                          <span className="fs-1 text text-primary">60+</span>
                          <p>
                            LOAN
                            <br />
                            OPTIONS
                          </p>
                        </div>

                        <div className="col text-center">
                          <span className="fs-1 text text-primary">20+</span>
                          <p>
                            LENDERS
                            <br />
                            COVERED
                          </p>
                        </div>

                        <div className="col text-center">
                          <span className="fs-1 text text-primary">24+</span>
                          <p>
                            COUNTRIES
                            <br />
                            COVERED
                          </p>
                        </div>
                      </div>

                      <img
                        src={require("../assests/img/loanAdd.png")}
                        className="img-fluid mt-0 ms-3"
                        alt="..."
                      />
                      <div className="m-3 ">
                        <span className="fs-2 text">
                          IT’S TIME FOR YOU TO WIN UPTO{" "}
                          <span className="text-primary fw-semibold">
                            $12,500
                          </span>
                        </span>
                        <br />
                        <span>
                          Get a chance to win upto $12,500 scholarship from
                          Path2Study. Yes, You read it <br /> right!
                        </span>
                        <Link
                          to="/pathstudyscholarship"
                          className="btn btn-outline-secondary w-100 mt-2"
                          onClick={handleClick}
                        >
                          Apply Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="container  "
                style={{ marginTop: "3rem", marginBottom: "3rem" }}
              >
                <div className=" text-center px-2 ">
                  <div className="border py-2 px-3 rounded-4">
                    <p className="fs-3">
                      {" "}
                      <p className=" mt-3 text-center px-3">
                        Kindly <span className="text-primary">login</span> to
                        access personalized scholarships options available for
                        you!
                      </p>
                      <button
                        className="btn-primary btn px-md-5 px-5"
                        onClick={openModal}
                      >
                        {" "}
                        Log In
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* <p className="lead">Please login to your account first to view the loan options available for you!</p> */}
          {/* <Link to="/" className="btn btn-primary">
          Go Home
        </Link> */}
        </div>

      </div>
    </div>
  );
};

export default NotShowData;
