import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
const CountryContext = createContext();
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const CountryProvider = ({ children }) => {
  const [countryByGoogle, setCountryByGoogle] = useState(""); // actual country location by ip
  const [selectedCountry, setSelectedCountry] = useState(countryByGoogle ? countryByGoogle : "usa"); // static location by btn
  // countryByGoogle ? countryByGoogle : "usa"///// this is for usa in dropdown first on refresh
  // countryByGoogle ///// this is for actual country in dropdown first on refresh
  useEffect(() => {

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const { latitude, longitude } = position.coords;
            
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
              // `https://maps.googleapis.com/maps/api/geocode/json?latlng=41.8781,-87.6298&&key=${apiKey}` // for usa chicago

            );
            // console.log("location trase",response);
            const country = response.data.results[0].address_components.find(
              (component) => component.types.includes("country")
            );
            setCountryByGoogle(country.long_name);
            if (country.long_name === "United States") {
              setSelectedCountry("usa");
            }
            if (country.long_name === "India") {
              setSelectedCountry('india')
            }
            
          } catch (error) {
            console.error("Failed to fetch data:", error);
          }
        },
        (error) => {
          // user close the location pop CountryContext.js:57 Error getting user location: User denied Geolocation
          console.error("Error getting user location:", error.message);
          // setCountryByGoogle("United States")
        }
      );  
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  }, []);

  return (
    <CountryContext.Provider
      value={{
        selectedCountry,
        setSelectedCountry,
        countryByGoogle,
        setCountryByGoogle,
      }}
    >
      {children}
    </CountryContext.Provider>
  );
};

export { CountryProvider, CountryContext };

