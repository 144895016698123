import React from "react";
import { Link } from "react-router-dom";
import "../style/chancetowin.css";


const ChanceToWin = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="container">
      <div className="card mb-3 border border-white">
        <div className="row ">
          <div className="col-md-5">
            <img
              src={require("../assests/img/win.png")}
              className="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div className="col-md-7 d-flex align-items-center justify-content-center">
            <div className="card-body ms-md-5 hide-br">
              {/* GET A CHANCE TO WIN UPTO $12000 SCHOLARSHIP FROM PATH2STUDY */}
              <h5 className="card-title text fs-1">
                {" "}
                GET A CHANCE TO{" "}
                <span className="text-primary fw-semibold">
                  {" "}
                  WIN <br />
                  UPTO $12,500{" "}
                </span>{" "}
                <br />
                SCHOLARSHIP FROM{" "}
                <span className="text-primary fw-semibold">
                  PATH2STUDY
                </span>{" "}
              </h5>
              <Link to="/pathstudyscholarship" className="text-decoration-none">
                <button
                  type="button"
                  className="btn btn-primary mt-4 d-md-block button-custom "
                  onClick={handleClick}
                >
                  APPLY NOW
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChanceToWin;
