import React from "react";
import "../style/statastick.css";

const Statastick = () => {
  return (
    <div className="container">
      <div className="container my-2">
        <div className="home-countries-cover-section span">
          <div className="d-flex flex-wrap">
            <div className="col-md-4 col-12 mt-3">
              <span className="text-center text-primary">100+</span>
              <p className="text-secondary text-center">
                SCHOLARSHIP OPPORTUNITIES
              </p>
            </div>
            {/* <div className="vr"></div> */}
            <div className="col-md-4 col-12 mt-3">
              <span className="text-center text-primary">$1M+</span>
              <p className="text-secondary text-center">SCHOLARSHIP AMOUNT</p>
            </div>
            {/* <div className="vr"></div> */}
            <div className="col-md-4 col-12 mt-3">
              {/* <span className="text-center text-primary">1 : 1</span>fw-semibold */}
              <span className="text-center text-primary">1 <span className="text-center mx-2 "> : </span> 1</span>

              <p className="text-secondary text-center">
              DEDICATED SUPPORT
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statastick;
