import React, { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

export default function BreadCrumbTwo() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const handleInternalNavigation = () => {
    // Check if there is a previous page in the browser's history
    if (window.history.length > 1) {
      window.history.back();
    } else {
      // Handle the case when there is no previous page
      // window.location.href = "/default-page"; // Redirect to a default page
    }
  };

  const isActivePageEditLender = pathnames.includes("edit-lender");

  // const modified=pathnames.re

  const indexOfAboutLender = pathnames.indexOf("about-lender");


  const [displayName, setDisplayName] = useState("");
  return (
    <div className="container-fluid admin-background-color">
      <Breadcrumb className="py-3 admin-breadcrumb">
        {/* {
         console.log("show", pathnames[2].splice(0,0))
        } */}
        {pathnames.length > 0 &&
          pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;

            // if (isActivePageEditLender && name === "about-lender") {
            const displayName = name.replace(/-/g, " ");
            // }

            return (
              <Breadcrumb.Item
                key={routeTo}
                active={isLast || routeTo === "/admin"}
                className={`${isLast ? "" : "text-capitalize"} ${
                  isLast ? "admin-link-active text-capitalize" : ""
                }`}
              >
                {index > 0 ? (
                  isLast ? (
                    // Render the current page without a link
                    displayName
                  ) : (
                    <React.Fragment>
                      {console.log("displayName", displayName)}
                      {isActivePageEditLender && name === "about-lender" ? (
                        // Disable link to "about-lender" when on "edit-lender"
                        <span className="admin-link-disabled">
                          {/* {displayName} */}
                        </span>
                      ) : (
                        <Link to={routeTo} className="admin-link-color">
                          {displayName}
                        </Link>
                      )}
                    </React.Fragment>
                  )
                ) : (
                  displayName
                )}
              </Breadcrumb.Item>
            );
          })}
      </Breadcrumb>
    </div>
  );
}
