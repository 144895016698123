import React from "react";
import { Link } from "react-router-dom";
import "../style/path2studyHighlight.css";

const Path2studyHighlights = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="container-fluid my-md-3 ">
      <div className="mb-md-2 forMarginInMobile">
        <span className="text-primary fs-2">PATH2STUDY </span>{" "}
        <span className="fs-2 text">HIGHLIGHTS</span>
      </div>

      <div className="row row-cols-1 row-cols-md-2 g-4">
        <div className="col">
          <div className="card text-white p2shighlight-card text-center w-75 mx-auto ">
            <img
              src={require("../assests/img/scholback.png")}
              className="card-img rounded-5"
              alt="..."
            />
            <div className="card-img-overlay d-flex flex-column justify-content-center smallbutton-font">
              <p className="card-text">
                Instant access to scholarship <br />
                opportunities tailored to your profile <br /> and aspiration.
              </p>
              <span className="card-text">
                <Link to="/pathstudyscholarship">
                  <button
                    onClick={handleClick}
                    type="button"
                    className=" btn btn-light mt-md-2 smallbutton "
                  >
                    Explore Scholarship
                  </button>
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card text-white p2shighlight-card text-center w-75 mx-auto">
            <img
              src={require("../assests/img/loanback.png")}
              className="card-img rounded-5"
              alt="..."
            />
            <div className="card-img-overlay d-flex flex-column justify-content-center smallbutton-font ">
              <p className="card-text ">
                Broaden your educational horizon <br /> with numerous funding
                <br />
                opportunities which is right for you.
              </p>
              <span className="card-text">
                <Link to="/loan">
                  <button
                    onClick={handleClick}
                    type="button"
                    className="btn btn-light mt-md-2 sm-mt-0 smallbutton "
                  >
                    Explore All Loans
                  </button>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Path2studyHighlights;
