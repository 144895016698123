// Note :-- ensure the db hase one entry for booked slote to reflect properly dropdown

import React, { useEffect, useRef, useState } from "react";
import "../style/ContactGoogleMeet.css";
import { TbArrowBadgeRightFilled } from "react-icons/tb";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "libphonenumber-js";
import Modal from "react-bootstrap/Modal";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";

export default function CreateGoogleMeet_v1({ fromBlur }) {
  const [user, setUser] = useState({
    name: "",
    email: "",
    contactNumber: "",
    message: "",
    selectedDate: "",
    selectedTime: "",
  });

  // Load till event created
  const [disableButton, setDisableButton] = useState(false);

  // Required fields
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false); //  for loader


  const [phoneNumber, setPhoneNumber] = useState(""); // for contact number with library
  const [showPopupConfirm, setShowPopupConfirm] = useState(false); // for final modal when meeting booked
  const [selectedDay, setSelectedDay] = useState("");

  // for modal handlers
  const handleClose = () => {
    setShowPopupConfirm(false);
    // setDirect(true);
    // resetDropdown();
    window.location.reload();
  };
  const closeModal = () => {
    setShowPopupConfirm(false);
    // resetDropdown();
    window.location.reload();
  };

  //get User
  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      const userToken = localStorage.getItem("userToken");
      const updatedToken = `Bearer ${userToken} `;
      const authAxios = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          authorization: updatedToken,
        },
      });
      try {
        const userId = localStorage.getItem("userId");
        const response = await authAxios.get(`/getUser/${userId}`);
        // console.log("getuserdetails", response.data);
        const formattedDate = selectedDate?.toISOString().split("T")[0];
        setUser({
          name: response.data.name,
          email: response.data.email,
          contactNumber: response.data.contactNumber || "",
          message: "",
          selectedDate: formattedDate,
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    fetchUserData();
    
   
  }, []);

  const handleChangePhone = (value) => {
    // Check if the phone number is empty or undefined
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumber: "Please enter a valid contact number",
      }));
      setDisableButton(true);
    } else if (!isValidPhoneNumber(value)) {
      // console.log("isValidPhoneNumber(value)", isValidPhoneNumber(value));
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumber: "Please enter a valid contact number",
      }));
      setDisableButton(true);
    } else {
      // Reset errors and enable button if phone number is valid
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumber: "", // Clear error message
      }));
      setDisableButton(false);
      setUser({ ...user, contactNumber: value });
    }
  };

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    contactNumber: "",
    selectedDate: "",
    selectedTime: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate the input based on its name
    switch (name) {
      case "name":
        // Perform name validation
        if (value.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: "Name is required",
          }));
          setDisableButton(true);
        } else if (value.length < 3) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: "Name is atleast 3 character",
          }));
          setDisableButton(true);
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: "",
          }));
          setDisableButton(false);
        }
        break;
      case "email":
        // Perform email validation
        if (!/\S+@\S+\.\S+/.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Invalid email address",
          }));
          setDisableButton(true);
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "",
          }));

          setDisableButton(false);
        }
        break;
      // case "contactNumber":
      //   // Perform contact number validation
      //   if (!/^\d{10}$/.test(value)) {
      //     setErrors((prevErrors) => ({
      //       ...prevErrors,
      //       contactNumber: "Invalid contact number",
      //     }));
      //     setDisableButton(true);
      //   } else {
      //     setErrors((prevErrors) => ({
      //       ...prevErrors,
      //       contactNumber: "",
      //     }));
      //     setDisableButton(false);
      //   }
      //   break;

      default:
        break;
    }

    // Update the user state
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
    if (user.selectedTime !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedTime: "",
      }));
      // setDisableButton(true);
    }
  };

  // const handleDayChange = (event) => {
  //   // Set selected day for useeffect to dependancy array/dropdown
  //   setSelectedDay(event.target.value);
  //   // console.log("handleDayChange",event.target.value)
  //   // Update user with selected date
  //   setUser((prevUser) => ({
  //     ...prevUser,
  //     selectedDate: event.target.value,
  //   }));

  //   // Clear error for selected date
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     selectedDate: "",
  //   }));
  // };

  // needed for handle submit changes date format
  function convertToUTC(dateString) {
    // Parse the date string using moment.js
    const date = moment(dateString, "dddd MMMM Do YYYY h:mm:ss a");

    // Check if the date is valid
    if (!date.isValid()) {
      return "Invalid date";
    }

    // Convert the date to UTC format
    const utcString = date.utc().format();

    return utcString;
  }

 

  const [filteredSlotsMain, setFilteredSlotsMain] = useState([]);
  const [filteredDay, setFilteredDay] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState([]);
  // const [slotsByDay, setslotsByDay] = useState([]);
  // const [ActiveCard, setActiveCard] = useState(""); // border
  // const [ActiveCardTime, setActiveCardTime] = useState(""); // border
  // const [currentTime] = useState(moment().format("LT"));
  // const [calendarTime] = useState(moment().calendar());
  // console.log("currentTime", currentTime, "calendarTime", calendarTime);
  // console.log("filteredSlotsMain", filteredSlotsMain);
  useEffect(() => {
    setIsLoading(true);
    // Fetch available slots from the API
    axios
      .get(`${process.env.REACT_APP_BASE_URL}getAvailableSlots`)
      .then((response) => {
        setIsLoading(false);
        // New object/array in the required format
        // const availableSlotsForWeek = {};
        const dates = response.data.availableSlotsForWeek;
        // setAvailableSlots(dates);
        console.log("response.data.", response.data.availableSlotsForWeek);
        // setDatesInIst(dates.map((item)=> item.start))
        const dateConvertedtoLocal = dates
          .map((item) => item.start)
          .map((utcDate) => {
            const localDateTime = moment.utc(utcDate).local();
            const localday = localDateTime.format("dddd");
            const endDate = localDateTime
              .clone()
              .add(30, "minutes")
              .format("MMMM Do YYYY, h:mm:ss a");
            return {
              day: localday,
              startdate: localDateTime.format("MMMM Do YYYY, h:mm:ss a"),
              enddate: endDate,
              // originaldatebybackend: utcDate,
            };
          });

        // console.log("originalby backend", dates);
        console.log("dateConvertedtoLocal", dateConvertedtoLocal);

        const transformedData = Object.values(
          dateConvertedtoLocal.reduce((acc, { day, startdate, enddate }) => {
            const date = startdate.split(",")[0]; // Extract date without time
            if (!acc[date]) {
              acc[date] = { day, date, slots: [] };
            }
            acc[date].slots.push({
              starttime: startdate.split(",")[1].trim(),
              endtime: enddate.split(",")[1].trim(),
            });
            return acc;
          }, {})
        );
        console.log("mayuresh", transformedData);
        // Initialize an empty result array
        // const finaldata = transformArray(dateConvertedtoLocal);
        //        console.log("finaldata", finaldata);
        setFilteredSlotsMain(transformedData); // Set filtered slots
      })
      .catch((error) => {
        console.error("Error fetching available slots:", error);
      });
  }, []);


  // main working
  useEffect(() => {
    // Filter available slots based on selected day
    const filteredday = filteredSlotsMain.map((slot) => slot.day);
    setFilteredDay(filteredday);
  }, [selectedDay, filteredSlotsMain]);

  // main working
  useEffect(() => {
    // Filter available slots based on selected day
    const filteredSlotsnew = filteredSlotsMain.filter(
      (slot) => slot.day === selectedDay
    );
    const slotsByDaynew = filteredSlotsMain.filter(
      (slot) => slot.day === selectedDay
    )[0]?.slots;
    // const slotsByDay=filteredSlotsnew[0].slots
    // setslotsByDay(slotsByDaynew);
    // setFilteredSlots(filteredSlotsnew);
    // console.log("filteredSlots",filteredSlotsnew)
  }, [selectedDay, filteredSlotsMain]);

  // console.log("slotsByDay", slotsByDay);

  // const handleCardSelection = (slot) => {
  //   console.log("slot", slot);
  //   setActiveCard(slot.day); // border
  //   setSelectedDay(slot.day);
  //   setActiveCardTime("");
  //   setUser((prevUser) => ({
  //     ...prevUser,
  //     selectedDate: `${slot.day} ${slot.date}`,
  //     selectedTime: "",
  //   }));
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     selectedDate: "",
  //   }));
  // };

  // const handleCardSelectionTime = (timeSlot) => {
  //   // console.log("timeslot", timeSlot);
  //   setActiveCardTime(timeSlot.starttime); //
  //   // setSelectedCard(timeSlot.day); // Update selected card
  //   // setSelectedTime(timeSlot.starttime);
  //   setUser((prevUser) => ({
  //     ...prevUser,
  //     selectedTime: timeSlot.starttime,
  //   }));
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     selectedTime: "",
  //   }));
  // };

 

  // const [selectedDate, setSelectedDate] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [dataSlots, setDataSlots] = useState(null);
  // const [TimeStart, setTimeStart] = useState([]);
  // const [TimeEnd, setTimeEnd] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [indexOfDate, setIndexOfDate] = useState();
  // const [timeZone, settimeZone] = useState("");


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const formattedDate = selectedDate?.toISOString().split("T")[0]; // Format date as 'YYYY-MM-DD'
        // setSelectedDate(formattedDate)
        // setUser({
        //   ...user,
        //   selectedDate: formattedDate,
        // });
        // const response = await axios.get(
        //   `${process.env.REACT_APP_BASE_URL}getAvailableSlots/${formattedDate}`
        // );
        // setDataSlots(response.data.availableSlotsForWeek);
        // setTimeStart(response.data.availableSlotsForWeek.start);
        // setTimeEnd(response.data.availableSlotsForWeek.end);
        // console.log(TimeStart, TimeEnd);
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}getAvailableSlots/${formattedDate}`
          )
          .then((response) => {
            setIsLoading(false);
            // New object/array in the required format
            // const availableSlotsForWeek = {};
            const dates = response.data.availableSlotsForWeek;
            // setAvailableSlots(dates);
            // console.log(
            //   "response.data.availableSlotsForWeek",
            //   response.data.availableSlotsForWeek
            // );
            // setDatesInIst(dates.map((item)=> item.start))
            const dateConvertedtoLocal = dates
              .map((item) => item.start)
              .map((utcDate) => {
                const localDateTime = moment.utc(utcDate).local();
                const localday = localDateTime.format("dddd");
                const endDate = localDateTime
                  .clone()
                  .add(30, "minutes")
                  .format("YYYY-MM-DD, h:mm a");
                return {
                  day: localday,
                  startdate: localDateTime.format("YYYY-MM-DD h:mm a"),
                  enddate: endDate,
                  // originaldatebybackend: utcDate,
                };
              });

            // console.log("originalby backend", dates);
            // console.log("dateConvertedtoLocal", dateConvertedtoLocal);

            // const transformedData = Object.values(
            //   dateConvertedtoLocal.reduce(
            //     (acc, { day, startdate, enddate }) => {
            //       const date = startdate.split(",")[0]; // Extract date without time
            //       if (!acc[date]) {
            //         acc[date] = { day, date, slots: [] };
            //       }
            //       acc[date].slots.push({
            //         starttime: startdate.split(",")[1].trim(),
            //         endtime: enddate.split(",")[1].trim(),
            //       });
            //       return acc;
            //     },
            //     {}
            //   )
            // );
            // console.log("mayuresh", transformedData);
            // Initialize an empty result array
            // const finaldata = transformArray(dateConvertedtoLocal);
            //        console.log("finaldata", finaldata);
            // setFilteredSlotsMain(transformedData); // Set filtered slots

            // Filter function to get data for a specific date
            function filterByDate(data, targetDate) {
              return data.filter((item) =>
                item.startdate.startsWith(targetDate)
              );
            }

            // Date to filter by
            // const targetDate = "2024-05-27";

            // Get filtered data
            const filteredData = filterByDate(
              dateConvertedtoLocal,
              formattedDate
            );

            console.log("slots", filteredData);
            setFilteredSlotsMain(filteredData);
          })
          .catch((error) => {
            console.error("Error fetching available slots:", error);
          });
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // console.log("selectedDate", selectedDate);
    // console.log("today", today);

    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero indexed
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    }

    // Usage
    // const today = new Date();
    const formattedDate = formatDate(new Date());
    const selectedDateNew = formatDate(selectedDate);
    // console.log(
    //   "formattedDate",
    //   formattedDate,
    //   "selectedDateNew",
    //   selectedDateNew
    // ); // Output: "2024-05-06"

    // formattedDate === selectedDateNew ? setIndexOfDate(0) : setIndexOfDate(1);
  }, [selectedDate]);



  function extractTime(dateTimeString) {
    // Split the string by space
    const parts = dateTimeString.split(" ");

    // The time part is the last two elements
    const timePart = parts[1] + " " + parts[2];

    return timePart;
  }

  // const handleDateChange = (event) => {
  //   const dateValue = event.target.value ? new Date(event.target.value) : null;
  //   // Assuming handleChange expects a date value, pass it accordingly
  //   handleChange(dateValue);
  // };

  // // Ensure selectedTime is a valid date
  // const selectedTime = user?.selectedTime ? new Date(user.selectedTime) : null;
  // const selectedTimeISO = selectedTime && !isNaN(selectedTime) ? selectedTime.toISOString().split("T")[0] : "";


  // const today = new Date().toISOString().split("T")[0];
  // const handleDateChange = (event) => {
  //   const { name, value } = event.target;
  //   const selectedDate = new Date(value);

  //   setSelectedDate(selectedDate || today);
  //   setUser({
  //     ...user,
  //     [name]: value,
  //   });
  // };

  const today = new Date().toISOString().split("T")[0];

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    const selectedDate = new Date(value);
    const isValidDate = !isNaN(selectedDate);

    // Use selected date if valid, otherwise fall back to today
    const finalDate = isValidDate ? selectedDate : new Date(today);

    // Set the selected date state
    setSelectedDate(finalDate);

    // Update user state with formatted date
    setUser((prevUser) => ({
      ...prevUser,
      [name]: isValidDate ? value : today,
    }));
  };

  
  // console.log("selected adte", user.selectedDate);
  // console.log("indexOfDate", indexOfDate);
  // console.log("filteredSlotsMain", filteredSlotsMain);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let formData = { ...user };

      if (
        user.selectedDate &&
        user.selectedDate !== "" &&
        user.selectedTime &&
        user.selectedTime !== "" &&
        user.contactNumber !== "" &&
        user.contactNumber
      ) {
        //old running
        // const Adminconverted = moment(user.selectedTime)
        //   .tz("America/Chicago")
        //   .format("YYYY-MM-DD HH:mm:ss");
        const Userselected = `${user.selectedTime}`;
        // const Userselected = `${user.selectedDate} ${user.selectedTime}`;
        // console.log("Userselected", Userselected);
        // const utcDateTime = convertToTimeZone(Userselected);
        // const utcDateTime = moment
        //   .utc(Userselected, "YYYY-MM-DD h:mm:ss a")
        //   .format();
        // console.log("utcDateTime", utcDateTime);
        // function convertToTimeZone(dateTimeString, timeZone) {
        //   const utcDateTime = moment.utc(
        //     dateTimeString,
        //     "YYYY-MM-DD h:mm:ss a"
        //   );
        //   const dateTimeInTimeZone = utcDateTime.tz("America/Chicago").format();
        //   return dateTimeInTimeZone;
        // }
        // const utcDateTimetz = moment
        //   .tz(Userselected, "YYYY-MM-DD h:mm:ss a")
        //   .format();

        // console.log("utcDateTimetz", utcDateTimetz);
        // console.log("utcDateTime", utcDateTime);

        // const UserConverted = utcDateTime;

        // const Adminconverted = moment
        //   .tz(utcDateTime, "America/Chicago")
        //   .format("MMMM Do YYYY, h:mm:ss a");
        //   // .format();
        // console.log("Adminconverted", Adminconverted);

        const UsersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // const UserConvertedtoutc = moment
        //   .tz(utcDateTime, UsersTimeZone)
        //   .format("MMMM Do YYYY, h:mm:ss a");
        // console.log("UserConvertedtoutc", UserConvertedtoutc);

        // const Adminconverted = moment.utc(UserConverted).tz('America/Chicago').format("MMMM Do YYYY, h:mm:ss a");
        // const UsersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // const UserConvertedtoutc = moment.utc(UserConverted).tz(UsersTimeZone).format("MMMM Do YYYY, h:mm:ss a");
        // console.log("Adminconverted",Adminconverted)
        // console.log("UserConvertedtoutc",UserConvertedtoutc)

        // for email
        const convertToChicagoTime = (Userselected) => {
          // Parse the given Indian date time using Moment.js
          const indianMoment = moment(Userselected, "YYYY-MM-DD hh:mm:ss a");

          // Convert Indian Standard Time (IST) to Central Daylight Time (CDT)
          const chicagoMoment = indianMoment.clone().tz("America/Chicago");

          // Format the Chicago time
          const formattedChicagoTime = chicagoMoment.format(
            "MMMM Do YYYY hh:mm:ss A"
          );

          return formattedChicagoTime;
        };
        // console.log("convertToChicagoTime", convertToChicagoTime(Userselected));
        const Adminconverted = convertToChicagoTime(Userselected);
        console.log("Adminconverted", Adminconverted);

        const chicagoDateTime = moment.tz(
          Adminconverted,
          "MMMM Do YYYY hh:mm:ss A",
          "America/Chicago"
        );

        const utcDateTimeAdmin = chicagoDateTime.utc();
        console.log(utcDateTimeAdmin.format());

        function formatTimestamp(isoString, timezone = "UTC") {
          return moment
            .tz(isoString, timezone)
            .format("MMMM Do YYYY, h:mm:ss a");
        }

        // console.log("utcDateTime",utcDateTime)
        const adminEmail = formatTimestamp(
          user.selectedTime,
          "America/Chicago"
        );
        const UserEmail = formatTimestamp(user.selectedTime, UsersTimeZone);
        // console.log("admin time ", adminEmail, "user time", UserEmail);

        formData = {
          ...formData,
          // selectedDateInAdminTZ:  adminEmail,
          // selectedDateInUserTZ: UserEmail,
          // selectedTime: user.selectedTime,
          selectedDateInAdminTZ: Adminconverted, // old working
          selectedDateInUserTZ: Userselected, //old working
          selectedTime: utcDateTimeAdmin.format(), //old working
          // selectedDateInUserTZ: UserConvertedtoutc,
          // selectedTime: UserConverted,
          // selectedTime1: UserConvertedtoutc,
        };

        // console.log("formdata", formData);
        // return;
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}bookMeeting`,
          formData
        );
        setError(false);
        if (response.data.message === "Meeting slot is already booked.") {
          toast(response.data.message);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 3500);
        }
        if (response.data.msg === "Meeting slot booked successfully!") {
          setShowPopupConfirm(true);
        }
      } else {
        // return
        if (user?.contactNumber === "" || user?.contactNumber == undefined) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            contactNumber: "enter valid contact number",
          }));
          setDisableButton(true);
        }
        // console.log("handleSubmit--else", user.selectedDate, user.selectedTime);
        if (user.selectedDate == undefined || user.selectedDate == "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            selectedDate: " Please select the day",
          }));
          // setDisableButton(true);
        }
        if (user.selectedTime == undefined || user.selectedTime == "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            selectedTime: "Please select the time slot",
          }));
          // setDisableButton(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={`google-meet  ${
        !fromBlur && "col-11 mx-auto"
      } bg-white h-100 `}
    >
      <div className="shadow rounded rounded-4 h-100">
        <div className="p-md-4 p-3 ">
          {user && !fromBlur && !isLoading ? (
            <>
              <div className="text-center">
                <h3 className="text-uppercase meet-green text-primary ">
                  Book 1:1 session with us
                </h3>
              </div>
              <div className="col-md-11 col-12 mx-auto">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="name" className="w-100 mt-2">
                    Name{" "}
                  </label>
                  <br />
                  <input
                    required
                    type="text"
                    id="name"
                    name="name"
                    className="border-0 w-100 border-bottom p-1"
                    onChange={handleChange}
                    value={user?.name}
                    disabled
                    // disabled={fromBlur}
                  />
                  {/* </label> */}
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                  <label htmlFor="email" className="w-100 mt-2">
                    Email{" "}
                  </label>
                  <br />
                  <input
                    required
                    type="email"
                    id="email"
                    name="email"
                    className="border-0 w-100 border-bottom p-1"
                    onChange={handleChange}
                    value={user?.email}
                    disabled
                  />
                  {/* </label> */}
                  {errors.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                  <label htmlFor="message" className="w-100 mt-2">
                    Message
                  </label>
                  <br />
                  <input
                    required
                    type="text"
                    id="message"
                    name="message"
                    className="border-0 w-100 border-bottom p-1"
                    onChange={handleChange}
                    value={user?.message || ""}
                    disabled={fromBlur}
                    placeholder="Share your goals for this session..."
                  />

                  <label
                    style={{
                      width: "100%",
                      color: "",
                      marginTop: ".4rem",
                      marginBottom: ".7rem",
                    }}
                  >
                    Contact Number
                    <PhoneInput
                      country="US" // in
                      defaultCountry="US"
                      value={phoneNumber || user?.contactNumber?.toString()}
                      onChange={handleChangePhone}
                      inputProps={{
                        required: true,
                      }}
                      // style={phoneCss}
                      className="mt-2"
                    />
                  </label>
                  {errors.contactNumber && (
                    <div className="text-danger">{errors.contactNumber}</div>
                  )}
                  <div>
                    <label htmlFor="selectedDate" className="w-100 my-2">
                      Select Date:
                      <br />
                      <div className="d-flex flex-wrap justify-content-between">
                        <div className="col-1 text-center">
                          <FaRegCalendarAlt className="w-50 h-75" />
                        </div>
                        <div className="col-11">
                          <input
                            required
                            className="border-0 w-100 border-bottom p-1 no-clear-button"
                            type="date"
                            // ref={dateInputRef}
                            // type="datetime-local"
                            id="selectedDate"
                            name="selectedDate"
                            value={selectedDate?.toISOString().split("T")[0]} // Format date as 'YYYY-MM-DD'
                            onChange={handleDateChange}
                            min={today} // Set min attribute to today's date
                            style={{
                              fontWeight: "400",
                              border: "revert-layer", // Remove default border on focus
                              outline: "none", // Remove default outline on focus
                              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                              marginBottom: ".5rem",
                              paddingLeft: ".5rem",
                            }}
                          />
                        </div>
                      </div>
                      {errors.selectedDate && (
                        <div className="text-danger">{errors.selectedDate}</div>
                      )}
                    </label>
                    <label htmlFor="selectedTime" className="w-100 my-2">
                      Select a time slot
                      <br />
                      <div className="d-flex flex-wrap justify-content-between">
                        <div className="col-1 text-center">
                          <FaRegClock className="w-50 h-75" />
                        </div>
                        <div className="col-11">
                          <select
                            name="selectedTime"
                            id="selectedTime"
                            className="border-0 w-100 border-bottom p-1 form-control"
                            onChange={handleChange}
                            value={user?.selectedTime || "Select time slot"}
                            disabled={fromBlur || disableButton}
                            style={{
                              fontWeight: "400",
                              border: "revert-layer", // Remove default border on focus
                              outline: "none", // Remove default outline on focus
                              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                              marginBottom: ".5rem",
                              paddingLeft: ".5rem",
                            }}
                          >
                            <option value="Select time slot"  disabled>
                              Select time slot
                            </option>
                            
                            {filteredSlotsMain?.length >= 1 ? (
                              filteredSlotsMain.map((data) => (
                                <option
                                  key={data.startdate}
                                  value={data.startdate}
                                >
                                  {`${extractTime(
                                    data.startdate
                                  )} - ${extractTime(data.enddate)}`}
                                </option>
                              ))
                            ) : (
                              <option value="" disabled>
                                Please try next day.
                              </option>
                            )}
                            
                          </select>
                        </div>
                        {errors.selectedTime && (
                          <div className="text-danger">
                            {errors.selectedTime}
                          </div>
                        )}
                      </div>
                    </label>
                    <button
                      type="submit"
                      disabled={fromBlur}
                      // disabled={fromBlur || disableButton}
                      className="btn btn-outline-primary w-100 mt-4 py-2"
                    >
                      {/* <span className=" h5 fw-normal">
                        {disableButton
                          ? "Fill form correctly"
                          : "Request 1:1 Meet"}{" "}
                      </span> */}
                      {!disableButton ? (
                        <>
                          <span className=" h5 fw-normal">
                            Request 1:1 Meet
                          </span>{" "}
                          <TbArrowBadgeRightFilled className="height-width-svg" />
                        </>
                      ) : (
                        <>
                          <span className=" h5 fw-normal">
                            Request 1:1 Meet
                          </span>{" "}
                          <TbArrowBadgeRightFilled className="height-width-svg" />
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <>
              <div className="loader-container-contact ">
                <span className="loader-contact"></span>
              </div>
            </>
          )}
        </div>
      </div>
      {/*  confirm modal */}
      <Modal show={showPopupConfirm} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> Congratulations !</Modal.Title>
          <button
            type="button"
            className="btn-close close-button-info "
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </Modal.Header>

        {/* <Modal.Body> */}
        <p className="px-3">
          <p>
            {/* Are you sure you want to apply to the{" "} */}
            <span className="fw-semibold">
              {/* "{scholarship?.scholarshipName}" */}
              Your 1:1 session has been successfully booked. We can't wait to
              assist you. Please check your email for confirmation details.
            </span>{" "}
          </p>
          {/* <p>Are you sure you want to apply for the scholarship?</p> */}
        </p>
        {/* </Modal.Body> */}
        <Modal.Footer>
          <button className="btn btn-outline-secondary " onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
