// import "bootstrap-icons/font/bootstrap-icons.css";
import "../../style/usernavbar.css";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation } from "react-router-dom";
function UserNavbar() {
  const location = useLocation();
  const path = location.pathname;
  return (
    <div className="container bg-light " style={{ marginTop: "5rem" }}>
      <Nav defaultActiveKey="/user/userprofile" variant="tabs">
        <Nav.Link
          eventKey="link-1"
          className={`text-decoration-none ${
            path === "/user/userprofile" && "active"
          }`}
        >
          <Link to="/user/userprofile" className="text-decoration-none p-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 20 19" // manage size
              fill="none"
            >
              <path
                d="M12 10C14.2091 10 16 8.20914 16 6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6C8 8.20914 9.79086 10 12 10Z"
                fill="#2167b1" // change color
              />
              <path
                opacity="0.5"
                d="M20 17.5C20 19.985 20 22 12 22C4 22 4 19.985 4 17.5C4 15.015 7.582 13 12 13C16.418 13 20 15.015 20 17.5Z"
                fill="#2167b1"
              />
            </svg>{" "}
            <span className="custom-span">Profile</span>
          </Link>
        </Nav.Link>

        <Nav.Link
          eventKey="link-2"
          className={`text-decoration-none ${
            path === "/user/trackscholarship" && "active"
          }`}
        >
          <Link to="/user/trackscholarship" className="text-decoration-none p-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 19 19"
              fill="none"
            >
              <path
                d="M13.385 19.0002C12.2703 19.0002 11.325 18.6122 10.549 17.8362C9.773 17.0589 9.385 16.1136 9.385 15.0002C9.385 13.8869 9.773 12.9416 10.549 12.1642C11.325 11.3882 12.2703 11.0002 13.385 11.0002C14.4983 11.0002 15.4433 11.3882 16.22 12.1642C16.9967 12.9402 17.385 13.8856 17.385 15.0002C17.385 16.1142 16.9967 17.0596 16.22 17.8362C15.4433 18.6129 14.4983 19.0009 13.385 19.0002ZM15.04 17.2022L15.587 16.6562L13.769 14.8382V12.1152H13V15.1622L15.04 17.2022ZM1.615 18.0002C1.16833 18.0002 0.787333 17.8429 0.472 17.5282C0.157333 17.2136 0 16.8326 0 16.3852V3.61523C0 3.16857 0.157333 2.78757 0.472 2.47223C0.786667 2.15757 1.16767 2.00023 1.615 2.00023H6.252C6.34533 1.6089 6.553 1.28023 6.875 1.01423C7.197 0.748234 7.572 0.615234 8 0.615234C8.436 0.615234 8.814 0.748568 9.134 1.01523C9.454 1.28057 9.66067 1.6089 9.754 2.00023H14.384C14.832 2.00023 15.2133 2.15757 15.528 2.47223C15.8427 2.7869 16 3.1679 16 3.61523V9.63523C15.828 9.54657 15.6653 9.4689 15.512 9.40224C15.3587 9.33557 15.188 9.2759 15 9.22323V3.61523C15 3.4619 14.936 3.3209 14.808 3.19223C14.6793 3.06423 14.5383 3.00023 14.385 3.00023H12V5.23023H4V3.00023H1.615C1.46167 3.00023 1.32067 3.06423 1.192 3.19223C1.064 3.3209 1 3.4619 1 3.61523V16.3852C1 16.5646 1.05767 16.7119 1.173 16.8272C1.28833 16.9426 1.43567 17.0002 1.615 17.0002H7.742C7.79533 17.1869 7.85833 17.3606 7.931 17.5212C8.00433 17.6812 8.09767 17.8409 8.211 18.0002H1.616H1.615ZM8 3.23023C8.232 3.23023 8.42467 3.1539 8.578 3.00123C8.73133 2.8479 8.808 2.65523 8.808 2.42323C8.808 2.19123 8.73133 1.99857 8.578 1.84523C8.42467 1.6919 8.232 1.61523 8 1.61523C7.768 1.61523 7.57533 1.6919 7.422 1.84523C7.26867 1.99857 7.192 2.19123 7.192 2.42323C7.192 2.65523 7.26867 2.8479 7.422 3.00123C7.57533 3.15457 7.768 3.23023 8 3.23023Z"
                fill="#2167b1"
              />
            </svg>{" "}
            <span className="custom-span">Track Scholarship </span>
          </Link>
        </Nav.Link>
      </Nav>
    </div>
  );
}

export default UserNavbar;

/*
<Link to="/user/userprofile" className="text-decoration-none">
          <Nav.Link eventKey="link-1" >
            Profile1
          </Nav.Link>
        </Link>
        <Link to="/user/trackschol" className="text-decoration-none">
          <Nav.Link eventKey="link-2" className="text-decoration-none">
            Track Scholarship Status
          </Nav.Link>
        </Link>
*/
