// // // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // import { getAnalytics } from "firebase/analytics";
// import { GoogleAuthProvider, getAuth } from "firebase/auth";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBetbC_z46ApiRwPwKtv6q-nTWNZC05W-A",
//   authDomain: "ize-testenv.firebaseapp.com",
//   projectId: "ize-testenv",
//   storageBucket: "ize-testenv.appspot.com",
//   messagingSenderId: "512245568762",
//   appId: "1:512245568762:web:51bc14a14c4df6f4f9a543",
//   measurementId: "G-QNCSBTKQSL"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// // const analytics = getAnalytics(app);
// export const googleAuthProvider = new GoogleAuthProvider();
// export const auth = getAuth(); 
// export default app; 




// coppied wroking 

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAV3Dc8Zh-fvrt7JvANfn0bX76D-r4-jEc",
  authDomain: "auth-path2study.firebaseapp.com",
  projectId: "auth-path2study",
  storageBucket: "auth-path2study.appspot.com",
  messagingSenderId: "803368885943",
  appId: "1:803368885943:web:a5eda58e69eaf8fea6b732",
  measurementId: "G-T5GQD32DXX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const googleAuthProvider = new GoogleAuthProvider();
export const auth = getAuth(); 
export default app; 








// // main cred from P2S
// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAV3Dc8Zh-fvrt7JvANfn0bX76D-r4-jEc",
//   authDomain: "auth-path2study.firebaseapp.com",
//   projectId: "auth-path2study",
//   storageBucket: "auth-path2study.appspot.com",
//   messagingSenderId: "803368885943",
//   appId: "1:803368885943:web:a5eda58e69eaf8fea6b732",
//   measurementId: "G-T5GQD32DXX"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);