import React from "react";
import ReactDOM from "react-dom/client";
// import "./custom.scss" // main effect removed in index.css
import "./index.css";
import { AppRouter } from "./AppRoute";
import reportWebVitals from "./reportWebVitals";
// import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import Home from "./components/Home";
// import Contact from "./components/Contact";
// import About from "./components/About";
// import PathStudyScholarship from "./components/PathStudyScholarship";
// import SignInModal from "./components/SignInModall";
// import ScholarshipInformation from "./components/ScholarshipInformation";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <App />,
//     // loader: rootLoader,
//     children: [
//       {
//         path: "/",
//         element: <Home />,
//       },

//       {
//         path: "/home",
//         element: <Home />,
//       },
//       {
//         path: "/about",
//         element: <About />, // without lazy laod
//       },
//       {
//         path: "/contact",
//         element: <Contact />,
//       },
//       {
//         path: "/pathstudyscholarship",
//         element: <PathStudyScholarship />,
//       },
//       {
//         path: "/scholarshipinfo",
//         element: <ScholarshipInformation />,
//       },
//     ],
//   },
// ]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <RouterProvider router={router} /> */}
    <AppRouter/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
