import React, { useEffect, useState } from "react";
import AdminSideBar from "./AdminSideBar";
import BreadCrumb from "./BreadCrumb";
import { FaCircleUser } from "react-icons/fa6";
// import { FaGraduationCap } from "react-icons/fa";
import axios from "axios";
import "./../styles/WinnersPage.css";
// import { MdLeaderboard } from "react-icons/md";
import { RiGroupLine } from "react-icons/ri";
import { BiDollar } from "react-icons/bi";
// import { API_BASE_URL } from "../../appConfig";
import { formatAmount } from "../../hooks/useFormattedAmount";
const WinnersPage = () => {
  const [currency, setCurrency] = useState('');
  const [allWinnerData, setAllWinnerData] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [winnerLoader, setWinnerLoader] = useState(true);
  const adminToken = localStorage.getItem("adminToken");
  const adminId = localStorage.getItem("adminId");
  useEffect(() => {
    const headers = {
      authorization: `bearer ${adminToken}`,
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/getWinner/${adminId}`, {
        headers: headers,
      })
      .then((responce) => {
        setAllWinnerData(responce.data.winners);
        setCurrency(responce.data.currency)
        const totalAmount = responce.data.winners.reduce(
          (total, winner) => total + winner.scholarship.amount,
          0
        );

        // Store the total amount in state
        setTotalAmount(totalAmount);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setWinnerLoader(false);
      });
  }, []);

  
  //pegination for Winners
  const [currentPagpath2studyeWinners, setCurrentPagpath2studyeWinners] =
    useState(1);
  const recordsPerPagepath2studyWinners = 10;

  // Calculate the index range for the current page
  const startIndexpath2studyWinners =
    (currentPagpath2studyeWinners - 1) * recordsPerPagepath2studyWinners;
  const endIndexpath2studyWinners =
    startIndexpath2studyWinners + recordsPerPagepath2studyWinners;

  // Slice the data based on the current page
  const visibleDataWinners = allWinnerData?.slice(
    startIndexpath2studyWinners,
    endIndexpath2studyWinners
  );

  // Calculate the total number of pages
  const totalPagespath2studyWinners = Math.ceil(
    allWinnerData?.length / recordsPerPagepath2studyWinners
  );


  const forSerialNumberWinners =
    recordsPerPagepath2studyWinners * (currentPagpath2studyeWinners - 1);

  // Function to handle page change
  const handlePageChangePathToStudyWinners = (newPageOne) => {
    setCurrentPagpath2studyeWinners(newPageOne);
  };

  return (
    <div className="winnerPage">
      <div className="d-flex">
        <div className="col-2">
          <AdminSideBar />
        </div>
        {winnerLoader ? (
          <div className="col-10 py-3 px-3 schlorshipBackground">
            <div className="text-center mt-3">
              
              <div className="loader-container">
                <div className="loader"></div>
                <div>Please wait while we fetch the latest data for you</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-10 py-3 px-3 schlorshipBackground">
            <div className="">
              <div className="d-flex">
                <BreadCrumb />
                <div className="px-4 py-3">
                  <div className="d-flex justify-content-between">
                    <FaCircleUser size={25} className="mt-1 mx-2" />
                    <div className="align-middle mt-2">
                      {localStorage.getItem("adminName")}
                    </div>
                  </div>
                </div>
              </div>
              <hr className=" my-1" />
            </div>

            {/* cards */}
            <div className="d-flex justify-content mt-5">
              <div className="col-6 pe-3">
                <div className="rounded-3 bg-white p-3">
                  <BiDollar size={30} />

                  <div className="d-flex justify-content mt-2">
                    <div className="col-8 text-start">
                      <h5>Total Amount Rewarded</h5>
                    </div>
                    <div className="col-4 text-end">
                      <h5>{currency ? currency : "$"}{formatAmount(totalAmount)}</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6 px-3 pe-0">
                <div className="rounded-3  bg-white  p-3">
                  <RiGroupLine size={30} className="" />
                  <div className="d-flex justify-content-between mt-2">
                    <div className="">
                      <h5 className="">Total No of Winners</h5>
                    </div>

                    <div className="">
                      <h5 className="">{allWinnerData?.length}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ backgroundColor: "#F8F9FB" }}
              className="mt-4 p-4  rounded "
            >
              <h5>Declared Winners</h5>
              {visibleDataWinners?.length == 0 ? (
                // <div className="text-center bg-white p-3">
                //   There are currently no declared winners.
                // </div>

                <div
                  className="p-2 bg-white border rounded"
                  style={{
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="text-center mt-1">
                    <h5> There are currently no declared winners.</h5>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="rounded-3 p-2 bg-white shadow-lg">
                    <table className="table table-hover   custom-table">
                      <thead className="">
                        <tr className="text-center">
                          <td className="headingloan-color ForRowfont py-3">
                            S.No.
                          </td>
                          <td className="headingloan-color py-3">
                            Scholarship Name
                          </td>
                          <td className="headingloan-color ForRowfont py-3">
                            Name of the winner
                          </td>
                          <td className="headingloan-color ForRowfont py-3">
                            Amount
                          </td>

                          <td className="headingloan-color ForRowfont py-3">
                            Winner declared date
                          </td>

                          <td className="headingloan-color ForRowfont py-3">
                            No. of Applicants
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {visibleDataWinners.map((winner, index) => {
                          return (
                            <tr>
                              <td className="text-center forTextSize">
                                {" "}
                                {forSerialNumberWinners + (index + 1)}
                              </td>
                              <td className="text-center forTextSize">
                                {winner.scholarship?.scholarshipName}
                              </td>
                              <td className="text-center forTextSize">
                                {winner.user?.name}
                              </td>
                              <td className="text-center forTextSize">
                              {currency ? currency : "$"}{formatAmount(winner.scholarship?.amount)}
                              </td>
                              <td className="text-center forTextSize">
                                {winner.createdAt &&
                                  new Date(
                                    winner?.createdAt
                                  )?.toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                  })}
                              </td>
                              <td className="text-center forTextSize">
                                {winner.scholarship?.appliedUsers.length}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    {/* Pagination controls */}

                    {totalPagespath2studyWinners > 1 ? (
                      <div className="d-flex justify-content-between">
                        <button
                          disabled={currentPagpath2studyeWinners === 1}
                          onClick={() =>
                            handlePageChangePathToStudyWinners(
                              currentPagpath2studyeWinners - 1
                            )
                          }
                          className="peginationButton custom-button"
                        >
                          <h3> ↼ </h3>
                        </button>
                        <div>
                          Page {currentPagpath2studyeWinners} of{" "}
                          {totalPagespath2studyWinners}
                        </div>
                        <button
                          disabled={
                            currentPagpath2studyeWinners ===
                            totalPagespath2studyWinners
                          }
                          onClick={() =>
                            handlePageChangePathToStudyWinners(
                              currentPagpath2studyeWinners + 1
                            )
                          }
                          className="custom-button"
                        >
                          <h3> ⇀</h3>
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WinnersPage;
