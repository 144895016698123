import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

export default function BreadCrumb() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const handleInternalNavigation = () => {
    window.history.back();
  };

  return (
    
    <div className="container-fluid admin-background-color">
      <Breadcrumb className="py-3 admin-breadcrumb">
        {pathnames.length > 0 &&
          pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;

            const displayName = name.replace(/-/g, " ");

            return (
              <Breadcrumb.Item
                key={routeTo}
                active={isLast || routeTo === "/admin"}
                className={`${isLast ? "" : "text-capitalize"} ${
                  isLast ? "admin-link-active text-capitalize" : ""
                }`}
              >
                {index > 0 ? (
                  isLast ? (
                    // Render the current page without a link
                    displayName
                  ) : (
                    <Link to={routeTo} className="admin-link-color">
                      {displayName}
                    </Link>
                  )
                ) : (
                  displayName
                )}
              </Breadcrumb.Item>
            );
          })}
      </Breadcrumb>
    </div>
  );
}
