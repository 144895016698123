import React from "react";

const UnlockPotential = () => {
  return (
    <div className="container my-5">
        <h2 className="text-primary">UNLOCK YOUR POTENTIAL <br /> WITH PATH2STUDY</h2>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        <div className="col">
          <div className="card h-100 text-center rounded rounded-5">
            <img
              src={require('../assests/img/unlock1.png')}
              className="card-img-top "
              alt="..."
            />
            <div className="card-body ">
              <h3 className="card-title ">Safe and Secure</h3>
              <p className="card-text">
                Your academic journey is safeguarded with Path2Study, providing
                a secure platform for your personal and educational information,
                ensuring a worry-free experience.
              </p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100 text-center rounded rounded-5">
            <img src={require('../assests/img/unlock2.png')} className="card-img-top" alt="..." />
            <div className="card-body">
              <h3 className="card-title">Verified Scholarship</h3>
              <p className="card-text">
                Explore genuine scholarship opportunities with Path2Study, as
                each listing undergoes a thorough verification process,
                guaranteeing the authenticity and legitimacy of the provided
                opportunities.
              </p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100 text-center rounded rounded-5">
            <img src={require('../assests/img/unlock3.png')} className="card-img-top" alt="..." />
            <div className="card-body">
              <h3 className="card-title">Reliable Guidance</h3>
              <p className="card-text">
                At Path2Study, we are committed to being your dependable
                academic companion, offering unwavering support and guidance to
                ensure a reliable pathway to your education.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlockPotential;
