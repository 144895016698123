
// Inside the ProfileLayout component
import '../../style/usernavbar.css'
import {  useNavigate } from "react-router-dom";
import UserNavbar from "./UserNavbar";
import { Outlet  } from "react-router-dom";
import { useEffect,  } from "react";

const ProfileLayout = () => {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate(); // React Router hook for navigation

  useEffect(() => {
    // Check if userId is not present, then navigate to home
    if (!userId) {
      navigate('/');
    }
  }, [userId, navigate]);

  return (
    <div style={{ }} className="bg-light">
      <UserNavbar />
      <Outlet />
    </div>
  );
};

export default ProfileLayout;


// <div style={{ marginTop: "0rem" }} className="bg-light ">
//       {/* Navbar for user routes */}
//       {/* <nav>
//         <ul>
          
//           <li>
//             <Link to="/user/userprofile">User Profile</Link>
//           </li>
//           <li>
//             <Link to="/user/trackschol">Track Scholarship</Link>
//           </li>
//         </ul>
//       </nav> */}
//       <UserNavbar />
//       {/* Content for the nested routes */}
//       <Outlet />
//       {/* <div>
//         <Routes>
//           <Route path="/user/userprofile" element={<UserProfile />} />
//           <Route path="/user/trackschol" element={<UserTrackScholarship />} />
//         </Routes>
//       </div> */}
//     </div>