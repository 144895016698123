import React from "react";
import { useNavigate } from "react-router-dom";

const ScholarBanksFooterIND = () => {
  const nevigate = useNavigate();

  const handleApplyNow = () => {
    nevigate("/loan");
    window.scrollTo(0, 0);
  };
  return (
    <>
    <div className="container d-none d-sm-block ">
      <div className="card mb-3 border border-white">
        <div className="row ">
          <div className="col-md-6 order-first order-md-1">
            <img
              src={require("../assests/img/loanInd111.png")}
              className="img-fluid "
              alt="..."
            />
            {/* <img
              src={require("../assests/img/India (2).png")}
              className="img-fluid "
              alt="..."
            /> */}
          </div>
          <div className="col-md-6 mt-lg-5 order-first order-md-1 ">
            <div className="card-body">
              <h5 className="card-title fs-1">
                {/* INSTANTLY FUND YOUR EDUCATION WITH SUPPORT FROM KEY LENDERS */}
                <span className="fs-2 text fw-semibold">INSTANTLY</span>{" "}
                <span className="fs-2 text fw-semibold text-primary">
                  FUND YOUR EDUCATION
                </span>{" "}
                <span className="fs-2 text fw-semibold">
                  <br />
                  WITH SUPPORT FROM
                </span>{" "}
                <span className="fs-2 text fw-semibold text-primary">
                  KEY LENDERS
                </span>{" "}
              </h5>
              <div className="col-md-5 col-12 order-last order-md-2 ">
                {" "}
                <button
                  type="button"
                  className="btn btn-primary mt-4 d-md-block  w-100 p-2 "
                  onClick={handleApplyNow}
                >
                  APPLY NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     {/* mobile version */}
     <div className="container d-md-none">
     <div className="card mb-3 border border-white">
       <div className="row ">
         <div className="col-md-6 mt-lg-5  ">
           <div className="card-body" style={{marginBottom:'0rem'}}>
             <h5 className="card-title mt-4 mb-0">
               <span className="card-title fs-1 mt-lg-3 text-align-right  text fw-semibold">
                 SECURE YOUR <br /> EDUCATION WITH
               </span>{" "}
               <span className="card-title fs-1 mt-lg-3 text-align-right  text-primary text fw-semibold">
                 FAST FUNDING OPTIONS
               </span>{" "}
             </h5>
             
           {/* </div> */}
           <div className="col-md-6 ">
             <img
               src={require("../assests/img/loanInd111.png")}
               className="img-fluid "
               alt="..."
             />
             {/* <img
               src={require("../assests/img/USA.png")}
               className="img-fluid "
               alt="..."
             /> */}
           </div>
           <div className="col-md-5 col-12 mb-4 ">
               {" "}
               <button
                 type="button"
                 className="btn btn-primary mt-4  w-100 p-2 "
                 onClick={handleApplyNow}
               >
                 APPLY NOW
               </button>
             </div>
             </div>
         </div>
       </div>
     </div>
   </div>
   </>
  );
};

export default ScholarBanksFooterIND;
