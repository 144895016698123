import React from "react";
import "../style/ContactGoogleMeet.css";
import CreateGoogleMeet from "./CreateGoogleMeet";

export default function BlurWindow() {
  return (
    <div className="blur-section p-md-5 p-4 h-100">
      <div className="blur-content h-100 shadow">
        <CreateGoogleMeet fromBlur={true} />
      </div>
    </div>
  );
}
