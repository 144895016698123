import React, { useState, useEffect } from "react";
import "../style/HomeWinner.css";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// import { useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import profileImage from "../assests/img/BG_LOGO1.png";
// import profileImage from "../assests/img/BG_LOGO.png";

import { formatAmount } from "../hooks/useFormattedAmount";

const HomeWinner = () => {
  const [cardData, setCardData] = useState([]);
  const [winner, setWinner] = useState();
  const [scholarship, setscholarship] = useState();
  const [currency, setCurrency] = useState('');
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getWinners`)
      // .get(`https://server-dot-path2study.el.r.appspot.com/getWinners`)
      .then((response) => {
        const WinnerUsersSchol = [];
        const WinnerUsers = [];
        response.data.map((item) => WinnerUsersSchol.push(item.scholarship));
        setscholarship(WinnerUsersSchol);
        response.data.map((item) => WinnerUsers.push(item.user));
        setCardData(response.data);
        setCurrency(response.data.currency)
        setWinner(WinnerUsers);
       
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="container mt-5">
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
        <div>
          <span className="fs-3 text fw-semibold text-primary ">
            CELEBRATING EXCELLENCE:{" "}
          </span>{" "}
          <span className="fs-3 text">
            {" "}
            MEET{" "}
            <span className="d-none d-lg-inline">
              <br /> {/* Will only show on large screens */}
            </span>
            OUR{" "}
          </span>
          <span className="fs-3 text fw-semibold text-primary ">
            SCHOLARSHIP WINNERS
          </span>
          <p className="mt-2">
            Meet the accomplished individuals who turned their dreams into
            reality with our scholarships. Effortlessly boost your chances of
            winning scholarship dollars as our platform simplifies and expedites
            the application process for you.
          </p>
        </div>
      </div>

      {/* Desktop view of swipper */}
      <Swiper
        className=" d-lg-block d-none"
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={50} //gap bettwen cards
        slidesPerView={3}
        autoplay={{ delay: 2500 }}
        // navigation
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + ' bg-primary">.</span>'; // Modify the content to only show the bullet without the dot
          },
        }}
      >
        <div className="d-flex flex-wrap mt-4 mb-4 ">
          {cardData?.map((card, i) => (
            <SwiperSlide key={i} className="">
              <div id="card" className=" rounded-5 border ">
                {card.user !== null ? (
                  <img
                    // src={card.user === null ?( profileImage) : (card.user?.profileImg !== null ? card.user?.profileImg : profileImage )}
                    src={profileImage}
                    className=" rounded-5 "
                    height={280}
                    width={380}
                    alt="Profile Image 1 "
                  />
                ) : (
                  "no usr found"
                )}

                {/* <img
                  src={require("../assests/img/BG (1).png")}
                  alt=""
                  className="img-fluid images rounded-5"
                /> */}

                <div className="images px-2 ">
                  <div className="d-flex flex-wrap justify-content-between">
                    <h6 className="text-white col-8 mb-0 text-truncate">
                      {card.user?.name}
                    </h6>
                    <span className="text-white col-4 text-end mb-0">
                      Won {currency ? currency : "$"}{formatAmount(card.scholarship?.amount)}
                    </span>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between mt-1">
                    <p className="text-white col-9 mb-0 text-truncate">
                      {card.scholarship?.scholarshipName}
                    </p>
                    <p className="text-white col-9 mb-0">
                      {card.scholarship?.scholarshipProvider}
                    </p>
                    <p className="text-white col-3 text-end mb-0 pb-0">
                      {" "}
                      {new Date(
                        card.scholarship?.createdAt
                      ).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>

      {/* mobile view of swipper  */}
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        // spaceBetween={50}
        autoplay={{ delay: 5000 }}
        className="d-lg-none d-block"
        pagination={{
          clickable: true,
        }}
        // slidesPerView={1}
        breakpoints={{
          280: { slidesPerView: 1 },
          350: { slidesPerView: 1 },
          480: { slidesPerView: 1 },
          576: { slidesPerView: 1 },
          768: { slidesPerView: 1 },
          992: { slidesPerView: 1 },
          1200: { slidesPerView: 1 },
          1400: { slidesPerView: 1 },
        }}
      >
        <div className="d-flex flex-wrap">
          {cardData?.map((card, i) => (
            <SwiperSlide key={i} className="">
              {/* <div id="card-mobile" className=" border rounded-5 border-1 ">
                {card.user !== null ? (
                  <img
                    // src={card.user === null ?( profileImage) : (card.user?.profileImg !== null ? card.user?.profileImg : profileImage )}
                    src={profileImage}
                    className=" rounded-5 images-mobile "
                    height={275}
                    width={340}
                    alt="Profile Image 1 "
                  />
                ) : (
                  "no usr found"
                )}

                <img
                  src={require("../assests/img/BG (1).png")}
                  alt=""
                  className="img-fluid images-mobile rounded-5"
                />

                <div className="images-mobile px-3 col-12 ">
                  <div className="d-flex flex-wrap justify-content-between">
                    <h6 className="text-white col-8 mb-0">
                      {card.scholarship?.scholarshipName}
                    </h6>
                    <h6 className="text-white col-4 text-end mb-0">
                      Won ${formatAmount(card.scholarship?.amount)}
                    </h6>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between mt-1">
                    <p className="text-white col-9 mb-0">
                      {card.scholarship?.scholarshipProvider}
                    </p>
                    <p className="text-white col-3 text-end mb-0 pb-0">
                      {" "}
                      {new Date(
                        card.scholarship?.createdAt
                      ).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div> */}
              <div id="card" className=" rounded-5 border">
                {card.user !== null ? (
                  <img
                    // src={card.user === null ?( profileImage) : (card.user?.profileImg !== null ? card.user?.profileImg : profileImage )}
                    src={profileImage}
                    className=" rounded-5 "
                    height={280}
                    width={370}
                    alt="Profile Image 1 "
                  />
                ) : (
                  "no usr found"
                )}

                <div className="images  col-12 ">
                  <div className="d-flex flex-wrap justify-content-between">
                    <h6 className="text-white col-8 mb-0 text-truncate">
                      {card.user?.name}
                    </h6>
                    <span className="text-white col-4 text-end mb-0">
                      Won {currency ? currency : "$"}{formatAmount(card.scholarship?.amount)}
                    </span>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between mt-1">
                    <p className="text-white col-9 mb-0 text-truncate">
                      {card.scholarship?.scholarshipName}
                    </p>
                    <p className="text-white col-9 mb-0">
                      {card.scholarship?.scholarshipProvider}
                    </p>
                    <p className="text-white col-3 text-end mb-0 pb-0">
                      {" "}
                      {new Date(
                        card.scholarship?.createdAt
                      ).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  );
};

export default HomeWinner;
