import React, { useContext } from "react";
import {  Form, Button, Col } from "react-bootstrap";
import axios from "axios";
import { ModalContext } from "../../contexts/ModalContext";

// import { API_BASE_URL } from "../../appConfig";
// import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import * as formik from "formik";
import * as yup from "yup";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { redirect } from "react-router-dom";

const ForgetPass2 = () => {
  const { handleNext, data ,closeModal} = useContext(ModalContext);

  const { Formik } = formik;
  const otpSchema = yup.object({
    emailOTP: yup
      .string()
      .length(6, "OTP must be exactly 6 digits")
      .matches(/^[0-9]+$/, "OTP must contain only numeric digits")
      .required("OTP is required"),
  });


   const handleVerifyRequest = async (values) => {
    const { email } = data;
    const postData = { emailOTP: values.emailOTP, email: email };
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/verifyOtp`, postData);
      // const response = await axios.post(`${API_BASE_URL}/verifyOtp`, postData);
      // console.log("Response:", response.data);

      handleNext(postData);
    } catch (error) {
      console.error("Error:", error);
      // Handle errors, if any
      if (error) {
        toast(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

 
  const handleResendOtp = async () => {
    const { email } = data;
    const postData = { email };
    console.log("verification component", postData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/generateOtp`,
        // `${API_BASE_URL}/generateOtp`,
        postData
      );
      // console.log("Response:", response.data);
      if( response.data=== 'OTP sent to your entered email'){
        toast('OTP sent to your entered email', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  return (
    <>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header className="d-grid justify-content-center">
          <Modal.Title>
            <p className="fs-1">Create Your Account</p>
          </Modal.Title>
        </Modal.Header>

        <p className="mx-5 justify-content-center fs-6">
          Path2Study gives you authentic information on loans and scholarship to
          help you achieve your education goals. Let’s create your profile on
          Path2Study.
        </p> */}
      
        <div className="card mx-md-4 shadow p-md-3 p-2 mb-3 bg-body rounded-lg">
          
          
          {/* new version  */}
          <Formik
            validationSchema={otpSchema}
            onSubmit={handleVerifyRequest}
            initialValues={{
              email: "",
              emailOTP: "",
              // password: "",
              // confirmPassword: "",
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                <Form.Group as={Col} controlId="validationFormik01">
                    <Form.Label className="">Enter six digit OTP here</Form.Label>
                    <Form.Control
                      placeholder="- - - - - -"
                      type="text"
                      name="emailOTP"
                      value={values.emailOTP}
                      onChange={handleChange}
                      isInvalid={!!errors.emailOTP}
                      style={{
                        fontWeight: "400",
                        border: "revert-layer", // Remove default border on focus
                        outline: "none", // Remove default outline on focus
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.emailOTP}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-end ">
                    <span
                      className="text-primary d-grid justify-content-end mt-1 pe-auto cursor-pointer text-decoration-underline "
                      onClick={handleResendOtp}
                    >
                      Resend Code
                    </span>
                  </div>
                  {/* <a
                    className="text-primary d-grid justify-content-end mt-1 pe-auto cursor-pointer text-decoration-underline "
                    onClick={handleResendOtp}
                  >
                    Resend Code
                  </a> */}
                </Row>

                <Button
                    variant="primary"
                    type="submit"
                    className="d-grid gap-2 col-6 mx-auto m-1 w-100"
                  >
                    Verify OTP
                  </Button>
              </Form>
            )}
          </Formik>
          
          
        </div>
      {/* </Modal> */}
    </>
  );
};

export default ForgetPass2;
