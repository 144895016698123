// SignupModal.js
import "../../style/signInmodal.css";
import React, { useState, useContext, useRef, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
// import * as Icon from "react-bootstrap-icons";
// import { GoogleLogin } from "@react-oauth/google";
import { ModalContext } from "../../contexts/ModalContext";
import * as formik from "formik";
import * as yup from "yup";
import { API_BASE_URL } from "../../appConfig";
import axios from "axios";
import { redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
import { UserContext } from "../../contexts/UserContext";
import ForgetPassSteps from "./ForgetPassSteps";

// google login
import { auth, googleAuthProvider } from "../../firebase";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import TermsPath2Study from "../../assests/dosc/Terms_and_Conditions.pdf"; // src folder
// import TermsPath2Study from "../../../public/TermsPath2Study.pdf" // public folder
// import Privacy from "../assests/dosc/Privacy.pdf";

const SignInModal = () => {
  const {
    // signUpOpen,
    // openModal,
    // isOpen,
    setIsOpen,
    // showSigninModal,
    setShowSigninModal,
    // steps,
    closeModal,
    allCloseModal,
    // handleNext,
    // openForget,
    // showForget, setShowForget,
  } = useContext(ModalContext);

  const { data, setData } = useContext(UserContext);
  const [showForget, setShowForget] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // for eye button
  // new implimentaion
  const { Formik } = formik;

  // console.log("login page --showSigninModal---> ", showSigninModal);
  // console.log("login page --signUpOpen,---> ", signUpOpen,);
  // console.log("login page --openForget---> ", showForget);

  const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email")
      .required("Email is Required"),
    // email: yup.string().email("Invalid email").required("Email is Required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
  });

  const handlePostRequest = async (values) => {
    const postData = { email: values.email, password: values.password };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/userLogin`,
        // `${API_BASE_URL}/userLogin`,
        // "https://path2study-server-dot-ize-testenv.uc.r.appspot.com/userLogin",
        postData
      );
      // console.log("Response:", response.data.tokenData.token);
      // console.log("Response:", response.data.message);
      if (
        response.data.message ===
        "We are sorry to inform you that your account has been temporarily disabled by the admin. You can contact our support team at support@path2study.com to know more or activate your account."
      ) {
        toast(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        redirect("/");
        // window.location.href = "/";
      }
      // Handle the response or update the state as needed
      if (response.data.message === "User logged in successfully") {
        const userType = jwtDecode(response.data.tokenData.token);
        // console.log(userType);
        const UserId = localStorage.setItem(
          "userId",
          response.data.tokenData.userId
        );
        const UserToken = localStorage.setItem(
          "userToken",
          response.data.tokenData.token
        );
        const UserType = localStorage.setItem("userType", userType.userType);
        allCloseModal();

        window.location.href = "/home"; // needed

        toast(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // window.location.href = "/";
        redirect("/");
      }
    } catch (error) {
      // console.error("Error:", error);
      // Handle errors, if any
      if (error) {
        toast(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const openForget = () => {
    // Your login logic here
    // setIsOpen(false);
    // allCloseModal()
    // setShowSigninModal(false);
    setShowForget(true);
    // closeModal()
    // clooseLogin();
  };

  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate("/home");
  };

  const handleSignUpOpen = (e) => {
    e.preventDefault();
    setShowSigninModal(false);
    setIsOpen(true);
  };

  // const navigate = useNavigate();
  const handleSignInWithGoogle = async () => {
    // console.log("clicked google login");
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const postData = {
        googleEmail: result.user.email,
        name: result.user.displayName,
        register: "no",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/userLogin`,
        // `${API_BASE_URL}/userLogin`,
        // "https://path2study-server-dot-ize-testenv.uc.r.appspot.com/userLogin",
        postData
      );
      if (
        response.data.message ===
        "We are sorry to inform you that your account has been temporarily disabled by the admin. You can contact our support team at support@path2study.com to know more or activate your account."
      ) {
        toast(
          "We are sorry to inform you that your account has been temporarily disabled by the admin. You can contact our support team at support@path2study.com to know more or activate your account.",
          {
            position: "top-right",
            autoClose: 6500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        // redirect("/");
        setTimeout(() => {
          // window.location.href = "/home";
          redirect("/home"); // need to refresh after ogin to set the navbar name
        }, 5000);
        // window.location.href = "/";
        return;
      }
      // console.log("Response: ---in g login", response.data);
      if (response.data.message === "User logged in successfully") {
        const userType = jwtDecode(response.data.tokenData.token);
        // console.log(userType);
        const UserId = localStorage.setItem(
          "userId",
          response.data.tokenData.userId
        );
        const UserToken = localStorage.setItem(
          "userToken",
          response.data.tokenData.token
        );
        const UserType = localStorage.setItem("userType", userType.userType);
        toast(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        allCloseModal();
        // Introduce a delay before redirecting to "/home"
        setTimeout(() => {
          window.location.href = "/home"; // need to refresh after ogin to set the navbar name
        }, 3000); // 1000 milliseconds (1 second) delay
      }
      if (
        response.data.message ===
        "This users is not registered. Please sign up first"
      ) {
        toast("User is not registered please register first", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      // console.error(error);
      if (error) {
        toast(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      allCloseModal();
      // window.location.href = "/";
      setTimeout(() => {
        // window.location.href = "/home";
        redirect("/home"); // need to refresh after ogin to set the navbar name
      }, 5000);
    }
  };

  // for outside close click on outthat medal
  // const modalRef = useRef();

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (modalRef.current && !modalRef.current.contains(event.target)) {
  //       // closeModal();
  //       allCloseModal();
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [allCloseModal]);

  // const onButtonClick = () => {
  //   const pdfUrl = "Terms.pdf";
  //   const link = document.createElement("a");
  //   link.href = pdfUrl;
  //   link.download = "Terms.pdf"; // specify the filename
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  const onButtonClick = () => {
    const hostname = window.location.hostname;
    const pdfUrl =
      hostname === "localhost"
        ? "/Terms.pdf"
        : "https://path2study.el.r.appspot.com/Terms.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Terms.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={allCloseModal}
        backdrop="static" // not close on click outer the modal
        id="#secondModal"
        className={`${showForget ? "d-none" : ""}`}
        // style={{ overflow: "hidden" }} // scrollable made it
        // size="lg"
      >
        {/* <div ref={modalRef}>  for outside close */}
        {/* <Modal.Header closeButton className="d-flex justify-content-end modal-header-signin btn-close-signin">
          <Modal.Title className="text-center">
            <h1 className="fs-1 text-center " style={{paddingLeft:'5rem'}}>Welcome back!</h1>
            <h1 className="fs-2 text-center " style={{paddingLeft:'5rem'}}>Sign in your account</h1>
          </Modal.Title>
        </Modal.Header> */}

        {/* new modal header */}
        <Modal.Header className="d-flex justify-content-center ">
          <Modal.Title className="text-center mx-auto">
            <h1 className="fs-1  ">Welcome back!</h1>
            <span className="fs-2 ">Sign in your account</span>
          </Modal.Title>
          <button
            type="button"
            className="btn-close close-button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={allCloseModal}
          ></button>
        </Modal.Header>

        <p
          className="mx-5 text-center  fs-6 "
          style={{ marginBottom: "1.8rem" }}
        >
          Please sign in to your account to access all the loans and scholarship
          information based on your profile.
        </p>
        <>
          <div className="px-2">
            <div className="card mx-md-5 mx-sm-3 shadow p-3 bg-body rounded-lg mb-4">
              <Formik
                validationSchema={LoginSchema}
                onSubmit={handlePostRequest}
                initialValues={{
                  email: "",
                  password: "",
                }}
              >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                  <Form className="mx-auto d-grid " onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="my-2">Email </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="username@domain.com"
                        name="email"
                        required
                        value={values.email}
                        isInvalid={!!errors.email}
                        onChange={handleChange}
                        style={{
                          fontWeight: "400",
                          border: "revert-layer", // Remove default border on focus
                          outline: "none", // Remove default outline on focus
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                        {/* {console.log(errors.email)} */}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* <Form.Group controlId="formBasicPassword">
                    <Form.Label className="my-2">Password</Form.Label>
                    <Form.Control
                      placeholder="xxxxxxxx"
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                      style={{
                        fontWeight: "400",
                        border: "revert-layer", // Remove default border on focus
                        outline: "none", // Remove default outline on focus
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group> */}
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label className="my-2">Password</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          placeholder="xxxxxxxx"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={touched.password && !!errors.password}
                          style={{
                            fontWeight: "400",
                            paddingRight: "2.5rem", // Adjust right padding to accommodate the eye icon
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                        <div
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            // top: "36%",
                            right: "33px",
                            transform: "translateY(-102%)",
                          }}
                        >
                          {showPassword ? (
                            <i
                              className="bi bi-eye"
                              style={{ fontSize: "1.2rem" }}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-eye-slash"
                              style={{ fontSize: "1.2rem" }}
                            ></i>
                          )}
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <div className="d-flex justify-content-end ">
                      <span
                        className="text-primary cursor-pointer mt-2"
                        onClick={() => openForget()}
                      >
                        Forgot Password?
                      </span>
                    </div>

                    <span className="fs-6 text">
                      By signing in I agree with the
                      <a
                        href={TermsPath2Study}
                        className="text-primary ms-1 text-decoration-none"
                        // onClick={onButtonClick}
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>{" "}
                      of Path2Study
                    </span>
                    <Button
                      variant="primary"
                      type="submit"
                      className="d-grid gap-2 col-6 mx-auto m-1 w-100 mb-2 "
                    >
                      Log In
                    </Button>
                  </Form>
                )}
              </Formik>
              <button
                className="d-flex justify-content-center align-items-center bg-body border rounded p-1 my-2"
                onClick={handleSignInWithGoogle}
              >
                <img
                  src={require("../../assests/img/google1.png")}
                  className="me-2"
                  alt=""
                />
                <span className="">Sign In with Google</span>
              </button>

              <span className="d-flex justify-content-center">
                Don’t have an account ?{" "}
                <span
                  className="text-primary ms-1 cursor-pointer"
                  onClick={(e) => handleSignUpOpen(e)}
                  // onClick={setIsOpen} // keep up
                >
                  Sign Up
                </span>
              </span>
            </div>
          </div>
        </>
        {/* </div> */}
      </Modal>

      {showForget && (
        <ForgetPassSteps
          onHide={closeModal}
          // handleClose={closeModal}
          // allCloseModal={allCloseModal}
          showForget={showForget}
          setShowForget={setShowForget}
        />
      )}
      {/* {(showForget ) ? <ForgetPassSteps onHide={closeModal} handleClose={closeModal} allCloseModal={allCloseModal} />: null} */}
    </>
  );
};

export default SignInModal;
