import { Link } from "react-router-dom";
import "../style/footer.css";
// import { Link as ScrollLink } from "react-scroll";
// import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { useRef } from "react";
// import Nav from "react-bootstrap/Nav";
import TermsPath2Study from "../assests/dosc/TermsPath2Study.pdf";
import Privacy from "../assests/dosc/Privacy.pdf";
import { useContext } from "react";
import { CountryContext } from "../contexts/CountryContext";
function Footer() {
  const { selectedCountry, setSelectedCountry, countryByGoogle } =
    useContext(CountryContext);
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const location = useLocation();
  // const [showFaq, setShowFaq] = useState();
  // Check the pathname to determine whether to show or hide the footer links
  const showFaq = location.pathname === "/home" || location.pathname === "/";

  // const onButtonClickDownload = () => {
  //   // Define the base URL for the PDF
  //   const baseUrl = "https://path2study.el.r.appspot.com";

  //   // Set the PDF URL based on the environment
  //   const pdfUrl =
  //     process.env.NODE_ENV === "production"
  //       ? `${baseUrl}/Terms.pdf` // Use production URL
  //       : "/Terms.pdf"; // Use local URL

  //   // Create a link element
  //   const link = document.createElement("a");
  //   link.href = pdfUrl;
  //   link.download = "Terms.pdf"; // Specify the filename
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  return (
    <>
      <footer className="text-primary border-top  border-primary-subtle">
        <div className="row">
          <div className="ms-0">
            {/* Vertical Links on Mobile */}
            <ul className="nav d-md-none flex-column ">
              <li className="nav-item ">
                <Link
                  className="navbar-brand  mr-auto"
                  to="/"
                  onClick={handleClick}
                >
                  {/* Path2Study add to class text-primary fw-bold fs-2 ms-3*/}
                  <img
                    src={require("../assests/img/newlogo.png")}
                    className="img-fluid w-25 ms-3 mb-3"
                    alt="..."
                  />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-primary"
                  to="/home"
                  onClick={handleClick}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-primary"
                  to="/loan"
                  onClick={handleClick}
                >
                  Student Loans
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-primary"
                  to="/pathstudyscholarship"
                  onClick={handleClick}
                >
                  Path2Study Scholarships
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-primary"
                  to="/otherscholarship"
                  onClick={handleClick}
                >
                  Other Scholarships
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link text-primary"
                  to="/about"
                  onClick={handleClick}
                >
                  About Us
                </Link>
              </li>
              {/* {showFaq ? (
              <li className="nav-item">
                <ScrollLink
                  className="nav-link text-primary"
                  to="faqsSection" // Use the same ID as your target section
                  smooth={true}
                  duration={300} // Adjust the duration as needed
                  onClick={handleClick}
                >
                  FAQs
                </ScrollLink>
              </li>
            ) : (
              ""
            )} */}

              <li className="nav-item">
                <Link
                  className="nav-link text-primary"
                  to="/contact"
                  onClick={handleClick}
                >
                  Contact Us
                </Link>
              </li>
            </ul>

            {/* ==============================##################################################============================== */}

            {/* Horizontal Links on Desktop */}
            {/* <hr className="shadow"/> */}
            {/* <hr /> */}
            {/* <hr className="mx-auto" style={{ width: "98%" }} /> */}
            <ul className="nav d-none d-md-flex align-items-center justify-content-end">
              <li
                className="nav-item"
                style={{ paddingRight: "29rem", paddingLeft: "0px" }}
              >
                <Link
                  className="nav-link text-primary fw-bold "
                  to="/"
                  onClick={handleClick}
                >
                  {/* Path2Study */}
                  <img
                    src={require("../assests/img/newlogo.png")}
                    className="img-fluid  " //w-25
                    alt="..."
                    style={{ width: "90px", height: "90px" }}
                  />
                </Link>
              </li>
              <li className="nav-item ">
                <Link
                  className="nav-link text-primary"
                  to="/home"
                  onClick={handleClick}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-primary"
                  to="/loan"
                  onClick={handleClick}
                >
                  Student Loans
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-primary"
                  to="/pathstudyscholarship"
                  onClick={handleClick}
                >
                  Path2Study Scholarships
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-primary"
                  to="/otherscholarship"
                  onClick={handleClick}
                >
                  Other Scholarships
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link text-primary"
                  to="/about"
                  onClick={handleClick}
                >
                  About Us
                </Link>
              </li>
              {/* {showFaq ? (
              <li className="nav-item">
                <ScrollLink
                  className="nav-link text-primary"
                  to="faqsSection" // Use the same ID as your target section
                  smooth={true}
                  duration={300} // Adjust the duration as needed
                  onClick={handleClick}
                >
                  FAQs
                </ScrollLink>
              </li>
            ) : (
              ""
            )} */}
              <li className="nav-item">
                <Link
                  className="nav-link text-primary"
                  to="/contact"
                  onClick={handleClick}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <hr className="mx-auto" style={{ width: "98%" }} />

          {/* deskto */}
          <div className="d-md-flex text-dark justify-content-between align-items-center">
            {/* style={{ paddingRight: "0", paddingLeft: "2rem" }} */}
            <div className="ms-2">
              <a
                href={TermsPath2Study}
                // download="Terms"
                className="link-offset-2 link-underline link-underline-opacity-0"
                target="_blank"
              >
                {" "}
                <span className="cursor-pointer color-class">
                  Terms and Conditions
                </span>{" "}
              </a>
              <a
                href={Privacy}
                // download="Privacy"
                className="link-offset-2 link-underline link-underline-opacity-0"
                target="_blank"
              >
                <span className="cursor-pointer color-class">
                  | Privacy Policy
                </span>
              </a>
              <br />
              <span className="fw-light">
                Copyright © 2024 Path2Study. All Rights Reserved
              </span>
              {/* <span clazssName="text-start fw-light mt-2 d-md-none">
                Copyright © 2023 Path2Study. All Rights Reserved
              </span> */}
            </div>

            <div
              className="d-flex "
              style={{ paddingRight: "5rem", paddingLeft: "0rem" }}
            >
              <Link
                className="nav-link text-primary"
                to="https://www.facebook.com/people/Path2Study/61556788353979/"
                target="_blank"
              >
                <i
                  className="bi bi-facebook mx-2"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              </Link>

              <Link
                className="nav-link text-danger"
                to="https://www.instagram.com/path2study/"
                target="_blank"
              >
                <i
                  className="bi bi-instagram mx-2"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              </Link>

              <Link
                className="nav-link text-danger"
                to="https://www.linkedin.com/in/path2study-your-partner-for-student-loan-and-scholarships-3785582a9/"
                target="_blank"
              >
                <i
                  className="bi bi-linkedin text-primary mx-2"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              </Link>

              <Link
                className="nav-link text-dark"
                to="https://x.com/pathtwostudy?s=20"
                target="_blank"
              >
                <i
                  className="bi bi-twitter-x mx-2"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              </Link>

              {countryByGoogle === "usa" ||
              countryByGoogle === "United States" ? (
                <></>
              ) : (
                <div className=" ">
                  <a
                    href="https://api.whatsapp.com/send?phone=18478631388"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="wtsapp"
                  >
                    <img
                      src={require("../assests/img/whatsapp-logo.png")}
                      alt="Whatsapp Connect"
                      width={31}
                      height={31}
                    />
                    {/* <i className="bi bi-whatsapp mx-2 "  style={{ fontSize: "1.5rem",color:'#25d366' }}></i> */}
                  </a>
                </div>
              )}
            </div>

            {/* <div className="d-none d-md-block">
              <span className="text-start fw-light mt-2">
                Copyright © 2023 Path2Study. All Rights Reserved
              </span>
            </div> */}
          </div>
        </div>
      </footer>
      {/* <div className="d-flex justify-content-end position-relative bottom-0 end-0 w-100">
        <img
          src={require("../assests/img/fotterimg1.png")}
          alt=""
          className="img-fluid w-55"
        />
      </div> */}
    </>
  );
}

export default Footer;
