import React, { useEffect } from "react";
import AdminHome from "./AdminHome";
import AdminSideBar from "./AdminSideBar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Outlet, useNavigate } from "react-router-dom";
import AdminLogin from "./AdminLogin";
const AdminLayout = () => {
  const adminId = localStorage.getItem("adminId");

  const navigate = useNavigate(); // React Router hook for navigation

  useEffect(() => {
    // Check if userId is not present, then navigate to home
    // console.log("adminId -------->>>>>", adminId);
    if (!adminId) {
      // navigate("/admin/login");
    }
  }, [adminId, navigate]);
  return (
    // <Container fluid>
    // <Row>
    //   {/* Sidebar Section */}
    //   {/* <Col md={3} lg={2}> */}
    //     {/* <AdminSideBar /> */}
    //   {/* </Col> */}

    //   {/* Content Section md={9} -> 9 columns out of 12 */}
    //   <Col md={9} lg={10}>
    //     <Outlet />
    //   </Col>
    // </Row>

    adminId ? (
      <div>
        {/* <Row> */}
          {/* <Col md={9} lg={10}> */}
            <Outlet />
          {/* </Col> */}
        {/* </Row> */}
      </div>
    ) : (
      <AdminLogin />
    )
  );
};

export default AdminLayout;
