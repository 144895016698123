import React, { useState, useContext, useRef, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { ModalContext } from "../../contexts/ModalContext";
import SignInModal from "./SignInModall";

const ForgetPassSteps = ({showForget, setShowForget}) => {
  const {
    // openModal,
    // isOpen,
    // setIsOpen,
    // signUpOpen,
    // setSignUpOpen,
    // logout,
    showSigninModal,
    // setShowSigninModal,
    // steps,
    // closeModal,
    // handleNext,
    // handlePrevious,
    currentStep,
    // setCurrentStep,
    allCloseModal,
    forgetpasssteps,
  } = useContext(ModalContext);

  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // closeModal();
        allCloseModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [allCloseModal]);
  // console.log("forgetpass---> login page", showSigninModal);
  // // console.log("isopen---> login page", isOpen);
  // console.log("forgetpass---> showForget", showForget);
  return (
    // backdrop="static" to make modal unclose untill form is submitted
    // n the MultiStepModal component, the backdrop="static" attribute is added to the Modal component. This prevents the modal from being closed when clicking outside (backdrop="static" makes the modal static and does not close it when clicking outside).size="lg"
    <>
      <Modal show={true} onHide={allCloseModal}
        // className={`${showSigninModal ? "d-none" : ""}`}
      
      >
        <div ref={modalRef}>
          {/* <Modal.Header closeButton> */}
          <Modal.Header className="d-grid justify-content-center">
            <Modal.Title >
              <p className="fs-1 mb-0 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 40 40"
                  fill="none"
                  className="mt-md-2"
                >
                  <path
                    d="M31.5 13.6666H35C35.4641 13.6666 35.9093 13.8466 36.2374 14.1669C36.5656 14.4873 36.75 14.9218 36.75 15.3749V35.8749C36.75 36.328 36.5656 36.7625 36.2374 37.0829C35.9093 37.4033 35.4641 37.5833 35 37.5833H7C6.53587 37.5833 6.09075 37.4033 5.76256 37.0829C5.43437 36.7625 5.25 36.328 5.25 35.8749V15.3749C5.25 14.9218 5.43437 14.4873 5.76256 14.1669C6.09075 13.8466 6.53587 13.6666 7 13.6666H10.5V11.9583C10.5 9.23978 11.6062 6.63266 13.5754 4.71041C15.5445 2.78816 18.2152 1.70825 21 1.70825C23.7848 1.70825 26.4555 2.78816 28.4246 4.71041C30.3938 6.63266 31.5 9.23978 31.5 11.9583V13.6666ZM28 13.6666V11.9583C28 10.1459 27.2625 8.40785 25.9497 7.12636C24.637 5.84486 22.8565 5.12492 21 5.12492C19.1435 5.12492 17.363 5.84486 16.0503 7.12636C14.7375 8.40785 14 10.1459 14 11.9583V13.6666H28ZM19.25 23.9166V27.3333H22.75V23.9166H19.25ZM12.25 23.9166V27.3333H15.75V23.9166H12.25ZM26.25 23.9166V27.3333H29.75V23.9166H26.25Z"
                    fill="#282828"
                  />
                </svg>
                {forgetpasssteps[currentStep].title}
              </p>
            </Modal.Title>
            <button
              type="button"
              className="btn-close close-button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={allCloseModal}
            ></button>
          </Modal.Header>

          <p className="mx-5 justify-content-center text-center fs-6 mb-0">
            {/* Don’t worry! We have got you covered. Enter your registered Email ID
            below to get the OTP. Once you enter the OTP you can reset your
            password. */}
            {forgetpasssteps[currentStep].subtitle}
          </p>

          <Modal.Body>
            {/* Dynamic components */}
            {forgetpasssteps[currentStep].component}
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
export default ForgetPassSteps;
