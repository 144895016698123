import React from "react";
import "./../styles/UserManagment.css";
// import { CiSearch } from "react-icons/ci";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AdminSideBar from "./AdminSideBar";
// import { GoArrowLeft } from "react-icons/go";
// import { GoArrowRight } from "react-icons/go";
// import { FaRegUser } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";
// import { LiaUserSolid } from "react-icons/lia";
import BreadCrumb from "./BreadCrumb";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { formatAmount } from "../../hooks/useFormattedAmount";
// import { API_BASE_URL } from "../../appConfig";
const UserManagment = () => {
  const [currency, setCurrency] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [wineerData, setWinnerData] = useState();
  const [wineerDataOne, setWinnerDataOne] = useState();

  const [wineerDataTwo, setWinnerDataTwo] = useState();
  const [userData, setUserData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [loading, setLoading] = useState(true);

  let adminToken = localStorage.getItem("adminToken");
  let adminId = localStorage.getItem("adminId");
  const navigate = useNavigate();

  //for winner section api

  React.useEffect(() => {
    const headers = {
      authorization: `bearer ${adminToken}`,
    };

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/getWinner/${adminId}`,
        //   `https://server-dot-path2study.el.r.appspot.com/admin/getWinner/${adminId}?scholarshipId=658befbeb98f2bb1c7b05f566`,

        {
          headers: headers,
        }
      )
      .then((response) => {
        setCurrency(response.data.currency)
        setWinnerData(response.data.winners);
        setWinnerDataOne(response.data.winners[1]);
        setWinnerDataTwo(response.data.winners[2]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const sendData = (user) => {
    // Use navigate to go to another component and pass data in state
    navigate("user-profile", { state: { myData: user } });

    window.scroll(0, 0);
  };

  React.useEffect(() => {
    const headers = {
      authorization: `bearer ${adminToken}`,
    };

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/getAllUsers/${adminId}`, {
        headers: headers,
      })
      .then((response) => {
        setCurrency(response.data.currency)
        setUserData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  let filteredUsers;

  if (searchTerm) {
    filteredUsers = userData?.filter((user) => {
      const userName = user?.name;
      const lowerSearchTerm = searchTerm?.toLowerCase();

      return (
        userName &&
        lowerSearchTerm &&
        userName.toLowerCase().includes(lowerSearchTerm)
      );
    });
  } else {
    // If searchTerm is not provided, return all data
    filteredUsers = userData;
  }

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    // onSearch(value);
  };

  //pegination
  const [currentPageUserManagment, setCurrentPageUserManagment] = useState(1);
  const recordsPerPageUserManagment = 15;

  // Calculate the index range for the current page
  const startIndexUserManagment =
    (currentPageUserManagment - 1) * recordsPerPageUserManagment;
  const endIndexUserManagment =
    startIndexUserManagment + recordsPerPageUserManagment;

  // Slice the data based on the current page
  const visibleDataUserManagment = filteredUsers?.slice(
    startIndexUserManagment,
    endIndexUserManagment
  );

  // Calculate the total number of pages
  const totalPagesUserManagment = Math.ceil(
    filteredUsers?.length / recordsPerPageUserManagment
  );

  const forSerialNumberUserManagemnt =
    recordsPerPageUserManagment * (currentPageUserManagment - 1);

  // Function to handle page change
  const handlePageChangeUserManagment = (newPageTwo) => {
    setCurrentPageUserManagment(newPageTwo);
  };
  //for add schlorship
  const addNavigte = useNavigate();
  const handleAddNavigation = () => {
    addNavigte("/admin/scholarship-management/addschlorship");
  };

  const updateToggle = (id, isActive) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/admin/updateUser/${adminId}/${id}`,
        { isActive: !isActive },

        {
          headers: { authorization: `bearer ${adminToken}` },
        }
      )
      .then((response) => {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/admin/getAllUsers/${adminId}`,
            {
              headers: { authorization: `bearer ${adminToken}` },
            }
          )
          .then((response) => {
            setUserData(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDownloadClick = () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/admin/AllUsersXL?startDate=${startDate}&endDate=${endDate}`
    );
  };
  return (
    <div className="UserManagment">
      <div className="d-flex justify-content">
        <div className="col-2">
          <AdminSideBar />
        </div>

        {loading ? (
          <div className="col-10  py-3 px-3">
            <div className="text-center mt-3">
              {/* <div className="spinner-border text-secondary" role="status">
                  <div className="sr-only text-center">Loading...</div>
                </div> */}
              <div className="loader-container">
                <div className="loader"></div>

                <div>Please wait while we fetch the latest data for you</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-10  py-3 px-3">
            <div className="d-flex">
              <BreadCrumb />
              <div className="px-4 py-3">
                <div className="d-flex justify-content-between">
                  <FaCircleUser size={25} className="mt-1 mx-2" />
                  <div className="align-middle mt-2">
                    {localStorage.getItem("adminName")}
                  </div>
                </div>
              </div>
            </div>
            <hr className=" my-1" />
            <div className="d-flex justify-content">
              <div
                className="col-lg-8 col-md-9 col-sm-4 mt-4  col-6 rounded-3 p-3"
                style={{ backgroundColor: "#F9FAFC" }}
              >
                <div className=" py-3 d-flex justify-content-between px-2">
                  <div className="col-4">
                    <h5 className="mt-2">Registered Users</h5>
                  </div>

                  <div className="col-5">
                    <div className="input-group rounded-5">
                      <div className="col-12 form-group has-search search-container">
                        <span className="fa fa-search form-control-feedback mt-1 "></span>
                        <input
                          type="text"
                          className="form-control text-start px-5
                         rounded-pill rounded-5 "
                          placeholder="Search by username"
                          aria-label="Search"
                          aria-describedby="searchIcon"
                          value={searchTerm}
                          onChange={handleSearch}
                          width={"100%"}
                          style={{
                            fontWeight: "400",
                            border: "revert-layer", // Remove default border on focus
                            outline: "none", // Remove default outline on focus
                            boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {userData?.length == 0 ? (
                  <div
                    className="bg-white pt-2 forBakgroundContainer rounded-3 bg-primary shadow-lg"
                    style={{ height: "92%" }}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "100%" }}
                    >
                      <div className="text-center mt-3">
                        <h4>Currently, there are no registered users.</h4>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="bg-white pt-2 forBakgroundContainer rounded-3 bg-primary shadow-lg"
                    style={{ overflowX: "auto", height: "92%" }}
                  >
                    {visibleDataUserManagment?.length === 0 ? (
                      <h6 className="text-center m-5">
                        No search results found
                      </h6>
                    ) : (
                      <table
                        className="table table-hover"
                        style={{ width: "100%" }}
                      >
                        <thead className="">
                          <tr className="text-center">
                            <td className="headingtext-color forTextSize">
                              S.No.
                            </td>
                            <td className="headingtext-color forTextSize">
                              User Name
                            </td>
                            {/* <td className="headingtext-color">User Id</td> */}
                            <td className="headingtext-color forTextSize">
                              Registered Date
                            </td>
                            <td className="headingtext-color forTextSize">
                              Account Activity
                            </td>
                            <td className="headingtext-color forTextSize">
                              Registered Email
                            </td>
                            <td className="headingtext-color forTextSize">
                              Action
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {visibleDataUserManagment?.map((user, index) => (
                            <tr>
                              <td
                                className="text-center forTextSize"
                                style={{ width: "4%" }}
                              >
                                {/* {index + 1
          } */}
                                {forSerialNumberUserManagemnt + (index + 1)}
                              </td>
                              <td
                                className="text-center forTextSize"
                                style={{ width: "25%" }}
                              >
                                {user?.name}
                              </td>
                              {/* <td>{user._id}</td> */}
                              <td
                                className="text-center forTextSize"
                                style={{ width: "15%" }}
                              >
                                {new Intl.DateTimeFormat("en-US").format(
                                  new Date(user?.createdAt)
                                )}
                              </td>
                              <td
                                className="text-center forTextSize"
                                style={{ width: "10%" }}
                              >
                                <div class="form-check form-switch d-flex align-items-center justify-content-center">
                                  <input
                                    class="form-check-input border-none shadow-none"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    //    checked={loan.isActive?
                                    // false:true}
                                    // onClick={()=>setNewChangedstatus(!loan.isActive)}  />
                                    // {

                                    // }
                                    // checked={loan.isActive ? true : false}
                                    // onClick={() =>
                                    //   handleActiveChange(
                                    //     loan.isActive,
                                    //     loan._id
                                    //   )
                                    // }
                                    checked={user?.isActive}
                                    onClick={() =>
                                      updateToggle(user._id, user?.isActive)
                                    }
                                  />
                                </div>
                              </td>
                              {/* <td>{user.createdAt}</td> */}
                              <td
                                className="text-center forTextSize"
                                style={{ width: "28%" }}
                              >
                                {user?.email}
                              </td>
                              <td
                                className="text-center "
                                style={{ width: "30%" }}
                              >
                                <button
                                  id="hover-button"
                                  type="button"
                                  className="btn btn-sm border-1 fs-sm border  pt-1 pb-1    rounded-3"
                                  onClick={() => sendData(user)}
                                >
                                  View Profile
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    {totalPagesUserManagment > 1 ? (
                      <div className="d-flex justify-content-between mx-3">
                        <button
                          disabled={currentPageUserManagment === 1}
                          onClick={() =>
                            handlePageChangeUserManagment(
                              currentPageUserManagment - 1
                            )
                          }
                          className="peginationButton custom-button fs-4 rotateArrow"
                        >
                          {/* <GoArrowLeft  size={25} className="col-12" /> */}↼
                        </button>
                        <div>
                          Page {currentPageUserManagment} of{" "}
                          {totalPagesUserManagment}
                        </div>
                        <button
                          disabled={
                            currentPageUserManagment === totalPagesUserManagment
                          }
                          onClick={() =>
                            handlePageChangeUserManagment(
                              currentPageUserManagment + 1
                            )
                          }
                          className="custom-button fs-4"
                        >
                          {/* <GoArrowRight size={25} /> */}⇀
                        </button>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>

              {/* ------------------- */}
              <div className="col-lg-4 col-md-4   col-sm-3  col-6 mt-3  rounded-5 ms-3 p-2   winner-Section">
                <div className="col bg-white rounded-3">
                  <div className="col-3 mt-1 px-3 py-3 ms-4">
                    <img
                      src={require("../assets/img/vector.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="col d-flex justify-content-between rounded-3">
                    <div className="col-8  px-3">
                      <h4 className="ms-4"> Total Users Registered</h4>
                    </div>
                    <div className="col-3 p-3">
                      <h4>{userData?.length}</h4>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between ">
                    <div className="col-6 px-2 pe-2">
                      <DatePicker
                        className="form-control"
                        selected={startDate ? startDate : null}
                        maxDate={new Date()}
                        dateFormat="MM-dd-yyyy"
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                    -
                    <div className="col-6 pe-2 px-2">
                      <DatePicker
                        className="form-control"
                        selected={endDate ? endDate : null}
                        maxDate={new Date()}
                        dateFormat="MM-dd-yyyy"
                        onChange={(date) => setEndDate(date)}
                      />
                    </div>
                  </div>
                  <div className="text-center pb-2">
                    <button
                      onClick={handleDownloadClick}
                      className=" rounded-3 border-0   download-reports mt-2 col-6 p-2"
                    >
                      Download Reports
                    </button>
                  </div>
                </div>

                {/* //scroll */}

                <div>
                  {/* div to delete */}

                  <div
                    className="rounded-3 mt-3 p-2 mb-0 colorForBackground"
                    // style={{ backgroundColor: "#F9FAFC" }}
                  >
                    {wineerData?.length !== 0 ? (
                      <div className="colorForBackground">
                        <h4 className="mt-3 pt-3 px-3 colorForBackground">
                          {" "}
                          Recent 3 Winners
                        </h4>
                        <div
                          className="bg-white rounded-3 px-3  overflow-y-scroll"
                          style={{ maxHeight: "600px" }}
                        >
                          {wineerData?.slice(0, 3)?.map((wineerData) => {
                            return (
                              <div className=" shadow p-3  rounded-3 mt-3">
                                <div className="" style={{ height: "350%" }}>
                                  <h6>
                                    {wineerData?.scholarship?.scholarshipName}
                                  </h6>
                                  <hr />
                                  <div className=" d-flex justify-content-between mt-2">
                                    <div className="col-5">
                                      <p className="dates">Start Date</p>
                                    </div>
                                    <div className="col-6">
                                      <p className="dates">
                                        {
                                          // wineerData
                                          // wineerData[0].createdAt

                                          wineerData &&
                                            new Date(
                                              wineerData?.scholarship?.createdAt
                                            )?.toLocaleDateString("en-US", {
                                              year: "numeric",
                                              month: "long",
                                              day: "numeric",
                                            })
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" d-flex justify-content-between mt-2">
                                    <div className="col-5">
                                      <p className="dates">End Date</p>
                                    </div>
                                    <div className="col-6">
                                      <p className="dates">
                                        {wineerData &&
                                          new Date(
                                            wineerData?.scholarship?.Deadline
                                          )?.toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                          })}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" d-flex justify-content-between mt-2">
                                    <div className="col-5">
                                      <p className="applicants">
                                        Total Applicants
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <p className="applicants">
                                        {
                                          wineerData?.scholarship?.appliedUsers
                                            ?.length
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" d-flex justify-content-between mt-2">
                                    <div className="col-5">
                                      <p className="applicants">
                                        Scholarship Amount
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <p className="applicants">
                                      {currency ? currency : "$"}{" "}
                                        {wineerData &&
                                          formatAmount(
                                            wineerData?.scholarship?.amount
                                          )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" mt-2">
                                    <div>
                                      <p className="applicants">
                                        Declared winner{" "}
                                      </p>
                                    </div>
                                    <div className=" d-flex justify-content-start">
                                      <div className="col-2">
                                        <div
                                          className={
                                            wineerData?.user?.profileImg
                                              ? "winner_background"
                                              : ""
                                          }
                                          // "winner_background"
                                        >
                                          {/* FaCircleUser */}
                                          {wineerData?.user?.profileImg ? (
                                            <div>
                                              <img
                                                src={
                                                  wineerData?.user?.profileImg
                                                }
                                                className=" rounded-circle"
                                                alt=""
                                                width={"50px"}
                                                height={"50px"}
                                              />
                                            </div>
                                          ) : (
                                            <div>
                                              <FaCircleUser size={50} />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="mx-3 my-lg-3">
                                        <h6>
                                          {wineerData && wineerData?.user?.name}
                                        </h6>
                                      </div>
                                      <div className="mx-3 my-lg-3">
                                        <h6 className=" text-end">
                                        {currency ? currency : "$"}
                                          {wineerData &&
                                            formatAmount(
                                              wineerData?.scholarship?.amount
                                            )}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div className="colorForBackground">
                        <h4 className="mt-3 pt-3 px-3 colorForBackground">
                          {" "}
                          Recent 3 Winners
                        </h4>
                        <div
                          className="bg-white rounded-3 px-3  overflow-y-scroll"
                          style={{ height: "550px" }}
                        >
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "100%" }}
                          >
                            <h5 className="text-center">
                              There are currently no declared winners yet.
                            </h5>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* --------------------------RECENT WINNERS SECTION--------------------- */}

            {/* //code to reuse */}

            {/* //code to reuse */}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserManagment;
