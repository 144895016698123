import React, { useEffect, useState, createRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/Editbank.css";

import AdminSideBar from "./AdminSideBar";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import BreadCrumb from "./BreadCrumb";
import { toast, ToastContainer } from "react-toastify";
import { FaCircleUser } from "react-icons/fa6";
// import Cropper from "react-cropper";
import {
  CountryDropdown,
  // RegionDropdown,
  // CountryRegionData,
} from "react-country-region-selector";
// import bayDefault from'../assets/img/bayDefault.png'
// import bydefault from "../assets/img/bydefault.png";
import BreadCrumbTwo from "./BreadCrumbTwo";
function EditBank() {
  //location
  const location = useLocation();
  const [editBankData, setEditBankData] = useState(location.state);

  const [newBlog, setNewBlog] = useState();
  const [imageUrl, setImageUrl] = useState("");

  // console.log(editBankData.bankLogo);
  // const Id = editBankData._id;

  const adminToken = localStorage.getItem("adminToken");
  const adminId = localStorage.getItem("adminId");

  const [editData, setEditData] = useState(null);
  // const [previewImage, setPreviewImage] = useState(editBankData?.bankLogo);

  const [newImage, setNewImage] = useState();

  const culturalIdentityData = [];

  const [hasDesiredStringFirst, setHasDesiredStringFirst] = useState(
    editBankData &&
      Array.isArray(editBankData.culturalIdentity) &&
      editBankData.culturalIdentity.some((element) => {
        return element === "US citizen or GC holder with US cosigner";
      })
  );

  const [hasDesiredStringSecond, setHasDesiredStringSecond] = useState(
    editBankData &&
      Array.isArray(editBankData.culturalIdentity) &&
      editBankData.culturalIdentity.some((element) => {
        return element === "US citizen or GC holder with no US cosigner";
      })
  );

  const [hasDesiredStringThree, setHasDesiredStringThree] = useState(
    editBankData &&
      Array.isArray(editBankData.culturalIdentity) &&
      editBankData.culturalIdentity.some((element) => {
        return element === "Non-US citizen";
      })
  );

  const handleCheckboxChange = (e) => {
    //to change checked status
    if (e.target.value === "US citizen or GC holder with US cosigner") {
      setHasDesiredStringFirst(!hasDesiredStringFirst);
    }
    if (e.target.value === "US citizen or GC holder with no US cosigner") {
      setHasDesiredStringSecond(!hasDesiredStringSecond);
    }
    if (e.target.value === "Non-US citizen") {
      setHasDesiredStringThree(!hasDesiredStringThree);
    }
    //to push valua accrodign to statu
  };

  const navigate = useNavigate();
  const saveChanges = () => {
    if (hasDesiredStringFirst) {
      culturalIdentityData.push("US citizen or GC holder with US cosigner");
    }

    if (hasDesiredStringSecond) {
      culturalIdentityData.push("US citizen or GC holder with no US cosigner");
    }

    if (hasDesiredStringThree) {
      culturalIdentityData.push("Non-US citizen");
    }

    const dataForCultureIdentity =
      // = JSON.stringify(
      culturalIdentityData;
    // );

    // delete editData.culturalIdentity;
    if (dataForCultureIdentity.length === 0) {
      editData.culturalIdentity = ["ALL"];
    } else {
      editData.culturalIdentity = dataForCultureIdentity;
    }

    delete editData.country;
    if (selectedCountryTwo !== "") {
      editData.country = selectedCountryTwo;
    } else {
      editData.country = "ALL";
    }

    editData.bankLogo = newImage;
   

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}admin/updateLoan/${adminId}/${editBankData._id}`,
        editData,
        {
          headers: {
            authorization: `bearer ${adminToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {

        setEditData(response.data);
        setShowSave(false);

        toast.success("lender updated succesfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setEditBankData(null);

        setTimeout(() => {
          navigate("/admin/loan-management/");
        }, 3000);
      })
      .catch((error) => {
        console.log("api error", error);
      });
  };

  const [show, setShow] = useState(false);
  const [showSave, setShowSave] = useState(false);

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleSaveClose = () => {
    setShowSave(false);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    setShowSave(true);
  };

  const handleDiscardClick = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/admin/deleteLoan/${adminId}/${editBankData._id}`,
        {
          headers: {
            authorization: `bearer ${adminToken}`,
          },
          data: { isdelete: true },
        }
      )

      .then((response) => {
        
        setEditData(null);
        // setPreviewImage(null);
        setEditBankData(null);
        setShow(false);
        // window.location.reload();
        toast.success("lender deleted succesfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          // onClose: navigate("/admin/scholarshipmanagement/")
          // setTimeout(delayFunction, 4000))
        });
        setTimeout(() => {
          navigate("/admin/loan-management/");
        }, 3000);
      })
      .catch((error) => {
        console.log("api error", error);
      });
  };

  useEffect(() => {
    setEditData(location.state);
  }, [location.state]);

  const handleEditChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
    setEditBankData({ ...editBankData, [e.target.name]: e.target.value });

    if (e.target.type === "file") {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setEditBankData({
          ...editBankData,
          bankLogo: reader.result, // Update the bankLogo with the new image data
        });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      setEditData({
        ...editData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // cropper //

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    // Fetch the list of countries from the REST Countries API
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleCountrySelect = (selectedCountry) => {
    setEditBankData({
      ...editData,
      country: selectedCountry,
    });
    setShowMap(false); // This line should only execute if a country is selected
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState();


  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setShowMap(true); // Always show the country list when the input is being typed
  };
  const filteredCountries = countries.filter((country) =>
    country.name.common.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [showMap, setShowMap] = useState(true);

 
  const [selectedCountryTwo, setSelectedCountryTwo] = useState(
    editBankData?.country
  );

  const handleCountryChange = (country) => {
    setSelectedCountryTwo(country);
  };

  return (
    <div className="">
      <ToastContainer />
      <div className="d-flex justify-content">
        <div className="col-2">
          <AdminSideBar />
        </div>
        <div style={{ backgroundColor: "#EFF2F5" }} className="col-10  pt-0">
          {/* <div className="d-flex">
            <BreadCrumb className="" />
            <div className="mt-3 text-muted pe-2 cursor-pointer">
              {localStorage.getItem("adminName")}
            </div>
          </div> */}
          <div className="px-3">
            <div className="d-flex mt-3">
              <BreadCrumbTwo />
              {/* <BreadCrumb /> */}
              <div className="px-4 py-3">
                <div className="d-flex justify-content-between">
                  <FaCircleUser size={25} className="mt-1 mx-2" />
                  <div className="align-middle mt-2">
                    {localStorage.getItem("adminName")}
                  </div>
                </div>
              </div>
            </div>
            <hr className=" my-1" />
          </div>
          <div className="px-4 mt-5">
            <div
              style={{ backgroundColor: "#F9FAFC" }}
              className="rounded-3 shadow p-4 mt-3"
            >
              <h4>+Edit lender details</h4>
              <form>
                <div className="shadow rounded-3 bg-white  d-flex flex-wrap justify-content-between ">
                  <div className="col-4 p-4 ">
                    <label>Lender name</label>
                    <input
                      type="text"
                      className="form-control shadow-none input"
                      name="bankName"
                      value={
                        editData?.bankName
                          ? editData?.bankName?.charAt(0)?.toUpperCase() +
                            editData?.bankName?.slice(1)
                          : ""
                      }
                      // value={editData?.bankName || ""}
                      onChange={handleEditChange}
                    />

                    <label className="mt-3">Fixed Interest Rates</label>
                    <input
                      type="text"
                      className="form-control shadow-none input"
                      name="fixedInterestRate"
                      defaultValue={editBankData?.fixedInterestRate || ""}
                      onChange={handleEditChange}
                    />

                    <label className="mt-3">About the loan</label>
                    <textarea
                      type="text"
                      rows={6}
                      className="form-control shadow-none input"
                      name="aboutTheLoan"
                      defaultValue={
                        editData?.aboutTheLoan
                          ? editData?.aboutTheLoan?.charAt(0)?.toUpperCase() +
                            editData?.aboutTheLoan?.slice(1)
                          : ""
                      }
                      // defaultValue={editBankData?.aboutTheLoan || ""}
                      onChange={handleEditChange}
                    />

                    <label className="mt-3">About the Lender (Know More)</label>
                    <textarea
                      type="text"
                      rows={6}
                      className="form-control shadow-none input"
                      name="aboutTheBank"
                      defaultValue={
                        editData?.aboutTheBank
                          ? editData?.aboutTheBank?.charAt(0)?.toUpperCase() +
                            editData?.aboutTheBank?.slice(1)
                          : ""
                      }
                      // defaultValue={editBankData?.aboutTheBank || ""}
                      onChange={handleEditChange}
                    />

                    <label className="mt-3">Please add Lender logo/image</label>
                    <label
                      htmlFor="file-input"
                      className="border-container cursor-pointer-container rounded-3 r p-3"
                    >
                      <div className="text-center">
                        <img
                          src={require("../../assests/img/Icon.png")}
                          className=""
                        />
                      </div>
                      <label
                        htmlFor="file-input"
                        className="text-center text-muted"
                      >
                        Browse and choose the files you want to upload from your
                        system.{" "}
                      </label>
                      <input
                        id="file-input"
                        className="d-none"
                        name="bankLogo"
                        type="file"
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];

                          if (selectedFile) {
                            setNewImage(selectedFile);
                            setImageUrl(URL.createObjectURL(selectedFile));
                          }
                        }}
                      ></input>
                    </label>
                    {/* <button className="btn w-100 rounded mt-2 border-1  border border-primary">
                Upload Bank Logo
              </button> */}
                    <label className="mt-3">
                      Add redirection URL link of lender
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none input "
                      placeholder="URL link of bank website"
                      name="redirectionalUrl"
                      value={editBankData?.redirectionalUrl}
                      onChange={handleEditChange}
                    ></input>
                  </div>

                  <div className="col-4 p-4">
                    {/* <label className="">Country</label>
                  <input
                    type="text"
                    className="form-control shadow-none input"
                    name="country"
                    value={editBankData?.country}
                    onChange={handleEditChange}
                    onInput={handleSearch}
                    autoComplete="off"
                  ></input> */}
                    <label className="">Country</label>
                    <div className="">
                      <CountryDropdown
                        value={selectedCountryTwo}
                        onChange={handleCountryChange}
                        className="form-select input"
                      />
                    </div>

                    {showMap !== true ? (
                      ""
                    ) : (
                      <div className="country-container">
                        {searchTerm && filteredCountries.length > 0 && (
                          <div className="country">
                            {filteredCountries.map((country, index) =>
                              index <= 5 ? (
                                <div
                                  className="country-background px-3"
                                  key={index}
                                  onClick={() =>
                                    handleCountrySelect(country.name.common)
                                  }
                                >
                                  {country.name.common}
                                </div>
                              ) : null
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    <label className="mt-3">Variable interest Rates</label>
                    <input
                      type="text"
                      className="form-control shadow-none input"
                      name="variableInterestRate"
                      value={editBankData?.variableInterestRate}
                      onChange={handleEditChange}
                    ></input>

                    <div>
                      <label className="mt-3">Disclosure</label>
                      <textarea
                        type="text"
                        rows={6}
                        className="form-control shadow-none input"
                        name="disclosure"
                        defaultValue={
                          editData?.disclosure
                            ? editData?.disclosure?.charAt(0)?.toUpperCase() +
                              editData?.disclosure?.slice(1)
                            : ""
                        }
                        // value={editBankData?.disclosure}
                        onChange={handleEditChange}
                      />
                    </div>
                    {/* <div className="mt-3">
                    <label>Cultural Identity</label>

                    <div className="d-flex flex-wrap justify-content-between mt-2">
                      <div className="col-1">
                        <input
                          type="radio"
                          className=""
                          name="culturalIdentity"
                          value="US citizen or GC holder with US cosigner"
                          checked={
                            editBankData?.culturalIdentity ===
                            "US citizen or GC holder with US cosigner"
                              ? true
                              : false
                          }
                          onChange={handleEditChange}
                        />
                      </div>
                      <div className="col-11">
                        US citizen or GC holder with US cosigner
                      </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-between mt-3">
                      <div className="col-1">
                        <input
                          type="radio"
                          className=""
                          name="culturalIdentity"
                          value="US citizen or GC holder with no US cosigner"
                          checked={
                            editBankData?.culturalIdentity ===
                            "US citizen or GC holder with no US cosigner"
                              ? true
                              : false
                          }
                          onChange={handleEditChange}
                        />
                      </div>
                      <div className=" col-11 ">
                        {" "}
                        US citizen or GC holder with no US cosigner
                      </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-between mt-3">
                      <div className="col-1">
                        <input
                          type="radio"
                          className=""
                          name="culturalIdentity"
                          value="Non-US citizen"
                          checked={
                            editBankData?.culturalIdentity === "Non-US citizen"
                              ? true
                              : false
                          }
                          onChange={handleEditChange}
                        />
                      </div>
                      <div className="col-11">Non-US citizen</div>
                    </div>
                  </div> */}

                    <h6 className="mt-4">Cultural Identity</h6>

                    <div className="d-flex">
                      <div className="col-1">
                       
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          checked={hasDesiredStringFirst}
                          value="US citizen or GC holder with US cosigner"
                          onChange={(e) => handleCheckboxChange(e)}
                        />
                      </div>
                      <div for="vehicle1" className="col-11">
                        {" "}
                        {/* {editBankData?.culturalIdentity[0]} */}
                        <div>US citizen or GC holder with US cosigner</div>
                      </div>
                    </div>

                    <div className="d-flex mt-2">
                      <div className="col-1">
                        
                        <input
                          type="checkbox"
                          id="vehicle2"
                          name="vehicle2"
                          value="US citizen or GC holder with no US cosigner"
                          // checked={
                          //   editBankData?.culturalIdentity[1] ===
                          //   "US citizen or GC holder with no US cosigner"
                          // }
                          // onChange={(e) => {}}

                          checked={hasDesiredStringSecond}
                          onChange={(e) => handleCheckboxChange(e)}
                        />
                      </div>
                      <div for="vehicle2" className="col-11">
                        <div>US citizen or GC holder with no US cosigner</div>
                      </div>
                      <br />
                    </div>

                    <div className="d-flex mt-2">
                      <div className="col-1">
                        {console.log(
                          "third culturalIdentity" +
                            editBankData?.culturalIdentity[2]
                        )}
                        <input
                          type="checkbox"
                          id="vehicle3"
                          name="vehicle3"
                          checked={hasDesiredStringThree}
                          // checked={
                          //   editBankData?.culturalIdentity[2] ===
                          //   "Non-US citizen"
                          // }
                          value="Non-US citizen"
                          onChange={(e) => handleCheckboxChange(e)}
                        />
                      </div>
                      <div for="vehicle3" className="col-11">
                        <div>Non-US citizen</div>
                      </div>
                    </div>

                    {
                      // editBankData?.culturalIdentity.map((element,i) => {
                      //   return (
                      //     <div >
                      //       {/* Your content goes here */}
                      //       <li> <input
                      //                         type="checkbox"
                      //                         id="vehicle1"
                      //                         name="vehicle1"
                      //                         className="col-1"
                      //                         checked={checked}
                      //                         onChange={(e) => {
                      //                           // handle checkbox change if needed
                      //                         }}
                      //                         onClick={()=>setChecked(!checked)}
                      //                       />{element}</li>
                      //     </div>
                      //   );
                      // })
                    }

                    {/* <input type="submit" value="Submit" /> */}
                    {/* </form> */}
                  </div>
                  {/* card for preview */}
                  <div className="col-4 p-4 ">
                    <h5 className="m-0">PREVIEW</h5>
                    <div class="card rounded">
                      <div className="d-flex align-items-center justify-content-center flex-wrap rounded ">
                        <div className="">
                          {/* {console.log("nnnnnnnnnnn", editBankData.bankLogo)} */}
                          {/* {newImage ? (
                            <img
                              src={require("../assets/img/placeLoan.png")}
                              alt=""
                              className="rounded-top img-fluid p-3"
                              name="bankLogo"
                            />
                          ) : (
                            <img
                              src={editBankData?.bankLogo}
                              // src={require("../assets/img/bydefault.png")}
                              alt=""
                              className="rounded-top img-fluid p-3"
                              name="bankLogo"
                            />
                          )} */}

                          {/* {console.log(
                            URL.createObjectURL(editBankData?.bankLogo)
                          )} */}

                          {editBankData?.bankLogo || imageUrl ? (
                            <div>
                              {imageUrl ? (
                                <img
                                  src={imageUrl}
                                  // {
                                  //   () => {
                                  //     return URL.createObjectURL(
                                  //       editBankData?.bankLogo
                                  //     );
                                  //   }
                                  // =>{
                                  // return URL.createObjectURL(editBankData?.bankLogo)

                                  alt=""
                                  className="rounded-top img-fluid p-3"
                                  name="newImage"
                                />
                              ) : (
                                <img
                                  src={editBankData?.bankLogo}
                                  // {
                                  //   () => {
                                  //     return URL.createObjectURL(
                                  //       editBankData?.bankLogo
                                  //     );
                                  //   }
                                  // =>{
                                  // return URL.createObjectURL(editBankData?.bankLogo)

                                  alt=""
                                  className="rounded-top img-fluid p-3"
                                  name="bankLogo"
                                />
                                // <img
                                //   src={require("../assets/img/placeLoan.png")}
                                //   alt=""
                                //   className="rounded-top img-fluid p-3"
                                //   name="bankLogo"
                                // />
                              )}
                            </div>
                          ) : (
                            <div className="">
                              <img
                                src={require("../assets/img/placeLoan.png")}
                                alt=""
                                className="rounded-top img-fluid p-3"
                                name="bankLogo"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="card-body mt-0 pt-0">
                        <h5 class="card-title">{editBankData?.bankName}</h5>

                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <label className="text-muted col-6">
                            Variable Rates:
                          </label>
                          <h6 className="col-6">
                            {editBankData?.variableInterestRate}
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between">
                          <label className="text-muted col-6">
                            Fixed Rates:
                          </label>
                          <h6 className="col-6">
                            {editBankData?.fixedInterestRate}
                          </h6>
                        </div>
                        <div>
                          <p
                            class="card-text overflow-auto"
                            style={{ height: "97px" }}
                          >
                            <ul>
                              <li>{editBankData?.aboutTheLoan}</li>
                            </ul>
                          </p>
                        </div>
                        <div>
                          <span
                            className="d-flex justify-content-end text-decoration-underline  me-1 cursor-pointer "
                            // data-bs-toggle="modal"
                            // data-bs-target={`#KnowmoreModal-${item._id}`}
                            // key={`trigger-${item._id}`}
                            disabled
                          >
                            Know More &gt;&gt;
                            {/* pop up */}
                          </span>
                        </div>
                        <div className=" d-flex justify-content-between">
                          <div className="col-6 pe-1">
                            <div className="btn border w-100">Disclosure</div>
                          </div>
                          <div className="col-6 px-1">
                            <div className="btn btn-primary w-100">
                              Apply Now
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="text-secondary mt-3">
                      This is the how your newly added lender card will appear
                      to the users of the website.
                    </p>
                    <div className="d-flex justify-content-between mt-3 ">
                      <div className="col-6 pe-1">
                        <button
                          onClick={handleShow}
                          className="btn border w-100"
                        >
                          Delete
                        </button>
                      </div>

                      <div className="col-6 px-1">
                        <button
                          className="btn btn-primary w-100"
                          onClick={handleSaveClick}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showSave} onHide={handleSaveClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm save changes</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body> */}
        {/* Woohoo, you are reading this text in a modal! */}
        <p className="px-3">
          All the lender information will be saved. Do you want to proceed?
        </p>

        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={handleSaveClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => saveChanges()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        {" "}
        {/* Corrected the prop */}
        <Modal.Header closeButton>
          <Modal.Title>Delete Lender ?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-0 pt-0">
          Are you sure you want to delete this lender? Once deleted, this action
          cannot be undone.
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={handleClose}>
            {" "}
            {/* Corrected the function name */}
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDiscardClick}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={showCropImage} onHide={handleCropImage}>
        <Modal.Header closeButton>
          <Modal.Title>Crop Lender Image</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <div className="px-5">
              <Cropper
                ref={cropperRef}
                style={{ height: "auto", width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={images}
                viewMode={1}
                minCropBoxHeight={200}
                minCropBoxWidth={384}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
                guides={true}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCropImage}>
            Cancel
          </Button>
          <Button variant="primary" onClick={getCropData}>
            Crop Image
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}
export default EditBank;
