import React, { useContext, useEffect, useState } from "react";
import "../style/loanpage.css";
import { Link } from "react-router-dom";
// import { API_BASE_URL } from "../appConfig";
import axios from "axios";
import { UserContext } from "../contexts/UserContext";
import NotShowData from "./NotShowData";
import { ModalContext } from "../contexts/ModalContext";
import CulturalIdentityPopup from "./CulturalIdentityPopup";
import { CountryContext } from "../contexts/CountryContext";
import { FaArrowRightLong } from "react-icons/fa6";
import LoansSecuringFinancialAidIND from "./LoansSecuringFinancialAidIND";
import LoansSecuringFinancialAidUSA from "./LoansSecuringFinancialAidUSA";
import Loan_AccordianUSA from "./Loan_AccordianUSA";
import Loan_AccordianIND from "./Loan_AccordianIND";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const BannerIND = () => {
  const swiper = useSwiper();
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={0}
      slidesPerView={1}
      autoplay={{ delay: 2500 }}
      // navigation
      pagination={{
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + ' bg-primary">.</span>'; // Modify the content to only show the bullet without the dot
        },
      }}
      // scrollbar={{ draggable: true }} // scroll bar down
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log("slide change")}
    >
      <SwiperSlide>
        <div className="container" style={{ marginTop: "6rem" }}>
          <div className="card mb-3 border border-white">
            <div className="row">
              <div className="col-md-6 mt-md-5">
                <div
                  className="card-body font-monospace text-sm-start ms-0 ps-0 hide-br"
                  style={{ marginLeft: "5rem" }}
                >
                  <span className="fs-1 text fw-light mt-2 d-sm-inline text-sm-start text-center">
                    GO GLOBAL DISCOVER STUDENT{" "}
                  </span>
                  <span className="fs-1 text-primary d-sm-inline text-sm-start text-center ">
                    LOAN CHOICES FOR YOUR EDUCATION
                  </span>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <img
                  src={require("../assests/img/loanindia.png")}
                  className="img-fluid rounded-pill"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="container" style={{ marginTop: "6rem" }}>
          <div className="card mb-3 border border-white">
            <div className="row">
              <div className="col-md-6 mt-md-4">
                <div
                  className="card-body text-sm-start ms-0 ps-0 hide-br font-monospace"
                  style={{ marginLeft: "5rem" }}
                >
                  <span className="fs-1 text fw-light mt-2 d-sm-inline text-sm-start text-center">
                    EMPOWERING{" "}
                  </span>
                  <span className="fs-1 text-primary d-sm-inline text-sm-start text-center ">
                    INTERNATIONAL STUDENTS{" "}
                  </span>
                  <span className="fs-1 text fw-light mt-2 d-sm-inline text-sm-start text-center">
                    TO REACH THEIR DREAMS WITH{" "}
                  </span>
                  <span className="fs-1 text-primary d-sm-inline text-sm-start text-center ">
                    TAILORED LOANS
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src={require("../assests/img/loanInd112.png")}
                  className="img-fluid "
                  alt="..."
                />
                {/* old image */}
                {/* <img
                  src={require("../assests/img/loanind.png")}
                  className="img-fluid"
                  alt="..."
                /> */}
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
const BannerUSA = () => {
  return (
    <div className="container " style={{ marginTop: "6rem" }}>
      <div className="card mb-3 border border-white ">
        <div className="row">
          <div className="col-md-6 mt-lg-5 ">
            <div
              className="card-body font-monospace text-sm-start ms-0 ps-0 hide-br"
              style={{ marginLeft: "5rem" }}
            >
              {/* <h5 className="card-title fs-1"> */}
              {/* Find The Best Abroad Education Loans In Usa */}
              {/* EXPAND YOUR HORIZONS
WITH STUDENT LOANS FOR YOUR EDUCATION */}
              <span className="fs-1 text fw-light mt-2 d-sm-inline text-sm-start text-center">
                EXPAND YOUR HORIZONS{" "}
              </span>
              <br />
              <span className="fs-1 text-primary d-sm-inline text-sm-start text-center ">
                WITH STUDENT LOANS FOR{" "}
              </span>
              <span className="fs-1 text fw-light mt-2 d-sm-inline text-sm-start text-center">
                YOUR EDUCATION
              </span>
              {/* Expand Your Horizons with Student Loans for Studying in USA */}
              {/* </h5> */}
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <img
              src={require("../assests/img/loanusa.png")}
              className="img-fluid rounded-pill"
              alt="..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const handleApplyClick = (loanId) => {
  window.scrollTo(0, 0);
  const fetchCityData = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const updatedToken = `Bearer ${userToken} `;
      const userId = localStorage.getItem("userId");
      const authAxios = axios.create({
        // baseURL: API_BASE_URL,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          authorization: updatedToken,
        },
      });
      const response = await authAxios.patch(
        `${process.env.REACT_APP_BASE_URL}/visit/${userId}/${loanId}`
        // `${API_BASE_URL}/visit/${userId}/${loanId}`
        //   https://server-dot-path2study.el.r.appspot.com/visit/{userId}/{loanId}
      );
    } catch (error) {
      console.log("Error fetching getVisiters data:", error);
    }
  };

  fetchCityData();
};

const Cards = ({ data }) => {
  return (
    <div key={data._id}>
      <div className="">
        <div className="card mb-3 rounded-3">
          <div className="row g-0">
            <div className="col-md-4">
              {/* <img src="..." className="img-fluid rounded-start" alt="..." /> */}
              {data.bankLogo ? (
                <img
                  src={data.bankLogo}
                  // src={require("../assests/img/loanbank1.png")}
                  className="img-fluid rounded-4 p-3   w-100 image-center-loan-card "
                  alt="..."
                  // style={{ marginTop: "5.5rem" }}
                />
              ) : (
                <img
                  src={require("../assests/img/placeLoan.png")}
                  className="card-img-top image-center-loan-card"
                  alt="..."
                  // style={{ marginTop: "5.5rem" }}
                />
              )}
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">
                  {data.bankName.toUpperCase()}
                </h5>
                <hr />
                <span className="card-text">
                  {/* This is a wider card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer. */}

                  {/* {splitTextMap[data._id]?.map((line, index) => (
                          <li key={index}>{line}</li>
                        ))} */}
                  <div className="loan-overflow-scroll ">
                    <span>{data.aboutTheLoan}</span>
                  </div>
                </span>

                <div className="d-flex m-0">
                  <div>
                    <span>Variable Rates:</span>
                    <p>Fixed Rates:</p>
                  </div>
                  <div className="ms-3">
                    <span>{data.variableInterestRate}</span>
                    <p>{data.fixedInterestRate}</p>
                  </div>
                  {/* show on md devices hide on small devices */}
                  {/* <span
                    className="mt-4 text-decoration-underline  me-1 cursor-pointer "
                    data-bs-toggle="modal"
                    data-bs-target={`#KnowmoreModal-${data._id}`}
                    key={`trigger-${data._id}`}
                    style={{ marginLeft: "17rem" }}
                  >
                    Know more &gt;&gt;
                  </span> */}
                </div>
                <div>
                  {/* show on small devices  */}
                  <span
                    className="d--none  d-flex justify-content-end text-decoration-underline  me-1 my-2 cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target={`#KnowmoreModal-${data._id}`}
                    key={`trigger-${data._id}`}
                  >
                    Know more &gt;&gt;
                  </span>
                </div>
                {/* <p className="card-text">
                  <small className="text-body-secondary">
                    Last updated 3 mins ago
                  </small>
                </p> */}

                {/* modal logic */}
                <div className="d-flex justify-content-between">
                  {/* Disclosure modal */}
                  <div className="col-6 ">
                    <button
                      type="button"
                      className="btn btn-outline-secondary rounded w-100 me-3"
                      data-bs-toggle="modal"
                      data-bs-target={`#DisclosureModal-${data._id}`}
                      key={`trigger-${data._id}`}
                      // onClick={()=>handleDisclosure(item._id)}
                    >
                      Disclosure
                      {/* pop up */}
                    </button>
                  </div>

                  <div className=""></div>
                  <div className="col-6 ">
                    <Link to={data.redirectionalUrl} target="_blank">
                      <button
                        type="button"
                        className="rounded-1 border-0 bg-primary pr-sm-3 w-100 ms-1"
                        onClick={() => handleApplyClick(data._id)}
                        // data-bs-toggle="modal"
                        // data-bs-target="#ApplyModal"
                      >
                        {/* {console.log(data._id)} */}
                        {/* rederect to banks links */}
                        <h6 className="text-white text-center mt-2 ">
                          Apply Now
                        </h6>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const handleClick = () => {
  window.scrollTo(0, 0);
};

const LoansPage_v1 = () => {
  const { countryByGoogle, selectedCountry } = useContext(CountryContext);
  //   const [data, setData] = useState([]); // add the to show search results
  const [CardData, setCardData] = useState([]);
  const [isProfileComlete, setIsProfileComlete] = useState(false);
  const { isUserLoggedIn } = useContext(UserContext);
  const { openModal } = useContext(ModalContext);
  const [noMatchFound, setNoMatchFound] = useState(false); //  for not match condition ui card
  const [isLoading, setIsLoading] = useState(false); //  for loader
  // condition for checking user logged in or not
  useEffect(() => {
    if (!isUserLoggedIn) {
      openModal();
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // Set loading state to true before fetching data
        const userToken = localStorage.getItem("userToken");
        const updatedToken = `Bearer ${userToken} `;
        const userId = localStorage.getItem("userId");
        const authAxios = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          // baseURL: API_BASE_URL,
          headers: {
            authorization: updatedToken,
          },
        });
        const response = await authAxios.get(
          `${process.env.REACT_APP_BASE_URL}/getAllLoans/${userId}`,
          // `${API_BASE_URL}/getAllLoans/${userId}`,
          // `${API_BASE_URL}/getAllLoans/${userId}?country=${countryByGoogle}`,
          {
            headers: {
              Authorization: `bearer ${userToken}`,
            },
          }
        );

        if (
          response.data.isProfileComlete === false &&
          response.data?.fieldsToUpdate?.includes("culturalIdentity")
        ) {
          setopenmodalLoan(true);
        } else {
          setopenmodalLoan(false);
        }

        if (response.data.isProfileComlete === true) {
          setIsProfileComlete(true);
        } else {
          setIsProfileComlete(false);
        }
        if (response.data?.loans) {
          const allLoans = response.data.loans;
          const ActiveLoans = allLoans.filter(
            (item) => item.isActive === true
            // && item.country?.toLowerCase() === selectedCountry?.toLowerCase()
          );
          // console.log("ActiveLoans",ActiveLoans);
          // console.log("check spelling",response.data?.loans?.filter((item)=> item.country), selectedCountry?.toLowerCase());
          setCardData(ActiveLoans); // Assuming data is an array
          if (ActiveLoans?.length === 0 || CardData?.length === 0) {
            setNoMatchFound(true);
          } else {
            setNoMatchFound(false);
          }
          setIsLoading(false);
        }
        // else {
        //   handleOpenModal();
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Set loading state back to false after fetching data
      }
    };

    // condition for checking user logged in or not
    if (isUserLoggedIn) {
      fetchData();
    }

    // fetchData();// comment after done and uncomment when diploy/push
  }, [isUserLoggedIn]);

  // }, [selectedCountry, countryByGoogle, isUserLoggedIn]); //  old working for selected country dropdown

  // To open model Cultural activity
  const [culturalIdentity, setculturalIdentity] = useState("");
  const [openmodalLoan, setopenmodalLoan] = useState(false);
  const handleOpenModal = () => {
    setopenmodalLoan(true);
  };
  const handleCloseModal = () => {
    setopenmodalLoan(false);
  };

  const handleRadioChange = (event) => {
    // Update the state with the selected value
    setculturalIdentity(event.target.value);
  };

  // Submit
  const handleLoanReq = async () => {
    // console.log("value selected ", culturalIdentity);
    try {
      const userId = localStorage.getItem("userId");
      const userToken = localStorage.getItem("userToken");
      const updatedToken = `Bearer ${userToken}`;
      // console.log('updatedToken',updatedToken)

      const postData = {
        culturalIdentityy: culturalIdentity,
      };
      // const postData = { culturalIdentity: culturalIdentity }; // old working
      // return
      const authAxios = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        // baseURL: API_BASE_URL,
        headers: {
          authorization: updatedToken,
        },
      });
      const response = await authAxios.put(
        `${process.env.REACT_APP_BASE_URL}/updateUser/${userId}`,
        // `${API_BASE_URL}/updateUser/${userId}`,
        postData
      );
      handleCloseModal();
      window.location.reload(); // remove after code done
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div>
      {/* <Banner /> */}

      {selectedCountry.toLowerCase() === "usa" ? <BannerUSA /> : <BannerIND />}
      {/* {selectedCountry === 'United States'? <BannerUSA /> : <BannerIND />} */}
      {/* new component for loasnfinancialAid */}
      {/* {console.log("loans page country", selectedCountry)} */}
      {selectedCountry.toLowerCase() === "usa" ? (
        <LoansSecuringFinancialAidUSA />
      ) : (
        <LoansSecuringFinancialAidIND />
      )}
      {!isUserLoggedIn && isUserLoggedIn !== undefined && <NotShowData />}

      {isUserLoggedIn && (
        <div className="container d-flex">
          <div className="container loan-overflow">
            {/* Add this loan-overflow to make scrollable */}

            {isLoading && (
              <div className="loader-container">
                <span className="loader"></span>
              </div>
            )}
            {CardData.length > 0 &&
              CardData?.map((item) => <Cards key={item._id} data={item} />)}

            {isProfileComlete &&
              noMatchFound &&
              isUserLoggedIn &&
              CardData.length == 0 && (
                <>
                  <div className="d-flex justify-content-center complete-profile">
                    <div className="text-primary border p-3 rounded-4 mx-1 ">
                      <h3 className="text-center text-dark  fs-4">
                        Thank you for completing your profile on Path2Study.{" "}
                        <br />
                        Currently, there are{" "}
                        <span className="text-primary">
                          no available loan options{" "}
                        </span>{" "}
                        based on your profile. Please try again later.
                      </h3>
                      <p className=" text-dark mt-5">
                        Note: Consider updating your profile for better matching
                        opportunities. Ignore this message if your profile
                        information is already updated.
                      </p>
                    </div>
                  </div>
                </>
              )}

            {!isProfileComlete && isUserLoggedIn && (
              <>
                <div className="text-center">
                  <div
                    className="d-flex justify-content-center text-center forMargin"
                    // style={{ marginTop: "16rem" }}
                  >
                    <div className="text-primary border p-5 rounded-4 mx-1">
                      <p className="fs-3">
                        {" "}
                        <span className="text-primary">
                          Kindly complete your profile to checkout loans curated
                          for you!
                        </span>
                      </p>
                      <Link
                        to="/user/userprofile"
                        className="text-decoration-underline fs-5 profile-link-button"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Complete Profile
                        <FaArrowRightLong
                          // style={{ verticalAlign: "bi" }}
                          className="ms-2 mt-1"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* Display side add bar desktop */}
          <div
            className="position-sticky hide-mobile "
            style={{ width: "30rem" }}
          >
            <div className="d-none d-md-flex align-items-center text-center">
              <div className=" card bg-light rounded-3 mb-5 border border-0">
                <div className="card-body ">
                  <span className="fs-2 text">
                    OUR{" "}
                    <span className="text-primary fw-semibold">NUMBERS</span>{" "}
                    ARE INTERESTING
                  </span>
                  <p className="card-text">
                    Explore array of loan options tailored <br /> to fund your
                    educational journey <br />
                    effortlessly.
                  </p>
                </div>
                <div className="row align-items-center justify-content-center">
                  <div className="col text-center">
                    <span className="fs-1 text text-primary">60+</span>
                    <p>
                      LOAN
                      <br />
                      OPTIONS
                    </p>
                  </div>

                  <div className="col text-center">
                    <span className="fs-1 text text-primary">20+</span>
                    <p>
                      LENDERS
                      <br />
                      COVERED
                    </p>
                  </div>

                  <div className="col text-center">
                    <span className="fs-1 text text-primary">24+</span>
                    <p>
                      COUNTRIES
                      <br />
                      COVERED
                    </p>
                  </div>
                </div>

                <img
                  src={require("../assests/img/loanAdd.png")}
                  className="img-fluid mt-0 ms-3"
                  alt="..."
                />
                <div className="m-3 ">
                  <span className="fs-2 text">
                    IT’S TIME FOR YOU TO WIN UPTO{" "}
                    <span className="text-primary fw-semibold">$12,500</span>
                  </span>
                  <br />
                  <span>
                    Get a chance to win upto $12,500 scholarship from
                    Path2Study. Yes, You read it right!
                  </span>
                  <Link
                    to="/pathstudyscholarship"
                    className="btn btn-outline-secondary w-100 mt-2"
                    onClick={handleClick}
                  >
                    Apply Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="my-5 ">
        {selectedCountry.toLowerCase() === "usa" ? (
          <Loan_AccordianUSA />
        ) : (
          <Loan_AccordianIND />
        )}
      </div>

      {/* Disclosure modal */}
      {CardData &&
        CardData.map((item, id) => {
          // console.log(item);
          return (
            <>
              <div key={item._id}>
                <div
                  className="modal fade"
                  id={`DisclosureModal-${item._id}`}
                  tabIndex="-1"
                  aria-labelledby={`DisclosureModalLabel${item._id}`}
                  aria-hidden="true"
                  key={`modal-${item._id}`}
                >
                  <div className="modal-dialog  modal-lg">
                    <div className="modal-content p-3">
                      {/* Show on laptop and hide on mobile */}
                      <div className="d-none d-lg-flex justify-content-between">
                        <h1
                          className="modal-title fs-4 ms-3 my-auto"
                          id={`DisclosureModalLabel-${item._id}`}
                        >
                          <p className="mt-4 text-center col-12 ">
                            {/* {item.bankName} */}
                            Disclosure
                          </p>
                        </h1>
                      </div>
                      {/* Hide on laptop and show on mobile */}
                      <div className="d-sm-flex d-md-none justify-content-center">
                        <h1
                          className="modal-title fs-4 col-12 my-auto mx-3 "
                          id={`DisclosureModalLabel-${item._id}`}
                        >
                          Disclosure
                        </h1>
                      </div>

                      {/* modal body */}
                      <div className="modal-body">{item.disclosure}</div>
                      <div className="modal-footer justify-content-center w-100">
                        <button
                          type="button"
                          className="btn btn-outline-secondary col-4 px-4"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}

      {/* Know more */}
      {CardData &&
        CardData.map((item, index) => {
          return (
            <>
              <div
                className="modal fade"
                id={`KnowmoreModal-${item._id}`}
                tabIndex="-1"
                aria-labelledby={`KnowmoreModalLabel-${item._id}`}
                aria-hidden="true"
                // key={item._id}
                key={`modal-${item._id}`}
              >
                <div className="modal-dialog  modal-lg">
                  <div className="modal-content p-3">
                    <div className="d-none d-lg-flex justify-content-between">
                      {/* <img
                        src={item.bankLogo}
                        className="col-md-3 p-1 col-6"
                        alt="..."
                      /> */}
                      {item.bankLogo ? (
                        <img
                          src={item.bankLogo}
                          // src={require("../assests/img/loanbank1.png")}
                          className="col-md-3 p-1 col-6 "
                          alt="..."
                          // style={{ marginTop: "5.5rem" }}
                        />
                      ) : (
                        <img
                          src={require("../assests/img/placeLoan.png")}
                          className="col-md-3 p-1 col-4"
                          alt="..."
                          // style={{ marginTop: "5.5rem" }}
                        />
                      )}
                      <h1
                        className="modal-title fs-4 col-8 my-auto"
                        id={`DisclosureModalLabel-${item._id}`}
                      >
                        <p className="mt-4 text-center col-6 ">
                          {item.bankName}
                        </p>
                      </h1>
                    </div>
                    {/* Hide on laptop and show on mobile */}
                    <div className="d-sm-flex d-md-none justify-content-center">
                      {/* <img
                        src={item.bankLogo}
                        className="col-md-3 p-1 col-12 "
                        alt="..."
                      /> */}
                      {item.bankLogo ? (
                        <img
                          src={item.bankLogo}
                          // src={require("../assests/img/loanbank1.png")}
                          className="col-md-3 p-1 col-12  "
                          alt="..."
                          // style={{ marginTop: "5.5rem" }}
                        />
                      ) : (
                        <img
                          src={require("../assests/img/placeLoan.png")}
                          className="col-md-3 p-1 col-12 "
                          alt="..."
                          // style={{ marginTop: "5.5rem" }}
                        />
                      )}
                      <h1
                        className="modal-title fs-4 col-12 my-auto mx-3 mt-3"
                        id={`DisclosureModalLabel-${item._id}`}
                      >
                        {item.bankName}
                      </h1>
                    </div>
                    <div className="modal-body">{item.aboutTheBank}</div>

                    <div className="modal-footer justify-content-center w-100">
                      <button
                        type="button"
                        className="btn btn-outline-secondary col-4 "
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}

      {/* Cultural Identity Popup  */}
      <CulturalIdentityPopup
        openmodalLoan={openmodalLoan}
        setopenmodalLoan={setopenmodalLoan}
        handleRadioChange={handleRadioChange}
        handleLoanReq={handleLoanReq}
        culturalIdentity={culturalIdentity}
      />
    </div>
  );
};

export default LoansPage_v1;
