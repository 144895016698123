import axios from "axios";
import "../style/hero.css";
import React, { useEffect, useState } from "react";
// import { API_BASE_URL } from "../appConfig";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/pagination";

const HeroSwiper = () => {
  const [banner, setBanner] = useState([]);
  // SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]); // Make sure to include Autoplay in SwiperCore

  useEffect(() => {
    const getBanners = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getbanners`);
        // const response = await axios.get(`${API_BASE_URL}/getbanners`);
        // server-dot-path2study.el.r.appspot.com/getbanners/
        // console.log("getbanner api response", response);
        setBanner(response.data);
        // console.log("banner of zero", banner.length);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getBanners();
  }, []);

  return (
    <div className="" style={{ marginTop: "5rem" ,marginBottom:"2rem"}}>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={0}
        autoplay={{ delay: 2500 }}
        slidesPerView={1}
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + ' bg-primary">.</span>'; // Modify the content to only show the bullet without the dot
          },
        }}
       
        // scrollbar={{
        //   hide: true,
        // }}
        // breakpoints={{
        //   280: { slidesPerView: 1 },
        //   350: { slidesPerView: 1 },
        //   480: { slidesPerView: 1 },
        //   576: { slidesPerView: 1 },
        //   768: { slidesPerView: 1 },
        //   992: { slidesPerView: 1 },
        //   1200: { slidesPerView:1 },
        //   1400: { slidesPerView:1 },
        // }}
      >
        <div className="d-flex flex-wrap">
          {banner.map((image, index) => (
            <SwiperSlide key={index}>
              <div className=" border rounded-5 border-1 ">
                <img
                  // style={{objectFit:'fill', }}
                  src={image}
                  className="img-fluid w-100 "
                  alt={`banner image ${index + 1}`}
                />
              </div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  );
};

export default HeroSwiper;
