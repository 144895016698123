import React, { useContext } from "react";
import "../style/OverlappingDesign.css";
// import bgImage from "../assests/img/blueimg.png"; // Adjust the path as necessary
// import mainImg from "../assests/img/overlapupdated.png"; // Adjust the path as necessary
import { useNavigate } from "react-router-dom";
import { ModalContext } from "../contexts/ModalContext";
import { UserContext } from "../contexts/UserContext";
import createImg from "../assests/img/create.png";
import create2Img from "../assests/img/create2.png";
import create3Img from "../assests/img/create3.png";

const HomeOverlap_v1 = () => {
  const { openModal } = useContext(ModalContext);
  const { isUserLoggedIn } = useContext(UserContext);

  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the desired URL path
    navigate("/path-to-navigate");
  };
  return (
    <>
      <div className="container">
        <span className="fs-3 text ">
          {/* DISCOVER YOUR <br /> IDEAL{" "}  */} DISCOVER YOUR IDEAL <br />{" "}
          <span className="fs-3 text fw-semibold text-primary">
            STUDENT LOAN
          </span>{" "}
          AND{" "}
        </span>
        <span className="fs-3 text fw-semibold text-primary">
          SCHOLARSHIP MATCH
        </span>{" "}
      </div>
      {/* <section>
        <div class="container-overlap ">
          <div class="left"></div>
          <div class="right ">
            <div class="content">
              <div className="text-container">
                <div className="text-box ">
                  <img
                    src={require("../assests/img/create.png")}
                    alt="2"
                    className="h-50"
                  />
                  <div>
                    {isUserLoggedIn ? (
                      <h2
                        className="text-primary cursor-pointer"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          navigate("/user/userprofile");
                        }}
                      >
                        Create Your Profile
                      </h2>
                    ) : (
                      <h2
                        className="ms-2 cursor-pointer text-primary"
                        onClick={openModal}
                      >
                        Create Your Profile
                      </h2>
                    )}
                    <span>
                    Build your academic persona by effortlessly sharing details, for personalized private student loan and scholarship matches tailored just for you.
                    </span>
                  </div>
                </div>
                <div className="text-box">
                  <img
                    src={require("../assests/img/create2.png")}
                    alt="2"
                    className="h-50"
                  />
                  <div className="">
                    {isUserLoggedIn ? (
                      <h2
                        className="text-primary cursor-pointer"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          navigate("/pathstudyscholarship");
                        }}
                      >
                        Access Tailored Student loan and Scholarship Matches
                      </h2>
                    ) : (
                      <h2
                        className="ms-2 cursor-pointer text-primary"
                        onClick={openModal}
                      >
                        Access Tailored Student loan and Scholarship Matches
                      </h2>
                    )}
                    <span>
                    Unlock a world of opportunities with instant access to Private Student loan options and scholarships tailored to your profile and aspirations.
                    </span>
                  </div>
                </div>
                <div className="text-box">
                  <img
                    src={require("../assests/img/create3.png")}
                    alt="2"
                    className="h-50"
                  />
                  <div>
                    {isUserLoggedIn ? (
                      <h2
                        className="text-primary cursor-pointer"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          navigate("/loan");
                        }}
                      >
                        Apply and get your education funded
                      </h2>
                    ) : (
                      <h2
                        className="ms-2 cursor-pointer text-primary"
                        onClick={openModal}
                      >
                        Apply and get your education funded
                      </h2>
                    )}
                    <span>
                    Take your educational adventure by applying for Student loan & scholarships and broadening your horizons with opportunities in numerous Schools and Universities.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="">
        <div className="container-overlap hide-card">
          <div className="left"></div>
          <div className="right">
            <div className="content">
              <div className="text-container">
                <div className="text-box">
                  <img src={createImg} alt="Create Profile" className="h-50" />
                  <div>
                    {isUserLoggedIn ? (
                      <h2
                        className="text-primary cursor-pointer"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          navigate("/user/userprofile");
                        }}
                      >
                        Create Your Profile
                      </h2>
                    ) : (
                      <h2
                        className="ms-2 cursor-pointer text-primary"
                        onClick={openModal}
                      >
                        Create Your Profile
                      </h2>
                    )}
                    <span className="ms-2">
                      Build your academic persona by effortlessly sharing
                      details, for personalized private student loan and
                      scholarship matches tailored just for you.
                    </span>
                  </div>
                </div>
                <div className="text-box">
                  <img
                    src={create2Img}
                    alt="Access Tailored Matches"
                    className="h-50"
                  />
                  <div className="">
                    {isUserLoggedIn ? (
                      <h2
                        className="text-primary cursor-pointer"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          navigate("/pathstudyscholarship");
                        }}
                      >
                        Access Tailored Student loan and Scholarship Matches
                      </h2>
                    ) : (
                      <h2
                        className="ms-2 cursor-pointer text-primary"
                        onClick={openModal}
                      >
                        Access Tailored Student loan and Scholarship Matches
                      </h2>
                    )}
                    <span className="ms-2">
                      Unlock a world of opportunities with instant access to
                      Private Student loan options and scholarships tailored to
                      your profile and aspirations.
                    </span>
                  </div>
                </div>
                <div className="text-box">
                  <img
                    src={create3Img}
                    alt="Apply and Get Funded"
                    className="h-50"
                  />
                  <div>
                    {isUserLoggedIn ? (
                      <h2
                        className="text-primary cursor-pointer"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          navigate("/loan");
                        }}
                      >
                        Apply and get your education funded
                      </h2>
                    ) : (
                      <h2
                        className="ms-2 cursor-pointer text-primary"
                        onClick={openModal}
                      >
                        Apply and get your education funded
                      </h2>
                    )}
                    <span className="ms-2">
                      Take your educational adventure by applying for Student
                      loan & scholarships and broadening your horizons with
                      opportunities in numerous Schools and Universities.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* New background overlay div */}
          <div className="background-overlay "></div>{" "}
        </div>
      </section>
      {/* for mobile */}
      <div className="hidden-card-mobile " style={{}}>
        <div className="mt-3 mb-3">
          <div className="text-center col-12 px-4 ">
            {" "}
            <img
              src={require("../assests/img/bluebox.png")}
              height={25}
              alt=""
              className="w-100 "
            />
          </div>

          <div className="align-top  px-4">
            <h5 className="text-primary d-flex align-items-center pt-2">
              <img
                src={require("../assests/img/create.png")}
                alt="2"
                className=""
              />
              {isUserLoggedIn ? (
                <span
                  className="ms-2 cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/user/userprofile");
                  }}
                >
                  Create Your Profile
                </span>
              ) : (
                <span className="ms-2 cursor-pointer" onClick={openModal}>
                  Create Your Profile
                </span>
              )}
            </h5>
            <p
              style={{ textAlign: "justify", paddingLeft: "2.5rem" }}
              className="mt-0 mx-2"
            >
              Build your academic persona by effortlessly sharing details, for
              personalized private student loan and scholarship matches tailored
              just for you.
            </p>

            <h5 className="text-primary d-flex align-items-center">
              <img
                src={require("../assests/img/create2.png")}
                alt="2"
                className="mr-2"
              />
              {/* Access Tailored Scholarship Matches */}
              {isUserLoggedIn ? (
                <span
                  className="ms-2 cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/pathstudyscholarship");
                  }}
                >
                  Access Tailored Student loan and Scholarship Matches
                </span>
              ) : (
                <span className="ms-2 cursor-pointer" onClick={openModal}>
                  Access Tailored Student loan and Scholarship Matches
                </span>
              )}
            </h5>
            <p
              style={{ textAlign: "justify", paddingLeft: "2.5rem" }}
              className="mt-0 mx-2"
            >
              Unlock a world of opportunities with instant access to Private
              Student loan options and scholarships tailored to your profile and
              aspirations.
            </p>

            <h5 className="text-primary d-flex align-items-center">
              <img
                src={require("../assests/img/create3.png")}
                alt="2"
                className="mr-2"
              />
              {/* Apply. Get High School Funding */}
              {isUserLoggedIn ? (
                <span
                  className="ms-2 cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/loan");
                  }}
                >
                  Apply and get your education funded
                </span>
              ) : (
                <span className="ms-2 cursor-pointer" onClick={openModal}>
                  Apply and get your education funded
                </span>
              )}
            </h5>
            <p
              style={{ textAlign: "justify", paddingLeft: "2.5rem" }}
              className="mt-0 mx-2"
            >
              Take your educational adventure by applying for Student loan &
              scholarships and broadening your horizons with opportunities in
              numerous Schools and Universities.
            </p>
          </div>
          <div className="text-center col-12 px-4 ">
            {" "}
            <img
              src={require("../assests/img/bluebox.png")}
              height={25}
              alt=""
              className="w-100"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeOverlap_v1;
