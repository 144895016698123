import React from "react";
import'../style/Faqs.css'
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "react-bootstrap/Accordion";

function Faqs() {
  return (
    <div className="container mt-5">
      <h2 className="text-primary" id="faqsSection">
      FREQUENTLY ASKED QUESTIONS (FAQs)
      </h2>
      <p style={{textAlign:"justify"}} className="mb-5">
        Explore our comprehensive FAQ section to find quick answers to common
        queries. We've curated a resource that addresses key concerns, ensuring
        clarity and guiding you seamlessly through your Path2Study experience.
      </p>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            How do I apply for a student loan on your platform?
          </Accordion.Header>
          <Accordion.Body style={{textAlign:"justify"}}  className="fw-light">
            Applying for a student loan is easy! Navigate to the "Loan Section,"
            choose your preferred loan option, and follow the guided application
            process provided by our trusted lending partners.
          </Accordion.Body>
        </Accordion.Item>
        <span className="my-2"></span>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Can I apply for multiple student loans simultaneously?
          </Accordion.Header>
          <Accordion.Body style={{textAlign:"justify"}}  className="fw-light">
            Absolutely! Our platform allows you to explore and apply for
            multiple student loans, providing flexibility and increasing your
            chances of securing the funding you need for your education.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            What types of student loans are available through your platform?
          </Accordion.Header>
          <Accordion.Body style={{textAlign:"justify"}}  className="fw-light">
            We offer a variety of student loan options, including private
            funding from both domestic and overseas lenders. Explore the "Loan
            Section" to find the perfect financing solution for your academic
            journey.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            Is my personal and financial information secure when applying for
            loans?
          </Accordion.Header>
          <Accordion.Body style={{textAlign:"justify"}}  className="fw-light">
            Yes, absolutely. We prioritize the security of your information. Our
            platform employs advanced encryption and follows stringent privacy
            measures to safeguard your personal and financial data.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            How can I get assistance if I encounter issues during the loan
            application process?
          </Accordion.Header>
          <Accordion.Body style={{textAlign:"justify"}}  className="fw-light">
            If you face any challenges or have questions while applying for a
            student loan, our dedicated support team is ready to assist. Contact
            us through the "Contact Us" page, and we'll ensure a swift
            resolution to your queries.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            How can I create my academic profile on Path2Study?
          </Accordion.Header>
          <Accordion.Body style={{textAlign:"justify"}}  className="fw-light">
            Creating your academic profile is simple. Click on the "Create
            Profile" button, fill in the required details, and embark on your
            journey to explore tailored scholarship opportunities.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            How are scholarships on Path2Study verified?
          </Accordion.Header>
          <Accordion.Body style={{textAlign:"justify"}}  className="fw-light">
            We prioritize authenticity. Each scholarship undergoes a meticulous
            verification process, ensuring that you have access to legitimate
            opportunities that align with your academic goals
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            Can I apply for multiple scholarships at once?
          </Accordion.Header>
          <Accordion.Body style={{textAlign:"justify"}}  className="fw-light">
            Absolutely! Path2Study allows you to apply for multiple scholarships
            simultaneously, streamlining the application process and maximizing
            your chances of securing financial assistance
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>
            {" "}
            How does Path2Study ensure the security of my personal information?
          </Accordion.Header>
          <Accordion.Body style={{textAlign:"justify"}}  className="fw-light">
            Your privacy is our priority. Path2Study employs state-of-the-art
            encryption and privacy measures, providing a secure and confidential
            environment for all your personal and academic information
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default Faqs;