
export function formatAmount(amount, decimalPlaces = 0) {
    if (amount > 1000) {
        // Convert amount to K format if greater than 1000
        // const formattedAmount = (amount / 1000).toLocaleString('en-US', {
        //     minimumFractionDigits: decimalPlaces,
        //     maximumFractionDigits: decimalPlaces,
        // });
        // return formattedAmount + '0';
        return amount?.toLocaleString('en-IN');
    } else {
        // Otherwise, display the amount as it is
        // return amount.toLocaleString('en-US', {
        //     minimumFractionDigits: decimalPlaces,
        //     maximumFractionDigits: decimalPlaces,
        // });
        return amount?.toLocaleString('en-IN');
    }
}
